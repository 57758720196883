const validNationalIdPrefixes = [
	"105",
	"106",
	"108",
	"109",
	"110",
	"111",
	"112",
	"113",
	"114",
	"115",
	"116",
	"117",
	"118",
	"119",
	"120",
	"121",
	"122",
	"123",
	"124",
	"125",
	"126",
	"127",
	"128",
	"129",
	"136",
	"137",
	"138",
	"145",
	"146",
	"149",
	"150",
	"152",
	"153",
	"154",
	"155",
	"158",
	"159",
	"160",
	"161",
	"162",
	"163",
	"164",
	"165",
	"166",
	"167",
	"168",
	"169",
	"170",
	"171",
	"172",
	"173",
	"174",
	"175",
	"181",
	"182",
	"183",
	"184",
	"185",
	"186",
	"187",
	"188",
	"189",
	"190",
	"191",
	"192",
	"193",
	"194",
	"195",
	"196",
	"197",
	"198",
	"199",
	"200",
	"202",
	"203",
	"205",
	"206",
	"208",
	"209",
	"211",
	"212",
	"213",
	"214",
	"215",
	"216",
	"217",
	"218",
	"219",
	"220",
	"221",
	"222",
	"223",
	"224",
	"225",
	"226",
	"227",
	"228",
	"229",
	"230",
	"236",
	"237",
	"238",
	"239",
	"240",
	"241",
	"242",
	"243",
	"244",
	"245",
	"246",
	"247",
	"248",
	"249",
	"250",
	"251",
	"252",
	"253",
	"255",
	"256",
	"257",
	"258",
	"259",
	"261",
	"262",
	"263",
	"264",
	"265",
	"266",
	"267",
	"268",
	"269",
	"270",
	"271",
	"272",
	"273",
	"274",
	"275",
	"279",
	"280",
	"282",
	"283",
	"284",
	"285",
	"286",
	"287",
	"288",
	"289",
	"290",
	"291",
	"292",
	"293",
	"294",
	"295",
	"296",
	"297",
	"298",
	"299",
	"302",
	"303",
	"304",
	"305",
	"306",
	"307",
	"308",
	"309",
	"310",
	"311",
	"312",
	"313",
	"314",
	"315",
	"316",
	"317",
	"318",
	"319",
	"320",
	"321",
	"322",
	"323",
	"324",
	"325",
	"330",
	"331",
	"332",
	"333",
	"334",
	"335",
	"336",
	"337",
	"338",
	"339",
	"341",
	"342",
	"343",
	"344",
	"345",
	"346",
	"348",
	"349",
	"350",
	"351",
	"352",
	"353",
	"354",
	"355",
	"356",
	"357",
	"358",
	"359",
	"361",
	"362",
	"364",
	"365",
	"366",
	"367",
	"369",
	"370",
	"371",
	"372",
	"373",
	"375",
	"376",
	"377",
	"378",
	"379",
	"380",
	"381",
	"382",
	"383",
	"384",
	"385",
	"386",
	"387",
	"392",
	"393",
	"394",
	"395",
	"396",
	"397",
	"398",
	"399",
	"400",
	"401",
	"402",
	"403",
	"404",
	"405",
	"406",
	"407",
	"412",
	"413",
	"416",
	"417",
	"418",
	"419",
	"420",
	"421",
	"422",
	"423",
	"424",
	"425",
	"426",
	"427",
	"428",
	"431",
	"432",
	"438",
	"439",
	"442",
	"443",
	"444",
	"445",
	"446",
	"447",
	"448",
	"449",
	"450",
	"451",
	"452",
	"453",
	"454",
	"455",
	"456",
	"457",
	"458",
	"459",
	"460",
	"461",
	"462",
	"465",
	"466",
	"467",
	"468",
	"469",
	"470",
	"481",
	"483",
	"484",
	"485",
	"486",
	"487",
	"488",
	"489",
	"490",
	"491",
	"492",
	"493",
	"496",
	"497",
	"498",
	"499",
	"500",
	"501",
	"502",
	"503",
	"504",
	"505",
	"506",
	"507",
	"508",
	"509",
	"510",
	"511",
	"512",
	"513",
	"514",
	"515",
	"516",
	"517",
	"518",
	"519",
	"520",
	"521",
	"522",
	"523",
	"524",
	"525",
	"526",
	"527",
	"528",
	"529",
	"530",
	"531",
	"532",
	"533",
	"534",
	"535",
	"536",
	"537",
	"538",
	"539",
	"540",
	"541",
	"542",
	"543",
	"544",
	"545",
	"546",
	"547",
	"548",
	"549",
	"550",
	"551",
	"552",
	"553",
	"554",
	"555",
	"556",
	"557",
	"558",
	"559",
	"561",
	"562",
	"563",
	"564",
	"565",
	"566",
	"567",
	"568",
	"569",
	"570",
	"571",
	"572",
	"573",
	"574",
	"575",
	"576",
	"577",
	"578",
	"579",
	"580",
	"581",
	"582",
	"583",
	"584",
	"585",
	"586",
	"588",
	"590",
	"591",
	"592",
	"593",
	"595",
	"596",
	"597",
	"598",
	"599",
	"600",
	"601",
	"603",
	"604",
	"605",
	"606",
	"607",
	"608",
	"609",
	"610",
	"611",
	"612",
	"613",
	"615",
	"616",
	"617",
	"618",
	"619",
	"620",
	"621",
	"622",
	"623",
	"625",
	"626",
	"627",
	"628",
	"629",
	"630",
	"631",
	"632",
	"633",
	"634",
	"635",
	"636",
	"637",
	"638",
	"640",
	"641",
	"642",
	"643",
	"644",
	"646",
	"647",
	"648",
	"649",
	"650",
	"651",
	"652",
	"653",
	"654",
	"655",
	"656",
	"657",
	"658",
	"659",
	"660",
	"661",
	"662",
	"663",
	"664",
	"665",
	"666",
	"667",
	"668",
	"669",
	"670",
	"671",
	"673",
	"674",
	"675",
	"676",
	"677",
	"678",
	"679",
	"680",
	"681",
	"682",
	"683",
	"684",
	"685",
	"686",
	"687",
	"688",
	"689",
	"690",
	"691",
	"692",
	"693",
	"694",
	"695",
	"696",
	"697",
	"698",
	"699",
	"700",
	"701",
	"702",
	"703",
	"704",
	"705",
	"706",
	"707",
	"711",
	"712",
	"713",
	"714",
	"715",
	"716",
	"717",
	"718",
	"719",
	"720",
	"721",
	"722",
	"723",
	"724",
	"725",
	"726",
	"727",
	"728",
	"729",
	"730",
	"731",
	"732",
	"001",
	"002",
	"003",
	"004",
	"005",
	"006",
	"007",
	"008",
	"011",
	"015",
	"020",
	"025",
	"031",
	"032",
	"037",
	"038",
	"041",
	"042",
	"043",
	"044",
	"045",
	"048",
	"049",
	"051",
	"052",
	"053",
	"055",
	"056",
	"057",
	"058",
	"059",
	"060",
	"061",
	"062",
	"063",
	"064",
	"065",
	"067",
	"068",
	"069",
	"070",
	"072",
	"073",
	"074",
	"075",
	"076",
	"077",
	"078",
	"079",
	"081",
	"082",
	"083",
	"084",
	"085",
	"086",
	"087",
	"088",
	"089",
	"090",
	"091",
	"092",
	"093",
	"094",
	"096",
	"097",
	"098",
];

/**
 * Check National-id validation
 * @category National id
 * @method verifyIranianNationalId
 * @param  {String?}          nationalId [String of national id - like this: 0018465986]
 * @return {Boolean}                    [valid or no]
 */
function verifyIranianNationalId(nationalId?: string | number): boolean | undefined {
	if (!nationalId) return;
	let code = nationalId.toString();
	const codeLength = code.length;
	const notAllowedDigits = {
		"0000000000": true,
		"1111111111": true,
		"2222222222": true,
		"3333333333": true,
		"4444444444": true,
		"5555555555": true,
		"6666666666": true,
		"7777777777": true,
		"8888888888": true,
		"9999999999": true,
	};

	if (code in notAllowedDigits) return false;
	if (codeLength < 8 || codeLength > 10) return false;
	code = ("00" + code).substring(codeLength + 2 - 10);
	if (+code.substring(3, 9) === 0) return false;
	const prefix = code.substring(0, 3);
	if (validNationalIdPrefixes.indexOf(prefix) === -1) return false;
	const lastNumber = +code.substring(9);
	let sum = 0;

	for (let i = 0; i < 9; i++) {
		sum += +code.substring(i, i + 1) * (10 - i);
	}

	sum = sum % 11;

	return (sum < 2 && lastNumber === sum) || (sum >= 2 && lastNumber === 11 - sum);
}

export default verifyIranianNationalId;
