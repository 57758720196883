export const GeoJSONData = {
	type: "FeatureCollection",
	name: "gadm41_IRN_1",
	crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
	features: [
		{
			properties: { "name:en": "Alborz", name: "البرز" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[51.1161, 35.733],
							[51.1904, 35.7057],
							[51.1525, 35.6861],
							[51.1265, 35.6619],
							[51.1161, 35.6462],
							[51.1161, 35.6126],
							[51.1057, 35.615],
							[51.0849, 35.5838],
							[51.0762, 35.5786],
							[51.0467, 35.5786],
							[51.0224, 35.5855],
							[50.9895, 35.6011],
							[50.9617, 35.6098],
							[50.9374, 35.6098],
							[50.8958, 35.5994],
							[50.8611, 35.5769],
							[50.8539, 35.5534],
							[50.8317, 35.5709],
							[50.8193, 35.5904],
							[50.8062, 35.6299],
							[50.7941, 35.6394],
							[50.7706, 35.6384],
							[50.7592, 35.6279],
							[50.7596, 35.618],
							[50.7723, 35.5885],
							[50.7727, 35.5785],
							[50.7609, 35.578],
							[50.7495, 35.5675],
							[50.7143, 35.5661],
							[50.7149, 35.5461],
							[50.7035, 35.5356],
							[50.6793, 35.5546],
							[50.6904, 35.575],
							[50.6901, 35.585],
							[50.7008, 35.6155],
							[50.6992, 35.6653],
							[50.6874, 35.6648],
							[50.6754, 35.6743],
							[50.675, 35.6842],
							[50.581, 35.68],
							[50.5695, 35.6695],
							[50.5698, 35.6596],
							[50.5819, 35.6501],
							[50.5943, 35.6308],
							[50.6185, 35.6119],
							[50.6189, 35.6019],
							[50.6309, 35.5925],
							[50.6195, 35.582],
							[50.6198, 35.572],
							[50.5963, 35.571],
							[50.5842, 35.5804],
							[50.5839, 35.5904],
							[50.5596, 35.6092],
							[50.5472, 35.6286],
							[50.5354, 35.6281],
							[50.5112, 35.6469],
							[50.4873, 35.6557],
							[50.405, 35.6419],
							[50.3691, 35.6501],
							[50.3099, 35.6473],
							[50.2856, 35.6661],
							[50.2853, 35.676],
							[50.2484, 35.7142],
							[50.2359, 35.7336],
							[50.235, 35.7635],
							[50.2227, 35.7729],
							[50.2224, 35.7829],
							[50.234, 35.7934],
							[50.2334, 35.8134],
							[50.2565, 35.8345],
							[50.28, 35.8456],
							[50.2915, 35.8561],
							[50.3034, 35.8566],
							[50.3501, 35.8788],
							[50.3855, 35.8804],
							[50.4088, 35.8915],
							[50.4203, 35.902],
							[50.4553, 35.9136],
							[50.4667, 35.9241],
							[50.502, 35.9257],
							[50.5252, 35.9367],
							[50.5366, 35.9472],
							[50.5354, 35.9772],
							[50.5573, 36.0181],
							[50.5569, 36.0281],
							[50.5675, 36.0586],
							[50.5788, 36.0691],
							[50.6005, 36.11],
							[50.6343, 36.1415],
							[50.633, 36.1714],
							[50.6209, 36.1809],
							[50.6205, 36.1909],
							[50.5967, 36.1999],
							[50.5733, 36.1989],
							[50.5144, 36.2064],
							[50.5027, 36.2059],
							[50.5023, 36.2159],
							[50.4784, 36.2249],
							[50.4659, 36.2445],
							[50.4654, 36.2545],
							[50.4532, 36.264],
							[50.4293, 36.273],
							[50.4176, 36.2725],
							[50.4054, 36.282],
							[50.404, 36.3121],
							[50.4265, 36.3331],
							[50.4499, 36.3341],
							[50.4962, 36.346],
							[50.5545, 36.3484],
							[50.5783, 36.3394],
							[50.59, 36.3398],
							[50.6604, 36.3327],
							[50.6842, 36.3237],
							[50.7191, 36.3252],
							[50.7661, 36.3172],
							[50.8242, 36.3197],
							[50.8596, 36.3112],
							[50.8717, 36.3017],
							[50.8833, 36.3022],
							[50.907, 36.2932],
							[50.9535, 36.2952],
							[50.9771, 36.2862],
							[51.012, 36.2876],
							[51.0124, 36.2776],
							[51.0241, 36.2781],
							[51.0482, 36.2591],
							[51.0612, 36.2297],
							[51.0728, 36.2302],
							[51.0969, 36.2112],
							[51.1317, 36.2126],
							[51.1438, 36.2032],
							[51.2134, 36.2059],
							[51.2255, 36.1964],
							[51.2728, 36.1783],
							[51.2843, 36.1787],
							[51.3196, 36.17],
							[51.3312, 36.1704],
							[51.3553, 36.1513],
							[51.359, 36.0715],
							[51.3706, 36.0719],
							[51.371, 36.0619],
							[51.4318, 36.0638],
							[51.4318, 36.04],
							[51.411, 36.0175],
							[51.3798, 36.0036],
							[51.3641, 36.0018],
							[51.3589, 35.9845],
							[51.3589, 35.9602],
							[51.3676, 35.9481],
							[51.3832, 35.9325],
							[51.3881, 35.9134],
							[51.3416, 35.9134],
							[51.3086, 35.9151],
							[51.2705, 35.9446],
							[51.2497, 35.9515],
							[51.2046, 35.9515],
							[51.1785, 35.9394],
							[51.149, 35.9064],
							[51.1126, 35.8787],
							[51.0883, 35.8648],
							[51.0693, 35.8475],
							[51.0606, 35.8371],
							[51.0623, 35.811],
							[51.0745, 35.7885],
							[51.0866, 35.7746],
							[51.0987, 35.7521],
							[51.1161, 35.733],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Ardebil", name: "اردبیل" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[48.5053, 38.0251],
							[48.4899, 38.0152],
							[48.4866, 37.996],
							[48.4989, 37.9867],
							[48.4973, 37.9771],
							[48.4822, 37.9669],
							[48.4808, 37.957],
							[48.4364, 37.9243],
							[48.4351, 37.903],
							[48.4483, 37.8826],
							[48.4618, 37.8729],
							[48.4768, 37.8525],
							[48.4781, 37.8418],
							[48.4919, 37.8321],
							[48.4998, 37.7681],
							[48.5124, 37.7691],
							[48.5164, 37.737],
							[48.5317, 37.7166],
							[48.5488, 37.6855],
							[48.5519, 37.6642],
							[48.5949, 37.6349],
							[48.5984, 37.6136],
							[48.6168, 37.5839],
							[48.6315, 37.5748],
							[48.6505, 37.5465],
							[48.6527, 37.5369],
							[48.6675, 37.528],
							[48.6845, 37.5097],
							[48.6867, 37.5002],
							[48.7015, 37.4914],
							[48.7037, 37.4819],
							[48.7185, 37.4731],
							[48.7207, 37.4635],
							[48.7376, 37.4451],
							[48.8566, 37.3743],
							[48.8737, 37.356],
							[48.8759, 37.3465],
							[48.8654, 37.3365],
							[48.8721, 37.3081],
							[48.8594, 37.3075],
							[48.866, 37.2791],
							[48.8555, 37.2691],
							[48.8662, 37.2217],
							[48.8825, 37.2028],
							[48.8572, 37.2017],
							[48.8281, 37.2198],
							[48.8261, 37.2294],
							[48.8113, 37.2383],
							[48.7732, 37.2363],
							[48.7753, 37.2269],
							[48.7433, 37.1963],
							[48.718, 37.195],
							[48.6738, 37.2216],
							[48.6485, 37.2203],
							[48.6506, 37.2109],
							[48.642, 37.1912],
							[48.6492, 37.1525],
							[48.5988, 37.15],
							[48.57, 37.1681],
							[48.5493, 37.2054],
							[48.5322, 37.2235],
							[48.5174, 37.2322],
							[48.4668, 37.2293],
							[48.4563, 37.2192],
							[48.4437, 37.2185],
							[48.4246, 37.246],
							[48.3677, 37.2712],
							[48.313, 37.287],
							[48.2563, 37.3122],
							[48.227, 37.3295],
							[48.181, 37.3648],
							[48.1833, 37.4128],
							[48.1627, 37.4499],
							[48.1402, 37.4966],
							[48.0737, 37.5116],
							[48.0219, 37.5178],
							[47.9576, 37.5231],
							[47.9414, 37.5414],
							[47.9179, 37.598],
							[47.9029, 37.6879],
							[47.9178, 37.7758],
							[47.9634, 37.8228],
							[47.9739, 37.8452],
							[47.9355, 37.8528],
							[47.9345, 37.8635],
							[47.8943, 37.8924],
							[47.8419, 37.9203],
							[47.8144, 37.9502],
							[47.7746, 37.979],
							[47.7728, 38.0003],
							[47.7579, 38.0313],
							[47.7544, 38.074],
							[47.7406, 38.1158],
							[47.7498, 38.1999],
							[47.7274, 38.2285],
							[47.6799, 38.2654],
							[47.6546, 38.2739],
							[47.4765, 38.2322],
							[47.4117, 38.237],
							[47.3733, 38.2442],
							[47.3742, 38.255],
							[47.3513, 38.2849],
							[47.3393, 38.2943],
							[47.3127, 38.2921],
							[47.2467, 38.2428],
							[47.2214, 38.2511],
							[47.2082, 38.2822],
							[47.2225, 38.305],
							[47.2249, 38.3265],
							[47.2141, 38.3464],
							[47.2154, 38.3566],
							[47.2301, 38.3677],
							[47.2717, 38.3803],
							[47.2777, 38.4198],
							[47.3097, 38.4505],
							[47.3267, 38.4707],
							[47.3675, 38.4728],
							[47.4015, 38.5129],
							[47.4315, 38.543],
							[47.4713, 38.5449],
							[47.5103, 38.537],
							[47.5367, 38.5382],
							[47.5757, 38.5303],
							[47.5883, 38.5213],
							[47.629, 38.5516],
							[47.63, 38.59],
							[47.5787, 38.6168],
							[47.5271, 38.6338],
							[47.5143, 38.6428],
							[47.5016, 38.6616],
							[47.5668, 38.684],
							[47.5926, 38.7045],
							[47.6052, 38.7245],
							[47.5911, 38.7628],
							[47.5892, 38.8016],
							[47.5482, 38.8387],
							[47.521, 38.8568],
							[47.5195, 38.8763],
							[47.5299, 38.906],
							[47.5413, 38.9256],
							[47.5269, 38.9441],
							[47.5254, 38.9629],
							[47.4986, 38.9714],
							[47.497, 38.9902],
							[47.5213, 39.0099],
							[47.5326, 39.0291],
							[47.5425, 39.058],
							[47.526, 39.0864],
							[47.5104, 39.1054],
							[47.4961, 39.1145],
							[47.4803, 39.1335],
							[47.4743, 39.1726],
							[47.4585, 39.1915],
							[47.4667, 39.2303],
							[47.4565, 39.3157],
							[47.4261, 39.3521],
							[47.3991, 39.3601],
							[47.3825, 39.388],
							[47.3454, 39.4112],
							[47.3734, 39.4406],
							[47.3849, 39.4576],
							[47.4044, 39.4628],
							[47.4255, 39.4638],
							[47.4781, 39.4814],
							[47.5059, 39.4881],
							[47.5419, 39.4996],
							[47.5679, 39.5128],
							[47.599, 39.5338],
							[47.6208, 39.5457],
							[47.7419, 39.598],
							[47.8078, 39.6501],
							[47.8356, 39.6589],
							[47.8997, 39.6549],
							[47.9122, 39.6598],
							[47.929, 39.6627],
							[47.947, 39.67],
							[47.9758, 39.6864],
							[47.985, 39.6945],
							[47.9908, 39.6963],
							[48.0055, 39.6807],
							[48.0587, 39.6423],
							[48.076, 39.6232],
							[48.0842, 39.6114],
							[48.1003, 39.5963],
							[48.1249, 39.5773],
							[48.1483, 39.5636],
							[48.1779, 39.5183],
							[48.1935, 39.5019],
							[48.2337, 39.4703],
							[48.2584, 39.4533],
							[48.2669, 39.4447],
							[48.2845, 39.4333],
							[48.298, 39.4207],
							[48.3191, 39.4089],
							[48.353, 39.3825],
							[48.3746, 39.3708],
							[48.3373, 39.3636],
							[48.3256, 39.3573],
							[48.3149, 39.3577],
							[48.3076, 39.3524],
							[48.291, 39.3475],
							[48.2703, 39.3377],
							[48.265, 39.3386],
							[48.265, 39.3336],
							[48.2587, 39.3297],
							[48.2442, 39.3256],
							[48.2332, 39.3268],
							[48.2185, 39.3213],
							[48.2131, 39.323],
							[48.2079, 39.3217],
							[48.1967, 39.3151],
							[48.1936, 39.3123],
							[48.1919, 39.3072],
							[48.1799, 39.3042],
							[48.1717, 39.298],
							[48.1653, 39.2976],
							[48.1529, 39.2871],
							[48.1496, 39.28],
							[48.1441, 39.2756],
							[48.1427, 39.27],
							[48.1348, 39.2686],
							[48.1318, 39.2648],
							[48.1329, 39.2541],
							[48.1296, 39.2403],
							[48.1355, 39.224],
							[48.1402, 39.2193],
							[48.1365, 39.2105],
							[48.1402, 39.2074],
							[48.1404, 39.204],
							[48.1637, 39.1876],
							[48.1921, 39.1738],
							[48.2025, 39.1707],
							[48.2184, 39.1607],
							[48.2178, 39.1559],
							[48.2235, 39.1467],
							[48.2251, 39.139],
							[48.2289, 39.1347],
							[48.2384, 39.1275],
							[48.2461, 39.1261],
							[48.2561, 39.1214],
							[48.2777, 39.1185],
							[48.2865, 39.1132],
							[48.298, 39.1115],
							[48.3014, 39.1089],
							[48.3062, 39.0891],
							[48.319, 39.08],
							[48.3401, 39.06],
							[48.3578, 39.0405],
							[48.3646, 39.0305],
							[48.3673, 39.0249],
							[48.3691, 39.0149],
							[48.3687, 39.0107],
							[48.3637, 39.0014],
							[48.3534, 38.9932],
							[48.3239, 38.9811],
							[48.2917, 38.965],
							[48.2757, 38.9625],
							[48.2467, 38.9614],
							[48.2008, 38.953],
							[48.1624, 38.952],
							[48.1412, 38.9443],
							[48.1093, 38.9428],
							[48.0908, 38.9384],
							[48.0866, 38.9361],
							[48.0828, 38.9314],
							[48.0804, 38.9137],
							[48.075, 38.9073],
							[48.0625, 38.9043],
							[48.0365, 38.9034],
							[48.0275, 38.9054],
							[48.0202, 38.9089],
							[48.0165, 38.9076],
							[48.0135, 38.9046],
							[48.0091, 38.8787],
							[48.0135, 38.8562],
							[48.0177, 38.8452],
							[48.0366, 38.8258],
							[48.0457, 38.8126],
							[48.05, 38.8089],
							[48.091, 38.7872],
							[48.104, 38.7824],
							[48.1119, 38.7729],
							[48.1121, 38.7694],
							[48.116, 38.7678],
							[48.1301, 38.7669],
							[48.1593, 38.7559],
							[48.1874, 38.7518],
							[48.1973, 38.7476],
							[48.2145, 38.7354],
							[48.2496, 38.7216],
							[48.2515, 38.7194],
							[48.2532, 38.7106],
							[48.2585, 38.7018],
							[48.2529, 38.6929],
							[48.2515, 38.6873],
							[48.2552, 38.6614],
							[48.2633, 38.6534],
							[48.2863, 38.6451],
							[48.2942, 38.6406],
							[48.3007, 38.6301],
							[48.3033, 38.6165],
							[48.3085, 38.608],
							[48.3157, 38.6014],
							[48.353, 38.5931],
							[48.3594, 38.5934],
							[48.3737, 38.598],
							[48.3902, 38.6012],
							[48.4039, 38.6012],
							[48.423, 38.6095],
							[48.4325, 38.6115],
							[48.4434, 38.61],
							[48.4573, 38.6032],
							[48.461, 38.5997],
							[48.4614, 38.5972],
							[48.4613, 38.5711],
							[48.4688, 38.5571],
							[48.4747, 38.5525],
							[48.4935, 38.5507],
							[48.5036, 38.5476],
							[48.5087, 38.5396],
							[48.5088, 38.5327],
							[48.5164, 38.519],
							[48.5201, 38.5155],
							[48.5352, 38.5073],
							[48.5526, 38.4892],
							[48.5744, 38.4712],
							[48.5855, 38.4562],
							[48.5942, 38.4359],
							[48.6024, 38.4204],
							[48.6068, 38.4154],
							[48.6072, 38.3991],
							[48.5942, 38.3892],
							[48.6074, 38.3799],
							[48.66, 38.3619],
							[48.6733, 38.3526],
							[48.6732, 38.3238],
							[48.6863, 38.3145],
							[48.6861, 38.3049],
							[48.7262, 38.3154],
							[48.7528, 38.3064],
							[48.7662, 38.2971],
							[48.766, 38.2875],
							[48.7526, 38.2872],
							[48.7524, 38.2776],
							[48.7245, 38.2579],
							[48.6968, 38.2478],
							[48.6666, 38.2185],
							[48.6515, 38.2087],
							[48.65, 38.1991],
							[48.6306, 38.1702],
							[48.6147, 38.1603],
							[48.5651, 38.1213],
							[48.5474, 38.102],
							[48.5455, 38.0924],
							[48.5298, 38.0826],
							[48.5279, 38.0731],
							[48.514, 38.0728],
							[48.5053, 38.0251],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Bushehr", name: "بوشهر" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[52.6704, 27.4607],
							[52.6738, 27.4599],
							[52.6746, 27.456],
							[52.6732, 27.4585],
							[52.6699, 27.4601],
							[52.6704, 27.4607],
						],
					],
					[
						[
							[52.1807, 28.182],
							[52.181, 28.1675],
							[52.2036, 28.1482],
							[52.2147, 28.1483],
							[52.2373, 28.1289],
							[52.2812, 28.1388],
							[52.2815, 28.1291],
							[52.304, 28.1098],
							[52.3043, 28.1001],
							[52.3266, 28.0904],
							[52.3381, 28.071],
							[52.3609, 28.042],
							[52.3947, 28.0129],
							[52.4179, 27.9644],
							[52.4296, 27.9353],
							[52.4523, 27.9062],
							[52.4528, 27.8868],
							[52.4762, 27.8287],
							[52.4877, 27.8094],
							[52.4994, 27.7805],
							[52.5107, 27.7708],
							[52.5109, 27.7612],
							[52.5224, 27.742],
							[52.5342, 27.7134],
							[52.5572, 27.6752],
							[52.58, 27.6464],
							[52.6025, 27.6271],
							[52.6247, 27.6171],
							[52.6584, 27.5881],
							[52.7478, 27.5293],
							[52.7927, 27.4905],
							[52.8258, 27.4802],
							[52.8819, 27.4316],
							[52.9263, 27.4115],
							[52.9597, 27.3918],
							[52.9709, 27.3821],
							[52.9384, 27.3641],
							[52.9168, 27.3555],
							[52.8621, 27.3575],
							[52.829, 27.3681],
							[52.7962, 27.3695],
							[52.7419, 27.363],
							[52.7201, 27.3642],
							[52.7095, 27.3558],
							[52.6986, 27.3565],
							[52.6729, 27.3129],
							[52.6651, 27.3193],
							[52.6599, 27.3185],
							[52.6601, 27.3218],
							[52.6554, 27.324],
							[52.6538, 27.3276],
							[52.6465, 27.3326],
							[52.6338, 27.3496],
							[52.6265, 27.3515],
							[52.6237, 27.3504],
							[52.624, 27.3479],
							[52.6179, 27.349],
							[52.6154, 27.3549],
							[52.5996, 27.366],
							[52.5929, 27.3729],
							[52.584, 27.3757],
							[52.5818, 27.3801],
							[52.5771, 27.3824],
							[52.5765, 27.3857],
							[52.5774, 27.3901],
							[52.5807, 27.3929],
							[52.6079, 27.4013],
							[52.6393, 27.4029],
							[52.6488, 27.4001],
							[52.6526, 27.3951],
							[52.6613, 27.3943],
							[52.6662, 27.3985],
							[52.671, 27.4057],
							[52.676, 27.4212],
							[52.6774, 27.444],
							[52.6746, 27.4496],
							[52.676, 27.4543],
							[52.6754, 27.4588],
							[52.6738, 27.4604],
							[52.6688, 27.4618],
							[52.6649, 27.461],
							[52.671, 27.4593],
							[52.6685, 27.459],
							[52.6749, 27.4549],
							[52.6738, 27.4521],
							[52.6557, 27.459],
							[52.6482, 27.4588],
							[52.6432, 27.4543],
							[52.641, 27.4543],
							[52.6365, 27.459],
							[52.6193, 27.461],
							[52.6085, 27.469],
							[52.6043, 27.4751],
							[52.6024, 27.4757],
							[52.6021, 27.4813],
							[52.5865, 27.4929],
							[52.5838, 27.4926],
							[52.5749, 27.5079],
							[52.5718, 27.519],
							[52.5685, 27.5224],
							[52.5693, 27.5238],
							[52.5668, 27.5249],
							[52.5621, 27.5324],
							[52.5554, 27.5388],
							[52.5543, 27.5421],
							[52.5432, 27.5543],
							[52.5412, 27.5626],
							[52.5349, 27.566],
							[52.5315, 27.5651],
							[52.5288, 27.5665],
							[52.5288, 27.5702],
							[52.5213, 27.5765],
							[52.5179, 27.5838],
							[52.5135, 27.5874],
							[52.5054, 27.5996],
							[52.5026, 27.6024],
							[52.4979, 27.6024],
							[52.4918, 27.6124],
							[52.4863, 27.6143],
							[52.4738, 27.6243],
							[52.4685, 27.626],
							[52.4596, 27.6235],
							[52.456, 27.6243],
							[52.4504, 27.631],
							[52.4465, 27.6321],
							[52.4474, 27.6332],
							[52.4449, 27.6363],
							[52.4338, 27.6471],
							[52.416, 27.6493],
							[52.4157, 27.6476],
							[52.4182, 27.6479],
							[52.4168, 27.646],
							[52.4113, 27.649],
							[52.4001, 27.6488],
							[52.3785, 27.6524],
							[52.3635, 27.6526],
							[52.3607, 27.6504],
							[52.3563, 27.6501],
							[52.3507, 27.6621],
							[52.346, 27.6651],
							[52.3238, 27.6676],
							[52.3179, 27.6643],
							[52.3143, 27.6682],
							[52.3079, 27.6668],
							[52.304, 27.6693],
							[52.3015, 27.6754],
							[52.2762, 27.6787],
							[52.2679, 27.6776],
							[52.2657, 27.6807],
							[52.2613, 27.6813],
							[52.2613, 27.6829],
							[52.2524, 27.686],
							[52.2393, 27.6901],
							[52.2337, 27.6887],
							[52.2243, 27.6974],
							[52.211, 27.7015],
							[52.2057, 27.7007],
							[52.1971, 27.7038],
							[52.189, 27.7124],
							[52.1854, 27.7199],
							[52.1813, 27.7199],
							[52.1796, 27.7226],
							[52.1743, 27.724],
							[52.1685, 27.7307],
							[52.1646, 27.7318],
							[52.1652, 27.7335],
							[52.1593, 27.7351],
							[52.1563, 27.7404],
							[52.1404, 27.7507],
							[52.1374, 27.7543],
							[52.1382, 27.7571],
							[52.136, 27.759],
							[52.1318, 27.769],
							[52.1285, 27.7713],
							[52.1201, 27.7726],
							[52.1135, 27.7815],
							[52.1012, 27.7865],
							[52.0962, 27.7913],
							[52.091, 27.8004],
							[52.0826, 27.8049],
							[52.0835, 27.806],
							[52.0804, 27.8057],
							[52.0721, 27.8124],
							[52.0685, 27.8215],
							[52.0654, 27.8251],
							[52.061, 27.8282],
							[52.056, 27.8282],
							[52.0551, 27.8304],
							[52.0588, 27.829],
							[52.0571, 27.8299],
							[52.0574, 27.8318],
							[52.056, 27.8307],
							[52.0532, 27.8349],
							[52.0457, 27.8401],
							[52.0299, 27.8426],
							[52.0046, 27.8401],
							[51.9965, 27.8407],
							[51.9896, 27.8388],
							[51.9676, 27.8404],
							[51.9504, 27.8371],
							[51.9326, 27.8307],
							[51.926, 27.8307],
							[51.9221, 27.8332],
							[51.9132, 27.8318],
							[51.9024, 27.8331],
							[51.8899, 27.8382],
							[51.876, 27.8385],
							[51.8735, 27.8401],
							[51.8718, 27.839],
							[51.8613, 27.8432],
							[51.8518, 27.8432],
							[51.8485, 27.8451],
							[51.8368, 27.8435],
							[51.8154, 27.8446],
							[51.7988, 27.8407],
							[51.7888, 27.836],
							[51.7593, 27.8274],
							[51.7518, 27.8318],
							[51.7385, 27.8315],
							[51.7012, 27.8235],
							[51.6843, 27.8315],
							[51.6757, 27.8315],
							[51.6743, 27.8365],
							[51.6707, 27.8382],
							[51.6696, 27.8412],
							[51.6662, 27.8424],
							[51.6521, 27.844],
							[51.6312, 27.8429],
							[51.6235, 27.846],
							[51.604, 27.8485],
							[51.5951, 27.8429],
							[51.5826, 27.8412],
							[51.5818, 27.8485],
							[51.5887, 27.8507],
							[51.5924, 27.8557],
							[51.591, 27.8599],
							[51.5796, 27.869],
							[51.5574, 27.8843],
							[51.5518, 27.8857],
							[51.5515, 27.8871],
							[51.531, 27.8876],
							[51.5171, 27.8901],
							[51.5157, 27.8893],
							[51.5163, 27.8874],
							[51.5115, 27.8838],
							[51.5099, 27.8793],
							[51.5107, 27.8768],
							[51.504, 27.8774],
							[51.499, 27.8824],
							[51.4985, 27.891],
							[51.4918, 27.8943],
							[51.4896, 27.8993],
							[51.4843, 27.9001],
							[51.4846, 27.8996],
							[51.4843, 27.899],
							[51.4826, 27.9013],
							[51.4832, 27.904],
							[51.491, 27.9043],
							[51.4943, 27.9107],
							[51.4926, 27.9135],
							[51.4863, 27.9171],
							[51.4829, 27.9171],
							[51.4785, 27.9143],
							[51.4699, 27.9193],
							[51.4651, 27.9293],
							[51.4579, 27.936],
							[51.4521, 27.9385],
							[51.4471, 27.9482],
							[51.4437, 27.9485],
							[51.4415, 27.9463],
							[51.4362, 27.9454],
							[51.436, 27.941],
							[51.434, 27.9404],
							[51.434, 27.9387],
							[51.429, 27.9393],
							[51.4293, 27.9346],
							[51.4343, 27.9296],
							[51.4341, 27.9229],
							[51.424, 27.9201],
							[51.4135, 27.9215],
							[51.4087, 27.9249],
							[51.4057, 27.924],
							[51.3976, 27.9346],
							[51.3951, 27.9526],
							[51.3929, 27.9565],
							[51.3879, 27.9768],
							[51.3821, 27.9882],
							[51.3799, 27.9963],
							[51.374, 28.0035],
							[51.3701, 28.0126],
							[51.364, 28.019],
							[51.3632, 28.0232],
							[51.3649, 28.0251],
							[51.3615, 28.0265],
							[51.3604, 28.0304],
							[51.3518, 28.0401],
							[51.3526, 28.0424],
							[51.351, 28.0415],
							[51.3537, 28.046],
							[51.3407, 28.0576],
							[51.3363, 28.0657],
							[51.3332, 28.0674],
							[51.3324, 28.0715],
							[51.3282, 28.0724],
							[51.3246, 28.0801],
							[51.3262, 28.0838],
							[51.319, 28.0835],
							[51.3151, 28.0896],
							[51.3154, 28.0943],
							[51.3129, 28.0924],
							[51.3101, 28.0929],
							[51.3037, 28.0974],
							[51.3015, 28.1149],
							[51.2988, 28.1143],
							[51.2979, 28.1174],
							[51.2957, 28.1174],
							[51.2943, 28.1154],
							[51.2876, 28.1174],
							[51.2857, 28.1154],
							[51.2846, 28.1176],
							[51.2768, 28.1196],
							[51.2751, 28.1218],
							[51.276, 28.1243],
							[51.2724, 28.1257],
							[51.2723, 28.1282],
							[51.2743, 28.129],
							[51.2729, 28.1332],
							[51.2699, 28.1332],
							[51.2679, 28.1257],
							[51.2754, 28.1138],
							[51.2735, 28.1132],
							[51.2696, 28.1179],
							[51.2596, 28.1385],
							[51.2593, 28.1429],
							[51.261, 28.1426],
							[51.2621, 28.1379],
							[51.2654, 28.1393],
							[51.269, 28.1524],
							[51.2849, 28.1907],
							[51.2893, 28.211],
							[51.2896, 28.2249],
							[51.2871, 28.2301],
							[51.2682, 28.2507],
							[51.2637, 28.2501],
							[51.2635, 28.2524],
							[51.2646, 28.2526],
							[51.2643, 28.2507],
							[51.266, 28.251],
							[51.266, 28.2529],
							[51.2524, 28.2674],
							[51.2476, 28.2757],
							[51.2399, 28.2807],
							[51.2365, 28.2865],
							[51.234, 28.2874],
							[51.231, 28.2915],
							[51.2265, 28.2926],
							[51.2243, 28.3007],
							[51.2207, 28.3029],
							[51.2165, 28.3121],
							[51.2113, 28.3163],
							[51.2076, 28.3168],
							[51.2012, 28.3293],
							[51.1871, 28.3444],
							[51.1763, 28.3501],
							[51.1743, 28.3632],
							[51.171, 28.3646],
							[51.1671, 28.3735],
							[51.1607, 28.3804],
							[51.1501, 28.3979],
							[51.144, 28.4029],
							[51.1427, 28.4115],
							[51.1335, 28.4199],
							[51.1335, 28.4271],
							[51.1304, 28.431],
							[51.1296, 28.4393],
							[51.124, 28.4443],
							[51.1201, 28.4449],
							[51.1199, 28.4504],
							[51.1174, 28.4543],
							[51.1183, 28.4599],
							[51.114, 28.4646],
							[51.1135, 28.4718],
							[51.1096, 28.4754],
							[51.1093, 28.4813],
							[51.1063, 28.4865],
							[51.1001, 28.4885],
							[51.0988, 28.4907],
							[51.0974, 28.5012],
							[51.0949, 28.5032],
							[51.0943, 28.5104],
							[51.0879, 28.5115],
							[51.0907, 28.5126],
							[51.0885, 28.5154],
							[51.0868, 28.5151],
							[51.0874, 28.511],
							[51.086, 28.5115],
							[51.0863, 28.5168],
							[51.091, 28.5165],
							[51.0921, 28.5193],
							[51.0926, 28.5318],
							[51.0913, 28.5368],
							[51.0874, 28.5385],
							[51.0879, 28.5457],
							[51.0812, 28.5515],
							[51.0807, 28.559],
							[51.0821, 28.5613],
							[51.0801, 28.5757],
							[51.0776, 28.5763],
							[51.0765, 28.5724],
							[51.0801, 28.5999],
							[51.0788, 28.6029],
							[51.079, 28.6204],
							[51.0762, 28.6251],
							[51.0746, 28.6662],
							[51.0713, 28.6921],
							[51.0685, 28.7021],
							[51.0696, 28.7032],
							[51.0671, 28.7046],
							[51.0638, 28.716],
							[51.054, 28.7138],
							[51.0513, 28.7157],
							[51.0596, 28.716],
							[51.0635, 28.7185],
							[51.0604, 28.7232],
							[51.056, 28.7226],
							[51.0582, 28.7246],
							[51.0565, 28.7257],
							[51.0574, 28.7279],
							[51.0507, 28.7462],
							[51.0293, 28.7796],
							[51.0324, 28.7832],
							[51.0326, 28.7921],
							[51.0301, 28.7985],
							[51.0282, 28.7996],
							[51.0271, 28.8121],
							[51.0254, 28.8157],
							[51.0174, 28.8232],
							[51.0143, 28.8299],
							[51.0079, 28.8363],
							[51.0068, 28.8413],
							[51.001, 28.8474],
							[50.9938, 28.8504],
							[50.9863, 28.8501],
							[50.981, 28.8518],
							[50.9721, 28.8501],
							[50.9657, 28.8507],
							[50.9663, 28.8487],
							[50.9801, 28.8457],
							[50.9787, 28.8435],
							[50.9832, 28.8382],
							[50.981, 28.8365],
							[50.9743, 28.839],
							[50.9712, 28.8424],
							[50.9682, 28.8404],
							[50.9651, 28.8418],
							[50.9432, 28.841],
							[50.959, 28.8335],
							[50.9754, 28.8185],
							[50.9824, 28.8213],
							[50.9851, 28.8201],
							[50.9793, 28.8165],
							[50.9649, 28.8168],
							[50.9646, 28.8182],
							[50.9415, 28.8221],
							[50.9276, 28.8201],
							[50.9204, 28.8224],
							[50.9188, 28.8207],
							[50.9185, 28.8229],
							[50.9154, 28.8243],
							[50.9107, 28.8238],
							[50.9096, 28.8193],
							[50.9079, 28.8185],
							[50.9054, 28.8221],
							[50.9032, 28.8226],
							[50.889, 28.8196],
							[50.8815, 28.826],
							[50.8724, 28.841],
							[50.8668, 28.8443],
							[50.8601, 28.8526],
							[50.8554, 28.854],
							[50.8482, 28.8676],
							[50.8363, 28.881],
							[50.8301, 28.8988],
							[50.826, 28.9021],
							[50.821, 28.9018],
							[50.8204, 28.904],
							[50.8174, 28.9057],
							[50.814, 28.9149],
							[50.8104, 28.9193],
							[50.8076, 28.9315],
							[50.8115, 28.954],
							[50.8132, 28.9549],
							[50.8112, 28.9618],
							[50.8201, 28.9679],
							[50.8251, 28.974],
							[50.8299, 28.9988],
							[50.8351, 28.9951],
							[50.8404, 28.9849],
							[50.8435, 28.9824],
							[50.8482, 28.9818],
							[50.8526, 28.9779],
							[50.856, 28.9774],
							[50.8657, 28.9657],
							[50.8693, 28.9646],
							[50.8746, 28.9671],
							[50.8737, 28.9643],
							[50.8765, 28.9607],
							[50.8854, 28.9554],
							[50.8826, 28.9535],
							[50.8788, 28.954],
							[50.8785, 28.9526],
							[50.8868, 28.9432],
							[50.8846, 28.9382],
							[50.8885, 28.9329],
							[50.8929, 28.9318],
							[50.9007, 28.9351],
							[50.9035, 28.9415],
							[50.9026, 28.944],
							[50.8968, 28.946],
							[50.8896, 28.944],
							[50.8868, 28.9468],
							[50.9021, 28.9593],
							[50.9093, 28.9562],
							[50.9118, 28.9585],
							[50.9146, 28.9582],
							[50.9157, 28.961],
							[50.9188, 28.9612],
							[50.9168, 28.9643],
							[50.9185, 28.9662],
							[50.9168, 28.9757],
							[50.9226, 28.9863],
							[50.9285, 28.989],
							[50.9235, 28.9921],
							[50.9246, 28.9974],
							[50.914, 28.9996],
							[50.9176, 29.0049],
							[50.9204, 29.0054],
							[50.9204, 29.0074],
							[50.9185, 29.0085],
							[50.9182, 29.0132],
							[50.921, 29.014],
							[50.9218, 29.0163],
							[50.9199, 29.019],
							[50.921, 29.0224],
							[50.9193, 29.0243],
							[50.9218, 29.0246],
							[50.9215, 29.0301],
							[50.9251, 29.0349],
							[50.9238, 29.0379],
							[50.9257, 29.0401],
							[50.9243, 29.0474],
							[50.9212, 29.0496],
							[50.9224, 29.0537],
							[50.9179, 29.0579],
							[50.9235, 29.0585],
							[50.9204, 29.0604],
							[50.9212, 29.0663],
							[50.9115, 29.0671],
							[50.9043, 29.0707],
							[50.8904, 29.0693],
							[50.8901, 29.0707],
							[50.8982, 29.0713],
							[50.9004, 29.0743],
							[50.8974, 29.0779],
							[50.8976, 29.0835],
							[50.8929, 29.0882],
							[50.8896, 29.0951],
							[50.8838, 29.0996],
							[50.884, 29.1029],
							[50.8801, 29.1051],
							[50.8776, 29.1051],
							[50.8749, 29.1015],
							[50.8654, 29.1015],
							[50.8626, 29.0949],
							[50.8604, 29.0946],
							[50.8568, 29.0949],
							[50.8526, 29.0976],
							[50.8521, 29.1007],
							[50.8451, 29.1018],
							[50.8424, 29.1051],
							[50.8424, 29.1107],
							[50.8457, 29.1146],
							[50.8504, 29.1162],
							[50.8457, 29.1174],
							[50.8493, 29.1201],
							[50.8465, 29.1188],
							[50.8429, 29.1193],
							[50.8421, 29.1218],
							[50.8338, 29.1276],
							[50.8229, 29.1321],
							[50.8174, 29.1368],
							[50.8143, 29.1376],
							[50.8082, 29.1357],
							[50.8149, 29.1385],
							[50.8182, 29.1432],
							[50.8201, 29.1504],
							[50.8221, 29.1479],
							[50.8307, 29.1499],
							[50.8304, 29.1518],
							[50.8249, 29.1507],
							[50.824, 29.1526],
							[50.8176, 29.1549],
							[50.8171, 29.1579],
							[50.8135, 29.1579],
							[50.8121, 29.1557],
							[50.811, 29.1599],
							[50.8088, 29.1615],
							[50.8071, 29.1596],
							[50.809, 29.1593],
							[50.8082, 29.1563],
							[50.8043, 29.1565],
							[50.8049, 29.1579],
							[50.801, 29.161],
							[50.7954, 29.1618],
							[50.7932, 29.1604],
							[50.7921, 29.1621],
							[50.7876, 29.1615],
							[50.7846, 29.1588],
							[50.7796, 29.1604],
							[50.7779, 29.1576],
							[50.7762, 29.1579],
							[50.7754, 29.161],
							[50.7732, 29.1596],
							[50.7724, 29.161],
							[50.7671, 29.1615],
							[50.7657, 29.1593],
							[50.7646, 29.161],
							[50.759, 29.1615],
							[50.7568, 29.1593],
							[50.759, 29.1576],
							[50.7588, 29.1565],
							[50.7557, 29.1585],
							[50.7565, 29.164],
							[50.7554, 29.1663],
							[50.7504, 29.1682],
							[50.7482, 29.1643],
							[50.744, 29.1651],
							[50.744, 29.1621],
							[50.7412, 29.1626],
							[50.744, 29.1585],
							[50.744, 29.1487],
							[50.7513, 29.149],
							[50.7529, 29.1443],
							[50.761, 29.144],
							[50.761, 29.1363],
							[50.7643, 29.1335],
							[50.7735, 29.1326],
							[50.7718, 29.1346],
							[50.7715, 29.139],
							[50.7749, 29.1418],
							[50.7829, 29.1371],
							[50.7843, 29.1335],
							[50.7774, 29.1298],
							[50.7552, 29.1313],
							[50.7468, 29.1299],
							[50.7446, 29.1221],
							[50.7271, 29.1199],
							[50.7235, 29.1162],
							[50.7196, 29.1168],
							[50.7179, 29.1188],
							[50.7118, 29.1165],
							[50.7004, 29.1176],
							[50.7029, 29.1129],
							[50.6999, 29.1121],
							[50.6993, 29.106],
							[50.6963, 29.1029],
							[50.6929, 29.1032],
							[50.6846, 29.1087],
							[50.6824, 29.1132],
							[50.6807, 29.1124],
							[50.6821, 29.111],
							[50.6813, 29.1099],
							[50.6729, 29.1149],
							[50.6735, 29.1171],
							[50.6757, 29.1174],
							[50.6749, 29.1212],
							[50.6721, 29.1201],
							[50.6674, 29.1251],
							[50.6654, 29.1249],
							[50.6671, 29.1274],
							[50.6662, 29.1301],
							[50.6638, 29.1313],
							[50.6638, 29.1338],
							[50.6563, 29.1421],
							[50.6554, 29.1454],
							[50.6515, 29.1479],
							[50.6538, 29.1568],
							[50.6485, 29.1563],
							[50.6471, 29.1618],
							[50.6451, 29.1624],
							[50.6507, 29.1729],
							[50.6585, 29.1771],
							[50.6587, 29.1801],
							[50.6613, 29.1815],
							[50.6599, 29.1857],
							[50.6618, 29.1921],
							[50.6593, 29.1935],
							[50.661, 29.2046],
							[50.6596, 29.2049],
							[50.6593, 29.2079],
							[50.6565, 29.2049],
							[50.6571, 29.2001],
							[50.6549, 29.199],
							[50.6518, 29.1932],
							[50.6499, 29.1929],
							[50.649, 29.1974],
							[50.6596, 29.2324],
							[50.659, 29.244],
							[50.6649, 29.2474],
							[50.6682, 29.2526],
							[50.6749, 29.2482],
							[50.6846, 29.2499],
							[50.6843, 29.2512],
							[50.6807, 29.251],
							[50.681, 29.2551],
							[50.6835, 29.2574],
							[50.6818, 29.2632],
							[50.6776, 29.2663],
							[50.6754, 29.2618],
							[50.6763, 29.2596],
							[50.6724, 29.2596],
							[50.6724, 29.2668],
							[50.6757, 29.2713],
							[50.6813, 29.2715],
							[50.696, 29.2899],
							[50.6926, 29.289],
							[50.6835, 29.2824],
							[50.6785, 29.2749],
							[50.6749, 29.2746],
							[50.6718, 29.2707],
							[50.6693, 29.2713],
							[50.6704, 29.2663],
							[50.669, 29.2649],
							[50.6649, 29.2668],
							[50.664, 29.2696],
							[50.6651, 29.2774],
							[50.6674, 29.2768],
							[50.6688, 29.2735],
							[50.6721, 29.2754],
							[50.669, 29.2838],
							[50.6721, 29.2907],
							[50.669, 29.2899],
							[50.6682, 29.2904],
							[50.6818, 29.294],
							[50.6874, 29.3051],
							[50.6926, 29.3104],
							[50.7001, 29.3124],
							[50.7054, 29.3115],
							[50.7065, 29.3129],
							[50.6982, 29.3137],
							[50.6854, 29.3096],
							[50.6824, 29.3076],
							[50.6813, 29.3015],
							[50.6751, 29.3001],
							[50.6679, 29.2921],
							[50.6657, 29.2915],
							[50.6649, 29.2929],
							[50.6651, 29.3157],
							[50.6629, 29.3354],
							[50.6649, 29.3613],
							[50.6649, 29.3949],
							[50.6632, 29.3971],
							[50.6596, 29.4199],
							[50.6546, 29.4324],
							[50.6554, 29.4337],
							[50.659, 29.4326],
							[50.6601, 29.4337],
							[50.6579, 29.4354],
							[50.6535, 29.4354],
							[50.6532, 29.4371],
							[50.6493, 29.4396],
							[50.6496, 29.4415],
							[50.6479, 29.4421],
							[50.6407, 29.4554],
							[50.639, 29.4557],
							[50.6404, 29.4618],
							[50.6379, 29.4629],
							[50.6365, 29.4693],
							[50.6263, 29.4818],
							[50.6268, 29.4779],
							[50.636, 29.4662],
							[50.6368, 29.4629],
							[50.6338, 29.4601],
							[50.6285, 29.461],
							[50.624, 29.4657],
							[50.6221, 29.4657],
							[50.6246, 29.4701],
							[50.6174, 29.4757],
							[50.6126, 29.4768],
							[50.6162, 29.4715],
							[50.6146, 29.4693],
							[50.6113, 29.4704],
							[50.6099, 29.4751],
							[50.6051, 29.4807],
							[50.6013, 29.489],
							[50.6063, 29.4915],
							[50.6126, 29.4876],
							[50.6151, 29.4935],
							[50.6099, 29.4968],
							[50.611, 29.4985],
							[50.6051, 29.5049],
							[50.5985, 29.5074],
							[50.5962, 29.5113],
							[50.586, 29.5187],
							[50.5785, 29.5165],
							[50.5799, 29.5149],
							[50.579, 29.5118],
							[50.5846, 29.5107],
							[50.5821, 29.5082],
							[50.5857, 29.506],
							[50.5843, 29.5032],
							[50.586, 29.5004],
							[50.5921, 29.5012],
							[50.5965, 29.4965],
							[50.5921, 29.4893],
							[50.5954, 29.4785],
							[50.5949, 29.4771],
							[50.5924, 29.4771],
							[50.5913, 29.4799],
							[50.5768, 29.4932],
							[50.5768, 29.4949],
							[50.5707, 29.499],
							[50.5701, 29.501],
							[50.5729, 29.5004],
							[50.5732, 29.5018],
							[50.5696, 29.5051],
							[50.5696, 29.5071],
							[50.5654, 29.5093],
							[50.5657, 29.5118],
							[50.5612, 29.5124],
							[50.5565, 29.5157],
							[50.5532, 29.5207],
							[50.5479, 29.5235],
							[50.5346, 29.5365],
							[50.5349, 29.5379],
							[50.5271, 29.5424],
							[50.5257, 29.5449],
							[50.5224, 29.5451],
							[50.5199, 29.5479],
							[50.5174, 29.5462],
							[50.5115, 29.5476],
							[50.5088, 29.5524],
							[50.5038, 29.5751],
							[50.499, 29.5863],
							[50.4935, 29.5949],
							[50.4912, 29.5949],
							[50.4912, 29.5968],
							[50.4885, 29.599],
							[50.4882, 29.6026],
							[50.4863, 29.6026],
							[50.4782, 29.6138],
							[50.4624, 29.6282],
							[50.4485, 29.6379],
							[50.4488, 29.639],
							[50.4301, 29.6485],
							[50.4249, 29.6504],
							[50.4215, 29.6488],
							[50.4179, 29.6521],
							[50.409, 29.6551],
							[50.4076, 29.6588],
							[50.4024, 29.6607],
							[50.3988, 29.6663],
							[50.3899, 29.6718],
							[50.3876, 29.6763],
							[50.3815, 29.6801],
							[50.3763, 29.6871],
							[50.3668, 29.6932],
							[50.3457, 29.7207],
							[50.339, 29.724],
							[50.3338, 29.7301],
							[50.3304, 29.7301],
							[50.324, 29.736],
							[50.3174, 29.7512],
							[50.3132, 29.7557],
							[50.3082, 29.7571],
							[50.3049, 29.7615],
							[50.3007, 29.7701],
							[50.2826, 29.7951],
							[50.2751, 29.8115],
							[50.2724, 29.8137],
							[50.2713, 29.8174],
							[50.2696, 29.8176],
							[50.2663, 29.8243],
							[50.2626, 29.8276],
							[50.2562, 29.8265],
							[50.2604, 29.8293],
							[50.2585, 29.8304],
							[50.2593, 29.8326],
							[50.2574, 29.8357],
							[50.2504, 29.8407],
							[50.249, 29.8457],
							[50.2471, 29.8457],
							[50.2443, 29.8499],
							[50.2293, 29.8627],
							[50.2262, 29.8676],
							[50.2224, 29.8696],
							[50.2199, 29.8765],
							[50.2179, 29.8757],
							[50.2154, 29.8771],
							[50.2168, 29.8807],
							[50.2151, 29.8824],
							[50.2068, 29.8829],
							[50.1996, 29.8929],
							[50.1971, 29.8937],
							[50.1888, 29.9035],
							[50.1768, 29.9132],
							[50.1774, 29.9157],
							[50.1734, 29.9165],
							[50.1674, 29.9224],
							[50.1565, 29.9274],
							[50.1504, 29.9329],
							[50.1437, 29.9296],
							[50.1432, 29.9332],
							[50.1463, 29.9382],
							[50.1407, 29.9393],
							[50.141, 29.9424],
							[50.1382, 29.9454],
							[50.1421, 29.9568],
							[50.1435, 29.9682],
							[50.1426, 29.989],
							[50.1449, 29.9999],
							[50.1468, 30.0018],
							[50.1454, 30.0037],
							[50.146, 30.0168],
							[50.1518, 30.0187],
							[50.1468, 30.0185],
							[50.1463, 30.0196],
							[50.1465, 30.0357],
							[50.1512, 30.039],
							[50.1465, 30.0393],
							[50.1424, 30.0721],
							[50.1351, 30.0946],
							[50.1235, 30.1162],
							[50.1232, 30.1187],
							[50.1249, 30.1185],
							[50.124, 30.1235],
							[50.1218, 30.1221],
							[50.1176, 30.1232],
							[50.1165, 30.1279],
							[50.1101, 30.1354],
							[50.1063, 30.1432],
							[50.0999, 30.1596],
							[50.0863, 30.1793],
							[50.0596, 30.199],
							[50.0488, 30.2035],
							[50.0428, 30.204],
							[50.1013, 30.2583],
							[50.1234, 30.2687],
							[50.1347, 30.269],
							[50.1456, 30.279],
							[50.1568, 30.2793],
							[50.1685, 30.2701],
							[50.1797, 30.2704],
							[50.2259, 30.2429],
							[50.2375, 30.2337],
							[50.2487, 30.234],
							[50.2603, 30.2247],
							[50.2829, 30.2252],
							[50.3058, 30.2162],
							[50.4299, 30.2189],
							[50.464, 30.2099],
							[50.4756, 30.2005],
							[50.5208, 30.2013],
							[50.5326, 30.1822],
							[50.5442, 30.1728],
							[50.5781, 30.1734],
							[50.5899, 30.1543],
							[50.6015, 30.1449],
							[50.5789, 30.1445],
							[50.5679, 30.1348],
							[50.5688, 30.1061],
							[50.5812, 30.068],
							[50.5815, 30.0585],
							[50.6159, 30.0304],
							[50.6276, 30.0116],
							[50.673, 29.993],
							[50.6845, 29.9836],
							[50.6847, 29.9741],
							[50.6961, 29.9647],
							[50.7195, 29.927],
							[50.7205, 29.8796],
							[50.7321, 29.87],
							[50.7326, 29.8603],
							[50.7438, 29.8604],
							[50.7555, 29.8507],
							[50.8342, 29.8513],
							[50.8684, 29.8417],
							[50.8688, 29.8319],
							[50.8801, 29.832],
							[50.9022, 29.8419],
							[51.0036, 29.8421],
							[51.0265, 29.8325],
							[51.049, 29.8325],
							[51.0834, 29.8133],
							[51.1062, 29.8036],
							[51.1736, 29.8037],
							[51.1852, 29.794],
							[51.208, 29.7844],
							[51.2196, 29.7746],
							[51.2557, 29.7159],
							[51.2576, 29.6765],
							[51.2469, 29.6666],
							[51.2495, 29.6171],
							[51.2729, 29.5974],
							[51.284, 29.5975],
							[51.2845, 29.5876],
							[51.2962, 29.5778],
							[51.3201, 29.5482],
							[51.3318, 29.5384],
							[51.344, 29.5186],
							[51.3906, 29.4793],
							[51.4017, 29.4794],
							[51.425, 29.4598],
							[51.4472, 29.4599],
							[51.4926, 29.4405],
							[51.5148, 29.4406],
							[51.5264, 29.4308],
							[51.5602, 29.4211],
							[51.5834, 29.4015],
							[51.6083, 29.3521],
							[51.6568, 29.2732],
							[51.6814, 29.2241],
							[51.7053, 29.185],
							[51.7414, 29.1168],
							[51.7529, 29.1071],
							[51.7656, 29.0681],
							[51.7885, 29.0487],
							[51.7888, 29.039],
							[51.8236, 29.0002],
							[51.8586, 28.9517],
							[51.8814, 28.9323],
							[51.8818, 28.9226],
							[51.8932, 28.913],
							[51.9049, 28.8936],
							[51.9163, 28.8839],
							[51.9167, 28.8742],
							[51.9404, 28.8258],
							[51.9882, 28.7193],
							[51.9998, 28.6999],
							[52.0118, 28.6709],
							[52.0235, 28.6516],
							[52.0241, 28.6322],
							[52.0361, 28.6032],
							[52.0477, 28.5838],
							[52.0498, 28.5161],
							[52.0617, 28.4871],
							[52.0733, 28.4678],
							[52.0971, 28.4098],
							[52.1206, 28.3614],
							[52.1554, 28.3034],
							[52.1899, 28.255],
							[52.1911, 28.2065],
							[52.1806, 28.187],
							[52.1807, 28.182],
						],
					],
					[
						[
							[51.4749, 27.826],
							[51.474, 27.8204],
							[51.4715, 27.8196],
							[51.469, 27.821],
							[51.4724, 27.8235],
							[51.4715, 27.8249],
							[51.474, 27.8274],
							[51.4749, 27.826],
						],
					],
					[
						[
							[51.5671, 27.8374],
							[51.5671, 27.834],
							[51.5651, 27.8326],
							[51.5649, 27.8351],
							[51.561, 27.834],
							[51.5665, 27.8385],
							[51.5671, 27.8374],
						],
					],
					[
						[
							[51.4301, 27.894],
							[51.444, 27.8818],
							[51.4582, 27.864],
							[51.4588, 27.8604],
							[51.4618, 27.8563],
							[51.4574, 27.8493],
							[51.4582, 27.8479],
							[51.4593, 27.8504],
							[51.4607, 27.8501],
							[51.4585, 27.846],
							[51.4562, 27.8449],
							[51.4488, 27.8457],
							[51.4424, 27.8563],
							[51.4426, 27.859],
							[51.4374, 27.8635],
							[51.4285, 27.8871],
							[51.4263, 27.889],
							[51.4276, 27.8946],
							[51.4301, 27.894],
						],
					],
					[
						[
							[51.4118, 27.9146],
							[51.4232, 27.9035],
							[51.4238, 27.901],
							[51.4168, 27.901],
							[51.411, 27.9088],
							[51.4104, 27.9132],
							[51.4082, 27.9154],
							[51.4118, 27.9146],
						],
					],
					[
						[
							[51.0015, 28.8179],
							[51.0032, 28.816],
							[51.0079, 28.8165],
							[51.0088, 28.8179],
							[51.0093, 28.8168],
							[51.0068, 28.8146],
							[51.0021, 28.8137],
							[51.001, 28.8157],
							[50.9985, 28.8176],
							[50.9996, 28.816],
							[50.9987, 28.814],
							[50.9982, 28.8179],
							[51.0015, 28.8179],
						],
					],
					[
						[
							[50.8974, 28.9424],
							[50.8954, 28.9429],
							[50.8929, 28.9418],
							[50.894, 28.944],
							[50.8976, 28.9438],
							[50.8974, 28.9424],
						],
					],
					[
						[
							[50.8782, 28.9674],
							[50.8779, 28.9649],
							[50.8754, 28.9662],
							[50.876, 28.9679],
							[50.8782, 28.9674],
						],
					],
					[
						[
							[50.8815, 28.9671],
							[50.8796, 28.9654],
							[50.879, 28.9657],
							[50.8793, 28.9685],
							[50.8818, 28.9688],
							[50.8815, 28.9671],
						],
					],
					[
						[
							[50.8943, 28.9674],
							[50.8935, 28.9665],
							[50.8915, 28.9685],
							[50.894, 28.969],
							[50.8943, 28.9674],
						],
					],
					[
						[
							[50.899, 28.9688],
							[50.8979, 28.9674],
							[50.8963, 28.9707],
							[50.8993, 28.9713],
							[50.899, 28.9688],
						],
					],
					[
						[
							[50.8749, 28.9707],
							[50.8751, 28.969],
							[50.871, 28.9671],
							[50.8671, 28.9668],
							[50.8643, 28.969],
							[50.8654, 28.9721],
							[50.874, 28.9724],
							[50.8749, 28.9707],
						],
					],
					[
						[
							[50.8771, 28.9826],
							[50.8743, 28.9804],
							[50.8699, 28.9804],
							[50.8688, 28.9821],
							[50.8701, 28.9843],
							[50.8763, 28.986],
							[50.8771, 28.9826],
						],
					],
					[
						[
							[50.841, 29.0001],
							[50.8418, 28.9974],
							[50.8457, 28.9949],
							[50.8421, 28.9912],
							[50.8387, 28.9921],
							[50.8379, 28.9951],
							[50.8343, 28.9976],
							[50.8363, 29.0004],
							[50.8393, 29.0012],
							[50.841, 29.0001],
						],
					],
					[
						[
							[50.7635, 29.0746],
							[50.7676, 29.0735],
							[50.769, 29.0749],
							[50.7704, 29.074],
							[50.7685, 29.0735],
							[50.7707, 29.0735],
							[50.774, 29.0763],
							[50.7726, 29.074],
							[50.7704, 29.0729],
							[50.7665, 29.0724],
							[50.7562, 29.0774],
							[50.7596, 29.0779],
							[50.7635, 29.0746],
						],
					],
					[
						[
							[50.7771, 29.0801],
							[50.7762, 29.0793],
							[50.7743, 29.0793],
							[50.7779, 29.0818],
							[50.7771, 29.0801],
						],
					],
					[
						[
							[50.764, 29.0771],
							[50.7635, 29.0785],
							[50.7629, 29.0785],
							[50.7635, 29.0763],
							[50.7621, 29.0763],
							[50.7588, 29.0793],
							[50.7529, 29.0785],
							[50.754, 29.0799],
							[50.7546, 29.0793],
							[50.7574, 29.0793],
							[50.759, 29.0799],
							[50.7604, 29.0793],
							[50.7621, 29.0801],
							[50.7596, 29.0807],
							[50.7562, 29.0796],
							[50.7521, 29.0812],
							[50.7524, 29.0824],
							[50.7496, 29.081],
							[50.7451, 29.084],
							[50.7471, 29.0846],
							[50.7493, 29.0829],
							[50.751, 29.0843],
							[50.7518, 29.0824],
							[50.7515, 29.0846],
							[50.7532, 29.0857],
							[50.7513, 29.0863],
							[50.7529, 29.0868],
							[50.7707, 29.0793],
							[50.7707, 29.076],
							[50.7649, 29.0768],
							[50.7654, 29.0754],
							[50.7637, 29.0754],
							[50.764, 29.0771],
						],
					],
					[
						[
							[50.7418, 29.0879],
							[50.7424, 29.0857],
							[50.7388, 29.0843],
							[50.7324, 29.0843],
							[50.7321, 29.0826],
							[50.7296, 29.0843],
							[50.7304, 29.0871],
							[50.7329, 29.0885],
							[50.7418, 29.0879],
						],
					],
					[
						[
							[50.7685, 29.0896],
							[50.7674, 29.0893],
							[50.7671, 29.0912],
							[50.769, 29.0932],
							[50.7685, 29.0896],
						],
					],
					[
						[
							[50.7735, 29.0965],
							[50.7754, 29.0954],
							[50.7749, 29.094],
							[50.769, 29.096],
							[50.771, 29.0974],
							[50.7735, 29.0965],
						],
					],
					[
						[
							[50.7463, 29.1015],
							[50.7471, 29.1001],
							[50.7457, 29.0993],
							[50.7465, 29.1007],
							[50.7446, 29.1032],
							[50.7463, 29.1015],
						],
					],
					[
						[
							[50.7479, 29.1068],
							[50.751, 29.1051],
							[50.7468, 29.1026],
							[50.7446, 29.1051],
							[50.7401, 29.1043],
							[50.7415, 29.1085],
							[50.7479, 29.1068],
						],
					],
					[
						[
							[50.746, 29.1087],
							[50.7435, 29.1118],
							[50.7493, 29.1082],
							[50.7526, 29.1071],
							[50.7551, 29.1082],
							[50.7562, 29.1068],
							[50.7526, 29.1057],
							[50.746, 29.1087],
						],
					],
					[
						[
							[50.7557, 29.1113],
							[50.754, 29.1107],
							[50.7532, 29.1124],
							[50.7557, 29.1135],
							[50.7557, 29.1113],
						],
					],
					[
						[
							[50.7568, 29.1171],
							[50.7549, 29.1168],
							[50.7562, 29.1162],
							[50.7557, 29.114],
							[50.7521, 29.1149],
							[50.7538, 29.1149],
							[50.7529, 29.1168],
							[50.7543, 29.1179],
							[50.7565, 29.1185],
							[50.7568, 29.1171],
						],
					],
					[
						[
							[50.7313, 29.0685],
							[50.7313, 29.0679],
							[50.7376, 29.0685],
							[50.7382, 29.0682],
							[50.7368, 29.0657],
							[50.731, 29.0663],
							[50.729, 29.0693],
							[50.7313, 29.0685],
						],
					],
					[
						[
							[50.7224, 29.079],
							[50.7235, 29.0779],
							[50.7226, 29.074],
							[50.7246, 29.074],
							[50.7265, 29.0687],
							[50.7232, 29.0707],
							[50.7232, 29.0726],
							[50.7207, 29.0721],
							[50.7188, 29.0765],
							[50.7171, 29.076],
							[50.7168, 29.0779],
							[50.7204, 29.0763],
							[50.7218, 29.0768],
							[50.721, 29.0793],
							[50.7224, 29.079],
						],
					],
					[
						[
							[50.721, 29.0799],
							[50.7204, 29.0782],
							[50.7188, 29.0785],
							[50.7188, 29.0812],
							[50.721, 29.0812],
							[50.721, 29.0799],
						],
					],
					[
						[
							[50.2849, 29.2326],
							[50.2885, 29.2274],
							[50.2885, 29.2237],
							[50.2857, 29.2254],
							[50.2824, 29.2337],
							[50.2849, 29.2326],
						],
					],
					[
						[
							[50.8785, 28.9965],
							[50.8807, 28.9946],
							[50.8838, 28.9951],
							[50.8846, 28.9924],
							[50.8751, 28.9874],
							[50.8671, 28.9851],
							[50.8649, 28.9821],
							[50.8607, 28.9801],
							[50.8568, 28.981],
							[50.8568, 28.989],
							[50.8587, 28.9951],
							[50.8785, 28.9965],
						],
					],
					[
						[
							[50.9104, 28.994],
							[50.911, 28.989],
							[50.9085, 28.9874],
							[50.9071, 28.9924],
							[50.9101, 28.9957],
							[50.9104, 28.994],
						],
					],
					[
						[
							[50.8812, 28.9982],
							[50.8829, 28.9974],
							[50.8793, 28.9988],
							[50.8801, 28.999],
							[50.8812, 28.9982],
						],
					],
					[
						[
							[50.8732, 29.0738],
							[50.8846, 29.0704],
							[50.8893, 29.0663],
							[50.8876, 29.0637],
							[50.8885, 29.0563],
							[50.8863, 29.0524],
							[50.8865, 29.0476],
							[50.8907, 29.0404],
							[50.8907, 29.029],
							[50.8974, 29.0168],
							[50.8926, 29.0151],
							[50.8885, 29.0065],
							[50.8843, 29.004],
							[50.8849, 29.0015],
							[50.8829, 29.0004],
							[50.8696, 29.0054],
							[50.866, 29.0163],
							[50.8679, 29.0313],
							[50.8707, 29.034],
							[50.8713, 29.039],
							[50.869, 29.0588],
							[50.8651, 29.0649],
							[50.8671, 29.0713],
							[50.8704, 29.0743],
							[50.8732, 29.0738],
						],
					],
					[
						[
							[50.7946, 29.154],
							[50.7926, 29.1515],
							[50.794, 29.1579],
							[50.7949, 29.1579],
							[50.7946, 29.154],
						],
					],
					[
						[
							[50.2954, 29.274],
							[50.3035, 29.2721],
							[50.3129, 29.2663],
							[50.3229, 29.2649],
							[50.3251, 29.2671],
							[50.3243, 29.2651],
							[50.3288, 29.2649],
							[50.3368, 29.2604],
							[50.3301, 29.2493],
							[50.3324, 29.2465],
							[50.3276, 29.2449],
							[50.3265, 29.239],
							[50.3276, 29.2357],
							[50.3301, 29.2354],
							[50.334, 29.2313],
							[50.3382, 29.2321],
							[50.3374, 29.2379],
							[50.3379, 29.239],
							[50.3396, 29.2382],
							[50.3413, 29.2213],
							[50.3399, 29.221],
							[50.3385, 29.2301],
							[50.3276, 29.2299],
							[50.3288, 29.221],
							[50.334, 29.2221],
							[50.3315, 29.2171],
							[50.3379, 29.2157],
							[50.336, 29.2124],
							[50.3329, 29.2113],
							[50.3321, 29.2146],
							[50.3288, 29.2149],
							[50.3249, 29.2121],
							[50.324, 29.2093],
							[50.3149, 29.2071],
							[50.311, 29.2076],
							[50.3074, 29.2143],
							[50.3043, 29.2151],
							[50.3029, 29.2207],
							[50.3015, 29.2199],
							[50.3032, 29.2226],
							[50.301, 29.2276],
							[50.299, 29.2276],
							[50.2996, 29.2243],
							[50.2976, 29.2243],
							[50.2968, 29.2271],
							[50.3001, 29.2293],
							[50.3001, 29.2318],
							[50.2971, 29.2354],
							[50.2968, 29.2407],
							[50.2943, 29.2424],
							[50.2957, 29.2479],
							[50.2946, 29.2546],
							[50.2921, 29.2596],
							[50.2874, 29.2618],
							[50.2824, 29.2682],
							[50.284, 29.2721],
							[50.2885, 29.274],
							[50.2954, 29.274],
						],
					],
					[
						[
							[50.679, 29.2538],
							[50.6763, 29.2521],
							[50.6751, 29.2543],
							[50.6776, 29.2557],
							[50.679, 29.2538],
						],
					],
					[
						[
							[50.3276, 29.2663],
							[50.326, 29.2663],
							[50.3257, 29.2676],
							[50.3288, 29.2676],
							[50.3276, 29.2663],
						],
					],
					[
						[
							[50.6793, 29.2962],
							[50.6793, 29.2957],
							[50.6749, 29.2954],
							[50.6785, 29.2985],
							[50.6793, 29.2962],
						],
					],
					[
						[
							[50.3563, 29.3412],
							[50.3579, 29.3365],
							[50.3507, 29.3263],
							[50.3426, 29.3054],
							[50.3396, 29.3007],
							[50.3343, 29.299],
							[50.3343, 29.3051],
							[50.3368, 29.3071],
							[50.339, 29.3146],
							[50.3446, 29.3235],
							[50.3465, 29.3285],
							[50.3465, 29.3346],
							[50.3521, 29.341],
							[50.3563, 29.3412],
						],
					],
					[
						[
							[50.6129, 29.4657],
							[50.6138, 29.4643],
							[50.6132, 29.4635],
							[50.611, 29.4662],
							[50.6129, 29.4657],
						],
					],
					[
						[
							[50.6046, 29.4949],
							[50.604, 29.494],
							[50.6024, 29.4946],
							[50.6032, 29.496],
							[50.6051, 29.496],
							[50.6046, 29.4949],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Chahar Mahalland Bakhtiari", name: "چهارمحال و بختیاری" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[51.1474, 32.1839],
							[51.1835, 32.155],
							[51.2071, 32.1456],
							[51.2311, 32.1264],
							[51.2541, 32.1267],
							[51.2782, 32.1074],
							[51.2907, 32.088],
							[51.3027, 32.0784],
							[51.3038, 32.0588],
							[51.3185, 32.0002],
							[51.3221, 31.9315],
							[51.3116, 31.9118],
							[51.3121, 31.902],
							[51.3011, 31.892],
							[51.2673, 31.8817],
							[51.2563, 31.8718],
							[51.2449, 31.8716],
							[51.2339, 31.8617],
							[51.2344, 31.8518],
							[51.2234, 31.8419],
							[51.2244, 31.8222],
							[51.2359, 31.8224],
							[51.2836, 31.7837],
							[51.295, 31.7839],
							[51.2955, 31.774],
							[51.3074, 31.7644],
							[51.3083, 31.7447],
							[51.2974, 31.7347],
							[51.2402, 31.734],
							[51.2406, 31.7242],
							[51.2297, 31.7142],
							[51.2183, 31.7141],
							[51.2073, 31.7041],
							[51.2151, 31.5371],
							[51.2274, 31.5175],
							[51.2283, 31.4979],
							[51.2406, 31.4783],
							[51.2647, 31.4491],
							[51.3015, 31.3904],
							[51.3019, 31.3806],
							[51.3149, 31.3414],
							[51.3158, 31.3217],
							[51.3296, 31.2627],
							[51.332, 31.2037],
							[51.3108, 31.1641],
							[51.2877, 31.1737],
							[51.2537, 31.1733],
							[51.2314, 31.1632],
							[51.2086, 31.163],
							[51.1977, 31.153],
							[51.1863, 31.1529],
							[51.1758, 31.1331],
							[51.1648, 31.1231],
							[51.1542, 31.1033],
							[51.1433, 31.0933],
							[51.0751, 31.0925],
							[51.0516, 31.1119],
							[51.0062, 31.1113],
							[50.9952, 31.1013],
							[50.9839, 31.1011],
							[50.9607, 31.1106],
							[50.938, 31.1103],
							[50.9031, 31.1295],
							[50.8677, 31.1584],
							[50.8563, 31.1583],
							[50.8327, 31.1775],
							[50.8205, 31.197],
							[50.8086, 31.2066],
							[50.7727, 31.2453],
							[50.7491, 31.2646],
							[50.7258, 31.274],
							[50.6902, 31.3027],
							[50.6898, 31.3126],
							[50.6423, 31.3509],
							[50.6418, 31.3607],
							[50.618, 31.3798],
							[50.6066, 31.3796],
							[50.5833, 31.3889],
							[50.5714, 31.3984],
							[50.5605, 31.3883],
							[50.492, 31.3866],
							[50.4572, 31.3955],
							[50.4458, 31.3952],
							[50.4105, 31.4139],
							[50.3757, 31.4227],
							[50.3523, 31.4319],
							[50.3045, 31.4698],
							[50.2576, 31.4879],
							[50.2685, 31.4981],
							[50.268, 31.5079],
							[50.2789, 31.5181],
							[50.2784, 31.5279],
							[50.2893, 31.538],
							[50.2888, 31.5478],
							[50.3325, 31.5884],
							[50.3439, 31.5887],
							[50.3548, 31.5988],
							[50.3543, 31.6086],
							[50.3652, 31.6187],
							[50.3629, 31.6578],
							[50.3148, 31.6956],
							[50.3131, 31.725],
							[50.3004, 31.7441],
							[50.2769, 31.7532],
							[50.2649, 31.7626],
							[50.2534, 31.7623],
							[50.2413, 31.7717],
							[50.228, 31.8006],
							[50.2274, 31.8104],
							[50.2032, 31.8292],
							[50.1917, 31.8288],
							[50.1796, 31.8382],
							[50.1668, 31.8573],
							[50.1547, 31.8667],
							[50.154, 31.8764],
							[50.1412, 31.8956],
							[50.129, 31.9049],
							[50.1033, 31.9431],
							[50.102, 31.9626],
							[50.087, 32.0109],
							[50.0849, 32.0401],
							[50.0727, 32.0495],
							[50.0713, 32.069],
							[50.059, 32.0783],
							[50.0359, 32.0775],
							[50.0237, 32.0868],
							[50.0006, 32.086],
							[49.9999, 32.0957],
							[49.9754, 32.1144],
							[49.9631, 32.1237],
							[49.9515, 32.1233],
							[49.9378, 32.1521],
							[49.9248, 32.1711],
							[49.9233, 32.1906],
							[49.8711, 32.2669],
							[49.8588, 32.2762],
							[49.8334, 32.3046],
							[49.7955, 32.3422],
							[49.7831, 32.3515],
							[49.77, 32.3706],
							[49.7677, 32.3999],
							[49.7546, 32.4189],
							[49.7399, 32.4575],
							[49.7361, 32.5064],
							[49.7587, 32.5171],
							[49.7931, 32.5283],
							[49.8047, 32.5287],
							[49.8157, 32.5389],
							[49.8494, 32.5599],
							[49.861, 32.5603],
							[49.872, 32.5705],
							[49.9057, 32.5914],
							[49.9408, 32.5928],
							[49.9518, 32.603],
							[49.9635, 32.6034],
							[49.9882, 32.5847],
							[49.9998, 32.5851],
							[50.0342, 32.5962],
							[50.0575, 32.597],
							[50.0796, 32.6174],
							[50.0912, 32.6178],
							[50.1379, 32.6194],
							[50.1747, 32.5913],
							[50.1998, 32.5627],
							[50.2365, 32.5345],
							[50.2481, 32.5349],
							[50.272, 32.5258],
							[50.283, 32.536],
							[50.3062, 32.5367],
							[50.3288, 32.5472],
							[50.3636, 32.5483],
							[50.388, 32.5294],
							[50.3996, 32.5297],
							[50.4221, 32.5402],
							[50.4337, 32.5405],
							[50.4888, 32.5912],
							[50.5004, 32.5915],
							[50.5114, 32.6017],
							[50.523, 32.602],
							[50.5457, 32.6124],
							[50.592, 32.6137],
							[50.6847, 32.6161],
							[50.6968, 32.6066],
							[50.7084, 32.6069],
							[50.731, 32.6173],
							[50.7426, 32.6176],
							[50.7537, 32.6277],
							[50.7532, 32.6375],
							[50.7753, 32.6577],
							[50.7869, 32.658],
							[50.798, 32.6681],
							[50.8212, 32.6686],
							[50.8217, 32.6588],
							[50.8332, 32.6591],
							[50.8695, 32.6304],
							[50.8705, 32.6107],
							[50.8599, 32.5908],
							[50.8619, 32.5515],
							[50.9198, 32.5528],
							[50.9213, 32.5233],
							[50.9097, 32.5231],
							[50.9103, 32.5132],
							[50.9223, 32.5037],
							[50.9349, 32.4843],
							[50.9591, 32.4651],
							[50.9617, 32.416],
							[50.9177, 32.3759],
							[50.9194, 32.3465],
							[50.932, 32.3272],
							[50.9441, 32.3176],
							[50.9556, 32.3178],
							[51.0028, 32.2991],
							[51.0746, 32.2513],
							[51.0752, 32.2415],
							[51.0993, 32.2223],
							[51.1348, 32.2033],
							[51.1354, 32.1935],
							[51.1474, 32.1839],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "East Azarbaijan", name: "آذربایجان شرقی" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[46.3816, 36.9745],
							[46.3706, 36.9644],
							[46.358, 36.9634],
							[46.3469, 36.9533],
							[46.35, 36.9349],
							[46.3389, 36.9247],
							[46.342, 36.9063],
							[46.3293, 36.9054],
							[46.3308, 36.8962],
							[46.3197, 36.8861],
							[46.307, 36.8851],
							[46.2832, 36.8741],
							[46.2705, 36.8731],
							[46.2422, 36.8896],
							[46.2265, 36.9071],
							[46.2123, 36.9154],
							[46.2109, 36.9246],
							[46.1982, 36.9237],
							[46.1826, 36.9412],
							[46.1558, 36.9485],
							[46.1416, 36.9568],
							[46.1148, 36.9642],
							[46.1021, 36.9632],
							[46.0739, 36.9798],
							[46.0725, 36.9891],
							[46.057, 37.0066],
							[46.0542, 37.0251],
							[46.026, 37.0418],
							[46.0134, 37.0409],
							[45.9993, 37.0492],
							[45.9867, 37.0483],
							[45.9853, 37.0576],
							[45.9713, 37.0659],
							[45.9699, 37.0752],
							[45.9559, 37.0835],
							[45.9546, 37.0928],
							[45.9392, 37.1104],
							[45.9113, 37.1271],
							[45.8736, 37.1244],
							[45.85, 37.1133],
							[45.8251, 37.1114],
							[45.7896, 37.0993],
							[45.7286, 37.0947],
							[45.7151, 37.1031],
							[45.7029, 37.1022],
							[45.7015, 37.1115],
							[45.6743, 37.1283],
							[45.673, 37.1376],
							[45.6323, 37.1628],
							[45.62, 37.162],
							[45.6187, 37.1713],
							[45.5929, 37.1789],
							[45.5658, 37.1959],
							[45.5128, 37.2206],
							[45.4857, 37.2377],
							[45.4734, 37.2369],
							[45.4204, 37.2619],
							[45.3945, 37.2697],
							[45.3933, 37.2791],
							[45.3797, 37.2877],
							[45.3649, 37.3056],
							[45.3514, 37.3143],
							[45.339, 37.4077],
							[45.3475, 37.4364],
							[45.3547, 37.4744],
							[45.3521, 37.4931],
							[45.3631, 37.5031],
							[45.3605, 37.5218],
							[45.3445, 37.5492],
							[45.3323, 37.5486],
							[45.3189, 37.5573],
							[45.2907, 37.5842],
							[45.2894, 37.5936],
							[45.2772, 37.593],
							[45.2746, 37.6118],
							[45.2611, 37.6206],
							[45.2598, 37.63],
							[45.245, 37.6483],
							[45.2286, 37.676],
							[45.2091, 37.8198],
							[45.2168, 37.885],
							[45.2124, 38.0386],
							[45.1998, 38.0708],
							[45.1996, 38.0928],
							[45.1873, 38.1139],
							[45.1872, 38.1249],
							[45.175, 38.1459],
							[45.1628, 38.156],
							[45.1628, 38.1668],
							[45.1142, 38.2073],
							[45.1143, 38.218],
							[45.1021, 38.2281],
							[45.09, 38.2276],
							[45.0892, 38.3012],
							[45.101, 38.3122],
							[45.1121, 38.3442],
							[45.2157, 38.4428],
							[45.2858, 38.488],
							[45.3554, 38.5439],
							[45.3548, 38.5541],
							[45.3663, 38.5651],
							[45.3652, 38.5855],
							[45.3762, 38.6067],
							[45.3756, 38.6169],
							[45.3629, 38.6263],
							[45.3624, 38.6364],
							[45.3267, 38.7015],
							[45.3202, 38.7542],
							[45.3314, 38.7654],
							[45.3409, 38.797],
							[45.3523, 38.808],
							[45.3486, 38.858],
							[45.3602, 38.8689],
							[45.3596, 38.8787],
							[45.3718, 38.8798],
							[45.3713, 38.8896],
							[45.3956, 38.9016],
							[45.4526, 38.9449],
							[45.4543, 38.9541],
							[45.4883, 38.977],
							[45.5112, 38.9706],
							[45.5366, 38.9586],
							[45.5453, 38.9575],
							[45.5504, 38.9584],
							[45.5687, 38.9666],
							[45.5773, 38.9654],
							[45.5892, 38.9554],
							[45.6047, 38.952],
							[45.6074, 38.9493],
							[45.6075, 38.9434],
							[45.6059, 38.9416],
							[45.6086, 38.9389],
							[45.6172, 38.9384],
							[45.6493, 38.9484],
							[45.663, 38.9475],
							[45.6761, 38.9431],
							[45.7106, 38.9373],
							[45.7169, 38.9328],
							[45.7201, 38.9264],
							[45.7368, 38.9227],
							[45.7675, 38.9215],
							[45.7921, 38.914],
							[45.8213, 38.8965],
							[45.8412, 38.9016],
							[45.851, 38.9006],
							[45.8566, 38.8975],
							[45.8619, 38.8873],
							[45.8676, 38.8831],
							[45.8786, 38.8809],
							[45.9046, 38.8704],
							[45.9153, 38.868],
							[45.921, 38.8698],
							[45.9399, 38.8845],
							[45.9463, 38.8865],
							[46.0, 38.869],
							[46.0, 38.8202],
							[46.0109, 38.8222],
							[46.0129, 38.8264],
							[46.0112, 38.8371],
							[46.0127, 38.8466],
							[46.0201, 38.8598],
							[46.0195, 38.8671],
							[46.0364, 38.865],
							[46.0498, 38.8664],
							[46.0547, 38.871],
							[46.0568, 38.8807],
							[46.0629, 38.8812],
							[46.0755, 38.8676],
							[46.0853, 38.8624],
							[46.1042, 38.8572],
							[46.1113, 38.8529],
							[46.1234, 38.8405],
							[46.1369, 38.8348],
							[46.1776, 38.8305],
							[46.1831, 38.8313],
							[46.1966, 38.8418],
							[46.2087, 38.8444],
							[46.2236, 38.856],
							[46.2366, 38.8582],
							[46.2509, 38.8658],
							[46.2717, 38.8852],
							[46.2866, 38.8925],
							[46.2909, 38.8937],
							[46.2981, 38.8917],
							[46.3087, 38.8954],
							[46.3197, 38.8928],
							[46.3462, 38.9062],
							[46.3591, 38.9073],
							[46.379, 38.8993],
							[46.3901, 38.8968],
							[46.4209, 38.8776],
							[46.4259, 38.8776],
							[46.4344, 38.8852],
							[46.4446, 38.8893],
							[46.4539, 38.8907],
							[46.4586, 38.8902],
							[46.4703, 38.8825],
							[46.4835, 38.8792],
							[46.5044, 38.8818],
							[46.5121, 38.8842],
							[46.5178, 38.8836],
							[46.5218, 38.8806],
							[46.5243, 38.8671],
							[46.5337, 38.8649],
							[46.5472, 38.8727],
							[46.5705, 38.8904],
							[46.6092, 38.9155],
							[46.627, 38.9373],
							[46.6457, 38.9492],
							[46.6587, 38.9619],
							[46.6728, 38.9791],
							[46.6777, 38.9882],
							[46.6938, 38.9978],
							[46.7029, 39.007],
							[46.7295, 39.0083],
							[46.755, 39.0239],
							[46.7598, 39.034],
							[46.7638, 39.054],
							[46.7991, 39.0876],
							[46.8403, 39.1192],
							[46.8527, 39.1396],
							[46.8705, 39.1471],
							[46.8884, 39.1523],
							[46.9079, 39.1555],
							[46.9247, 39.1563],
							[46.9369, 39.1448],
							[46.9548, 39.1325],
							[46.9956, 39.152],
							[46.9976, 39.162],
							[47.0131, 39.1628],
							[47.0332, 39.1758],
							[47.0473, 39.194],
							[47.0604, 39.2254],
							[47.0647, 39.2431],
							[47.0832, 39.2594],
							[47.0992, 39.2711],
							[47.1437, 39.2984],
							[47.2106, 39.3114],
							[47.2275, 39.3308],
							[47.3106, 39.3719],
							[47.3253, 39.3836],
							[47.3379, 39.4039],
							[47.3454, 39.4112],
							[47.3825, 39.388],
							[47.3991, 39.3601],
							[47.4261, 39.3521],
							[47.4565, 39.3157],
							[47.4667, 39.2303],
							[47.4585, 39.1915],
							[47.4743, 39.1726],
							[47.4803, 39.1335],
							[47.4961, 39.1145],
							[47.5104, 39.1054],
							[47.526, 39.0864],
							[47.5425, 39.058],
							[47.5326, 39.0291],
							[47.5213, 39.0099],
							[47.497, 38.9902],
							[47.4986, 38.9714],
							[47.5254, 38.9629],
							[47.5269, 38.9441],
							[47.5413, 38.9256],
							[47.5299, 38.906],
							[47.5195, 38.8763],
							[47.521, 38.8568],
							[47.5482, 38.8387],
							[47.5892, 38.8016],
							[47.5911, 38.7628],
							[47.6052, 38.7245],
							[47.5926, 38.7045],
							[47.5668, 38.684],
							[47.5016, 38.6616],
							[47.5143, 38.6428],
							[47.5271, 38.6338],
							[47.5787, 38.6168],
							[47.63, 38.59],
							[47.629, 38.5516],
							[47.5883, 38.5213],
							[47.5757, 38.5303],
							[47.5367, 38.5382],
							[47.5103, 38.537],
							[47.4713, 38.5449],
							[47.4315, 38.543],
							[47.4015, 38.5129],
							[47.3675, 38.4728],
							[47.3267, 38.4707],
							[47.3097, 38.4505],
							[47.2777, 38.4198],
							[47.2717, 38.3803],
							[47.2301, 38.3677],
							[47.2154, 38.3566],
							[47.2141, 38.3464],
							[47.2249, 38.3265],
							[47.2225, 38.305],
							[47.2082, 38.2822],
							[47.2214, 38.2511],
							[47.2467, 38.2428],
							[47.3127, 38.2921],
							[47.3393, 38.2943],
							[47.3513, 38.2849],
							[47.3742, 38.255],
							[47.3733, 38.2442],
							[47.4117, 38.237],
							[47.4765, 38.2322],
							[47.6546, 38.2739],
							[47.6799, 38.2654],
							[47.7274, 38.2285],
							[47.7498, 38.1999],
							[47.7406, 38.1158],
							[47.7544, 38.074],
							[47.7579, 38.0313],
							[47.7728, 38.0003],
							[47.7746, 37.979],
							[47.8144, 37.9502],
							[47.8419, 37.9203],
							[47.8943, 37.8924],
							[47.9345, 37.8635],
							[47.9355, 37.8528],
							[47.9739, 37.8452],
							[47.9634, 37.8228],
							[47.9178, 37.7758],
							[47.9029, 37.6879],
							[47.9179, 37.598],
							[47.9414, 37.5414],
							[47.9576, 37.5231],
							[48.0219, 37.5178],
							[48.0737, 37.5116],
							[48.1402, 37.4966],
							[48.1627, 37.4499],
							[48.1833, 37.4128],
							[48.181, 37.3648],
							[48.227, 37.3295],
							[48.2563, 37.3122],
							[48.313, 37.287],
							[48.3677, 37.2712],
							[48.4246, 37.246],
							[48.4437, 37.2185],
							[48.4311, 37.2178],
							[48.4206, 37.2077],
							[48.387, 37.1868],
							[48.366, 37.1665],
							[48.3281, 37.1643],
							[48.3007, 37.1722],
							[48.2755, 37.1707],
							[48.2482, 37.1786],
							[48.2334, 37.1872],
							[48.2082, 37.1857],
							[48.1935, 37.1943],
							[48.1662, 37.2021],
							[48.1411, 37.2005],
							[48.0803, 37.1873],
							[47.9924, 37.1817],
							[47.9777, 37.1903],
							[47.9401, 37.1878],
							[47.8858, 37.2033],
							[47.8503, 37.1915],
							[47.8377, 37.1906],
							[47.8148, 37.1797],
							[47.7396, 37.1747],
							[47.725, 37.1831],
							[47.675, 37.1798],
							[47.6645, 37.1696],
							[47.602, 37.1653],
							[47.5686, 37.1442],
							[47.5457, 37.1332],
							[47.5332, 37.1323],
							[47.5248, 37.1129],
							[47.5038, 37.0927],
							[47.4808, 37.0817],
							[47.4557, 37.08],
							[47.4452, 37.0699],
							[47.4096, 37.0581],
							[47.3779, 37.0277],
							[47.3799, 37.0185],
							[47.348, 36.9881],
							[47.3498, 36.9788],
							[47.3282, 36.9584],
							[47.3156, 36.9576],
							[47.2688, 36.9354],
							[47.225, 36.8943],
							[47.2155, 36.8745],
							[47.1933, 36.8538],
							[47.1849, 36.8243],
							[47.1737, 36.814],
							[47.1776, 36.7851],
							[47.1938, 36.7569],
							[47.1801, 36.7658],
							[47.1551, 36.7643],
							[47.1438, 36.7538],
							[47.0435, 36.7476],
							[47.0082, 36.726],
							[46.9705, 36.7236],
							[46.9693, 36.7332],
							[46.9568, 36.7324],
							[46.9555, 36.742],
							[46.9052, 36.7388],
							[46.8283, 36.7433],
							[46.8144, 36.752],
							[46.8091, 36.7899],
							[46.83, 36.82],
							[46.8397, 36.8396],
							[46.8335, 36.877],
							[46.8049, 36.8937],
							[46.7728, 36.9287],
							[46.7711, 36.9379],
							[46.7837, 36.9388],
							[46.7819, 36.948],
							[46.8036, 36.9683],
							[46.8019, 36.9775],
							[46.7876, 36.9858],
							[46.7858, 36.995],
							[46.7573, 37.0116],
							[46.7447, 37.0107],
							[46.7179, 37.018],
							[46.6803, 37.0153],
							[46.6786, 37.0245],
							[46.6643, 37.0328],
							[46.661, 37.0513],
							[46.6326, 37.0679],
							[46.5574, 37.0623],
							[46.5465, 37.0522],
							[46.5514, 37.0245],
							[46.5137, 37.0217],
							[46.4996, 37.03],
							[46.487, 37.0291],
							[46.4651, 37.0087],
							[46.4147, 37.005],
							[46.3816, 36.9745],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Esfahan", name: "اصفهان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[51.2297, 31.7142],
							[51.2406, 31.7242],
							[51.2402, 31.734],
							[51.2974, 31.7347],
							[51.3083, 31.7447],
							[51.3074, 31.7644],
							[51.2955, 31.774],
							[51.295, 31.7839],
							[51.2836, 31.7837],
							[51.2359, 31.8224],
							[51.2244, 31.8222],
							[51.2234, 31.8419],
							[51.2344, 31.8518],
							[51.2339, 31.8617],
							[51.2449, 31.8716],
							[51.2563, 31.8718],
							[51.2673, 31.8817],
							[51.3011, 31.892],
							[51.3121, 31.902],
							[51.3116, 31.9118],
							[51.3221, 31.9315],
							[51.3185, 32.0002],
							[51.3038, 32.0588],
							[51.3027, 32.0784],
							[51.2907, 32.088],
							[51.2782, 32.1074],
							[51.2541, 32.1267],
							[51.2311, 32.1264],
							[51.2071, 32.1456],
							[51.1835, 32.155],
							[51.1354, 32.1935],
							[51.1348, 32.2033],
							[51.0993, 32.2223],
							[51.0752, 32.2415],
							[51.0746, 32.2513],
							[51.0028, 32.2991],
							[50.9556, 32.3178],
							[50.9441, 32.3176],
							[50.932, 32.3272],
							[50.9194, 32.3465],
							[50.9177, 32.3759],
							[50.9617, 32.416],
							[50.9591, 32.4651],
							[50.9349, 32.4843],
							[50.9223, 32.5037],
							[50.9103, 32.5132],
							[50.9097, 32.5231],
							[50.9213, 32.5233],
							[50.9198, 32.5528],
							[50.8619, 32.5515],
							[50.8599, 32.5908],
							[50.8705, 32.6107],
							[50.8695, 32.6304],
							[50.8332, 32.6591],
							[50.8217, 32.6588],
							[50.8212, 32.6686],
							[50.798, 32.6681],
							[50.7869, 32.658],
							[50.7753, 32.6577],
							[50.7532, 32.6375],
							[50.7537, 32.6277],
							[50.7426, 32.6176],
							[50.731, 32.6173],
							[50.7084, 32.6069],
							[50.6968, 32.6066],
							[50.6847, 32.6161],
							[50.592, 32.6137],
							[50.5457, 32.6124],
							[50.523, 32.602],
							[50.5114, 32.6017],
							[50.5004, 32.5915],
							[50.4888, 32.5912],
							[50.4337, 32.5405],
							[50.4221, 32.5402],
							[50.3996, 32.5297],
							[50.388, 32.5294],
							[50.3636, 32.5483],
							[50.3288, 32.5472],
							[50.3062, 32.5367],
							[50.283, 32.536],
							[50.272, 32.5258],
							[50.2481, 32.5349],
							[50.2365, 32.5345],
							[50.1998, 32.5627],
							[50.1747, 32.5913],
							[50.1379, 32.6194],
							[50.0912, 32.6178],
							[50.0796, 32.6174],
							[50.0575, 32.597],
							[50.0342, 32.5962],
							[49.9998, 32.5851],
							[49.9882, 32.5847],
							[49.9635, 32.6034],
							[49.9518, 32.603],
							[49.9408, 32.5928],
							[49.9057, 32.5914],
							[49.872, 32.5705],
							[49.861, 32.5603],
							[49.8494, 32.5599],
							[49.8157, 32.5389],
							[49.8047, 32.5287],
							[49.7931, 32.5283],
							[49.7587, 32.5171],
							[49.7361, 32.5064],
							[49.7339, 32.5357],
							[49.7441, 32.5557],
							[49.7551, 32.566],
							[49.7756, 32.606],
							[49.7984, 32.6167],
							[49.797, 32.6363],
							[49.7348, 32.6829],
							[49.7231, 32.6825],
							[49.7106, 32.6918],
							[49.6047, 32.6875],
							[49.5466, 32.6752],
							[49.523, 32.6742],
							[49.5105, 32.6835],
							[49.5097, 32.6933],
							[49.4971, 32.7026],
							[49.4956, 32.7222],
							[49.4674, 32.7799],
							[49.4666, 32.7897],
							[49.454, 32.799],
							[49.4503, 32.8481],
							[49.4622, 32.8486],
							[49.4727, 32.8687],
							[49.4846, 32.8692],
							[49.4958, 32.8795],
							[49.5077, 32.88],
							[49.5308, 32.8909],
							[49.5664, 32.8923],
							[49.5895, 32.9032],
							[49.6013, 32.9036],
							[49.6237, 32.9243],
							[49.6356, 32.9248],
							[49.6468, 32.9351],
							[49.6443, 32.9743],
							[49.6181, 33.0126],
							[49.6157, 33.0519],
							[49.627, 33.0622],
							[49.662, 33.0734],
							[49.6739, 33.0739],
							[49.6852, 33.0842],
							[49.6841, 33.1038],
							[49.6954, 33.1141],
							[49.731, 33.1155],
							[49.7552, 33.1066],
							[49.7665, 33.1169],
							[49.7897, 33.1276],
							[49.8607, 33.1304],
							[49.8602, 33.1402],
							[49.8716, 33.1504],
							[49.8701, 33.1799],
							[49.8815, 33.1901],
							[49.881, 33.2],
							[49.9035, 33.2303],
							[49.9257, 33.2704],
							[49.9251, 33.29],
							[49.9367, 33.3003],
							[49.9479, 33.3204],
							[49.9826, 33.3511],
							[49.9939, 33.3711],
							[49.9926, 33.4103],
							[49.9804, 33.4196],
							[49.9566, 33.4187],
							[49.9444, 33.4281],
							[49.9438, 33.4476],
							[49.9669, 33.4681],
							[49.9897, 33.4983],
							[50.0015, 33.4987],
							[50.0127, 33.5187],
							[50.0589, 33.5594],
							[50.1057, 33.5807],
							[50.1172, 33.5908],
							[50.2338, 33.6436],
							[50.2926, 33.6553],
							[50.3752, 33.658],
							[50.4217, 33.679],
							[50.4335, 33.6793],
							[50.4567, 33.6898],
							[50.5509, 33.6927],
							[50.5741, 33.7031],
							[50.5977, 33.7038],
							[50.6326, 33.7146],
							[50.6791, 33.7354],
							[50.7141, 33.7461],
							[50.7484, 33.7764],
							[50.7831, 33.7968],
							[50.8175, 33.827],
							[50.8293, 33.8274],
							[50.8413, 33.8179],
							[50.8769, 33.8091],
							[50.8886, 33.8094],
							[50.8889, 33.7997],
							[50.9007, 33.8],
							[50.9236, 33.8201],
							[50.9233, 33.8299],
							[50.9345, 33.8497],
							[50.934, 33.8692],
							[50.9211, 33.9079],
							[50.9322, 33.9277],
							[50.932, 33.9374],
							[50.9434, 33.9475],
							[50.9667, 33.9579],
							[50.9896, 33.978],
							[51.0129, 33.9883],
							[51.0241, 34.0081],
							[51.035, 34.0377],
							[51.0465, 34.0477],
							[51.0451, 34.0964],
							[51.033, 34.1059],
							[50.9974, 34.1147],
							[50.9733, 34.1335],
							[50.973, 34.1432],
							[50.9842, 34.1631],
							[50.9839, 34.1729],
							[50.995, 34.2028],
							[50.9945, 34.2226],
							[51.0056, 34.2527],
							[51.0043, 34.3127],
							[51.0154, 34.3532],
							[51.015, 34.3733],
							[51.0265, 34.3836],
							[51.0379, 34.4041],
							[51.0609, 34.4248],
							[51.0843, 34.4355],
							[51.0961, 34.4359],
							[51.1312, 34.4469],
							[51.143, 34.4472],
							[51.1781, 34.4582],
							[51.225, 34.4694],
							[51.4372, 34.4745],
							[51.4964, 34.4658],
							[51.6143, 34.4683],
							[51.6611, 34.4793],
							[51.6729, 34.4795],
							[51.6962, 34.49],
							[51.708, 34.4902],
							[51.7545, 34.5112],
							[51.7889, 34.542],
							[51.8253, 34.5126],
							[51.8849, 34.4936],
							[51.8971, 34.4837],
							[51.9331, 34.4643],
							[52.0045, 34.4455],
							[52.0163, 34.4457],
							[52.0521, 34.4362],
							[52.0876, 34.4369],
							[52.111, 34.4473],
							[52.1583, 34.4482],
							[52.1817, 34.4586],
							[52.2291, 34.4595],
							[52.3477, 34.4615],
							[52.3717, 34.4519],
							[52.4192, 34.4527],
							[52.455, 34.4433],
							[52.479, 34.4336],
							[52.5744, 34.4152],
							[52.5984, 34.4055],
							[52.6342, 34.3961],
							[52.6581, 34.3864],
							[52.6702, 34.3766],
							[52.7059, 34.3671],
							[52.7536, 34.3579],
							[52.825, 34.3389],
							[52.8369, 34.3391],
							[52.8409, 34.3291],
							[52.8844, 34.3298],
							[52.9082, 34.3201],
							[53.1689, 34.324],
							[53.2401, 34.315],
							[53.5361, 34.3188],
							[53.6069, 34.3297],
							[53.9382, 34.3332],
							[53.9616, 34.3435],
							[54.0089, 34.344],
							[54.0915, 34.3548],
							[54.4231, 34.3575],
							[54.4583, 34.3678],
							[55.0285, 34.371],
							[55.1233, 34.3813],
							[55.1586, 34.3914],
							[55.2658, 34.3916],
							[55.3021, 34.3817],
							[55.3141, 34.3817],
							[55.3384, 34.3717],
							[55.3509, 34.3618],
							[55.3628, 34.3618],
							[55.3633, 34.3518],
							[55.3752, 34.342],
							[55.375, 34.2133],
							[55.387, 34.1834],
							[55.3874, 34.0352],
							[55.3996, 33.9757],
							[55.4011, 33.7095],
							[55.3893, 33.6901],
							[55.3894, 33.6507],
							[55.3776, 33.6312],
							[55.3539, 33.5825],
							[55.3421, 33.5631],
							[55.3421, 33.5532],
							[55.3302, 33.5338],
							[55.3183, 33.5242],
							[55.2946, 33.4952],
							[55.2826, 33.4757],
							[55.2469, 33.437],
							[55.235, 33.4268],
							[55.1876, 33.4361],
							[55.1046, 33.425],
							[55.0572, 33.4244],
							[54.9976, 33.3736],
							[54.9975, 33.3636],
							[54.9855, 33.3435],
							[54.9852, 33.3134],
							[54.9733, 33.3033],
							[54.9728, 33.2632],
							[54.9604, 33.223],
							[54.9603, 33.213],
							[54.9359, 33.1626],
							[54.9233, 33.1124],
							[54.9113, 33.1023],
							[54.899, 33.0721],
							[54.8745, 33.0218],
							[54.8505, 33.0016],
							[54.8383, 32.9815],
							[54.8024, 32.9612],
							[54.7783, 32.941],
							[54.7067, 32.9105],
							[54.6591, 32.9001],
							[54.6472, 32.9],
							[54.5044, 32.8591],
							[54.4569, 32.8488],
							[54.3974, 32.8384],
							[54.3498, 32.828],
							[54.338, 32.8279],
							[54.2904, 32.8176],
							[54.2667, 32.8174],
							[54.1597, 32.7865],
							[54.0881, 32.7459],
							[53.9932, 32.7151],
							[53.922, 32.6845],
							[53.9103, 32.6844],
							[53.8748, 32.6741],
							[53.8629, 32.664],
							[53.7924, 32.6335],
							[53.7105, 32.613],
							[53.6169, 32.6123],
							[53.5817, 32.622],
							[53.5348, 32.6416],
							[53.4997, 32.6414],
							[53.4412, 32.6509],
							[53.3709, 32.6703],
							[53.3476, 32.6701],
							[53.3008, 32.6797],
							[53.2776, 32.6695],
							[53.231, 32.6692],
							[53.1733, 32.6389],
							[53.15, 32.6387],
							[53.1158, 32.6086],
							[53.1042, 32.6086],
							[53.0812, 32.5984],
							[53.0587, 32.5685],
							[53.0474, 32.5585],
							[53.0245, 32.5484],
							[52.9784, 32.5382],
							[52.932, 32.5378],
							[52.9091, 32.5277],
							[52.8975, 32.5276],
							[52.8866, 32.5077],
							[52.8753, 32.4978],
							[52.8791, 32.389],
							[52.8924, 32.3397],
							[52.8954, 32.2607],
							[52.9085, 32.2213],
							[52.9108, 32.1621],
							[52.9, 32.1423],
							[52.9015, 32.1029],
							[52.8903, 32.0929],
							[52.8911, 32.0732],
							[52.8596, 31.984],
							[52.8487, 31.9641],
							[52.8382, 31.9343],
							[52.8409, 31.8452],
							[52.8297, 31.8352],
							[52.8193, 31.7955],
							[52.8216, 31.7163],
							[52.8336, 31.6965],
							[52.846, 31.6668],
							[52.858, 31.647],
							[52.8697, 31.6372],
							[52.8708, 31.5975],
							[52.8478, 31.5974],
							[52.8252, 31.5874],
							[52.7569, 31.5673],
							[52.7117, 31.5473],
							[52.6092, 31.5271],
							[52.5751, 31.5171],
							[52.5522, 31.517],
							[52.5296, 31.507],
							[52.5068, 31.5069],
							[52.4493, 31.5166],
							[52.4035, 31.5164],
							[52.3689, 31.5261],
							[52.3574, 31.5261],
							[52.3343, 31.5358],
							[52.3228, 31.5358],
							[52.2996, 31.5455],
							[52.231, 31.5452],
							[52.2193, 31.555],
							[52.2078, 31.555],
							[52.1843, 31.5746],
							[52.1839, 31.5845],
							[52.1722, 31.5943],
							[52.1715, 31.614],
							[52.1815, 31.6536],
							[52.1808, 31.6733],
							[52.1687, 31.693],
							[52.168, 31.7128],
							[52.1561, 31.7226],
							[52.1447, 31.7225],
							[52.1336, 31.7125],
							[52.134, 31.7027],
							[52.1229, 31.6927],
							[52.1114, 31.6927],
							[52.1003, 31.6827],
							[52.0317, 31.6823],
							[52.0095, 31.6624],
							[52.0103, 31.6426],
							[51.9882, 31.6228],
							[51.9767, 31.6227],
							[51.9657, 31.6127],
							[51.9085, 31.6124],
							[51.9089, 31.6025],
							[51.8978, 31.5926],
							[51.8872, 31.5728],
							[51.8761, 31.5628],
							[51.8537, 31.5528],
							[51.8426, 31.5429],
							[51.8312, 31.5428],
							[51.8088, 31.5328],
							[51.7977, 31.5228],
							[51.7407, 31.5224],
							[51.7289, 31.5322],
							[51.7175, 31.5321],
							[51.6596, 31.5514],
							[51.6482, 31.5513],
							[51.6372, 31.5413],
							[51.6258, 31.5412],
							[51.6262, 31.5314],
							[51.638, 31.5216],
							[51.6384, 31.5118],
							[51.6502, 31.502],
							[51.6742, 31.4726],
							[51.6863, 31.453],
							[51.7327, 31.4336],
							[51.7331, 31.4238],
							[51.8015, 31.4242],
							[51.8364, 31.4047],
							[51.8478, 31.4048],
							[51.8713, 31.3852],
							[51.8717, 31.3754],
							[51.8834, 31.3656],
							[51.8844, 31.336],
							[51.8523, 31.2766],
							[51.853, 31.2569],
							[51.8419, 31.247],
							[51.8426, 31.2272],
							[51.8325, 31.1877],
							[51.8341, 31.1384],
							[51.7899, 31.0987],
							[51.7902, 31.0889],
							[51.7684, 31.0592],
							[51.758, 31.0295],
							[51.7479, 30.99],
							[51.7372, 30.9702],
							[51.7267, 30.9405],
							[51.7282, 30.8912],
							[51.7407, 30.8518],
							[51.7528, 30.8223],
							[51.7537, 30.7926],
							[51.7424, 30.7926],
							[51.7426, 30.7827],
							[51.6974, 30.7825],
							[51.6638, 30.7724],
							[51.6412, 30.7723],
							[51.618, 30.7918],
							[51.6177, 30.8017],
							[51.5835, 30.8114],
							[51.5603, 30.8309],
							[51.549, 30.8309],
							[51.5147, 30.8405],
							[51.3751, 30.9577],
							[51.351, 30.9969],
							[51.3394, 31.0067],
							[51.3032, 31.0655],
							[51.2998, 31.1541],
							[51.3108, 31.1641],
							[51.332, 31.2037],
							[51.3296, 31.2627],
							[51.3158, 31.3217],
							[51.3149, 31.3414],
							[51.3019, 31.3806],
							[51.3015, 31.3904],
							[51.2647, 31.4491],
							[51.2406, 31.4783],
							[51.2283, 31.4979],
							[51.2274, 31.5175],
							[51.2151, 31.5371],
							[51.2073, 31.7041],
							[51.2183, 31.7141],
							[51.2297, 31.7142],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Fars", name: "فارس" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[52.9134, 27.4173],
							[52.8819, 27.4316],
							[52.8258, 27.4802],
							[52.7927, 27.4905],
							[52.7478, 27.5293],
							[52.6584, 27.5881],
							[52.6247, 27.6171],
							[52.6025, 27.6271],
							[52.58, 27.6464],
							[52.5572, 27.6752],
							[52.5342, 27.7134],
							[52.5224, 27.742],
							[52.5109, 27.7612],
							[52.5107, 27.7708],
							[52.4994, 27.7805],
							[52.4877, 27.8094],
							[52.4762, 27.8287],
							[52.4528, 27.8868],
							[52.4523, 27.9062],
							[52.4296, 27.9353],
							[52.4179, 27.9644],
							[52.3947, 28.0129],
							[52.3609, 28.042],
							[52.3381, 28.071],
							[52.3266, 28.0904],
							[52.3043, 28.1001],
							[52.304, 28.1098],
							[52.2815, 28.1291],
							[52.2812, 28.1388],
							[52.2373, 28.1289],
							[52.2147, 28.1483],
							[52.2036, 28.1482],
							[52.181, 28.1675],
							[52.1806, 28.187],
							[52.1911, 28.2065],
							[52.1899, 28.255],
							[52.1554, 28.3034],
							[52.1206, 28.3614],
							[52.0971, 28.4098],
							[52.0733, 28.4678],
							[52.0617, 28.4871],
							[52.0498, 28.5161],
							[52.0477, 28.5838],
							[52.0361, 28.6032],
							[52.0241, 28.6322],
							[52.0235, 28.6516],
							[52.0118, 28.6709],
							[51.9998, 28.6999],
							[51.9882, 28.7193],
							[51.9404, 28.8258],
							[51.9167, 28.8742],
							[51.9163, 28.8839],
							[51.9049, 28.8936],
							[51.8932, 28.913],
							[51.8818, 28.9226],
							[51.8814, 28.9323],
							[51.8586, 28.9517],
							[51.8236, 29.0002],
							[51.7888, 29.039],
							[51.7885, 29.0487],
							[51.7656, 29.0681],
							[51.7529, 29.1071],
							[51.7414, 29.1168],
							[51.7053, 29.185],
							[51.6814, 29.2241],
							[51.6568, 29.2732],
							[51.6083, 29.3521],
							[51.5834, 29.4015],
							[51.5602, 29.4211],
							[51.5264, 29.4308],
							[51.5148, 29.4406],
							[51.4926, 29.4405],
							[51.4472, 29.4599],
							[51.425, 29.4598],
							[51.4017, 29.4794],
							[51.3906, 29.4793],
							[51.344, 29.5186],
							[51.3318, 29.5384],
							[51.3201, 29.5482],
							[51.2962, 29.5778],
							[51.2845, 29.5876],
							[51.284, 29.5975],
							[51.2729, 29.5974],
							[51.2495, 29.6171],
							[51.2469, 29.6666],
							[51.2576, 29.6765],
							[51.2557, 29.7159],
							[51.2196, 29.7746],
							[51.208, 29.7844],
							[51.1852, 29.794],
							[51.1736, 29.8037],
							[51.1062, 29.8036],
							[51.0834, 29.8133],
							[51.049, 29.8325],
							[51.0265, 29.8325],
							[51.0036, 29.8421],
							[50.9022, 29.8419],
							[50.8801, 29.832],
							[50.8688, 29.8319],
							[50.8684, 29.8417],
							[50.8342, 29.8513],
							[50.7555, 29.8507],
							[50.7438, 29.8604],
							[50.7326, 29.8603],
							[50.7321, 29.87],
							[50.7205, 29.8796],
							[50.7195, 29.927],
							[50.6961, 29.9647],
							[50.6847, 29.9741],
							[50.6845, 29.9836],
							[50.673, 29.993],
							[50.6276, 30.0116],
							[50.6159, 30.0304],
							[50.5815, 30.0585],
							[50.5812, 30.068],
							[50.5688, 30.1061],
							[50.5679, 30.1348],
							[50.5789, 30.1445],
							[50.6015, 30.1449],
							[50.6012, 30.1545],
							[50.6576, 30.1553],
							[50.68, 30.1652],
							[50.691, 30.1751],
							[50.7023, 30.1752],
							[50.7365, 30.166],
							[50.7481, 30.1564],
							[50.7594, 30.1566],
							[50.7592, 30.1663],
							[50.7934, 30.157],
							[50.8273, 30.1574],
							[50.8271, 30.1671],
							[50.8384, 30.1673],
							[50.8613, 30.1578],
							[50.884, 30.158],
							[50.9178, 30.1683],
							[50.9291, 30.1684],
							[50.9516, 30.1786],
							[50.9739, 30.1987],
							[51.0075, 30.2291],
							[51.0073, 30.2391],
							[51.0188, 30.2293],
							[51.0529, 30.2299],
							[51.064, 30.2401],
							[51.0979, 30.2506],
							[51.1428, 30.2813],
							[51.1878, 30.302],
							[51.199, 30.3121],
							[51.1988, 30.3221],
							[51.2102, 30.3223],
							[51.2087, 30.402],
							[51.1743, 30.4213],
							[51.1627, 30.4311],
							[51.1625, 30.4411],
							[51.1737, 30.4512],
							[51.1848, 30.4613],
							[51.1962, 30.4615],
							[51.1958, 30.4813],
							[51.1726, 30.5009],
							[51.1717, 30.5406],
							[51.137, 30.5698],
							[51.1363, 30.5996],
							[51.1471, 30.6195],
							[51.1466, 30.6393],
							[51.1237, 30.6489],
							[51.1123, 30.6488],
							[51.1121, 30.6587],
							[51.1232, 30.6687],
							[51.2027, 30.6698],
							[51.2486, 30.6505],
							[51.2602, 30.6407],
							[51.2831, 30.6311],
							[51.3176, 30.6117],
							[51.3178, 30.6018],
							[51.3631, 30.6023],
							[51.3747, 30.5925],
							[51.386, 30.5926],
							[51.3868, 30.5629],
							[51.3983, 30.5531],
							[51.4096, 30.5532],
							[51.432, 30.5633],
							[51.4436, 30.5535],
							[51.4549, 30.5536],
							[51.4777, 30.5439],
							[51.5008, 30.5243],
							[51.5234, 30.5245],
							[51.5458, 30.5346],
							[51.5684, 30.5347],
							[51.602, 30.5449],
							[51.6243, 30.5549],
							[51.6354, 30.5648],
							[51.6577, 30.5749],
							[51.6575, 30.5847],
							[51.6688, 30.5848],
							[51.6683, 30.6046],
							[51.6796, 30.6046],
							[51.6788, 30.6342],
							[51.6662, 30.6835],
							[51.6657, 30.7033],
							[51.6533, 30.7427],
							[51.6412, 30.7723],
							[51.6638, 30.7724],
							[51.6974, 30.7825],
							[51.7426, 30.7827],
							[51.7424, 30.7926],
							[51.7537, 30.7926],
							[51.7528, 30.8223],
							[51.7407, 30.8518],
							[51.7282, 30.8912],
							[51.7267, 30.9405],
							[51.7372, 30.9702],
							[51.7479, 30.99],
							[51.758, 31.0295],
							[51.7684, 31.0592],
							[51.7902, 31.0889],
							[51.7899, 31.0987],
							[51.8341, 31.1384],
							[51.8325, 31.1877],
							[51.8426, 31.2272],
							[51.8419, 31.247],
							[51.853, 31.2569],
							[51.8523, 31.2766],
							[51.8844, 31.336],
							[51.8834, 31.3656],
							[51.8717, 31.3754],
							[51.8713, 31.3852],
							[51.8478, 31.4048],
							[51.8364, 31.4047],
							[51.8015, 31.4242],
							[51.7331, 31.4238],
							[51.7327, 31.4336],
							[51.6863, 31.453],
							[51.6742, 31.4726],
							[51.6502, 31.502],
							[51.6384, 31.5118],
							[51.638, 31.5216],
							[51.6262, 31.5314],
							[51.6258, 31.5412],
							[51.6372, 31.5413],
							[51.6482, 31.5513],
							[51.6596, 31.5514],
							[51.7175, 31.5321],
							[51.7289, 31.5322],
							[51.7407, 31.5224],
							[51.7977, 31.5228],
							[51.8088, 31.5328],
							[51.8312, 31.5428],
							[51.8426, 31.5429],
							[51.8537, 31.5528],
							[51.8761, 31.5628],
							[51.8872, 31.5728],
							[51.8978, 31.5926],
							[51.9089, 31.6025],
							[51.9085, 31.6124],
							[51.9657, 31.6127],
							[51.9767, 31.6227],
							[51.9882, 31.6228],
							[52.0103, 31.6426],
							[52.0095, 31.6624],
							[52.0317, 31.6823],
							[52.1003, 31.6827],
							[52.1114, 31.6927],
							[52.1229, 31.6927],
							[52.134, 31.7027],
							[52.1336, 31.7125],
							[52.1447, 31.7225],
							[52.1561, 31.7226],
							[52.168, 31.7128],
							[52.1687, 31.693],
							[52.1808, 31.6733],
							[52.1815, 31.6536],
							[52.1715, 31.614],
							[52.1722, 31.5943],
							[52.1839, 31.5845],
							[52.1843, 31.5746],
							[52.2078, 31.555],
							[52.2193, 31.555],
							[52.231, 31.5452],
							[52.2996, 31.5455],
							[52.3228, 31.5358],
							[52.3343, 31.5358],
							[52.3574, 31.5261],
							[52.3689, 31.5261],
							[52.4035, 31.5164],
							[52.4493, 31.5166],
							[52.5068, 31.5069],
							[52.5296, 31.507],
							[52.5522, 31.517],
							[52.5751, 31.5171],
							[52.6092, 31.5271],
							[52.7117, 31.5473],
							[52.7569, 31.5673],
							[52.8252, 31.5874],
							[52.8478, 31.5974],
							[52.8708, 31.5975],
							[52.894, 31.5877],
							[52.9286, 31.5779],
							[52.9401, 31.5779],
							[52.9747, 31.5681],
							[53.0211, 31.5484],
							[53.0904, 31.5288],
							[53.1018, 31.5288],
							[53.1252, 31.509],
							[53.1599, 31.4992],
							[53.2295, 31.4597],
							[53.3345, 31.3705],
							[53.3463, 31.3506],
							[53.3695, 31.3308],
							[53.3931, 31.2911],
							[53.3932, 31.2811],
							[53.4048, 31.2712],
							[53.4166, 31.2514],
							[53.417, 31.2216],
							[53.4403, 31.1918],
							[53.4525, 31.1322],
							[53.5226, 30.9834],
							[53.5342, 30.9635],
							[53.5574, 30.9139],
							[53.5804, 30.8842],
							[53.6035, 30.8445],
							[53.6035, 30.8346],
							[53.6266, 30.785],
							[53.6381, 30.7651],
							[53.6496, 30.7354],
							[53.6496, 30.7255],
							[53.6725, 30.6957],
							[53.6954, 30.6759],
							[53.7069, 30.6461],
							[53.7411, 30.5965],
							[53.764, 30.5568],
							[53.7754, 30.5469],
							[53.7982, 30.5172],
							[53.8096, 30.4874],
							[53.821, 30.4676],
							[53.9119, 30.3881],
							[53.9346, 30.3584],
							[53.98, 30.3187],
							[54.0026, 30.2889],
							[54.0139, 30.2493],
							[54.0251, 30.2196],
							[54.0363, 30.1701],
							[54.036, 30.0811],
							[54.0471, 30.0317],
							[54.047, 30.0119],
							[54.0582, 30.002],
							[54.0694, 29.9822],
							[54.092, 29.9624],
							[54.1031, 29.9426],
							[54.1257, 29.9327],
							[54.1708, 29.9029],
							[54.182, 29.893],
							[54.2272, 29.8829],
							[54.2611, 29.8729],
							[54.2724, 29.863],
							[54.3062, 29.853],
							[54.3514, 29.8331],
							[54.3738, 29.8133],
							[54.4189, 29.7933],
							[54.4302, 29.7933],
							[54.4295, 29.7341],
							[54.4407, 29.7242],
							[54.4518, 29.7044],
							[54.4516, 29.6945],
							[54.4739, 29.6648],
							[54.4738, 29.6549],
							[54.485, 29.645],
							[54.4848, 29.6352],
							[54.4959, 29.6154],
							[54.5403, 29.556],
							[54.5401, 29.5363],
							[54.5618, 29.477],
							[54.573, 29.4671],
							[54.5843, 29.467],
							[54.5953, 29.4472],
							[54.6178, 29.4373],
							[54.629, 29.4274],
							[54.6628, 29.4173],
							[54.8665, 29.4163],
							[54.889, 29.4063],
							[54.9117, 29.4062],
							[54.9228, 29.3962],
							[54.9342, 29.3962],
							[54.9453, 29.3862],
							[54.9452, 29.3764],
							[54.9671, 29.3368],
							[54.9992, 29.2281],
							[55.0209, 29.1786],
							[55.0315, 29.139],
							[55.0423, 29.1094],
							[55.0532, 29.0895],
							[55.064, 29.0599],
							[55.0749, 29.04],
							[55.0856, 29.0103],
							[55.0855, 29.0005],
							[55.1077, 28.9806],
							[55.1075, 28.9707],
							[55.1297, 28.9508],
							[55.1295, 28.9409],
							[55.1404, 28.9211],
							[55.1509, 28.8815],
							[55.1726, 28.8319],
							[55.1834, 28.8121],
							[55.183, 28.7923],
							[55.1941, 28.7824],
							[55.1939, 28.7725],
							[55.1823, 28.7527],
							[55.171, 28.7528],
							[55.1596, 28.7429],
							[55.1145, 28.7432],
							[55.114, 28.7134],
							[55.1251, 28.7034],
							[55.1697, 28.6733],
							[55.1921, 28.6633],
							[55.2476, 28.6133],
							[55.3479, 28.5432],
							[55.359, 28.5333],
							[55.3924, 28.5132],
							[55.4814, 28.4335],
							[55.4814, 28.4236],
							[55.4924, 28.3939],
							[55.4923, 28.335],
							[55.4597, 28.2086],
							[55.4599, 28.1989],
							[55.4385, 28.1409],
							[55.417, 28.1025],
							[55.4176, 28.0734],
							[55.407, 28.0542],
							[55.418, 28.054],
							[55.4184, 28.0347],
							[55.4296, 28.0248],
							[55.4407, 28.0247],
							[55.4631, 28.005],
							[55.4964, 27.9948],
							[55.5525, 27.9455],
							[55.5526, 27.9359],
							[55.564, 27.9164],
							[55.565, 27.8682],
							[55.5541, 27.8588],
							[55.5545, 27.8396],
							[55.5221, 27.8114],
							[55.5223, 27.8018],
							[55.5113, 27.8021],
							[55.4785, 27.7931],
							[55.4345, 27.794],
							[55.3793, 27.8046],
							[55.3464, 27.8053],
							[55.3356, 27.7959],
							[55.3246, 27.7961],
							[55.3176, 27.79],
							[55.3139, 27.7868],
							[55.3034, 27.7679],
							[55.2819, 27.7492],
							[55.2709, 27.7494],
							[55.2602, 27.7401],
							[55.2276, 27.7312],
							[55.2166, 27.7315],
							[55.195, 27.7224],
							[55.1736, 27.7037],
							[55.1633, 27.6849],
							[55.1532, 27.6565],
							[55.1435, 27.6187],
							[55.1336, 27.5904],
							[55.1231, 27.5811],
							[55.1238, 27.5621],
							[55.1032, 27.534],
							[55.0931, 27.5152],
							[55.0614, 27.4969],
							[55.0405, 27.4782],
							[54.9076, 27.4796],
							[54.863, 27.4992],
							[54.8519, 27.4993],
							[54.8296, 27.5091],
							[54.763, 27.5096],
							[54.7407, 27.5195],
							[54.7296, 27.5195],
							[54.7183, 27.5293],
							[54.6629, 27.5297],
							[54.6075, 27.5204],
							[54.5966, 27.5108],
							[54.5855, 27.5109],
							[54.5636, 27.4917],
							[54.5528, 27.4725],
							[54.5195, 27.4727],
							[54.5083, 27.4824],
							[54.4972, 27.4825],
							[54.486, 27.4922],
							[54.4858, 27.5019],
							[54.4747, 27.502],
							[54.4635, 27.5117],
							[54.4302, 27.512],
							[54.4082, 27.5024],
							[54.3751, 27.4929],
							[54.3308, 27.4932],
							[54.3084, 27.503],
							[54.2641, 27.5033],
							[54.2199, 27.4938],
							[54.1978, 27.494],
							[54.1536, 27.4845],
							[54.1315, 27.4846],
							[54.1095, 27.475],
							[54.0764, 27.4655],
							[54.0327, 27.4367],
							[54.0108, 27.4272],
							[53.989, 27.4079],
							[54.0008, 27.369],
							[54.0016, 27.3204],
							[53.9907, 27.3107],
							[53.9908, 27.301],
							[53.9794, 27.2711],
							[53.9691, 27.262],
							[53.9692, 27.2523],
							[53.9803, 27.2522],
							[54.0027, 27.2326],
							[54.0029, 27.2131],
							[53.97, 27.1839],
							[53.9258, 27.1742],
							[53.9147, 27.1743],
							[53.8708, 27.1352],
							[53.8376, 27.1354],
							[53.771, 27.1454],
							[53.7378, 27.1455],
							[53.6822, 27.1653],
							[53.6044, 27.1852],
							[53.5601, 27.1853],
							[53.5489, 27.1951],
							[53.5268, 27.1854],
							[53.5047, 27.1855],
							[53.4715, 27.1757],
							[53.4604, 27.1758],
							[53.4494, 27.166],
							[53.4382, 27.1758],
							[53.4161, 27.1758],
							[53.3714, 27.2151],
							[53.3491, 27.2249],
							[53.3378, 27.2445],
							[53.3267, 27.2445],
							[53.3045, 27.2543],
							[53.2933, 27.2641],
							[53.1714, 27.2643],
							[53.0944, 27.2556],
							[53.0835, 27.2559],
							[53.0613, 27.2661],
							[53.0504, 27.2665],
							[53.0168, 27.2958],
							[53.0052, 27.3244],
							[53.0049, 27.3338],
							[52.9935, 27.353],
							[52.9933, 27.3626],
							[52.9597, 27.3918],
							[52.9263, 27.4115],
							[52.9134, 27.4173],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Gilan", name: "گيلان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[50.0194, 36.6401],
							[49.9954, 36.6393],
							[49.925, 36.6065],
							[49.913, 36.6061],
							[49.9135, 36.596],
							[49.9021, 36.5855],
							[49.8901, 36.5851],
							[49.8666, 36.5742],
							[49.8552, 36.5636],
							[49.7591, 36.5605],
							[49.7465, 36.5703],
							[49.7099, 36.5793],
							[49.6978, 36.5789],
							[49.6846, 36.5988],
							[49.6485, 36.5977],
							[49.637, 36.5872],
							[49.6123, 36.5966],
							[49.5761, 36.5955],
							[49.5508, 36.6152],
							[49.5393, 36.6046],
							[49.5273, 36.6043],
							[49.5146, 36.6142],
							[49.4904, 36.6135],
							[49.4657, 36.6231],
							[49.4148, 36.6629],
							[49.4014, 36.6831],
							[49.3501, 36.7229],
							[49.3359, 36.7534],
							[49.3474, 36.764],
							[49.3467, 36.7742],
							[49.3209, 36.7942],
							[49.3202, 36.8045],
							[49.2956, 36.8039],
							[49.2826, 36.8139],
							[49.2458, 36.8131],
							[49.2328, 36.8232],
							[49.2275, 36.8957],
							[49.1982, 36.9575],
							[49.1842, 36.978],
							[49.1826, 36.9988],
							[49.1427, 37.0289],
							[49.1284, 37.0492],
							[49.115, 37.0592],
							[49.0871, 37.0893],
							[49.0736, 37.0991],
							[49.0338, 37.1183],
							[49.005, 37.1478],
							[48.9785, 37.157],
							[48.9646, 37.1665],
							[48.952, 37.166],
							[48.9505, 37.176],
							[48.9364, 37.1854],
							[48.9238, 37.1848],
							[48.9095, 37.1942],
							[48.8825, 37.2028],
							[48.8662, 37.2217],
							[48.8555, 37.2691],
							[48.866, 37.2791],
							[48.8594, 37.3075],
							[48.8721, 37.3081],
							[48.8654, 37.3365],
							[48.8759, 37.3465],
							[48.8737, 37.356],
							[48.8566, 37.3743],
							[48.7376, 37.4451],
							[48.7207, 37.4635],
							[48.7185, 37.4731],
							[48.7037, 37.4819],
							[48.7015, 37.4914],
							[48.6867, 37.5002],
							[48.6845, 37.5097],
							[48.6675, 37.528],
							[48.6527, 37.5369],
							[48.6505, 37.5465],
							[48.6315, 37.5748],
							[48.6168, 37.5839],
							[48.5984, 37.6136],
							[48.5949, 37.6349],
							[48.5519, 37.6642],
							[48.5488, 37.6855],
							[48.5317, 37.7166],
							[48.5164, 37.737],
							[48.5124, 37.7691],
							[48.4998, 37.7681],
							[48.4919, 37.8321],
							[48.4781, 37.8418],
							[48.4768, 37.8525],
							[48.4618, 37.8729],
							[48.4483, 37.8826],
							[48.4351, 37.903],
							[48.4364, 37.9243],
							[48.4808, 37.957],
							[48.4822, 37.9669],
							[48.4973, 37.9771],
							[48.4989, 37.9867],
							[48.4866, 37.996],
							[48.4899, 38.0152],
							[48.5053, 38.0251],
							[48.514, 38.0728],
							[48.5279, 38.0731],
							[48.5298, 38.0826],
							[48.5455, 38.0924],
							[48.5474, 38.102],
							[48.5651, 38.1213],
							[48.6147, 38.1603],
							[48.6306, 38.1702],
							[48.65, 38.1991],
							[48.6515, 38.2087],
							[48.6666, 38.2185],
							[48.6968, 38.2478],
							[48.7245, 38.2579],
							[48.7385, 38.2677],
							[48.7524, 38.2776],
							[48.7526, 38.2872],
							[48.766, 38.2875],
							[48.7662, 38.2971],
							[48.7528, 38.3064],
							[48.7262, 38.3154],
							[48.6861, 38.3049],
							[48.6863, 38.3145],
							[48.6732, 38.3238],
							[48.6733, 38.3526],
							[48.66, 38.3619],
							[48.6074, 38.3799],
							[48.5942, 38.3892],
							[48.6072, 38.3991],
							[48.6068, 38.4154],
							[48.6162, 38.4094],
							[48.64, 38.3999],
							[48.6536, 38.3977],
							[48.6781, 38.3972],
							[48.6969, 38.4002],
							[48.7147, 38.4053],
							[48.7327, 38.4067],
							[48.7421, 38.4102],
							[48.7525, 38.4185],
							[48.7671, 38.435],
							[48.7726, 38.4358],
							[48.7911, 38.4446],
							[48.8189, 38.4433],
							[48.8388, 38.4455],
							[48.8704, 38.4374],
							[48.8794, 38.437],
							[48.8797, 38.4171],
							[48.869, 38.3702],
							[48.8649, 38.3337],
							[48.8645, 38.2958],
							[48.8667, 38.2929],
							[48.8694, 38.2779],
							[48.8695, 38.2637],
							[48.8753, 38.2448],
							[48.8743, 38.2214],
							[48.8829, 38.2031],
							[48.884, 38.1916],
							[48.8871, 38.1823],
							[48.8948, 38.1715],
							[48.8992, 38.1579],
							[48.8939, 38.1392],
							[48.8995, 38.1194],
							[48.8969, 38.0913],
							[48.9063, 38.051],
							[48.905, 38.0419],
							[48.907, 38.0277],
							[48.9207, 38.0007],
							[48.9259, 37.9936],
							[48.9312, 37.9774],
							[48.9406, 37.9616],
							[48.9373, 37.9386],
							[48.9322, 37.9217],
							[48.9326, 37.9009],
							[48.9407, 37.8809],
							[48.9603, 37.8553],
							[48.9756, 37.8431],
							[48.9814, 37.8277],
							[48.9796, 37.7907],
							[48.9808, 37.782],
							[48.9979, 37.7532],
							[48.9971, 37.7449],
							[49.004, 37.7267],
							[49.0346, 37.6922],
							[49.0748, 37.6532],
							[49.1576, 37.595],
							[49.1879, 37.5786],
							[49.2039, 37.5671],
							[49.2243, 37.5557],
							[49.2687, 37.5389],
							[49.3237, 37.5151],
							[49.3754, 37.5],
							[49.3851, 37.4957],
							[49.4564, 37.4809],
							[49.4627, 37.4744],
							[49.4651, 37.4792],
							[49.4835, 37.4785],
							[49.5073, 37.4715],
							[49.5434, 37.4668],
							[49.6941, 37.4544],
							[49.7057, 37.4521],
							[49.8344, 37.4429],
							[49.8617, 37.4432],
							[49.8834, 37.4487],
							[49.8922, 37.4552],
							[49.9059, 37.4624],
							[49.9434, 37.4673],
							[49.9542, 37.462],
							[49.9599, 37.4555],
							[49.9625, 37.4443],
							[49.9604, 37.4385],
							[49.9545, 37.4365],
							[49.9569, 37.4403],
							[49.9542, 37.4441],
							[49.9471, 37.4433],
							[49.9383, 37.4461],
							[49.9356, 37.4431],
							[49.9368, 37.4408],
							[49.9508, 37.433],
							[49.9921, 37.4169],
							[50.0131, 37.4138],
							[50.0378, 37.4072],
							[50.0783, 37.4034],
							[50.1092, 37.3982],
							[50.1446, 37.397],
							[50.1679, 37.394],
							[50.1847, 37.3895],
							[50.1896, 37.3895],
							[50.1994, 37.3853],
							[50.2133, 37.3723],
							[50.219, 37.3641],
							[50.2209, 37.3561],
							[50.2205, 37.3288],
							[50.2313, 37.2914],
							[50.2406, 37.2744],
							[50.2474, 37.2559],
							[50.2571, 37.2371],
							[50.2639, 37.2296],
							[50.2714, 37.2162],
							[50.276, 37.2138],
							[50.2772, 37.2012],
							[50.2816, 37.1868],
							[50.299, 37.1554],
							[50.3175, 37.137],
							[50.3356, 37.1235],
							[50.3753, 37.1037],
							[50.3978, 37.0839],
							[50.4509, 37.0526],
							[50.4631, 37.0436],
							[50.4731, 37.0312],
							[50.483, 37.0231],
							[50.4987, 37.0148],
							[50.533, 37.0009],
							[50.5757, 36.9859],
							[50.5765, 36.9535],
							[50.5536, 36.9323],
							[50.5419, 36.9318],
							[50.5187, 36.9207],
							[50.5073, 36.9101],
							[50.4721, 36.9088],
							[50.4379, 36.8771],
							[50.4268, 36.8564],
							[50.4279, 36.826],
							[50.4193, 36.745],
							[50.408, 36.7346],
							[50.4084, 36.7245],
							[50.3744, 36.6931],
							[50.3281, 36.6712],
							[50.2927, 36.6699],
							[50.2809, 36.6695],
							[50.2326, 36.6879],
							[50.1731, 36.6859],
							[50.1377, 36.6745],
							[50.1018, 36.6733],
							[50.0903, 36.6627],
							[50.0663, 36.6619],
							[50.0548, 36.6514],
							[50.0308, 36.6506],
							[50.0194, 36.6401],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Golestan", name: "گلستان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[53.742, 36.8008],
							[53.792, 36.7942],
							[53.8157, 36.7974],
							[53.8284, 36.7931],
							[53.8832, 36.7851],
							[53.8929, 36.7856],
							[53.9061, 36.79],
							[53.919, 36.7908],
							[53.9366, 36.7878],
							[53.9486, 36.7934],
							[53.9695, 36.797],
							[53.971, 36.7997],
							[54.0241, 36.8066],
							[54.0343, 36.8142],
							[54.0392, 36.8316],
							[54.0438, 36.8581],
							[54.0422, 36.8891],
							[54.0381, 36.8999],
							[54.0425, 36.9042],
							[54.0427, 36.9109],
							[54.0394, 36.9451],
							[54.0363, 36.958],
							[54.0321, 36.9615],
							[54.0227, 36.9637],
							[53.997, 36.976],
							[53.9936, 36.9797],
							[53.9999, 36.9814],
							[54.0049, 36.9882],
							[54.0048, 37.0073],
							[54.0094, 37.0104],
							[53.996, 37.0498],
							[53.9958, 37.0549],
							[53.9884, 37.0668],
							[53.9763, 37.1074],
							[53.9702, 37.118],
							[53.9671, 37.133],
							[53.9568, 37.1581],
							[53.944, 37.2032],
							[53.9343, 37.218],
							[53.9116, 37.2995],
							[53.8978, 37.3408],
							[53.9818, 37.3349],
							[54.1438, 37.3179],
							[54.2436, 37.3092],
							[54.2857, 37.3429],
							[54.3463, 37.3418],
							[54.359, 37.3442],
							[54.3945, 37.3564],
							[54.4348, 37.3779],
							[54.4497, 37.3899],
							[54.4671, 37.3975],
							[54.4757, 37.4061],
							[54.507, 37.4307],
							[54.5246, 37.4324],
							[54.5307, 37.4346],
							[54.5354, 37.4393],
							[54.5415, 37.442],
							[54.5522, 37.4436],
							[54.5569, 37.4472],
							[54.5667, 37.4461],
							[54.5767, 37.4551],
							[54.583, 37.4554],
							[54.5875, 37.4531],
							[54.5924, 37.4537],
							[54.5944, 37.4513],
							[54.6004, 37.4517],
							[54.6031, 37.4463],
							[54.6161, 37.4445],
							[54.6256, 37.4377],
							[54.6417, 37.4383],
							[54.6492, 37.431],
							[54.6532, 37.4307],
							[54.6757, 37.4404],
							[54.7181, 37.4727],
							[54.7495, 37.4839],
							[54.7579, 37.4886],
							[54.7757, 37.5063],
							[54.7727, 37.5107],
							[54.7751, 37.5137],
							[54.7858, 37.5166],
							[54.7978, 37.5231],
							[54.7979, 37.5451],
							[54.8001, 37.5514],
							[54.8063, 37.5557],
							[54.8068, 37.5612],
							[54.8036, 37.5626],
							[54.8066, 37.5653],
							[54.8092, 37.5742],
							[54.8166, 37.588],
							[54.8191, 37.6054],
							[54.8158, 37.6105],
							[54.8071, 37.6171],
							[54.7981, 37.6157],
							[54.7924, 37.6174],
							[54.7891, 37.6207],
							[54.784, 37.6327],
							[54.7897, 37.6486],
							[54.801, 37.6606],
							[54.8023, 37.6642],
							[54.7981, 37.6691],
							[54.8003, 37.6732],
							[54.7998, 37.6771],
							[54.8031, 37.6805],
							[54.8113, 37.6847],
							[54.8169, 37.6985],
							[54.8217, 37.7021],
							[54.8209, 37.7049],
							[54.8146, 37.7064],
							[54.8236, 37.7165],
							[54.8225, 37.7193],
							[54.8238, 37.7252],
							[54.8347, 37.73],
							[54.8431, 37.7402],
							[54.8398, 37.7448],
							[54.8521, 37.7505],
							[54.8519, 37.7554],
							[54.8567, 37.7605],
							[54.8649, 37.7645],
							[54.87, 37.7621],
							[54.876, 37.7693],
							[54.885, 37.7687],
							[54.892, 37.7657],
							[54.9494, 37.8009],
							[54.9577, 37.8182],
							[55.0119, 37.8497],
							[55.0279, 37.8631],
							[55.0313, 37.873],
							[55.0428, 37.8902],
							[55.0683, 37.9022],
							[55.1293, 37.951],
							[55.1515, 37.958],
							[55.1861, 37.9587],
							[55.1922, 37.9523],
							[55.2052, 37.9646],
							[55.245, 37.9812],
							[55.2655, 37.9821],
							[55.2836, 37.9933],
							[55.3033, 38.0028],
							[55.3352, 38.0123],
							[55.3821, 38.0321],
							[55.4064, 38.0403],
							[55.4292, 38.0531],
							[55.4417, 38.057],
							[55.4469, 38.0612],
							[55.4529, 38.0708],
							[55.4742, 38.0843],
							[55.4844, 38.0855],
							[55.4973, 38.0724],
							[55.5087, 38.0747],
							[55.5226, 38.082],
							[55.5407, 38.0867],
							[55.5884, 38.0903],
							[55.6223, 38.0978],
							[55.6484, 38.0993],
							[55.6785, 38.0971],
							[55.7272, 38.1029],
							[55.7419, 38.1099],
							[55.7481, 38.1109],
							[55.7632, 38.1113],
							[55.7862, 38.1073],
							[55.8134, 38.1115],
							[55.8371, 38.1021],
							[55.8722, 38.0852],
							[55.8986, 38.077],
							[55.9181, 38.0789],
							[55.9324, 38.0829],
							[55.9495, 38.0823],
							[55.9601, 38.0784],
							[55.9626, 38.0753],
							[55.9808, 38.065],
							[55.9927, 38.0666],
							[56.0017, 38.0655],
							[56.0397, 38.0664],
							[56.0797, 38.0734],
							[56.1424, 38.0817],
							[56.1665, 38.0831],
							[56.1773, 38.0413],
							[56.1724, 38.0156],
							[56.1718, 37.9808],
							[56.1618, 37.9718],
							[56.1762, 37.9255],
							[56.1856, 37.8875],
							[56.1747, 37.8781],
							[56.1789, 37.8589],
							[56.1678, 37.8495],
							[56.1697, 37.8398],
							[56.1585, 37.8303],
							[56.162, 37.8108],
							[56.1522, 37.7913],
							[56.1551, 37.7716],
							[56.1707, 37.7515],
							[56.172, 37.7415],
							[56.1861, 37.7312],
							[56.2245, 37.7304],
							[56.2386, 37.7201],
							[56.2514, 37.7198],
							[56.2654, 37.7094],
							[56.2921, 37.6988],
							[56.2932, 37.6887],
							[56.307, 37.6783],
							[56.3081, 37.6681],
							[56.3228, 37.6476],
							[56.3237, 37.6374],
							[56.3128, 37.6175],
							[56.3026, 37.5874],
							[56.2907, 37.5776],
							[56.2287, 37.5588],
							[56.1905, 37.5597],
							[56.1658, 37.5501],
							[56.1531, 37.5504],
							[56.1411, 37.5406],
							[56.0902, 37.5416],
							[56.0909, 37.5315],
							[56.0655, 37.5321],
							[56.0534, 37.5222],
							[56.0541, 37.5121],
							[56.0298, 37.4924],
							[55.9916, 37.4932],
							[55.9672, 37.4735],
							[55.9545, 37.4738],
							[55.9426, 37.4538],
							[55.9438, 37.4135],
							[55.8675, 37.3544],
							[55.8549, 37.3647],
							[55.842, 37.3549],
							[55.8292, 37.3551],
							[55.816, 37.3351],
							[55.7472, 37.2557],
							[55.7137, 37.1958],
							[55.712, 37.1858],
							[55.6881, 37.1356],
							[55.6701, 37.1157],
							[55.6547, 37.1058],
							[55.6523, 37.0957],
							[55.5911, 37.0563],
							[55.5889, 37.0462],
							[55.5716, 37.0264],
							[55.5487, 36.9066],
							[55.5404, 36.847],
							[55.5354, 36.7973],
							[55.5208, 36.7777],
							[55.4934, 36.7582],
							[55.4678, 36.7586],
							[55.4413, 36.749],
							[55.4285, 36.7492],
							[55.2522, 36.7815],
							[55.2267, 36.7818],
							[55.1637, 36.7925],
							[55.0488, 36.7937],
							[55.0225, 36.784],
							[54.958, 36.7746],
							[54.9453, 36.7747],
							[54.8927, 36.755],
							[54.8265, 36.7254],
							[54.7867, 36.7056],
							[54.7452, 36.6658],
							[54.7045, 36.636],
							[54.635, 36.5664],
							[54.6206, 36.5465],
							[54.607, 36.5365],
							[54.5943, 36.5365],
							[54.5672, 36.5166],
							[54.5419, 36.5165],
							[54.5156, 36.5065],
							[54.4903, 36.5065],
							[54.4767, 36.4965],
							[54.4504, 36.4865],
							[54.4233, 36.4665],
							[54.4106, 36.4664],
							[54.3362, 36.486],
							[54.2981, 36.4858],
							[54.2736, 36.4956],
							[54.1719, 36.4951],
							[54.1329, 36.4849],
							[54.1202, 36.4848],
							[54.1084, 36.4947],
							[54.0858, 36.5242],
							[54.0641, 36.5636],
							[54.0053, 36.6125],
							[53.9574, 36.6416],
							[53.9086, 36.6608],
							[53.859, 36.6701],
							[53.7977, 36.689],
							[53.7742, 36.7083],
							[53.7381, 36.7275],
							[53.7281, 36.7567],
							[53.7053, 36.7857],
							[53.7068, 36.8032],
							[53.7152, 36.8013],
							[53.742, 36.8008],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Hamadan", name: "همدان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[48.5296, 34.0563],
							[48.5178, 34.0462],
							[48.5182, 34.0365],
							[48.5064, 34.0264],
							[48.5068, 34.0168],
							[48.4823, 34.0158],
							[48.4571, 34.0341],
							[48.4448, 34.0336],
							[48.4322, 34.0428],
							[48.371, 34.0404],
							[48.3584, 34.0496],
							[48.2971, 34.0473],
							[48.2844, 34.0565],
							[48.1986, 34.0535],
							[48.1224, 34.109],
							[48.1093, 34.1279],
							[48.0711, 34.1557],
							[48.0588, 34.1553],
							[48.0338, 34.1641],
							[47.9842, 34.1722],
							[47.9715, 34.1815],
							[47.9592, 34.1811],
							[47.9464, 34.1903],
							[47.9214, 34.1992],
							[47.8704, 34.2364],
							[47.8455, 34.2454],
							[47.8201, 34.2643],
							[47.7825, 34.2829],
							[47.7816, 34.3027],
							[47.7184, 34.3506],
							[47.694, 34.3498],
							[47.7176, 34.3707],
							[47.7172, 34.3807],
							[47.7292, 34.3912],
							[47.7283, 34.4214],
							[47.7526, 34.4424],
							[47.765, 34.4428],
							[47.8025, 34.4339],
							[47.8647, 34.4259],
							[47.9143, 34.4275],
							[47.9269, 34.4179],
							[47.9393, 34.4183],
							[47.9644, 34.399],
							[47.9892, 34.3998],
							[48.0013, 34.4102],
							[48.0009, 34.4304],
							[47.9883, 34.44],
							[47.9877, 34.4702],
							[47.9871, 34.5005],
							[47.999, 34.5211],
							[47.9973, 34.602],
							[47.985, 34.6016],
							[47.9724, 34.6113],
							[47.9477, 34.6105],
							[47.9113, 34.579],
							[47.8617, 34.5774],
							[47.8619, 34.5673],
							[47.8501, 34.5365],
							[47.8381, 34.5159],
							[47.8383, 34.5058],
							[47.826, 34.4953],
							[47.8134, 34.505],
							[47.801, 34.5046],
							[47.7884, 34.5143],
							[47.787, 34.5852],
							[47.7744, 34.5949],
							[47.7496, 34.5941],
							[47.7369, 34.6038],
							[47.6996, 34.6026],
							[47.6869, 34.6124],
							[47.6741, 34.6322],
							[47.6367, 34.6311],
							[47.624, 34.6408],
							[47.6115, 34.6404],
							[47.5988, 34.6501],
							[47.5859, 34.67],
							[47.5732, 34.6798],
							[47.5725, 34.7102],
							[47.5848, 34.7207],
							[47.6095, 34.7316],
							[47.6092, 34.7418],
							[47.7585, 34.7463],
							[47.7706, 34.7568],
							[47.7698, 34.7871],
							[47.7568, 34.807],
							[47.756, 34.8373],
							[47.7802, 34.8582],
							[47.7925, 34.8586],
							[47.8167, 34.8795],
							[47.829, 34.8799],
							[47.8411, 34.8903],
							[47.8407, 34.9004],
							[47.8528, 34.9109],
							[47.8511, 34.9614],
							[47.8751, 34.9823],
							[47.8998, 34.983],
							[47.9248, 34.9736],
							[47.9375, 34.9639],
							[47.9621, 34.9646],
							[47.9741, 34.975],
							[47.9737, 34.9851],
							[47.9857, 34.9955],
							[47.9846, 35.0258],
							[47.9966, 35.0363],
							[48.0089, 35.0366],
							[48.0215, 35.0269],
							[48.0223, 35.0067],
							[48.0226, 34.9966],
							[48.0107, 34.9862],
							[48.0613, 34.9473],
							[48.0735, 34.9476],
							[48.0855, 34.9581],
							[48.0978, 34.9584],
							[48.0974, 34.9685],
							[48.1209, 34.9995],
							[48.144, 35.0405],
							[48.1662, 35.1017],
							[48.1646, 35.142],
							[48.1384, 35.1816],
							[48.1258, 35.1914],
							[48.1127, 35.2112],
							[48.0873, 35.2307],
							[48.0751, 35.2304],
							[48.0624, 35.2401],
							[48.0251, 35.2492],
							[48.012, 35.269],
							[47.987, 35.2785],
							[47.9616, 35.298],
							[47.9612, 35.3081],
							[47.9475, 35.3381],
							[47.9056, 35.4483],
							[47.8924, 35.4682],
							[47.8786, 35.4983],
							[47.8526, 35.528],
							[47.8393, 35.5479],
							[47.8008, 35.5772],
							[47.7988, 35.6177],
							[47.8111, 35.618],
							[47.8229, 35.6285],
							[47.8352, 35.6289],
							[47.8588, 35.6498],
							[47.8583, 35.66],
							[47.8701, 35.6705],
							[47.8824, 35.6708],
							[47.908, 35.6512],
							[47.9101, 35.6107],
							[47.9229, 35.6009],
							[47.9234, 35.5907],
							[47.9489, 35.5711],
							[47.9858, 35.5721],
							[47.9853, 35.5822],
							[47.9976, 35.5826],
							[48.0447, 35.6244],
							[48.057, 35.6248],
							[48.0943, 35.6156],
							[48.1066, 35.6159],
							[48.1183, 35.6264],
							[48.1179, 35.6365],
							[48.1051, 35.6464],
							[48.0913, 35.6765],
							[48.0785, 35.6863],
							[48.0908, 35.6866],
							[48.0903, 35.6968],
							[48.0647, 35.7165],
							[48.0397, 35.726],
							[48.0018, 35.7453],
							[47.9889, 35.7551],
							[47.9879, 35.7754],
							[48.0125, 35.7761],
							[48.075, 35.7574],
							[48.1502, 35.7289],
							[48.2007, 35.6998],
							[48.2135, 35.6899],
							[48.2257, 35.6903],
							[48.2262, 35.6801],
							[48.2522, 35.6504],
							[48.2649, 35.6406],
							[48.2654, 35.6304],
							[48.2781, 35.6206],
							[48.2904, 35.621],
							[48.3031, 35.6112],
							[48.3276, 35.6119],
							[48.3525, 35.6025],
							[48.377, 35.6032],
							[48.3887, 35.6136],
							[48.401, 35.614],
							[48.4245, 35.635],
							[48.4733, 35.6364],
							[48.5231, 35.6175],
							[48.561, 35.5882],
							[48.5858, 35.5788],
							[48.6723, 35.551],
							[48.7209, 35.5524],
							[48.7691, 35.5639],
							[48.8055, 35.565],
							[48.8021, 35.646],
							[48.8139, 35.6564],
							[48.826, 35.6568],
							[48.8377, 35.6673],
							[48.9348, 35.6703],
							[48.9716, 35.6614],
							[48.9967, 35.642],
							[49.0213, 35.6328],
							[49.0343, 35.613],
							[49.0968, 35.5649],
							[49.0976, 35.5448],
							[49.0859, 35.5343],
							[49.0132, 35.5316],
							[49.0015, 35.5211],
							[49.0023, 35.5009],
							[49.0274, 35.4817],
							[49.052, 35.4726],
							[49.1371, 35.4658],
							[49.2338, 35.4695],
							[49.2455, 35.4801],
							[49.3059, 35.4824],
							[49.3183, 35.4729],
							[49.3304, 35.4734],
							[49.3553, 35.4543],
							[49.3674, 35.4548],
							[49.3791, 35.4653],
							[49.4032, 35.4663],
							[49.4277, 35.4572],
							[49.4401, 35.4477],
							[49.4409, 35.4277],
							[49.4292, 35.4172],
							[49.43, 35.3971],
							[49.4424, 35.3876],
							[49.4428, 35.3776],
							[49.48, 35.3491],
							[49.4928, 35.3297],
							[49.4815, 35.3091],
							[49.4699, 35.2986],
							[49.4461, 35.2876],
							[49.4345, 35.2771],
							[49.3863, 35.2751],
							[49.3855, 35.2951],
							[49.3731, 35.3046],
							[49.384, 35.3351],
							[49.3832, 35.3551],
							[49.3708, 35.3646],
							[49.3704, 35.3746],
							[49.3584, 35.3741],
							[49.3233, 35.3426],
							[49.3241, 35.3226],
							[49.3128, 35.3021],
							[49.3132, 35.2921],
							[49.3264, 35.2625],
							[49.3392, 35.243],
							[49.3396, 35.233],
							[49.3893, 35.1951],
							[49.3897, 35.1851],
							[49.4021, 35.1757],
							[49.4032, 35.1457],
							[49.392, 35.1252],
							[49.3927, 35.1053],
							[49.3694, 35.0843],
							[49.3091, 35.0816],
							[49.2846, 35.0906],
							[49.2485, 35.0891],
							[49.2488, 35.0791],
							[49.2372, 35.0686],
							[49.2375, 35.0586],
							[49.25, 35.0491],
							[49.2504, 35.0391],
							[49.2628, 35.0297],
							[49.2873, 35.0207],
							[49.3234, 35.0223],
							[49.3359, 35.0128],
							[49.3603, 35.0039],
							[49.3844, 35.005],
							[49.3969, 34.9956],
							[49.4213, 34.9867],
							[49.4454, 34.9878],
							[49.4702, 34.969],
							[49.4822, 34.9695],
							[49.507, 34.9507],
							[49.5074, 34.9408],
							[49.5198, 34.9314],
							[49.5205, 34.9115],
							[49.5325, 34.912],
							[49.5453, 34.8927],
							[49.5467, 34.853],
							[49.5598, 34.8238],
							[49.5602, 34.8138],
							[49.574, 34.7648],
							[49.575, 34.735],
							[49.5517, 34.7139],
							[49.5157, 34.7122],
							[49.5153, 34.7221],
							[49.5033, 34.7215],
							[49.4909, 34.7309],
							[49.4782, 34.7502],
							[49.4534, 34.7689],
							[49.4417, 34.7584],
							[49.4297, 34.7578],
							[49.4173, 34.7672],
							[49.417, 34.7771],
							[49.4046, 34.7865],
							[49.4031, 34.8263],
							[49.4148, 34.8368],
							[49.4144, 34.8467],
							[49.4257, 34.8672],
							[49.4374, 34.8777],
							[49.437, 34.8877],
							[49.4487, 34.8982],
							[49.4483, 34.9081],
							[49.4359, 34.9175],
							[49.3517, 34.9137],
							[49.3272, 34.9226],
							[49.1584, 34.9152],
							[49.1335, 34.9341],
							[49.1214, 34.9336],
							[49.1097, 34.9231],
							[49.1115, 34.8732],
							[49.1244, 34.8538],
							[49.1379, 34.8144],
							[49.15, 34.8149],
							[49.1503, 34.8049],
							[49.1386, 34.7944],
							[49.139, 34.7845],
							[49.0907, 34.7824],
							[49.0672, 34.7614],
							[49.0679, 34.7414],
							[49.081, 34.712],
							[49.0831, 34.6522],
							[49.0958, 34.6328],
							[49.0964, 34.6129],
							[49.1088, 34.6035],
							[49.0974, 34.583],
							[49.0977, 34.5731],
							[49.0732, 34.582],
							[49.0112, 34.6291],
							[48.9991, 34.6286],
							[48.9752, 34.6176],
							[48.9268, 34.6155],
							[48.9019, 34.6344],
							[48.8898, 34.6339],
							[48.8773, 34.6434],
							[48.8655, 34.6329],
							[48.8665, 34.6029],
							[48.8789, 34.5935],
							[48.891, 34.594],
							[48.9156, 34.585],
							[48.928, 34.5756],
							[48.9643, 34.5772],
							[48.9891, 34.5583],
							[48.99, 34.5284],
							[48.9786, 34.4979],
							[48.9676, 34.4474],
							[48.9688, 34.3977],
							[48.9812, 34.3883],
							[48.9938, 34.369],
							[49.0067, 34.3398],
							[49.0438, 34.3118],
							[49.0565, 34.2927],
							[49.0568, 34.2828],
							[49.0698, 34.2539],
							[49.0736, 34.1475],
							[49.0857, 34.148],
							[49.0861, 34.1385],
							[49.0994, 34.1103],
							[49.0998, 34.1007],
							[49.0881, 34.0906],
							[49.0889, 34.0714],
							[49.0768, 34.0709],
							[49.0534, 34.0506],
							[49.0413, 34.05],
							[49.0296, 34.0399],
							[49.0058, 34.0292],
							[48.9824, 34.0089],
							[48.9711, 33.9891],
							[48.9589, 33.9886],
							[48.9339, 34.0066],
							[48.9097, 34.0055],
							[48.8971, 34.0146],
							[48.8729, 34.0135],
							[48.8482, 34.022],
							[48.7992, 34.0294],
							[48.7866, 34.0384],
							[48.7745, 34.0379],
							[48.7368, 34.0651],
							[48.7234, 34.0933],
							[48.7108, 34.1024],
							[48.6987, 34.1019],
							[48.6751, 34.0816],
							[48.5898, 34.078],
							[48.5772, 34.0871],
							[48.565, 34.0866],
							[48.5296, 34.0563],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Hormozgan", name: "هرمزگان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[55.0412, 25.8982],
							[55.0438, 25.896],
							[55.0471, 25.896],
							[55.0468, 25.889],
							[55.0493, 25.8888],
							[55.051, 25.8857],
							[55.0499, 25.8813],
							[55.0549, 25.8693],
							[55.0551, 25.8649],
							[55.0579, 25.8621],
							[55.0551, 25.8571],
							[55.0415, 25.8601],
							[55.0374, 25.8587],
							[55.0337, 25.8538],
							[55.0321, 25.8551],
							[55.0321, 25.8579],
							[55.0296, 25.8574],
							[55.0301, 25.8607],
							[55.0238, 25.8585],
							[55.0265, 25.8618],
							[55.0249, 25.8638],
							[55.0207, 25.8638],
							[55.0193, 25.8612],
							[55.0163, 25.8618],
							[55.0174, 25.8729],
							[55.0115, 25.8751],
							[55.0124, 25.8696],
							[55.0107, 25.8693],
							[55.0093, 25.8707],
							[55.0093, 25.8768],
							[55.0129, 25.8832],
							[55.0171, 25.8818],
							[55.0226, 25.8888],
							[55.0279, 25.889],
							[55.0279, 25.8904],
							[55.0249, 25.8899],
							[55.0254, 25.8921],
							[55.0321, 25.8932],
							[55.0332, 25.8971],
							[55.0374, 25.8962],
							[55.0376, 25.899],
							[55.0412, 25.8982],
						],
					],
					[
						[
							[54.5462, 25.929],
							[54.5493, 25.9254],
							[54.551, 25.9176],
							[54.5549, 25.9112],
							[54.5532, 25.9043],
							[54.5513, 25.9026],
							[54.5551, 25.896],
							[54.5601, 25.8932],
							[54.5607, 25.8913],
							[54.5376, 25.8943],
							[54.5354, 25.8968],
							[54.5299, 25.8971],
							[54.5265, 25.9004],
							[54.4957, 25.9001],
							[54.4963, 25.9082],
							[54.4993, 25.914],
							[54.5018, 25.9168],
							[54.5037, 25.9168],
							[54.5043, 25.9188],
							[54.5129, 25.919],
							[54.5207, 25.9251],
							[54.5249, 25.9251],
							[54.5285, 25.9282],
							[54.5337, 25.9263],
							[54.5363, 25.9274],
							[54.5363, 25.9301],
							[54.5454, 25.9301],
							[54.5462, 25.929],
						],
					],
					[
						[
							[55.1418, 26.2483],
							[55.1435, 26.2469],
							[55.1485, 26.2471],
							[55.1512, 26.2451],
							[55.151, 26.2437],
							[55.1526, 26.2451],
							[55.1524, 26.2432],
							[55.1554, 26.2424],
							[55.1535, 26.2363],
							[55.1443, 26.2337],
							[55.1399, 26.2421],
							[55.1404, 26.2451],
							[55.1376, 26.246],
							[55.139, 26.2488],
							[55.1418, 26.2483],
						],
					],
					[
						[
							[55.3157, 26.2824],
							[55.3221, 26.2738],
							[55.3224, 26.2699],
							[55.3237, 26.2693],
							[55.3229, 26.2657],
							[55.3271, 26.266],
							[55.3268, 26.2643],
							[55.3232, 26.2626],
							[55.3224, 26.2599],
							[55.3221, 26.2563],
							[55.324, 26.254],
							[55.3224, 26.2532],
							[55.3229, 26.2471],
							[55.2946, 26.2482],
							[55.2918, 26.2532],
							[55.289, 26.254],
							[55.2849, 26.2593],
							[55.2882, 26.2707],
							[55.291, 26.2712],
							[55.2912, 26.2738],
							[55.2938, 26.2757],
							[55.3021, 26.2771],
							[55.3071, 26.2824],
							[55.3135, 26.2835],
							[55.3157, 26.2824],
						],
					],
					[
						[
							[54.5192, 26.3165],
							[54.5288, 26.3135],
							[54.5312, 26.309],
							[54.5332, 26.3085],
							[54.5393, 26.2929],
							[54.5401, 26.2818],
							[54.5363, 26.2699],
							[54.5288, 26.2568],
							[54.5257, 26.2537],
							[54.5229, 26.254],
							[54.5204, 26.2518],
							[54.5174, 26.2524],
							[54.516, 26.2507],
							[54.5129, 26.2529],
							[54.5099, 26.2518],
							[54.5079, 26.2532],
							[54.5043, 26.2665],
							[54.5007, 26.2679],
							[54.4921, 26.2821],
							[54.4896, 26.2832],
							[54.4849, 26.296],
							[54.4907, 26.306],
							[54.4907, 26.3093],
							[54.4954, 26.3107],
							[54.4982, 26.3135],
							[54.5035, 26.3135],
							[54.5088, 26.3171],
							[54.5192, 26.3165],
						],
					],
					[
						[
							[54.0146, 26.4976],
							[54.0082, 26.4976],
							[53.9985, 26.4946],
							[53.9854, 26.4943],
							[53.9707, 26.4985],
							[53.9649, 26.4985],
							[53.9604, 26.501],
							[53.9596, 26.4999],
							[53.9557, 26.5007],
							[53.9437, 26.5074],
							[53.9362, 26.5065],
							[53.9324, 26.5082],
							[53.9285, 26.5132],
							[53.926, 26.5129],
							[53.919, 26.5174],
							[53.9162, 26.5174],
							[53.911, 26.5218],
							[53.9051, 26.5349],
							[53.8988, 26.5418],
							[53.8988, 26.5507],
							[53.9038, 26.5471],
							[53.9082, 26.5493],
							[53.9099, 26.554],
							[53.9132, 26.5557],
							[53.9157, 26.5604],
							[53.9293, 26.5693],
							[53.941, 26.5704],
							[53.9496, 26.5743],
							[53.9596, 26.5746],
							[53.9649, 26.5721],
							[53.9654, 26.5704],
							[53.9846, 26.5682],
							[53.9921, 26.5649],
							[54.0046, 26.5646],
							[54.0043, 26.5674],
							[54.0057, 26.5674],
							[54.0071, 26.5637],
							[54.0096, 26.5657],
							[54.0132, 26.5658],
							[54.019, 26.5621],
							[54.0229, 26.5626],
							[54.0271, 26.5579],
							[54.0268, 26.556],
							[54.024, 26.5557],
							[54.0238, 26.5526],
							[54.024, 26.5454],
							[54.026, 26.5404],
							[54.0282, 26.5376],
							[54.0335, 26.5368],
							[54.0351, 26.5332],
							[54.0368, 26.5329],
							[54.036, 26.5313],
							[54.0401, 26.5268],
							[54.0418, 26.5271],
							[54.0424, 26.5251],
							[54.0493, 26.524],
							[54.0504, 26.5182],
							[54.0465, 26.5085],
							[54.041, 26.5021],
							[54.0257, 26.4985],
							[54.0146, 26.4976],
						],
					],
					[
						[
							[54.9132, 26.5743],
							[54.8974, 26.5651],
							[54.8904, 26.5574],
							[54.8896, 26.5487],
							[54.881, 26.5479],
							[54.8743, 26.5418],
							[54.8671, 26.5401],
							[54.8599, 26.5357],
							[54.8457, 26.5174],
							[54.8304, 26.5224],
							[54.8218, 26.5218],
							[54.8107, 26.5185],
							[54.8026, 26.5096],
							[54.8001, 26.501],
							[54.7946, 26.4954],
							[54.791, 26.4957],
							[54.7815, 26.5021],
							[54.7799, 26.5012],
							[54.7793, 26.5029],
							[54.7693, 26.5029],
							[54.764, 26.5074],
							[54.7618, 26.5068],
							[54.7571, 26.509],
							[54.7543, 26.5082],
							[54.7457, 26.5118],
							[54.7379, 26.5126],
							[54.7346, 26.5137],
							[54.7343, 26.5157],
							[54.7326, 26.514],
							[54.7215, 26.5149],
							[54.7165, 26.5129],
							[54.714, 26.5143],
							[54.714, 26.5126],
							[54.7035, 26.514],
							[54.6704, 26.5135],
							[54.6618, 26.5085],
							[54.6643, 26.506],
							[54.6574, 26.5076],
							[54.6421, 26.5029],
							[54.6246, 26.5004],
							[54.6146, 26.5046],
							[54.614, 26.5068],
							[54.6087, 26.5107],
							[54.6065, 26.5096],
							[54.5874, 26.5574],
							[54.5782, 26.5699],
							[54.5779, 26.5726],
							[54.5676, 26.5846],
							[54.5607, 26.5901],
							[54.5529, 26.5929],
							[54.5462, 26.5926],
							[54.5304, 26.5979],
							[54.5107, 26.599],
							[54.4938, 26.5971],
							[54.4707, 26.5879],
							[54.4593, 26.586],
							[54.4568, 26.5838],
							[54.4465, 26.5863],
							[54.4393, 26.5843],
							[54.4315, 26.5901],
							[54.4215, 26.5896],
							[54.4168, 26.5932],
							[54.4118, 26.5921],
							[54.404, 26.5957],
							[54.3968, 26.6038],
							[54.3901, 26.6046],
							[54.3818, 26.6196],
							[54.3754, 26.6235],
							[54.3643, 26.6499],
							[54.3624, 26.6515],
							[54.3604, 26.651],
							[54.3618, 26.6518],
							[54.361, 26.6535],
							[54.3585, 26.6515],
							[54.3587, 26.6549],
							[54.3554, 26.6579],
							[54.3432, 26.6812],
							[54.3363, 26.6899],
							[54.3332, 26.6907],
							[54.3249, 26.6993],
							[54.3229, 26.7046],
							[54.3196, 26.7057],
							[54.319, 26.7085],
							[54.3157, 26.7099],
							[54.316, 26.716],
							[54.314, 26.7163],
							[54.3124, 26.7135],
							[54.3068, 26.714],
							[54.3024, 26.7179],
							[54.2946, 26.7213],
							[54.2896, 26.7296],
							[54.2907, 26.7226],
							[54.2871, 26.7257],
							[54.2818, 26.7262],
							[54.281, 26.7246],
							[54.281, 26.7271],
							[54.2779, 26.7285],
							[54.2696, 26.7285],
							[54.2599, 26.7262],
							[54.2588, 26.7226],
							[54.251, 26.7213],
							[54.2465, 26.7185],
							[54.2449, 26.7146],
							[54.229, 26.7151],
							[54.2268, 26.714],
							[54.226, 26.711],
							[54.2118, 26.7107],
							[54.2085, 26.7099],
							[54.2046, 26.7062],
							[54.1963, 26.7082],
							[54.1915, 26.7038],
							[54.1899, 26.7062],
							[54.1826, 26.7096],
							[54.1754, 26.711],
							[54.1657, 26.7087],
							[54.1654, 26.7115],
							[54.1635, 26.7126],
							[54.1526, 26.7135],
							[54.144, 26.7157],
							[54.1415, 26.7179],
							[54.139, 26.7179],
							[54.1374, 26.716],
							[54.1282, 26.7179],
							[54.1224, 26.7135],
							[54.1096, 26.7204],
							[54.1026, 26.7213],
							[54.0988, 26.7196],
							[54.0935, 26.724],
							[54.0913, 26.7243],
							[54.0896, 26.7224],
							[54.0874, 26.7254],
							[54.0793, 26.7307],
							[54.0754, 26.7315],
							[54.0751, 26.7329],
							[54.0665, 26.7357],
							[54.0629, 26.7349],
							[54.051, 26.741],
							[54.0482, 26.739],
							[54.0435, 26.7463],
							[54.0387, 26.7485],
							[54.0371, 26.751],
							[54.0335, 26.7512],
							[54.0229, 26.7568],
							[54.0099, 26.7576],
							[54.0076, 26.7537],
							[53.9896, 26.7543],
							[53.9849, 26.7526],
							[53.9751, 26.7457],
							[53.9668, 26.7443],
							[53.9512, 26.7326],
							[53.9435, 26.724],
							[53.926, 26.7193],
							[53.9151, 26.7193],
							[53.9057, 26.7221],
							[53.8979, 26.7218],
							[53.8971, 26.7204],
							[53.8865, 26.7218],
							[53.8776, 26.7199],
							[53.8543, 26.7101],
							[53.8324, 26.7076],
							[53.8124, 26.711],
							[53.7801, 26.7204],
							[53.7768, 26.7188],
							[53.7743, 26.7218],
							[53.761, 26.7226],
							[53.7393, 26.7168],
							[53.7304, 26.7087],
							[53.7293, 26.7062],
							[53.7301, 26.701],
							[53.726, 26.7001],
							[53.7215, 26.7082],
							[53.7065, 26.7207],
							[53.7004, 26.7337],
							[53.6843, 26.7393],
							[53.6437, 26.7585],
							[53.6265, 26.7626],
							[53.6096, 26.7696],
							[53.6065, 26.7729],
							[53.6024, 26.774],
							[53.5901, 26.7824],
							[53.584, 26.7876],
							[53.5838, 26.7907],
							[53.5793, 26.7913],
							[53.5696, 26.7999],
							[53.5638, 26.8024],
							[53.5624, 26.8051],
							[53.5576, 26.8082],
							[53.5546, 26.8082],
							[53.5496, 26.8135],
							[53.5407, 26.8176],
							[53.5365, 26.8221],
							[53.5293, 26.8235],
							[53.5165, 26.8321],
							[53.5079, 26.8357],
							[53.4993, 26.8443],
							[53.4946, 26.8468],
							[53.4885, 26.8538],
							[53.4837, 26.8643],
							[53.486, 26.9268],
							[53.4846, 26.9276],
							[53.4851, 26.9349],
							[53.4824, 26.951],
							[53.4735, 26.9701],
							[53.4718, 26.971],
							[53.4699, 26.9696],
							[53.4646, 26.9765],
							[53.4604, 26.9788],
							[53.4613, 26.9799],
							[53.4596, 26.9813],
							[53.444, 26.9788],
							[53.4304, 26.9904],
							[53.419, 26.9921],
							[53.4137, 26.991],
							[53.3982, 26.9951],
							[53.3857, 26.9951],
							[53.3721, 26.9924],
							[53.3685, 26.9968],
							[53.3632, 26.9976],
							[53.3621, 27.0012],
							[53.3599, 27.0026],
							[53.3296, 27.0126],
							[53.3268, 27.0124],
							[53.3199, 27.016],
							[53.3007, 27.0215],
							[53.2974, 27.0243],
							[53.2932, 27.0237],
							[53.2829, 27.0276],
							[53.2754, 27.036],
							[53.2637, 27.0385],
							[53.2571, 27.0421],
							[53.2513, 27.0415],
							[53.2463, 27.046],
							[53.2407, 27.0474],
							[53.2368, 27.0462],
							[53.2346, 27.0493],
							[53.2326, 27.0493],
							[53.2301, 27.046],
							[53.2251, 27.0471],
							[53.2207, 27.0512],
							[53.2182, 27.0515],
							[53.2171, 27.0546],
							[53.2126, 27.0529],
							[53.1893, 27.0643],
							[53.1693, 27.0701],
							[53.1632, 27.0757],
							[53.1576, 27.074],
							[53.1432, 27.0776],
							[53.1379, 27.0854],
							[53.134, 27.0868],
							[53.1312, 27.084],
							[53.1196, 27.0926],
							[53.1124, 27.0935],
							[53.1018, 27.0907],
							[53.0865, 27.096],
							[53.0829, 27.0938],
							[53.0751, 27.0951],
							[53.0662, 27.0985],
							[53.0638, 27.1012],
							[53.0604, 27.1007],
							[53.0549, 27.1026],
							[53.0504, 27.1085],
							[53.041, 27.1118],
							[53.0368, 27.1107],
							[53.0346, 27.1149],
							[53.0262, 27.1201],
							[53.021, 27.1185],
							[53.0062, 27.1249],
							[52.9985, 27.1265],
							[52.9979, 27.1282],
							[52.9918, 27.1304],
							[52.9829, 27.1315],
							[52.979, 27.1343],
							[52.9724, 27.136],
							[52.9663, 27.141],
							[52.961, 27.141],
							[52.9604, 27.1385],
							[52.9585, 27.1385],
							[52.9515, 27.1424],
							[52.9499, 27.1485],
							[52.9476, 27.1479],
							[52.9415, 27.1515],
							[52.9415, 27.1529],
							[52.9379, 27.1493],
							[52.9335, 27.1568],
							[52.9285, 27.1549],
							[52.9265, 27.1624],
							[52.9187, 27.1654],
							[52.9174, 27.1696],
							[52.911, 27.1721],
							[52.9099, 27.174],
							[52.9074, 27.1735],
							[52.906, 27.1704],
							[52.8835, 27.1801],
							[52.8812, 27.1832],
							[52.8757, 27.1835],
							[52.8718, 27.1865],
							[52.8693, 27.186],
							[52.8582, 27.1907],
							[52.8554, 27.1943],
							[52.8526, 27.1935],
							[52.8326, 27.2026],
							[52.8226, 27.2099],
							[52.8215, 27.2149],
							[52.819, 27.2146],
							[52.8176, 27.2182],
							[52.8157, 27.2174],
							[52.8129, 27.2188],
							[52.8099, 27.2232],
							[52.8032, 27.2282],
							[52.8013, 27.2332],
							[52.7982, 27.2337],
							[52.7971, 27.2399],
							[52.7935, 27.2457],
							[52.7874, 27.251],
							[52.7804, 27.2512],
							[52.7788, 27.2499],
							[52.7793, 27.2468],
							[52.7768, 27.2476],
							[52.7643, 27.2576],
							[52.7632, 27.261],
							[52.7579, 27.2643],
							[52.7535, 27.2712],
							[52.7476, 27.2707],
							[52.7429, 27.274],
							[52.7407, 27.2726],
							[52.7279, 27.2779],
							[52.726, 27.2801],
							[52.7135, 27.2843],
							[52.711, 27.2868],
							[52.6957, 27.2915],
							[52.686, 27.3079],
							[52.6807, 27.3124],
							[52.6757, 27.3115],
							[52.6734, 27.3129],
							[52.6986, 27.3565],
							[52.7095, 27.3558],
							[52.7201, 27.3642],
							[52.7419, 27.363],
							[52.7962, 27.3695],
							[52.829, 27.3681],
							[52.8621, 27.3575],
							[52.9168, 27.3555],
							[52.9384, 27.3641],
							[52.9709, 27.3821],
							[52.9933, 27.3626],
							[52.9935, 27.353],
							[53.0049, 27.3338],
							[53.0052, 27.3244],
							[53.0168, 27.2958],
							[53.0504, 27.2665],
							[53.0613, 27.2661],
							[53.0835, 27.2559],
							[53.0944, 27.2556],
							[53.1714, 27.2643],
							[53.2933, 27.2641],
							[53.3045, 27.2543],
							[53.3267, 27.2445],
							[53.3378, 27.2445],
							[53.3491, 27.2249],
							[53.3714, 27.2151],
							[53.4161, 27.1758],
							[53.4382, 27.1758],
							[53.4494, 27.166],
							[53.4604, 27.1758],
							[53.4715, 27.1757],
							[53.5047, 27.1855],
							[53.5268, 27.1854],
							[53.5489, 27.1951],
							[53.5601, 27.1853],
							[53.6044, 27.1852],
							[53.6822, 27.1653],
							[53.7378, 27.1455],
							[53.771, 27.1454],
							[53.8376, 27.1354],
							[53.8708, 27.1352],
							[53.9147, 27.1743],
							[53.9258, 27.1742],
							[53.97, 27.1839],
							[54.0029, 27.2131],
							[54.0027, 27.2326],
							[53.9803, 27.2522],
							[53.9692, 27.2523],
							[53.9691, 27.262],
							[53.9794, 27.2711],
							[53.9908, 27.301],
							[53.9907, 27.3107],
							[54.0016, 27.3204],
							[54.0008, 27.369],
							[53.989, 27.4079],
							[54.0108, 27.4272],
							[54.0327, 27.4367],
							[54.0764, 27.4655],
							[54.1095, 27.475],
							[54.1315, 27.4846],
							[54.1536, 27.4845],
							[54.1978, 27.494],
							[54.2199, 27.4938],
							[54.2641, 27.5033],
							[54.3084, 27.503],
							[54.3308, 27.4932],
							[54.3751, 27.4929],
							[54.4082, 27.5024],
							[54.4302, 27.512],
							[54.4635, 27.5117],
							[54.4747, 27.502],
							[54.4858, 27.5019],
							[54.486, 27.4922],
							[54.4972, 27.4825],
							[54.5083, 27.4824],
							[54.5195, 27.4727],
							[54.5528, 27.4725],
							[54.5636, 27.4917],
							[54.5855, 27.5109],
							[54.5966, 27.5108],
							[54.6075, 27.5204],
							[54.6629, 27.5297],
							[54.7183, 27.5293],
							[54.7296, 27.5195],
							[54.7407, 27.5195],
							[54.763, 27.5096],
							[54.8296, 27.5091],
							[54.8519, 27.4993],
							[54.863, 27.4992],
							[54.9076, 27.4796],
							[55.0405, 27.4782],
							[55.0614, 27.4969],
							[55.0931, 27.5152],
							[55.1032, 27.534],
							[55.1238, 27.5621],
							[55.1231, 27.5811],
							[55.1336, 27.5904],
							[55.1435, 27.6187],
							[55.1532, 27.6565],
							[55.1633, 27.6849],
							[55.1736, 27.7037],
							[55.195, 27.7224],
							[55.2166, 27.7315],
							[55.2276, 27.7312],
							[55.2602, 27.7401],
							[55.2709, 27.7494],
							[55.2819, 27.7492],
							[55.3034, 27.7679],
							[55.3139, 27.7868],
							[55.3176, 27.79],
							[55.3246, 27.7961],
							[55.3356, 27.7959],
							[55.3464, 27.8053],
							[55.3793, 27.8046],
							[55.4345, 27.794],
							[55.4675, 27.7933],
							[55.4785, 27.7931],
							[55.5113, 27.8021],
							[55.5223, 27.8018],
							[55.5221, 27.8114],
							[55.5545, 27.8396],
							[55.5541, 27.8588],
							[55.565, 27.8682],
							[55.564, 27.9164],
							[55.5526, 27.9359],
							[55.5525, 27.9455],
							[55.4964, 27.9948],
							[55.4631, 28.005],
							[55.4407, 28.0247],
							[55.4296, 28.0248],
							[55.4184, 28.0347],
							[55.418, 28.054],
							[55.407, 28.0542],
							[55.4176, 28.0734],
							[55.417, 28.1025],
							[55.4385, 28.1409],
							[55.4599, 28.1989],
							[55.4597, 28.2086],
							[55.4923, 28.335],
							[55.4924, 28.3939],
							[55.4814, 28.4236],
							[55.4814, 28.4335],
							[55.3924, 28.5132],
							[55.359, 28.5333],
							[55.3479, 28.5432],
							[55.2476, 28.6133],
							[55.1921, 28.6633],
							[55.1697, 28.6733],
							[55.1251, 28.7034],
							[55.114, 28.7134],
							[55.1145, 28.7432],
							[55.1596, 28.7429],
							[55.171, 28.7528],
							[55.1823, 28.7527],
							[55.1939, 28.7725],
							[55.1941, 28.7824],
							[55.183, 28.7923],
							[55.1834, 28.8121],
							[55.1726, 28.8319],
							[55.1509, 28.8815],
							[55.1404, 28.9211],
							[55.1295, 28.9409],
							[55.1297, 28.9508],
							[55.1975, 28.9504],
							[55.2086, 28.9404],
							[55.3217, 28.9396],
							[55.3328, 28.9297],
							[55.3554, 28.9295],
							[55.3663, 28.9097],
							[55.3777, 28.9096],
							[55.4449, 28.8795],
							[55.4558, 28.8596],
							[55.4669, 28.8496],
							[55.5003, 28.8296],
							[55.511, 28.7999],
							[55.5666, 28.7599],
							[55.5664, 28.75],
							[55.5777, 28.7499],
							[55.6001, 28.7398],
							[55.6453, 28.7395],
							[55.6569, 28.7492],
							[55.7026, 28.7686],
							[55.7951, 28.8469],
							[55.8412, 28.8761],
							[55.8528, 28.8859],
							[55.91, 28.9051],
							[55.9558, 28.9244],
							[55.9672, 28.9243],
							[55.9788, 28.9341],
							[56.0017, 28.9438],
							[56.0131, 28.9436],
							[56.0242, 28.9336],
							[56.0355, 28.9335],
							[56.0466, 28.9235],
							[56.0461, 28.9037],
							[56.0566, 28.8739],
							[56.0675, 28.854],
							[56.0629, 28.6859],
							[56.072, 28.6067],
							[56.0704, 28.5474],
							[56.0574, 28.4883],
							[56.0218, 28.4293],
							[56.0208, 28.3897],
							[56.0316, 28.3697],
							[56.0313, 28.3598],
							[56.0534, 28.3397],
							[56.0973, 28.2897],
							[56.1194, 28.2696],
							[56.1527, 28.2494],
							[56.2078, 28.1992],
							[56.2411, 28.179],
							[56.2409, 28.1691],
							[56.2854, 28.1488],
							[56.2967, 28.1487],
							[56.3187, 28.1286],
							[56.3412, 28.1284],
							[56.3522, 28.1183],
							[56.386, 28.118],
							[56.4196, 28.1077],
							[56.4985, 28.1067],
							[56.5443, 28.1256],
							[56.5785, 28.1347],
							[56.5901, 28.1444],
							[56.636, 28.1631],
							[56.6593, 28.1823],
							[56.6823, 28.1916],
							[56.7176, 28.2302],
							[56.7296, 28.2497],
							[56.741, 28.2494],
							[56.7527, 28.259],
							[56.765, 28.2883],
							[56.7767, 28.2979],
							[56.7771, 28.3078],
							[56.8231, 28.3265],
							[56.8345, 28.3262],
							[56.8462, 28.3358],
							[56.8572, 28.3257],
							[56.8799, 28.3252],
							[56.9022, 28.3147],
							[56.9132, 28.3046],
							[56.9245, 28.3043],
							[56.9582, 28.2936],
							[56.9696, 28.2934],
							[56.9692, 28.2835],
							[56.9915, 28.2731],
							[56.99, 28.2335],
							[56.9776, 28.2042],
							[56.9753, 28.1449],
							[56.9965, 28.1048],
							[56.9946, 28.0554],
							[57.0059, 28.0552],
							[57.0153, 28.0055],
							[57.0149, 27.9956],
							[57.0259, 27.9854],
							[57.0255, 27.9755],
							[57.0368, 27.9753],
							[57.0477, 27.9651],
							[57.1043, 27.9637],
							[57.1265, 27.9532],
							[57.1378, 27.953],
							[57.1484, 27.9329],
							[57.1593, 27.9227],
							[57.1803, 27.8826],
							[57.1904, 27.8526],
							[57.2017, 27.8523],
							[57.2123, 27.8322],
							[57.245, 27.8017],
							[57.2672, 27.7912],
							[57.289, 27.7708],
							[57.2994, 27.7507],
							[57.299, 27.7407],
							[57.3095, 27.7205],
							[57.2978, 27.7108],
							[57.297, 27.6908],
							[57.2854, 27.6811],
							[57.2741, 27.6814],
							[57.2512, 27.6719],
							[57.2399, 27.6722],
							[57.2395, 27.6622],
							[57.228, 27.6525],
							[57.2276, 27.6426],
							[57.2494, 27.6221],
							[57.2831, 27.6213],
							[57.3158, 27.5906],
							[57.327, 27.5903],
							[57.3267, 27.5804],
							[57.3364, 27.5305],
							[57.3572, 27.4706],
							[57.3681, 27.4604],
							[57.3658, 27.3329],
							[57.3765, 27.3032],
							[57.3751, 27.1667],
							[57.3858, 27.1371],
							[57.3853, 27.0789],
							[57.3962, 27.0591],
							[57.4071, 27.049],
							[57.407, 27.0393],
							[57.4398, 27.0089],
							[57.4728, 27.0077],
							[57.4838, 26.9976],
							[57.5499, 26.9952],
							[57.5718, 26.975],
							[57.5937, 26.9645],
							[57.6047, 26.9545],
							[57.6267, 26.944],
							[57.6376, 26.9339],
							[57.6928, 26.9321],
							[57.7805, 26.8516],
							[57.7915, 26.8513],
							[57.7915, 26.8416],
							[57.8025, 26.8412],
							[57.8136, 26.8506],
							[57.8247, 26.8502],
							[57.8358, 26.8595],
							[57.8359, 26.8692],
							[57.8582, 26.8879],
							[57.8804, 26.897],
							[57.8916, 26.916],
							[57.8918, 26.9355],
							[57.903, 26.9546],
							[57.9141, 26.9543],
							[57.9361, 26.9439],
							[57.9583, 26.9432],
							[57.9695, 26.9527],
							[57.9805, 26.9523],
							[57.9915, 26.9423],
							[58.0137, 26.9417],
							[58.0356, 26.9215],
							[58.0577, 26.9112],
							[58.1016, 26.871],
							[58.1347, 26.8506],
							[58.1677, 26.8205],
							[58.1788, 26.8202],
							[58.2118, 26.7902],
							[58.2117, 26.7707],
							[58.2225, 26.7317],
							[58.2335, 26.712],
							[58.2224, 26.7026],
							[58.2113, 26.703],
							[58.1892, 26.7229],
							[58.1782, 26.7426],
							[58.1671, 26.7429],
							[58.1561, 26.7529],
							[58.134, 26.7536],
							[58.1339, 26.7439],
							[58.1228, 26.7442],
							[58.1227, 26.7249],
							[58.1337, 26.7149],
							[58.1447, 26.6856],
							[58.1557, 26.6756],
							[58.1555, 26.6273],
							[58.1444, 26.618],
							[58.1444, 26.6083],
							[58.1111, 26.5804],
							[58.1111, 26.5707],
							[58.1221, 26.5319],
							[58.1221, 26.5126],
							[58.111, 26.5033],
							[58.111, 26.4937],
							[58.0999, 26.4748],
							[58.1001, 26.3883],
							[58.0891, 26.379],
							[58.0892, 26.3598],
							[58.1115, 26.34],
							[58.1115, 26.3304],
							[58.0894, 26.3118],
							[58.0895, 26.2926],
							[58.1007, 26.2827],
							[58.1008, 26.2731],
							[58.0898, 26.2543],
							[58.0787, 26.2546],
							[58.0788, 26.245],
							[58.0677, 26.2453],
							[58.0567, 26.2361],
							[58.0568, 26.2169],
							[58.0679, 26.2166],
							[58.068, 26.207],
							[58.0791, 26.2067],
							[58.0901, 26.2159],
							[58.1013, 26.2156],
							[58.1234, 26.2246],
							[58.1344, 26.2339],
							[58.2011, 26.232],
							[58.2457, 26.2212],
							[58.2569, 26.2113],
							[58.2791, 26.2107],
							[58.3014, 26.2005],
							[58.3125, 26.2001],
							[58.3456, 26.2279],
							[58.3679, 26.2273],
							[58.3789, 26.2366],
							[58.39, 26.2363],
							[58.4122, 26.2164],
							[58.4233, 26.2161],
							[58.4343, 26.2061],
							[58.5227, 26.2035],
							[58.5227, 26.1939],
							[58.5337, 26.1935],
							[58.5337, 26.1839],
							[58.5115, 26.1653],
							[58.5113, 26.1365],
							[58.5333, 26.1358],
							[58.5443, 26.1259],
							[58.5332, 26.1166],
							[58.5331, 26.0973],
							[58.5328, 26.0685],
							[58.5438, 26.0585],
							[58.5437, 26.0489],
							[58.5878, 26.0475],
							[58.5989, 26.0568],
							[58.6039, 26.0567],
							[58.643, 26.0555],
							[58.654, 26.0455],
							[58.687, 26.0445],
							[58.6982, 26.0538],
							[58.6982, 26.0634],
							[58.7093, 26.0727],
							[58.7095, 26.092],
							[58.7206, 26.1012],
							[58.7316, 26.1009],
							[58.7754, 26.0611],
							[58.7974, 26.0604],
							[58.7975, 26.07],
							[58.8086, 26.0793],
							[58.8197, 26.0789],
							[58.8306, 26.069],
							[58.8302, 26.0209],
							[58.8412, 26.0206],
							[58.8521, 26.0106],
							[58.8631, 26.0103],
							[58.852, 26.001],
							[58.841, 26.0014],
							[58.8298, 25.9825],
							[58.8297, 25.9632],
							[58.8186, 25.954],
							[58.8185, 25.9443],
							[58.8295, 25.9344],
							[58.8294, 25.9248],
							[58.8735, 25.9234],
							[58.8844, 25.9135],
							[58.8843, 25.9039],
							[58.939, 25.8542],
							[58.9938, 25.8141],
							[59.0157, 25.7942],
							[59.0706, 25.7638],
							[59.0924, 25.7343],
							[59.1034, 25.7244],
							[59.1031, 25.6668],
							[59.114, 25.6568],
							[59.114, 25.6472],
							[59.169, 25.6456],
							[59.1799, 25.6357],
							[59.1689, 25.6264],
							[59.1798, 25.6165],
							[59.1798, 25.5973],
							[59.2017, 25.5775],
							[59.2127, 25.5772],
							[59.2127, 25.5676],
							[59.2347, 25.5478],
							[59.2347, 25.5286],
							[59.2457, 25.5187],
							[59.2457, 25.5091],
							[59.2567, 25.5088],
							[59.2567, 25.5046],
							[59.2595, 25.5019],
							[59.257, 25.4992],
							[59.2677, 25.4989],
							[59.2677, 25.5084],
							[59.2787, 25.5081],
							[59.2787, 25.489],
							[59.2677, 25.4893],
							[59.2787, 25.4794],
							[59.2693, 25.4714],
							[59.2842, 25.4694],
							[59.2879, 25.4658],
							[59.2839, 25.4632],
							[59.2632, 25.4621],
							[59.2571, 25.458],
							[59.246, 25.4546],
							[59.2299, 25.4662],
							[59.2193, 25.4665],
							[59.2132, 25.464],
							[59.2051, 25.4571],
							[59.1913, 25.4521],
							[59.1829, 25.4449],
							[59.1632, 25.4401],
							[59.1554, 25.4446],
							[59.1543, 25.4479],
							[59.1551, 25.4529],
							[59.164, 25.4593],
							[59.1635, 25.4654],
							[59.1665, 25.4688],
							[59.1693, 25.4682],
							[59.1712, 25.4649],
							[59.1782, 25.4646],
							[59.1799, 25.4688],
							[59.1801, 25.4776],
							[59.1838, 25.4807],
							[59.1846, 25.4835],
							[59.1793, 25.4776],
							[59.179, 25.4696],
							[59.1776, 25.4676],
							[59.1735, 25.4671],
							[59.1701, 25.4704],
							[59.1668, 25.4701],
							[59.1629, 25.466],
							[59.1618, 25.4596],
							[59.1532, 25.4538],
							[59.1451, 25.4571],
							[59.1451, 25.4596],
							[59.1496, 25.4646],
							[59.1499, 25.4679],
							[59.146, 25.4704],
							[59.1393, 25.4704],
							[59.1379, 25.4724],
							[59.1432, 25.476],
							[59.1443, 25.4771],
							[59.1435, 25.4776],
							[59.1376, 25.4737],
							[59.1376, 25.471],
							[59.1393, 25.4696],
							[59.1457, 25.4696],
							[59.1488, 25.4679],
							[59.1488, 25.4654],
							[59.1435, 25.4604],
							[59.1435, 25.4582],
							[59.1515, 25.4521],
							[59.1496, 25.4426],
							[59.1529, 25.4404],
							[59.1535, 25.4382],
							[59.1401, 25.431],
							[59.1371, 25.4262],
							[59.1332, 25.4243],
							[59.1335, 25.4229],
							[59.1379, 25.4226],
							[59.1418, 25.4307],
							[59.1446, 25.4321],
							[59.1463, 25.431],
							[59.149, 25.4343],
							[59.1538, 25.4365],
							[59.161, 25.4376],
							[59.1674, 25.436],
							[59.1787, 25.4374],
							[59.2071, 25.444],
							[59.2135, 25.4493],
							[59.2199, 25.461],
							[59.2238, 25.4643],
							[59.2293, 25.4629],
							[59.2438, 25.4465],
							[59.2515, 25.4424],
							[59.2532, 25.4407],
							[59.2538, 25.4357],
							[59.2529, 25.434],
							[59.2468, 25.4346],
							[59.2399, 25.4404],
							[59.2335, 25.441],
							[59.2026, 25.4374],
							[59.1715, 25.4287],
							[59.1096, 25.4026],
							[59.1021, 25.406],
							[59.0951, 25.4049],
							[59.0938, 25.4026],
							[59.0876, 25.4021],
							[59.0854, 25.4082],
							[59.0821, 25.4121],
							[59.0729, 25.4126],
							[59.0496, 25.4093],
							[59.0371, 25.4104],
							[59.0174, 25.4151],
							[59.0015, 25.4229],
							[58.9763, 25.4479],
							[58.9318, 25.4996],
							[58.916, 25.5135],
							[58.8968, 25.5257],
							[58.8815, 25.5318],
							[58.8621, 25.5357],
							[58.8343, 25.5387],
							[58.8338, 25.5407],
							[58.836, 25.541],
							[58.8376, 25.5443],
							[58.8365, 25.5476],
							[58.8307, 25.5535],
							[58.8104, 25.5599],
							[58.8085, 25.5643],
							[58.806, 25.5657],
							[58.8046, 25.5704],
							[58.7912, 25.5746],
							[58.7726, 25.5754],
							[58.7443, 25.5735],
							[58.7035, 25.5668],
							[58.6901, 25.5668],
							[58.6704, 25.5729],
							[58.6518, 25.576],
							[58.6276, 25.5838],
							[58.5921, 25.591],
							[58.5774, 25.5907],
							[58.5707, 25.5871],
							[58.5493, 25.5918],
							[58.5163, 25.5879],
							[58.4985, 25.5879],
							[58.4963, 25.586],
							[58.4929, 25.5876],
							[58.4837, 25.5879],
							[58.4682, 25.586],
							[58.4449, 25.5804],
							[58.4393, 25.5829],
							[58.4374, 25.5885],
							[58.4332, 25.5926],
							[58.4254, 25.5943],
							[58.4049, 25.5949],
							[58.3885, 25.594],
							[58.3749, 25.5904],
							[58.3721, 25.5904],
							[58.3707, 25.5924],
							[58.3635, 25.5921],
							[58.3615, 25.5904],
							[58.3521, 25.589],
							[58.3449, 25.5912],
							[58.3413, 25.5888],
							[58.3099, 25.5815],
							[58.3035, 25.5768],
							[58.2821, 25.576],
							[58.2687, 25.5732],
							[58.2493, 25.5651],
							[58.2451, 25.5651],
							[58.2432, 25.5774],
							[58.2421, 25.5774],
							[58.2415, 25.5663],
							[58.2457, 25.5624],
							[58.2454, 25.5604],
							[58.2357, 25.5635],
							[58.224, 25.564],
							[58.2212, 25.5665],
							[58.2126, 25.5668],
							[58.2076, 25.5646],
							[58.2071, 25.5674],
							[58.2054, 25.5657],
							[58.206, 25.5632],
							[58.1963, 25.5637],
							[58.1621, 25.5593],
							[58.1546, 25.5571],
							[58.1393, 25.5568],
							[58.1382, 25.5554],
							[58.1293, 25.5576],
							[58.1151, 25.5585],
							[58.094, 25.5637],
							[58.034, 25.5976],
							[58.0074, 25.6299],
							[58.0076, 25.6374],
							[58.0051, 25.6404],
							[58.0065, 25.6435],
							[58.0051, 25.6443],
							[58.0049, 25.6493],
							[58.0062, 25.6501],
							[58.0013, 25.6526],
							[58.0035, 25.6551],
							[58.0035, 25.6582],
							[57.9957, 25.6629],
							[57.9976, 25.6688],
							[57.9946, 25.6665],
							[57.989, 25.6668],
							[57.9826, 25.6732],
							[57.9776, 25.6829],
							[57.9676, 25.6907],
							[57.9571, 25.6951],
							[57.9565, 25.6971],
							[57.9507, 25.7001],
							[57.9374, 25.7021],
							[57.9204, 25.7018],
							[57.8971, 25.6974],
							[57.8896, 25.6938],
							[57.8793, 25.6929],
							[57.8613, 25.686],
							[57.8524, 25.6807],
							[57.8537, 25.676],
							[57.8518, 25.6732],
							[57.8526, 25.6779],
							[57.8504, 25.6774],
							[57.8493, 25.6787],
							[57.8488, 25.6768],
							[57.851, 25.6751],
							[57.8468, 25.6774],
							[57.8138, 25.6574],
							[57.7863, 25.6457],
							[57.7821, 25.6426],
							[57.7799, 25.6429],
							[57.771, 25.6371],
							[57.7637, 25.6357],
							[57.7626, 25.636],
							[57.7637, 25.6437],
							[57.7668, 25.6443],
							[57.7674, 25.6465],
							[57.7707, 25.6443],
							[57.7713, 25.6474],
							[57.7757, 25.6493],
							[57.7788, 25.6563],
							[57.7768, 25.6801],
							[57.7863, 25.6879],
							[57.7879, 25.6871],
							[57.7874, 25.6843],
							[57.7854, 25.6832],
							[57.7879, 25.6821],
							[57.7904, 25.6832],
							[57.791, 25.6913],
							[57.7893, 25.691],
							[57.7893, 25.6913],
							[57.7912, 25.699],
							[57.7901, 25.7013],
							[57.7835, 25.7046],
							[57.7821, 25.7096],
							[57.7693, 25.7265],
							[57.7593, 25.7349],
							[57.7557, 25.7354],
							[57.7549, 25.7374],
							[57.7507, 25.7376],
							[57.7496, 25.7357],
							[57.7515, 25.7407],
							[57.7493, 25.7407],
							[57.749, 25.7424],
							[57.7485, 25.741],
							[57.746, 25.7429],
							[57.7446, 25.7421],
							[57.7435, 25.744],
							[57.7351, 25.7451],
							[57.736, 25.7463],
							[57.7249, 25.7521],
							[57.7115, 25.749],
							[57.7054, 25.7499],
							[57.7049, 25.7524],
							[57.7085, 25.7535],
							[57.7079, 25.7563],
							[57.706, 25.7565],
							[57.7049, 25.7599],
							[57.7026, 25.7593],
							[57.6988, 25.7626],
							[57.6971, 25.7624],
							[57.6954, 25.7554],
							[57.6904, 25.7568],
							[57.6901, 25.7549],
							[57.6876, 25.7546],
							[57.6876, 25.7518],
							[57.6843, 25.7515],
							[57.6879, 25.7496],
							[57.6932, 25.7538],
							[57.6951, 25.7532],
							[57.7026, 25.7565],
							[57.7038, 25.7524],
							[57.7015, 25.7482],
							[57.6824, 25.7471],
							[57.6796, 25.746],
							[57.6801, 25.7449],
							[57.6743, 25.7435],
							[57.6665, 25.7435],
							[57.666, 25.7449],
							[57.6624, 25.7451],
							[57.6574, 25.7432],
							[57.6524, 25.7443],
							[57.6512, 25.7418],
							[57.6554, 25.7388],
							[57.6549, 25.7354],
							[57.6443, 25.7332],
							[57.6338, 25.7357],
							[57.6282, 25.7343],
							[57.601, 25.741],
							[57.5735, 25.7435],
							[57.5674, 25.746],
							[57.5051, 25.7432],
							[57.4968, 25.749],
							[57.429, 25.7565],
							[57.3963, 25.7643],
							[57.3951, 25.7687],
							[57.3896, 25.7693],
							[57.391, 25.7663],
							[57.3946, 25.7638],
							[57.394, 25.7626],
							[57.3896, 25.7654],
							[57.3876, 25.7721],
							[57.3857, 25.771],
							[57.3829, 25.7751],
							[57.3749, 25.7796],
							[57.3285, 25.781],
							[57.3154, 25.7832],
							[57.3029, 25.7885],
							[57.2968, 25.796],
							[57.296, 25.7993],
							[57.3001, 25.7935],
							[57.311, 25.7865],
							[57.3201, 25.7855],
							[57.3226, 25.7835],
							[57.3299, 25.7821],
							[57.3346, 25.7821],
							[57.3357, 25.7846],
							[57.3399, 25.7854],
							[57.3368, 25.7882],
							[57.3357, 25.7921],
							[57.3304, 25.794],
							[57.3268, 25.8007],
							[57.3165, 25.8085],
							[57.3118, 25.809],
							[57.3112, 25.8118],
							[57.3065, 25.8079],
							[57.3037, 25.8085],
							[57.301, 25.8065],
							[57.2974, 25.8376],
							[57.2921, 25.8563],
							[57.2926, 25.8632],
							[57.2893, 25.8746],
							[57.2899, 25.8782],
							[57.2885, 25.8793],
							[57.2854, 25.8937],
							[57.2837, 25.8946],
							[57.2849, 25.8979],
							[57.2837, 25.8996],
							[57.2851, 25.9013],
							[57.2793, 25.9251],
							[57.271, 25.9421],
							[57.2643, 25.9488],
							[57.2599, 25.9599],
							[57.2521, 25.9685],
							[57.2557, 25.969],
							[57.2538, 25.976],
							[57.2482, 25.981],
							[57.2454, 25.9863],
							[57.2407, 25.989],
							[57.239, 25.996],
							[57.2335, 25.9954],
							[57.2265, 25.9996],
							[57.219, 25.9993],
							[57.2135, 26.0049],
							[57.209, 26.0051],
							[57.2015, 26.009],
							[57.1974, 26.0146],
							[57.2021, 26.0221],
							[57.1901, 26.031],
							[57.1879, 26.0357],
							[57.1868, 26.0515],
							[57.1904, 26.0568],
							[57.1913, 26.0629],
							[57.189, 26.0701],
							[57.1901, 26.0782],
							[57.1874, 26.0782],
							[57.1879, 26.0829],
							[57.1857, 26.0857],
							[57.1857, 26.0899],
							[57.1832, 26.0899],
							[57.1832, 26.0954],
							[57.1913, 26.1021],
							[57.1932, 26.1093],
							[57.1979, 26.1121],
							[57.2029, 26.1221],
							[57.2204, 26.1432],
							[57.2251, 26.1565],
							[57.2218, 26.1712],
							[57.2196, 26.1738],
							[57.2054, 26.1784],
							[57.1943, 26.1868],
							[57.1932, 26.2015],
							[57.1854, 26.2104],
							[57.1782, 26.2096],
							[57.1768, 26.2129],
							[57.1604, 26.2229],
							[57.1512, 26.2274],
							[57.1396, 26.2296],
							[57.1371, 26.2337],
							[57.1371, 26.2446],
							[57.129, 26.2554],
							[57.129, 26.2626],
							[57.1254, 26.2638],
							[57.1254, 26.2654],
							[57.1196, 26.2699],
							[57.1162, 26.2751],
							[57.1171, 26.2951],
							[57.1135, 26.2974],
							[57.1126, 26.3043],
							[57.1065, 26.3013],
							[57.1065, 26.3043],
							[57.1101, 26.3043],
							[57.1107, 26.3065],
							[57.099, 26.3057],
							[57.0949, 26.3093],
							[57.0951, 26.3118],
							[57.0935, 26.3121],
							[57.0921, 26.3168],
							[57.0957, 26.3232],
							[57.094, 26.324],
							[57.091, 26.3179],
							[57.0893, 26.3179],
							[57.0812, 26.3274],
							[57.0796, 26.3299],
							[57.079, 26.3365],
							[57.0762, 26.339],
							[57.0751, 26.3426],
							[57.0718, 26.3449],
							[57.0718, 26.3499],
							[57.0696, 26.351],
							[57.0718, 26.3549],
							[57.0687, 26.3554],
							[57.0693, 26.3604],
							[57.0654, 26.3632],
							[57.0663, 26.3707],
							[57.064, 26.3732],
							[57.0618, 26.3732],
							[57.0615, 26.391],
							[57.0588, 26.3965],
							[57.0635, 26.3968],
							[57.0624, 26.4096],
							[57.064, 26.4171],
							[57.0629, 26.4201],
							[57.0657, 26.4224],
							[57.064, 26.424],
							[57.0676, 26.4282],
							[57.0638, 26.4407],
							[57.0674, 26.4521],
							[57.0701, 26.4521],
							[57.074, 26.4549],
							[57.074, 26.4588],
							[57.0704, 26.4624],
							[57.0776, 26.4657],
							[57.0762, 26.4676],
							[57.0782, 26.4754],
							[57.0832, 26.4835],
							[57.0815, 26.4988],
							[57.0804, 26.4996],
							[57.0785, 26.4982],
							[57.0824, 26.5051],
							[57.0779, 26.5079],
							[57.0829, 26.5324],
							[57.0849, 26.5571],
							[57.0849, 26.5682],
							[57.0807, 26.5937],
							[57.0776, 26.6304],
							[57.066, 26.6882],
							[57.0507, 26.7313],
							[57.0488, 26.7329],
							[57.041, 26.7535],
							[57.0304, 26.7738],
							[57.0218, 26.8082],
							[57.009, 26.8354],
							[57.0038, 26.8404],
							[56.9918, 26.8571],
							[56.9704, 26.879],
							[56.9565, 26.8896],
							[56.9474, 26.8913],
							[56.9388, 26.8968],
							[56.9399, 26.9015],
							[56.9437, 26.8985],
							[56.9443, 26.8999],
							[56.9493, 26.899],
							[56.9557, 26.9063],
							[56.9393, 26.9057],
							[56.9332, 26.9085],
							[56.9332, 26.9104],
							[56.9263, 26.919],
							[56.929, 26.9221],
							[56.9274, 26.9276],
							[56.9287, 26.9329],
							[56.924, 26.936],
							[56.9221, 26.9426],
							[56.9149, 26.9535],
							[56.9157, 26.9549],
							[56.9132, 26.9557],
							[56.9154, 26.9607],
							[56.9126, 26.9635],
							[56.9126, 26.9671],
							[56.9162, 26.969],
							[56.9171, 26.9668],
							[56.9199, 26.969],
							[56.9229, 26.9687],
							[56.9221, 26.9632],
							[56.9246, 26.9604],
							[56.9254, 26.9557],
							[56.9293, 26.9538],
							[56.9307, 26.9507],
							[56.9335, 26.9504],
							[56.9365, 26.9443],
							[56.9415, 26.941],
							[56.9437, 26.9413],
							[56.9432, 26.9437],
							[56.9454, 26.9474],
							[56.9468, 26.9443],
							[56.949, 26.9463],
							[56.9507, 26.9454],
							[56.9526, 26.9393],
							[56.9554, 26.9404],
							[56.9521, 26.9476],
							[56.9526, 26.9493],
							[56.9501, 26.9524],
							[56.9468, 26.9532],
							[56.9443, 26.9593],
							[56.9396, 26.9565],
							[56.9396, 26.9607],
							[56.9426, 26.9599],
							[56.944, 26.9632],
							[56.9413, 26.9654],
							[56.9443, 26.9676],
							[56.9418, 26.9735],
							[56.944, 26.9765],
							[56.9432, 26.9732],
							[56.9454, 26.9718],
							[56.9474, 26.9737],
							[56.9493, 26.9735],
							[56.9499, 26.9704],
							[56.9535, 26.9682],
							[56.9549, 26.971],
							[56.966, 26.9685],
							[56.9663, 26.9721],
							[56.969, 26.9737],
							[56.959, 26.9724],
							[56.9582, 26.974],
							[56.9532, 26.9746],
							[56.9543, 26.9768],
							[56.9526, 26.9782],
							[56.9496, 26.9774],
							[56.9482, 26.9799],
							[56.951, 26.9837],
							[56.9546, 26.9821],
							[56.954, 26.9849],
							[56.9565, 26.9826],
							[56.9585, 26.9829],
							[56.9571, 26.9863],
							[56.9618, 26.984],
							[56.9568, 26.9879],
							[56.9568, 26.9899],
							[56.9626, 26.9951],
							[56.9593, 26.9954],
							[56.956, 26.9929],
							[56.956, 26.9954],
							[56.954, 26.9974],
							[56.9579, 27.0012],
							[56.9618, 27.0026],
							[56.9626, 27.006],
							[56.9613, 27.0065],
							[56.9613, 27.009],
							[56.9668, 27.0143],
							[56.9704, 27.0132],
							[56.9707, 27.0107],
							[56.9726, 27.0096],
							[56.9751, 27.0104],
							[56.9718, 27.0115],
							[56.9721, 27.0138],
							[56.969, 27.014],
							[56.9726, 27.0165],
							[56.9682, 27.0154],
							[56.9676, 27.0196],
							[56.9704, 27.021],
							[56.9668, 27.0215],
							[56.966, 27.016],
							[56.9604, 27.011],
							[56.9585, 27.0115],
							[56.9585, 27.0074],
							[56.9574, 27.0074],
							[56.9577, 27.0093],
							[56.9551, 27.0079],
							[56.9565, 27.0051],
							[56.954, 27.0046],
							[56.9532, 27.0065],
							[56.9521, 27.0035],
							[56.9507, 27.0046],
							[56.9515, 27.0076],
							[56.9499, 27.0076],
							[56.9499, 27.009],
							[56.9565, 27.0121],
							[56.9565, 27.0157],
							[56.9624, 27.0179],
							[56.9593, 27.0182],
							[56.9613, 27.0235],
							[56.9599, 27.0263],
							[56.9615, 27.0285],
							[56.9649, 27.029],
							[56.9585, 27.0293],
							[56.9574, 27.019],
							[56.956, 27.0204],
							[56.9565, 27.0243],
							[56.9551, 27.0265],
							[56.9529, 27.0263],
							[56.9535, 27.0213],
							[56.951, 27.0215],
							[56.9535, 27.0185],
							[56.9524, 27.0138],
							[56.9518, 27.0174],
							[56.9499, 27.0179],
							[56.9485, 27.016],
							[56.946, 27.021],
							[56.9451, 27.019],
							[56.9468, 27.0146],
							[56.9418, 27.0185],
							[56.9429, 27.0129],
							[56.9454, 27.011],
							[56.944, 27.0099],
							[56.9415, 27.0107],
							[56.9415, 27.0126],
							[56.9396, 27.0126],
							[56.9396, 27.0149],
							[56.9349, 27.0199],
							[56.9296, 27.0232],
							[56.9371, 27.0118],
							[56.9368, 27.0088],
							[56.9274, 27.0149],
							[56.9318, 27.0085],
							[56.9276, 27.0101],
							[56.929, 27.0071],
							[56.924, 27.0065],
							[56.924, 27.0054],
							[56.9265, 27.0051],
							[56.9279, 27.0021],
							[56.9315, 27.0015],
							[56.9324, 26.999],
							[56.9274, 27.001],
							[56.9301, 26.9982],
							[56.9287, 26.9962],
							[56.9296, 26.9946],
							[56.9199, 26.9949],
							[56.9171, 26.996],
							[56.9165, 27.0007],
							[56.9154, 27.001],
							[56.9151, 26.9982],
							[56.9129, 26.9999],
							[56.9099, 26.9996],
							[56.9046, 27.0065],
							[56.9049, 27.0088],
							[56.909, 27.0085],
							[56.9071, 27.0121],
							[56.9046, 27.0124],
							[56.9051, 27.0154],
							[56.904, 27.0157],
							[56.9054, 27.0224],
							[56.9063, 27.0338],
							[56.9057, 27.0351],
							[56.9038, 27.0232],
							[56.9026, 27.0249],
							[56.8963, 27.0207],
							[56.8926, 27.0243],
							[56.8907, 27.0243],
							[56.884, 27.0165],
							[56.8835, 27.0124],
							[56.8788, 27.0057],
							[56.8737, 27.0068],
							[56.8671, 27.0149],
							[56.8746, 27.0174],
							[56.879, 27.0235],
							[56.874, 27.0185],
							[56.8693, 27.0221],
							[56.8662, 27.0218],
							[56.8657, 27.0204],
							[56.8604, 27.0207],
							[56.8535, 27.0251],
							[56.846, 27.0349],
							[56.8424, 27.0368],
							[56.8424, 27.0415],
							[56.841, 27.0432],
							[56.8363, 27.0435],
							[56.8413, 27.0476],
							[56.8465, 27.0479],
							[56.8501, 27.0454],
							[56.8587, 27.0446],
							[56.8699, 27.0468],
							[56.8693, 27.0493],
							[56.8596, 27.0479],
							[56.8574, 27.046],
							[56.8557, 27.0471],
							[56.8601, 27.0485],
							[56.8629, 27.0518],
							[56.8607, 27.0565],
							[56.8713, 27.064],
							[56.8671, 27.0632],
							[56.8629, 27.0588],
							[56.8501, 27.0543],
							[56.8529, 27.0615],
							[56.8501, 27.0621],
							[56.8488, 27.0654],
							[56.8535, 27.071],
							[56.8474, 27.074],
							[56.8518, 27.076],
							[56.8513, 27.079],
							[56.8462, 27.0815],
							[56.8465, 27.0801],
							[56.8435, 27.0796],
							[56.8449, 27.0774],
							[56.8438, 27.074],
							[56.8382, 27.0754],
							[56.8357, 27.0743],
							[56.8346, 27.0768],
							[56.8363, 27.0785],
							[56.8349, 27.0799],
							[56.8324, 27.0793],
							[56.8299, 27.0821],
							[56.8301, 27.0838],
							[56.8354, 27.0863],
							[56.8349, 27.0885],
							[56.831, 27.0907],
							[56.8315, 27.0924],
							[56.836, 27.0938],
							[56.8357, 27.0976],
							[56.834, 27.0993],
							[56.8257, 27.1007],
							[56.8315, 27.099],
							[56.8299, 27.0904],
							[56.8338, 27.0876],
							[56.8335, 27.086],
							[56.826, 27.0871],
							[56.8171, 27.0849],
							[56.8132, 27.089],
							[56.8146, 27.0908],
							[56.8004, 27.1029],
							[56.8001, 27.1071],
							[56.7982, 27.1076],
							[56.7968, 27.1129],
							[56.7893, 27.1101],
							[56.7951, 27.1171],
							[56.8004, 27.1196],
							[56.8043, 27.1185],
							[56.8074, 27.1146],
							[56.8043, 27.1204],
							[56.8057, 27.1243],
							[56.8051, 27.1299],
							[56.8065, 27.1332],
							[56.8101, 27.136],
							[56.8071, 27.1349],
							[56.8049, 27.1318],
							[56.8035, 27.1235],
							[56.7943, 27.1215],
							[56.7921, 27.1232],
							[56.7876, 27.1201],
							[56.7849, 27.1238],
							[56.7807, 27.1251],
							[56.7807, 27.1299],
							[56.7779, 27.1276],
							[56.7762, 27.1307],
							[56.771, 27.1304],
							[56.7701, 27.1363],
							[56.7651, 27.134],
							[56.7643, 27.136],
							[56.7618, 27.1335],
							[56.7585, 27.1338],
							[56.7585, 27.1293],
							[56.7538, 27.1285],
							[56.7543, 27.1307],
							[56.7568, 27.1321],
							[56.7554, 27.1368],
							[56.7454, 27.1371],
							[56.7443, 27.136],
							[56.7454, 27.1326],
							[56.7515, 27.1326],
							[56.7496, 27.1288],
							[56.7371, 27.1296],
							[56.7393, 27.1315],
							[56.7365, 27.131],
							[56.7379, 27.1357],
							[56.7365, 27.1388],
							[56.7382, 27.1396],
							[56.739, 27.1385],
							[56.7401, 27.1407],
							[56.7326, 27.1396],
							[56.7318, 27.1429],
							[56.7293, 27.1443],
							[56.729, 27.1463],
							[56.7271, 27.1451],
							[56.7265, 27.1485],
							[56.7215, 27.1535],
							[56.7201, 27.1513],
							[56.7229, 27.1482],
							[56.7218, 27.1463],
							[56.719, 27.1471],
							[56.7196, 27.1513],
							[56.7185, 27.1518],
							[56.7151, 27.1518],
							[56.7132, 27.149],
							[56.7093, 27.149],
							[56.7096, 27.151],
							[56.7129, 27.1504],
							[56.7137, 27.1515],
							[56.7068, 27.1537],
							[56.7032, 27.1504],
							[56.7035, 27.1449],
							[56.6999, 27.1501],
							[56.6963, 27.1446],
							[56.694, 27.144],
							[56.6929, 27.1471],
							[56.6915, 27.1471],
							[56.6882, 27.144],
							[56.6851, 27.1493],
							[56.6832, 27.146],
							[56.6771, 27.1463],
							[56.6768, 27.1476],
							[56.6782, 27.1474],
							[56.6796, 27.1496],
							[56.6779, 27.1507],
							[56.676, 27.1504],
							[56.676, 27.149],
							[56.6715, 27.1499],
							[56.6701, 27.1524],
							[56.6757, 27.1554],
							[56.6699, 27.1557],
							[56.6685, 27.1576],
							[56.6654, 27.1582],
							[56.6615, 27.1526],
							[56.6532, 27.1513],
							[56.6551, 27.1496],
							[56.654, 27.1488],
							[56.6518, 27.1529],
							[56.6493, 27.1526],
							[56.651, 27.1565],
							[56.6485, 27.1568],
							[56.6499, 27.159],
							[56.6482, 27.1588],
							[56.6474, 27.1607],
							[56.6493, 27.1638],
							[56.6546, 27.1624],
							[56.6607, 27.1663],
							[56.6635, 27.1726],
							[56.6624, 27.1787],
							[56.664, 27.1849],
							[56.6613, 27.1804],
							[56.6621, 27.1721],
							[56.6607, 27.169],
							[56.6565, 27.1651],
							[56.651, 27.166],
							[56.6476, 27.1643],
							[56.6421, 27.1537],
							[56.6415, 27.1476],
							[56.6399, 27.1471],
							[56.6379, 27.1474],
							[56.6365, 27.1526],
							[56.6326, 27.1515],
							[56.6324, 27.1529],
							[56.626, 27.1537],
							[56.626, 27.1582],
							[56.624, 27.1576],
							[56.6237, 27.1593],
							[56.6213, 27.1588],
							[56.6188, 27.1607],
							[56.6129, 27.1579],
							[56.6104, 27.1632],
							[56.6085, 27.1607],
							[56.6063, 27.1612],
							[56.6065, 27.166],
							[56.6082, 27.1679],
							[56.6057, 27.1696],
							[56.606, 27.1663],
							[56.5979, 27.1646],
							[56.5954, 27.1621],
							[56.5874, 27.1667],
							[56.5885, 27.1707],
							[56.5915, 27.1704],
							[56.5918, 27.1751],
							[56.5949, 27.1754],
							[56.5965, 27.1812],
							[56.5946, 27.1801],
							[56.5932, 27.1763],
							[56.5899, 27.1757],
							[56.5899, 27.1726],
							[56.5857, 27.1699],
							[56.5846, 27.1663],
							[56.579, 27.1671],
							[56.579, 27.1643],
							[56.5765, 27.1635],
							[56.5771, 27.1615],
							[56.5738, 27.1626],
							[56.5699, 27.1612],
							[56.5693, 27.1571],
							[56.5649, 27.1607],
							[56.5629, 27.1585],
							[56.561, 27.1596],
							[56.5582, 27.1562],
							[56.5518, 27.156],
							[56.5479, 27.1576],
							[56.5468, 27.1601],
							[56.5446, 27.1599],
							[56.5438, 27.1629],
							[56.5376, 27.1638],
							[56.5385, 27.1663],
							[56.5349, 27.1646],
							[56.5307, 27.1663],
							[56.5321, 27.1696],
							[56.5299, 27.1685],
							[56.5276, 27.1704],
							[56.5299, 27.1746],
							[56.5221, 27.1743],
							[56.521, 27.1718],
							[56.5179, 27.1721],
							[56.5213, 27.1696],
							[56.5226, 27.1643],
							[56.5204, 27.1643],
							[56.5193, 27.1665],
							[56.5132, 27.166],
							[56.5126, 27.1685],
							[56.5029, 27.1671],
							[56.4999, 27.1685],
							[56.4918, 27.1663],
							[56.4893, 27.1682],
							[56.4885, 27.1665],
							[56.4857, 27.1671],
							[56.479, 27.1638],
							[56.4688, 27.1624],
							[56.4549, 27.1665],
							[56.4521, 27.1651],
							[56.4482, 27.166],
							[56.4413, 27.1685],
							[56.4393, 27.1712],
							[56.4362, 27.171],
							[56.4349, 27.1732],
							[56.4324, 27.1732],
							[56.4279, 27.1688],
							[56.4226, 27.1682],
							[56.4151, 27.1707],
							[56.406, 27.1765],
							[56.4043, 27.1765],
							[56.4021, 27.1732],
							[56.3996, 27.174],
							[56.3996, 27.1724],
							[56.394, 27.1749],
							[56.3932, 27.1776],
							[56.3946, 27.1807],
							[56.4032, 27.184],
							[56.4043, 27.1887],
							[56.4026, 27.186],
							[56.3921, 27.1807],
							[56.3915, 27.1818],
							[56.3893, 27.181],
							[56.3882, 27.1832],
							[56.3821, 27.1824],
							[56.3713, 27.1851],
							[56.3729, 27.1868],
							[56.371, 27.1882],
							[56.3629, 27.1863],
							[56.3504, 27.1899],
							[56.3371, 27.186],
							[56.3312, 27.1871],
							[56.3276, 27.1846],
							[56.3204, 27.1851],
							[56.319, 27.1829],
							[56.2865, 27.1796],
							[56.2796, 27.1776],
							[56.2782, 27.1757],
							[56.2765, 27.1771],
							[56.2457, 27.1649],
							[56.2349, 27.1579],
							[56.2351, 27.1543],
							[56.2315, 27.1565],
							[56.2304, 27.1543],
							[56.2232, 27.151],
							[56.2212, 27.1582],
							[56.2226, 27.1604],
							[56.2207, 27.1621],
							[56.2196, 27.1593],
							[56.2221, 27.1526],
							[56.2218, 27.1424],
							[56.2174, 27.139],
							[56.2101, 27.136],
							[56.2043, 27.1388],
							[56.2101, 27.1415],
							[56.2135, 27.1401],
							[56.2146, 27.1429],
							[56.2126, 27.1435],
							[56.2124, 27.1463],
							[56.211, 27.1437],
							[56.2054, 27.1426],
							[56.2046, 27.1468],
							[56.2062, 27.1476],
							[56.2046, 27.1482],
							[56.2046, 27.1529],
							[56.2035, 27.1532],
							[56.204, 27.1451],
							[56.2021, 27.1432],
							[56.2035, 27.1374],
							[56.2026, 27.136],
							[56.1988, 27.136],
							[56.1996, 27.1307],
							[56.2018, 27.1285],
							[56.1996, 27.129],
							[56.1974, 27.1357],
							[56.186, 27.1365],
							[56.1857, 27.1379],
							[56.1885, 27.1365],
							[56.1913, 27.1462],
							[56.1907, 27.1513],
							[56.1885, 27.1526],
							[56.181, 27.1543],
							[56.1379, 27.1537],
							[56.1249, 27.1499],
							[56.1174, 27.1451],
							[56.1065, 27.1349],
							[56.1074, 27.1326],
							[56.1051, 27.1335],
							[56.0971, 27.1268],
							[56.0938, 27.121],
							[56.0946, 27.1193],
							[56.0915, 27.1199],
							[56.0901, 27.1165],
							[56.089, 27.1115],
							[56.0918, 27.1113],
							[56.0915, 27.1101],
							[56.089, 27.1107],
							[56.0843, 27.1049],
							[56.0799, 27.1057],
							[56.0796, 27.101],
							[56.0721, 27.1007],
							[56.0685, 27.1076],
							[56.0657, 27.1087],
							[56.0701, 27.1015],
							[56.0674, 27.0985],
							[56.0612, 27.0965],
							[56.0621, 27.101],
							[56.0604, 27.1004],
							[56.0601, 27.1035],
							[56.0582, 27.1046],
							[56.0563, 27.1037],
							[56.0582, 27.1001],
							[56.056, 27.0987],
							[56.0529, 27.1004],
							[56.0557, 27.0996],
							[56.0571, 27.1007],
							[56.0565, 27.1026],
							[56.0543, 27.1012],
							[56.0543, 27.1035],
							[56.051, 27.1001],
							[56.0485, 27.1001],
							[56.049, 27.0976],
							[56.0474, 27.0982],
							[56.0454, 27.0946],
							[56.0479, 27.0896],
							[56.0496, 27.089],
							[56.049, 27.0879],
							[56.0576, 27.0865],
							[56.046, 27.0874],
							[56.0476, 27.0893],
							[56.0443, 27.0951],
							[56.0421, 27.0957],
							[56.0418, 27.0938],
							[56.0385, 27.094],
							[56.0368, 27.0918],
							[56.0351, 27.0924],
							[56.0276, 27.0879],
							[56.0187, 27.0865],
							[55.9793, 27.0682],
							[55.9782, 27.0665],
							[55.9807, 27.0615],
							[55.9829, 27.0626],
							[55.9849, 27.0601],
							[55.9851, 27.0512],
							[55.9824, 27.049],
							[55.9763, 27.0501],
							[55.9751, 27.0454],
							[55.9746, 27.0507],
							[55.9712, 27.0468],
							[55.971, 27.0501],
							[55.9688, 27.0493],
							[55.9688, 27.0476],
							[55.9665, 27.0499],
							[55.9601, 27.0479],
							[55.9588, 27.0454],
							[55.9596, 27.0401],
							[55.9657, 27.0396],
							[55.9701, 27.0313],
							[55.9749, 27.0313],
							[55.9751, 27.0304],
							[55.9665, 27.0293],
							[55.9632, 27.0335],
							[55.9576, 27.0335],
							[55.9562, 27.0315],
							[55.9576, 27.0299],
							[55.9538, 27.0276],
							[55.9518, 27.0232],
							[55.9457, 27.0213],
							[55.9362, 27.0226],
							[55.9263, 27.021],
							[55.9135, 27.0163],
							[55.9065, 27.0163],
							[55.906, 27.0182],
							[55.9001, 27.0201],
							[55.8988, 27.0168],
							[55.8974, 27.021],
							[55.8932, 27.0215],
							[55.8949, 27.019],
							[55.8926, 27.0174],
							[55.8821, 27.0154],
							[55.8743, 27.0088],
							[55.8704, 27.0093],
							[55.8635, 27.0054],
							[55.8599, 27.0054],
							[55.8596, 27.0074],
							[55.856, 27.0076],
							[55.854, 27.0054],
							[55.8493, 27.0063],
							[55.836, 27.0012],
							[55.8268, 27.0001],
							[55.819, 27.0004],
							[55.8171, 27.0032],
							[55.8135, 27.0038],
							[55.8129, 27.0135],
							[55.811, 27.0149],
							[55.8143, 27.0182],
							[55.8146, 27.0226],
							[55.8129, 27.0257],
							[55.8107, 27.0263],
							[55.8126, 27.0204],
							[55.8093, 27.014],
							[55.8093, 27.0099],
							[55.8143, 26.9993],
							[55.8063, 26.996],
							[55.789, 26.9996],
							[55.7879, 26.9982],
							[55.7835, 26.9982],
							[55.7799, 26.9949],
							[55.7757, 26.9957],
							[55.7757, 27.0007],
							[55.7724, 27.0051],
							[55.7671, 27.0065],
							[55.7635, 27.0101],
							[55.7649, 27.0132],
							[55.7713, 27.014],
							[55.7646, 27.0151],
							[55.7615, 27.0121],
							[55.7707, 27.0021],
							[55.7718, 26.9974],
							[55.774, 26.9974],
							[55.7751, 26.9935],
							[55.7724, 26.9929],
							[55.7715, 26.9893],
							[55.7679, 26.9857],
							[55.7618, 26.9849],
							[55.7626, 26.9885],
							[55.759, 26.9868],
							[55.7532, 26.9801],
							[55.7507, 26.9746],
							[55.7485, 26.9724],
							[55.7449, 26.9718],
							[55.739, 26.9779],
							[55.7279, 26.9821],
							[55.7188, 26.9824],
							[55.7076, 26.9804],
							[55.6974, 26.9849],
							[55.6813, 26.9865],
							[55.6707, 26.984],
							[55.6474, 26.9835],
							[55.6399, 26.9804],
							[55.6332, 26.9751],
							[55.631, 26.9715],
							[55.6307, 26.9654],
							[55.6243, 26.9568],
							[55.6188, 26.9526],
							[55.6063, 26.9504],
							[55.5801, 26.9304],
							[55.5762, 26.9268],
							[55.5751, 26.9226],
							[55.5687, 26.9138],
							[55.5707, 26.9104],
							[55.5812, 26.9232],
							[55.5849, 26.9207],
							[55.5843, 26.9235],
							[55.5865, 26.926],
							[55.5899, 26.9271],
							[55.5957, 26.926],
							[55.5962, 26.921],
							[55.5907, 26.9107],
							[55.5882, 26.9129],
							[55.5849, 26.9121],
							[55.5821, 26.9079],
							[55.5851, 26.9076],
							[55.5857, 26.9049],
							[55.5788, 26.904],
							[55.5724, 26.8979],
							[55.5729, 26.8971],
							[55.5765, 26.9013],
							[55.5782, 26.9001],
							[55.5804, 26.9021],
							[55.5868, 26.9032],
							[55.5893, 26.9004],
							[55.591, 26.8896],
							[55.581, 26.884],
							[55.5746, 26.8865],
							[55.5732, 26.8907],
							[55.5724, 26.887],
							[55.5757, 26.8843],
							[55.5738, 26.8821],
							[55.5765, 26.8726],
							[55.5541, 26.8704],
							[55.5626, 26.8707],
							[55.5635, 26.8685],
							[55.5618, 26.8671],
							[55.5687, 26.8649],
							[55.5685, 26.8613],
							[55.5718, 26.8613],
							[55.5738, 26.8568],
							[55.5735, 26.8532],
							[55.5629, 26.8565],
							[55.5576, 26.8515],
							[55.554, 26.8513],
							[55.5576, 26.8507],
							[55.561, 26.8543],
							[55.5646, 26.8554],
							[55.574, 26.851],
							[55.5724, 26.8454],
							[55.5682, 26.8396],
							[55.5596, 26.8374],
							[55.5574, 26.8343],
							[55.5576, 26.8304],
							[55.5604, 26.829],
							[55.5624, 26.8215],
							[55.5618, 26.8179],
							[55.5646, 26.8143],
							[55.5632, 26.8113],
							[55.5574, 26.8046],
							[55.5499, 26.7999],
							[55.5421, 26.7968],
							[55.5374, 26.7971],
							[55.5226, 26.7832],
							[55.5199, 26.7829],
							[55.5182, 26.7801],
							[55.4949, 26.7654],
							[55.4768, 26.7579],
							[55.4651, 26.7504],
							[55.4601, 26.7496],
							[55.4465, 26.7518],
							[55.4407, 26.7504],
							[55.4388, 26.7526],
							[55.4343, 26.749],
							[55.4315, 26.7493],
							[55.4282, 26.7515],
							[55.4149, 26.7549],
							[55.4076, 26.7532],
							[55.3832, 26.7526],
							[55.3757, 26.7546],
							[55.3654, 26.7629],
							[55.3526, 26.7696],
							[55.3438, 26.7796],
							[55.3338, 26.7868],
							[55.3276, 26.7857],
							[55.3232, 26.7876],
							[55.321, 26.7857],
							[55.3182, 26.7862],
							[55.3171, 26.7846],
							[55.3129, 26.7837],
							[55.3121, 26.7821],
							[55.309, 26.784],
							[55.2996, 26.7824],
							[55.2776, 26.7843],
							[55.2526, 26.7832],
							[55.2496, 26.779],
							[55.2429, 26.7796],
							[55.2357, 26.7765],
							[55.2276, 26.7612],
							[55.2265, 26.7537],
							[55.2212, 26.7457],
							[55.2179, 26.7346],
							[55.2129, 26.7301],
							[55.204, 26.7285],
							[55.1999, 26.7229],
							[55.191, 26.7226],
							[55.1768, 26.7193],
							[55.1596, 26.7218],
							[55.1482, 26.7138],
							[55.1393, 26.7004],
							[55.1301, 26.6993],
							[55.1124, 26.701],
							[55.0982, 26.6954],
							[55.0907, 26.6874],
							[55.0885, 26.6824],
							[55.0854, 26.6807],
							[55.0843, 26.6774],
							[55.0785, 26.6757],
							[55.0737, 26.6638],
							[55.0649, 26.6485],
							[55.061, 26.6446],
							[55.0535, 26.6413],
							[55.0301, 26.6418],
							[55.0265, 26.6401],
							[55.0204, 26.6332],
							[55.0137, 26.6318],
							[55.0112, 26.6285],
							[55.0088, 26.6293],
							[55.0035, 26.626],
							[54.9918, 26.6262],
							[54.9865, 26.6221],
							[54.9796, 26.6218],
							[54.9754, 26.619],
							[54.9715, 26.6187],
							[54.9679, 26.6157],
							[54.9685, 26.6132],
							[54.9629, 26.6132],
							[54.9626, 26.6113],
							[54.9507, 26.6001],
							[54.951, 26.5904],
							[54.9501, 26.594],
							[54.9435, 26.5937],
							[54.9335, 26.5854],
							[54.9185, 26.5785],
							[54.9132, 26.5743],
						],
					],
					[
						[
							[55.274, 26.5901],
							[55.2732, 26.5918],
							[55.2743, 26.5932],
							[55.2746, 26.5921],
							[55.274, 26.5901],
						],
					],
					[
						[
							[55.2729, 26.6618],
							[55.2724, 26.661],
							[55.2696, 26.6624],
							[55.2721, 26.6629],
							[55.2729, 26.6618],
						],
					],
					[
						[
							[53.6138, 26.6951],
							[53.6151, 26.6926],
							[53.6185, 26.6932],
							[53.6235, 26.689],
							[53.6276, 26.689],
							[53.6274, 26.6904],
							[53.6285, 26.6904],
							[53.6332, 26.6876],
							[53.6468, 26.6885],
							[53.6518, 26.6871],
							[53.6585, 26.6824],
							[53.659, 26.6799],
							[53.6654, 26.6815],
							[53.6712, 26.6782],
							[53.6715, 26.676],
							[53.6665, 26.6699],
							[53.6643, 26.6643],
							[53.6565, 26.6582],
							[53.6496, 26.6554],
							[53.6293, 26.6568],
							[53.6276, 26.6607],
							[53.6196, 26.6624],
							[53.6143, 26.6654],
							[53.6126, 26.6621],
							[53.6082, 26.6629],
							[53.5965, 26.6719],
							[53.5935, 26.6832],
							[53.5965, 26.6921],
							[53.5999, 26.6946],
							[53.6076, 26.6963],
							[53.6138, 26.6951],
						],
					],
					[
						[
							[55.6129, 26.7432],
							[55.606, 26.7385],
							[55.6021, 26.7376],
							[55.6013, 26.7393],
							[55.6049, 26.7438],
							[55.6138, 26.7474],
							[55.6129, 26.7432],
						],
					],
					[
						[
							[55.6201, 26.7604],
							[55.6185, 26.7554],
							[55.6149, 26.7526],
							[55.6087, 26.7493],
							[55.6049, 26.7493],
							[55.6138, 26.7588],
							[55.6185, 26.7621],
							[55.6201, 26.7604],
						],
					],
					[
						[
							[55.634, 26.7621],
							[55.6343, 26.7613],
							[55.6304, 26.7599],
							[55.6318, 26.7629],
							[55.634, 26.7621],
						],
					],
					[
						[
							[55.7457, 26.8126],
							[55.7504, 26.8071],
							[55.7499, 26.806],
							[55.7451, 26.806],
							[55.744, 26.8137],
							[55.7457, 26.8126],
						],
					],
					[
						[
							[55.7538, 26.8207],
							[55.7543, 26.8149],
							[55.756, 26.8143],
							[55.7551, 26.8182],
							[55.7576, 26.8188],
							[55.7613, 26.8137],
							[55.761, 26.8074],
							[55.7585, 26.8049],
							[55.754, 26.806],
							[55.7546, 26.8074],
							[55.752, 26.8085],
							[55.7582, 26.8096],
							[55.7585, 26.8107],
							[55.756, 26.8096],
							[55.754, 26.8113],
							[55.7513, 26.809],
							[55.7465, 26.8135],
							[55.7487, 26.814],
							[55.7515, 26.8213],
							[55.7538, 26.8207],
						],
					],
					[
						[
							[55.7426, 26.8326],
							[55.751, 26.8243],
							[55.7504, 26.8188],
							[55.7476, 26.8151],
							[55.7457, 26.8157],
							[55.7457, 26.819],
							[55.7435, 26.8165],
							[55.7365, 26.8196],
							[55.7374, 26.8335],
							[55.741, 26.834],
							[55.7426, 26.8326],
						],
					],
					[
						[
							[55.7454, 26.8521],
							[55.744, 26.8515],
							[55.7463, 26.8493],
							[55.746, 26.8457],
							[55.7501, 26.8438],
							[55.7574, 26.8357],
							[55.7565, 26.8288],
							[55.7518, 26.8321],
							[55.7449, 26.8329],
							[55.7379, 26.8368],
							[55.7418, 26.8526],
							[55.7443, 26.8538],
							[55.7454, 26.8521],
						],
					],
					[
						[
							[55.7249, 26.8568],
							[55.7263, 26.8535],
							[55.7243, 26.8465],
							[55.7279, 26.8365],
							[55.724, 26.8404],
							[55.7038, 26.8415],
							[55.7001, 26.8432],
							[55.7001, 26.8454],
							[55.716, 26.8532],
							[55.7193, 26.8568],
							[55.7249, 26.8568],
						],
					],
					[
						[
							[55.7618, 26.8599],
							[55.7651, 26.8582],
							[55.7665, 26.8493],
							[55.7718, 26.8404],
							[55.7726, 26.8357],
							[55.7707, 26.8329],
							[55.7651, 26.834],
							[55.7621, 26.8399],
							[55.7565, 26.8432],
							[55.7532, 26.8479],
							[55.7582, 26.8604],
							[55.7601, 26.8613],
							[55.7618, 26.8599],
						],
					],
					[
						[
							[55.7613, 26.869],
							[55.7621, 26.8685],
							[55.7624, 26.8668],
							[55.7607, 26.8688],
							[55.7607, 26.8713],
							[55.7613, 26.869],
						],
					],
					[
						[
							[55.7035, 26.9346],
							[55.7065, 26.9315],
							[55.7074, 26.9249],
							[55.7026, 26.901],
							[55.7057, 26.9118],
							[55.711, 26.9174],
							[55.7149, 26.9112],
							[55.7154, 26.8918],
							[55.7196, 26.8887],
							[55.7163, 26.8824],
							[55.7071, 26.8762],
							[55.7149, 26.8801],
							[55.7201, 26.8871],
							[55.7221, 26.8857],
							[55.7196, 26.8826],
							[55.7149, 26.8699],
							[55.7101, 26.869],
							[55.7137, 26.8679],
							[55.7126, 26.8637],
							[55.7004, 26.8543],
							[55.6996, 26.8496],
							[55.696, 26.8482],
							[55.6924, 26.849],
							[55.6924, 26.8479],
							[55.6838, 26.8465],
							[55.681, 26.8443],
							[55.679, 26.8393],
							[55.6807, 26.8326],
							[55.6712, 26.834],
							[55.669, 26.836],
							[55.6743, 26.8418],
							[55.6763, 26.8465],
							[55.6751, 26.8515],
							[55.6701, 26.8549],
							[55.6738, 26.8565],
							[55.6776, 26.8615],
							[55.6696, 26.856],
							[55.6662, 26.8568],
							[55.6635, 26.8632],
							[55.6715, 26.8707],
							[55.6776, 26.871],
							[55.6801, 26.8679],
							[55.6874, 26.8665],
							[55.6865, 26.8685],
							[55.681, 26.8685],
							[55.6796, 26.8701],
							[55.6829, 26.8724],
							[55.6896, 26.8732],
							[55.6913, 26.8788],
							[55.6904, 26.8857],
							[55.6874, 26.8887],
							[55.6888, 26.891],
							[55.6874, 26.8988],
							[55.6838, 26.9049],
							[55.6749, 26.9104],
							[55.6735, 26.9165],
							[55.6743, 26.9179],
							[55.6765, 26.9168],
							[55.6832, 26.9179],
							[55.6926, 26.9218],
							[55.6954, 26.9257],
							[55.6996, 26.9276],
							[55.7015, 26.9354],
							[55.7035, 26.9346],
						],
					],
					[
						[
							[55.6785, 26.9054],
							[55.6835, 26.9007],
							[55.6857, 26.8943],
							[55.6854, 26.8857],
							[55.689, 26.8829],
							[55.6888, 26.8804],
							[55.6843, 26.8749],
							[55.6765, 26.8715],
							[55.6701, 26.8715],
							[55.6643, 26.8646],
							[55.6621, 26.8649],
							[55.661, 26.8743],
							[55.6574, 26.8776],
							[55.6593, 26.8812],
							[55.6596, 26.8865],
							[55.6688, 26.8951],
							[55.6724, 26.9038],
							[55.6771, 26.9071],
							[55.6785, 26.9054],
						],
					],
					[
						[
							[55.6621, 26.9026],
							[55.664, 26.9001],
							[55.6635, 26.8963],
							[55.6551, 26.8868],
							[55.6507, 26.886],
							[55.6507, 26.891],
							[55.6576, 26.9024],
							[55.6601, 26.9046],
							[55.6621, 26.9026],
						],
					],
					[
						[
							[55.7399, 26.9088],
							[55.7415, 26.9068],
							[55.741, 26.9057],
							[55.7385, 26.9063],
							[55.7374, 26.9101],
							[55.7399, 26.9088],
						],
					],
					[
						[
							[55.7287, 26.9229],
							[55.7276, 26.9221],
							[55.7265, 26.9243],
							[55.729, 26.9249],
							[55.7287, 26.9229],
						],
					],
					[
						[
							[55.5863, 26.8782],
							[55.5854, 26.8754],
							[55.5818, 26.8776],
							[55.5854, 26.8801],
							[55.5863, 26.8782],
						],
					],
					[
						[
							[55.6293, 26.891],
							[55.6271, 26.8904],
							[55.6251, 26.8926],
							[55.6293, 26.8929],
							[55.6293, 26.891],
						],
					],
					[
						[
							[55.604, 26.9151],
							[55.6015, 26.9126],
							[55.601, 26.9093],
							[55.599, 26.9082],
							[55.5974, 26.9099],
							[55.5979, 26.9138],
							[55.6024, 26.9171],
							[55.6037, 26.9171],
							[55.604, 26.9151],
						],
					],
					[
						[
							[55.6604, 26.9818],
							[55.6668, 26.9807],
							[55.6749, 26.9754],
							[55.6632, 26.966],
							[55.644, 26.9704],
							[55.6376, 26.9654],
							[55.6318, 26.9696],
							[55.6324, 26.9729],
							[55.6399, 26.9796],
							[55.6496, 26.9824],
							[55.6604, 26.9818],
						],
					],
					[
						[
							[53.4063, 26.7963],
							[53.416, 26.7935],
							[53.4179, 26.7943],
							[53.419, 26.7913],
							[53.416, 26.789],
							[53.4162, 26.7876],
							[53.4126, 26.7876],
							[53.4126, 26.7888],
							[53.4096, 26.7876],
							[53.4051, 26.7901],
							[53.4024, 26.7949],
							[53.4035, 26.7968],
							[53.4063, 26.7963],
						],
					],
					[
						[
							[53.1763, 26.8504],
							[53.1893, 26.8476],
							[53.1968, 26.8482],
							[53.2337, 26.8343],
							[53.2663, 26.8276],
							[53.2707, 26.8288],
							[53.279, 26.826],
							[53.2826, 26.8268],
							[53.2996, 26.8249],
							[53.3037, 26.8221],
							[53.3099, 26.8224],
							[53.3107, 26.8246],
							[53.3163, 26.8232],
							[53.3249, 26.8243],
							[53.3288, 26.8229],
							[53.3312, 26.8263],
							[53.3343, 26.8263],
							[53.3418, 26.8213],
							[53.3476, 26.8224],
							[53.3515, 26.8198],
							[53.3568, 26.8196],
							[53.3679, 26.8124],
							[53.3735, 26.811],
							[53.3785, 26.8054],
							[53.3854, 26.8032],
							[53.3887, 26.7979],
							[53.3874, 26.7965],
							[53.3882, 26.7935],
							[53.3774, 26.7954],
							[53.356, 26.7899],
							[53.3257, 26.7954],
							[53.3229, 26.7924],
							[53.3065, 26.7874],
							[53.2976, 26.7879],
							[53.2957, 26.791],
							[53.2721, 26.7901],
							[53.2596, 26.7913],
							[53.2337, 26.7976],
							[53.2182, 26.804],
							[53.2154, 26.8032],
							[53.2101, 26.8076],
							[53.1982, 26.8113],
							[53.1796, 26.8201],
							[53.1746, 26.8251],
							[53.1699, 26.8268],
							[53.1618, 26.8376],
							[53.1585, 26.8388],
							[53.1554, 26.8432],
							[53.1557, 26.8485],
							[53.1601, 26.8482],
							[53.1662, 26.8513],
							[53.1763, 26.8504],
						],
					],
					[
						[
							[58.0015, 25.6557],
							[58.0007, 25.6551],
							[57.9971, 25.6576],
							[58.0004, 25.659],
							[58.0021, 25.6582],
							[58.0015, 25.6557],
						],
					],
					[
						[
							[57.7768, 25.6704],
							[57.7768, 25.6738],
							[57.7771, 25.6738],
							[57.7774, 25.6696],
							[57.7768, 25.6704],
						],
					],
					[
						[
							[57.1018, 26.3029],
							[57.1024, 26.3015],
							[57.1007, 26.3018],
							[57.0993, 26.3043],
							[57.1018, 26.3029],
						],
					],
					[
						[
							[56.8849, 27.0024],
							[56.8879, 26.9999],
							[56.8932, 26.9988],
							[56.8935, 26.9965],
							[56.8901, 26.9962],
							[56.894, 26.9937],
							[56.8893, 26.9935],
							[56.8963, 26.9918],
							[56.8963, 26.9874],
							[56.8999, 26.9837],
							[56.8868, 26.9907],
							[56.8846, 26.9946],
							[56.8818, 26.994],
							[56.8824, 26.9962],
							[56.8807, 26.9976],
							[56.8749, 26.9996],
							[56.8774, 27.0004],
							[56.8851, 26.9962],
							[56.8835, 26.9979],
							[56.8851, 27.0004],
							[56.8826, 27.004],
							[56.8849, 27.0024],
						],
					],
					[
						[
							[56.9382, 26.9982],
							[56.9374, 26.996],
							[56.9362, 26.996],
							[56.9379, 26.9979],
							[56.9362, 26.9999],
							[56.9385, 26.9999],
							[56.9382, 26.9982],
						],
					],
					[
						[
							[56.9351, 26.9993],
							[56.934, 26.9988],
							[56.9329, 27.001],
							[56.9346, 27.001],
							[56.9351, 26.9993],
						],
					],
					[
						[
							[56.3896, 26.8849],
							[56.4018, 26.8846],
							[56.4046, 26.8829],
							[56.4093, 26.8751],
							[56.4115, 26.8751],
							[56.4118, 26.8768],
							[56.4135, 26.8754],
							[56.4118, 26.8662],
							[56.3993, 26.8479],
							[56.3913, 26.8412],
							[56.3885, 26.8418],
							[56.3796, 26.8354],
							[56.3768, 26.8374],
							[56.3735, 26.8335],
							[56.3743, 26.8321],
							[56.3676, 26.8299],
							[56.3662, 26.8276],
							[56.3496, 26.8257],
							[56.3476, 26.8246],
							[56.3488, 26.8226],
							[56.3451, 26.8221],
							[56.3371, 26.8243],
							[56.3299, 26.8232],
							[56.3299, 26.8213],
							[56.3276, 26.8207],
							[56.3246, 26.8218],
							[56.3249, 26.8249],
							[56.3187, 26.8296],
							[56.3149, 26.8371],
							[56.3149, 26.851],
							[56.3168, 26.8513],
							[56.3171, 26.8579],
							[56.3196, 26.8576],
							[56.3235, 26.8626],
							[56.3271, 26.8635],
							[56.3265, 26.8646],
							[56.3285, 26.8649],
							[56.3443, 26.8824],
							[56.3693, 26.8857],
							[56.3896, 26.8849],
						],
					],
					[
						[
							[56.4543, 27.0982],
							[56.4596, 27.0918],
							[56.4718, 27.0901],
							[56.4774, 27.0868],
							[56.4832, 27.0879],
							[56.4832, 27.0907],
							[56.4774, 27.091],
							[56.4785, 27.0943],
							[56.4796, 27.094],
							[56.4837, 27.0912],
							[56.4851, 27.0843],
							[56.4887, 27.0835],
							[56.4899, 27.0799],
							[56.4943, 27.0793],
							[56.4985, 27.076],
							[56.5018, 27.0682],
							[56.5015, 27.0593],
							[56.4976, 27.0504],
							[56.4943, 27.0479],
							[56.4946, 27.0443],
							[56.4963, 27.0429],
							[56.4896, 27.0415],
							[56.4865, 27.0388],
							[56.4832, 27.039],
							[56.4749, 27.0326],
							[56.4718, 27.0338],
							[56.459, 27.0329],
							[56.4579, 27.0346],
							[56.4515, 27.0354],
							[56.446, 27.0382],
							[56.441, 27.0371],
							[56.4268, 27.0443],
							[56.4257, 27.0476],
							[56.4218, 27.0507],
							[56.4207, 27.0532],
							[56.4226, 27.066],
							[56.4243, 27.0654],
							[56.4279, 27.0679],
							[56.4249, 27.0735],
							[56.4263, 27.0762],
							[56.4432, 27.0854],
							[56.4449, 27.0885],
							[56.4407, 27.0926],
							[56.441, 27.0957],
							[56.4415, 27.0929],
							[56.4451, 27.091],
							[56.4457, 27.0929],
							[56.4437, 27.0951],
							[56.4479, 27.0943],
							[56.4524, 27.1021],
							[56.4538, 27.1018],
							[56.4543, 27.0982],
						],
					],
					[
						[
							[56.0657, 27.0863],
							[56.0649, 27.0868],
							[56.0624, 27.0863],
							[56.0593, 27.0868],
							[56.0693, 27.0871],
							[56.0657, 27.0863],
						],
					],
					[
						[
							[56.074, 27.0918],
							[56.0762, 27.0907],
							[56.0724, 27.0893],
							[56.074, 27.0912],
							[56.0687, 27.0926],
							[56.074, 27.0918],
						],
					],
					[
						[
							[56.9465, 26.9993],
							[56.9443, 26.9996],
							[56.9443, 27.0004],
							[56.9471, 27.0012],
							[56.9465, 26.9993],
						],
					],
					[
						[
							[56.8824, 27.0012],
							[56.8829, 26.9996],
							[56.8812, 26.9993],
							[56.8788, 27.0015],
							[56.8824, 27.0012],
						],
					],
					[
						[
							[56.9021, 27.0118],
							[56.8999, 27.0107],
							[56.8993, 27.0129],
							[56.9015, 27.0132],
							[56.9021, 27.0118],
						],
					],
					[
						[
							[56.8518, 27.049],
							[56.8493, 27.0488],
							[56.849, 27.0499],
							[56.851, 27.0504],
							[56.8518, 27.049],
						],
					],
					[
						[
							[55.8937, 26.6849],
							[55.8949, 26.6821],
							[55.9007, 26.6771],
							[55.9038, 26.6771],
							[55.9046, 26.6746],
							[55.9096, 26.6715],
							[55.909, 26.6674],
							[55.9107, 26.6612],
							[55.9151, 26.6604],
							[55.9135, 26.6537],
							[55.9093, 26.6504],
							[55.9096, 26.6482],
							[55.9032, 26.6376],
							[55.9024, 26.6346],
							[55.9038, 26.6318],
							[55.9021, 26.6285],
							[55.8988, 26.624],
							[55.889, 26.6165],
							[55.8793, 26.6149],
							[55.874, 26.6121],
							[55.8654, 26.6129],
							[55.8626, 26.6104],
							[55.8579, 26.6126],
							[55.8568, 26.6107],
							[55.8549, 26.6135],
							[55.8501, 26.6143],
							[55.8499, 26.6162],
							[55.8451, 26.6193],
							[55.8438, 26.626],
							[55.8451, 26.629],
							[55.8479, 26.6293],
							[55.8499, 26.6346],
							[55.8532, 26.6351],
							[55.8532, 26.6393],
							[55.8563, 26.6435],
							[55.8568, 26.649],
							[55.8613, 26.6526],
							[55.8688, 26.6676],
							[55.8751, 26.671],
							[55.8763, 26.6768],
							[55.8776, 26.6774],
							[55.8774, 26.6813],
							[55.8893, 26.6832],
							[55.8918, 26.6868],
							[55.8937, 26.6849],
						],
					],
					[
						[
							[56.1585, 26.9985],
							[56.1665, 26.9924],
							[56.1732, 26.9912],
							[56.1868, 26.9932],
							[56.1837, 26.9974],
							[56.1849, 26.9993],
							[56.1899, 26.9912],
							[56.2051, 26.9935],
							[56.2088, 26.9965],
							[56.2168, 26.9943],
							[56.2232, 26.9868],
							[56.2326, 26.9807],
							[56.2368, 26.9851],
							[56.2349, 26.9799],
							[56.2374, 26.979],
							[56.2401, 26.9829],
							[56.2379, 26.979],
							[56.2396, 26.9776],
							[56.2438, 26.9771],
							[56.2457, 26.9782],
							[56.2446, 26.9799],
							[56.2454, 26.9804],
							[56.2471, 26.979],
							[56.2454, 26.9765],
							[56.2565, 26.9735],
							[56.2701, 26.9649],
							[56.2718, 26.9646],
							[56.2715, 26.9676],
							[56.2738, 26.9676],
							[56.2738, 26.9643],
							[56.2776, 26.9626],
							[56.2813, 26.9546],
							[56.2807, 26.949],
							[56.2851, 26.9479],
							[56.2824, 26.9476],
							[56.2821, 26.9426],
							[56.2849, 26.9429],
							[56.2871, 26.9476],
							[56.2868, 26.944],
							[56.2843, 26.9415],
							[56.2796, 26.9401],
							[56.2754, 26.9338],
							[56.2682, 26.9318],
							[56.264, 26.9279],
							[56.2529, 26.9274],
							[56.2429, 26.9249],
							[56.231, 26.9251],
							[56.2199, 26.9229],
							[56.201, 26.9221],
							[56.1874, 26.9179],
							[56.1665, 26.9074],
							[56.1654, 26.9038],
							[56.1671, 26.8988],
							[56.1624, 26.8968],
							[56.1615, 26.8924],
							[56.1582, 26.8885],
							[56.1574, 26.8849],
							[56.1599, 26.884],
							[56.1546, 26.8779],
							[56.156, 26.8771],
							[56.1549, 26.8688],
							[56.1457, 26.8554],
							[56.1457, 26.8507],
							[56.1415, 26.8446],
							[56.1413, 26.8412],
							[56.1332, 26.8365],
							[56.1326, 26.8329],
							[56.1299, 26.8307],
							[56.1246, 26.8326],
							[56.1143, 26.8254],
							[56.1076, 26.8174],
							[56.1082, 26.8137],
							[56.1018, 26.8132],
							[56.0951, 26.8071],
							[56.0893, 26.8057],
							[56.0835, 26.7954],
							[56.084, 26.7926],
							[56.0874, 26.7929],
							[56.0879, 26.7907],
							[56.0774, 26.781],
							[56.069, 26.7749],
							[56.0657, 26.7757],
							[56.061, 26.7729],
							[56.0585, 26.7735],
							[56.0543, 26.7704],
							[56.0507, 26.7626],
							[56.0438, 26.7618],
							[56.0429, 26.7601],
							[56.0329, 26.7568],
							[56.0262, 26.7515],
							[56.0251, 26.7471],
							[56.0182, 26.7471],
							[56.0137, 26.7451],
							[56.0065, 26.7385],
							[56.0062, 26.7401],
							[56.0032, 26.7396],
							[56.0026, 26.7382],
							[56.0046, 26.7371],
							[56.0021, 26.7371],
							[55.9979, 26.7326],
							[55.9912, 26.7321],
							[55.9815, 26.7276],
							[55.9765, 26.7207],
							[55.9785, 26.719],
							[55.976, 26.7168],
							[55.9785, 26.7154],
							[55.9782, 26.7129],
							[55.9604, 26.6982],
							[55.9437, 26.689],
							[55.9382, 26.6874],
							[55.9346, 26.6885],
							[55.9249, 26.6846],
							[55.9207, 26.689],
							[55.9174, 26.6887],
							[55.9154, 26.6907],
							[55.9135, 26.696],
							[55.9129, 26.7071],
							[55.9026, 26.7196],
							[55.8971, 26.724],
							[55.881, 26.7332],
							[55.8743, 26.7337],
							[55.8743, 26.7357],
							[55.8521, 26.7337],
							[55.8446, 26.7307],
							[55.8454, 26.7293],
							[55.8374, 26.729],
							[55.8315, 26.7229],
							[55.8249, 26.7215],
							[55.8149, 26.7121],
							[55.8076, 26.7093],
							[55.8024, 26.7015],
							[55.7976, 26.6982],
							[55.7643, 26.6821],
							[55.7435, 26.679],
							[55.7168, 26.6857],
							[55.716, 26.6832],
							[55.7207, 26.6824],
							[55.7201, 26.681],
							[55.7176, 26.681],
							[55.7151, 26.6821],
							[55.7149, 26.6857],
							[55.7082, 26.6868],
							[55.6985, 26.6874],
							[55.6857, 26.6849],
							[55.6654, 26.6707],
							[55.6604, 26.6646],
							[55.656, 26.6635],
							[55.6471, 26.6549],
							[55.6387, 26.6529],
							[55.6282, 26.6449],
							[55.6237, 26.6443],
							[55.6162, 26.639],
							[55.5968, 26.639],
							[55.5935, 26.6363],
							[55.5899, 26.6365],
							[55.581, 26.6326],
							[55.5801, 26.6301],
							[55.5735, 26.6274],
							[55.5568, 26.6254],
							[55.5443, 26.6221],
							[55.5415, 26.6185],
							[55.5346, 26.6171],
							[55.5282, 26.6135],
							[55.5246, 26.6096],
							[55.5232, 26.6051],
							[55.5201, 26.6032],
							[55.5215, 26.6029],
							[55.521, 26.6018],
							[55.5157, 26.601],
							[55.516, 26.5993],
							[55.5129, 26.5971],
							[55.5013, 26.5954],
							[55.4924, 26.5921],
							[55.491, 26.589],
							[55.4882, 26.5888],
							[55.4782, 26.5926],
							[55.4726, 26.5915],
							[55.4718, 26.5935],
							[55.459, 26.5946],
							[55.4446, 26.5904],
							[55.434, 26.5854],
							[55.4332, 26.5835],
							[55.4154, 26.5757],
							[55.3746, 26.5654],
							[55.3654, 26.5654],
							[55.3599, 26.5607],
							[55.3576, 26.5643],
							[55.354, 26.566],
							[55.3535, 26.5685],
							[55.3496, 26.5687],
							[55.3471, 26.5718],
							[55.3318, 26.5718],
							[55.3179, 26.5532],
							[55.3207, 26.5493],
							[55.3232, 26.549],
							[55.3218, 26.5465],
							[55.3176, 26.5443],
							[55.3046, 26.5493],
							[55.2968, 26.5482],
							[55.2915, 26.5438],
							[55.2915, 26.5415],
							[55.2957, 26.5393],
							[55.2946, 26.5357],
							[55.2807, 26.5407],
							[55.2762, 26.5465],
							[55.2718, 26.5563],
							[55.2713, 26.5621],
							[55.2687, 26.5657],
							[55.2671, 26.5735],
							[55.2687, 26.5824],
							[55.2715, 26.5812],
							[55.2726, 26.5824],
							[55.2715, 26.5854],
							[55.2754, 26.5885],
							[55.2746, 26.6007],
							[55.2757, 26.6079],
							[55.271, 26.6151],
							[55.2682, 26.614],
							[55.2651, 26.6188],
							[55.264, 26.6265],
							[55.2676, 26.644],
							[55.2601, 26.6501],
							[55.261, 26.6518],
							[55.2593, 26.6532],
							[55.2618, 26.6571],
							[55.2687, 26.6596],
							[55.269, 26.6615],
							[55.2696, 26.6593],
							[55.2732, 26.6604],
							[55.2776, 26.6585],
							[55.281, 26.6604],
							[55.2824, 26.6576],
							[55.2854, 26.6579],
							[55.2865, 26.6543],
							[55.2918, 26.6499],
							[55.2985, 26.6501],
							[55.3015, 26.6521],
							[55.3121, 26.6463],
							[55.326, 26.6474],
							[55.3293, 26.6449],
							[55.3326, 26.6457],
							[55.3349, 26.6437],
							[55.3393, 26.6437],
							[55.3651, 26.6504],
							[55.3779, 26.6593],
							[55.3963, 26.6643],
							[55.406, 26.6729],
							[55.4104, 26.6746],
							[55.4182, 26.6732],
							[55.421, 26.6754],
							[55.4279, 26.6726],
							[55.4335, 26.6771],
							[55.4485, 26.6779],
							[55.4521, 26.6813],
							[55.4599, 26.6821],
							[55.4621, 26.6843],
							[55.4679, 26.6857],
							[55.4715, 26.6904],
							[55.4774, 26.6899],
							[55.4854, 26.6963],
							[55.4971, 26.7015],
							[55.5068, 26.7026],
							[55.5224, 26.7015],
							[55.5271, 26.7051],
							[55.5276, 26.709],
							[55.5313, 26.711],
							[55.5337, 26.711],
							[55.5363, 26.7082],
							[55.5485, 26.7093],
							[55.5568, 26.714],
							[55.5663, 26.7154],
							[55.5679, 26.7201],
							[55.5721, 26.7235],
							[55.5824, 26.7257],
							[55.5879, 26.7251],
							[55.5935, 26.729],
							[55.5943, 26.7329],
							[55.5985, 26.7318],
							[55.6085, 26.7351],
							[55.6174, 26.7421],
							[55.6174, 26.744],
							[55.6229, 26.7465],
							[55.6329, 26.759],
							[55.6468, 26.7671],
							[55.6393, 26.7651],
							[55.6368, 26.7624],
							[55.634, 26.7629],
							[55.6346, 26.7651],
							[55.636, 26.7649],
							[55.6396, 26.7687],
							[55.6457, 26.7715],
							[55.6512, 26.7713],
							[55.6701, 26.7763],
							[55.6779, 26.7804],
							[55.6801, 26.784],
							[55.6824, 26.7824],
							[55.6871, 26.7832],
							[55.6874, 26.7846],
							[55.6829, 26.7835],
							[55.6821, 26.7851],
							[55.6954, 26.8032],
							[55.6968, 26.8071],
							[55.7004, 26.8063],
							[55.7099, 26.7885],
							[55.7199, 26.7835],
							[55.7204, 26.7815],
							[55.731, 26.7832],
							[55.7329, 26.7854],
							[55.7346, 26.7846],
							[55.7371, 26.7815],
							[55.736, 26.7776],
							[55.7343, 26.776],
							[55.7285, 26.7754],
							[55.7296, 26.774],
							[55.7346, 26.774],
							[55.7382, 26.7776],
							[55.7385, 26.7804],
							[55.7479, 26.781],
							[55.7443, 26.7851],
							[55.7571, 26.7932],
							[55.7601, 26.7988],
							[55.7618, 26.7988],
							[55.7626, 26.7968],
							[55.7685, 26.7985],
							[55.7749, 26.7932],
							[55.7721, 26.799],
							[55.7665, 26.8018],
							[55.7657, 26.8065],
							[55.7676, 26.8096],
							[55.7663, 26.8221],
							[55.7699, 26.821],
							[55.771, 26.8218],
							[55.7646, 26.8238],
							[55.7637, 26.8299],
							[55.771, 26.8307],
							[55.7751, 26.8338],
							[55.7854, 26.8351],
							[55.779, 26.836],
							[55.7782, 26.8393],
							[55.7788, 26.8424],
							[55.781, 26.8424],
							[55.7807, 26.8463],
							[55.7785, 26.8493],
							[55.7715, 26.8513],
							[55.769, 26.8574],
							[55.7713, 26.8646],
							[55.7701, 26.8674],
							[55.771, 26.8735],
							[55.7732, 26.8785],
							[55.7715, 26.8824],
							[55.7693, 26.8846],
							[55.7668, 26.8832],
							[55.7676, 26.8876],
							[55.7649, 26.8887],
							[55.7626, 26.8924],
							[55.7579, 26.894],
							[55.7571, 26.8963],
							[55.7543, 26.8968],
							[55.7535, 26.8999],
							[55.7499, 26.9024],
							[55.7465, 26.9076],
							[55.7451, 26.9074],
							[55.7438, 26.9115],
							[55.7396, 26.9151],
							[55.7376, 26.9193],
							[55.7332, 26.9207],
							[55.7304, 26.9279],
							[55.7307, 26.9354],
							[55.7321, 26.9365],
							[55.731, 26.9385],
							[55.729, 26.9376],
							[55.7282, 26.939],
							[55.7343, 26.9457],
							[55.7412, 26.946],
							[55.7474, 26.9488],
							[55.7504, 26.9524],
							[55.7649, 26.9507],
							[55.7726, 26.9471],
							[55.7832, 26.9476],
							[55.7926, 26.944],
							[55.7924, 26.9415],
							[55.796, 26.9404],
							[55.8049, 26.931],
							[55.8101, 26.9296],
							[55.8135, 26.9265],
							[55.8238, 26.9221],
							[55.8271, 26.9185],
							[55.8329, 26.9176],
							[55.8387, 26.9124],
							[55.8418, 26.9121],
							[55.8521, 26.9026],
							[55.8704, 26.8976],
							[55.8768, 26.8976],
							[55.8807, 26.9001],
							[55.891, 26.8993],
							[55.8988, 26.9015],
							[55.9004, 26.9057],
							[55.9165, 26.9065],
							[55.9357, 26.9138],
							[55.9404, 26.9174],
							[55.9423, 26.9249],
							[55.9429, 26.9224],
							[55.9451, 26.9218],
							[55.9471, 26.9238],
							[55.9568, 26.9254],
							[55.9571, 26.9285],
							[55.9554, 26.9304],
							[55.9607, 26.9371],
							[55.9663, 26.9401],
							[55.9682, 26.9376],
							[55.9757, 26.9385],
							[55.981, 26.9426],
							[55.9837, 26.9521],
							[55.9863, 26.954],
							[55.9854, 26.9501],
							[55.9865, 26.9496],
							[55.9943, 26.9507],
							[55.9971, 26.9524],
							[55.9971, 26.954],
							[56.0049, 26.9537],
							[56.0101, 26.9518],
							[56.0243, 26.9532],
							[56.0271, 26.9549],
							[56.0288, 26.9601],
							[56.0335, 26.961],
							[56.0385, 26.9588],
							[56.044, 26.9593],
							[56.0526, 26.969],
							[56.0576, 26.9657],
							[56.0624, 26.9651],
							[56.0657, 26.9657],
							[56.0657, 26.9712],
							[56.0668, 26.9687],
							[56.0726, 26.9676],
							[56.0804, 26.9729],
							[56.0871, 26.9743],
							[56.0913, 26.9785],
							[56.0924, 26.9821],
							[56.0949, 26.9835],
							[56.1124, 26.9826],
							[56.1271, 26.984],
							[56.1465, 26.9904],
							[56.1501, 26.994],
							[56.1512, 26.9988],
							[56.1576, 26.9999],
							[56.1585, 26.9985],
						],
					],
					[
						[
							[56.0946, 26.7963],
							[56.0957, 26.7996],
							[56.0985, 26.8001],
							[56.0982, 26.7985],
							[56.0946, 26.7963],
						],
					],
					[
						[
							[56.1129, 26.8135],
							[56.1124, 26.8099],
							[56.1107, 26.8087],
							[56.1107, 26.8135],
							[56.1115, 26.8146],
							[56.1129, 26.8135],
						],
					],
					[
						[
							[55.744, 26.7933],
							[55.7399, 26.784],
							[55.7368, 26.7824],
							[55.7343, 26.7862],
							[55.7379, 26.7932],
							[55.7407, 26.7951],
							[55.7435, 26.7949],
							[55.744, 26.7933],
						],
					],
					[
						[
							[55.7451, 26.796],
							[55.7435, 26.7965],
							[55.7457, 26.7965],
							[55.7501, 26.7988],
							[55.7487, 26.796],
							[55.7451, 26.796],
						],
					],
					[
						[
							[55.7479, 26.8004],
							[55.749, 26.799],
							[55.7468, 26.7985],
							[55.7457, 26.7971],
							[55.7424, 26.7982],
							[55.7449, 26.8012],
							[55.7468, 26.8018],
							[55.7479, 26.8004],
						],
					],
					[
						[
							[55.7554, 26.8029],
							[55.7565, 26.8015],
							[55.7546, 26.7999],
							[55.7507, 26.801],
							[55.7515, 26.8037],
							[55.7554, 26.8029],
						],
					],
					[
						[
							[55.7396, 26.8074],
							[55.7424, 26.806],
							[55.7443, 26.8024],
							[55.7404, 26.7971],
							[55.739, 26.7974],
							[55.7376, 26.8087],
							[55.7396, 26.8074],
						],
					],
					[
						[
							[55.6485, 26.8432],
							[55.6521, 26.8426],
							[55.661, 26.836],
							[55.6838, 26.831],
							[55.6851, 26.8282],
							[55.6846, 26.8249],
							[55.6793, 26.8157],
							[55.6818, 26.8132],
							[55.681, 26.816],
							[55.6868, 26.8288],
							[55.681, 26.839],
							[55.6838, 26.8426],
							[55.6976, 26.844],
							[55.6985, 26.8407],
							[55.7204, 26.839],
							[55.7249, 26.8365],
							[55.7235, 26.8343],
							[55.7263, 26.8349],
							[55.7265, 26.8326],
							[55.7246, 26.8312],
							[55.7243, 26.8263],
							[55.7215, 26.821],
							[55.7115, 26.8157],
							[55.706, 26.8188],
							[55.7015, 26.8263],
							[55.6957, 26.8265],
							[55.6926, 26.8113],
							[55.6871, 26.8026],
							[55.6729, 26.7871],
							[55.6626, 26.781],
							[55.6451, 26.7779],
							[55.6301, 26.7699],
							[55.6265, 26.7701],
							[55.6263, 26.7732],
							[55.629, 26.7735],
							[55.634, 26.7776],
							[55.6379, 26.7832],
							[55.6374, 26.7874],
							[55.6393, 26.7904],
							[55.6418, 26.791],
							[55.6404, 26.8051],
							[55.6432, 26.806],
							[55.6449, 26.8093],
							[55.6449, 26.8135],
							[55.6421, 26.8163],
							[55.6446, 26.8193],
							[55.6376, 26.8168],
							[55.6338, 26.8193],
							[55.6343, 26.8213],
							[55.6371, 26.8193],
							[55.6357, 26.8229],
							[55.6379, 26.8282],
							[55.6371, 26.8365],
							[55.6435, 26.844],
							[55.6485, 26.8432],
						],
					],
					[
						[
							[55.7435, 26.8087],
							[55.7443, 26.8074],
							[55.7443, 26.8065],
							[55.7424, 26.8101],
							[55.7435, 26.8087],
						],
					],
					[
						[
							[55.7293, 26.8296],
							[55.7287, 26.8193],
							[55.7315, 26.8004],
							[55.7301, 26.7915],
							[55.7257, 26.7862],
							[55.7204, 26.7849],
							[55.7143, 26.791],
							[55.7062, 26.8082],
							[55.7001, 26.811],
							[55.6988, 26.8151],
							[55.6974, 26.8154],
							[55.6974, 26.824],
							[55.6988, 26.826],
							[55.7065, 26.8163],
							[55.7096, 26.8143],
							[55.7124, 26.8146],
							[55.7199, 26.8174],
							[55.7254, 26.8235],
							[55.7282, 26.8321],
							[55.7296, 26.8315],
							[55.7293, 26.8296],
						],
					],
					[
						[
							[55.5807, 26.8229],
							[55.5774, 26.824],
							[55.579, 26.8263],
							[55.581, 26.8265],
							[55.5807, 26.8229],
						],
					],
					[
						[
							[55.5846, 26.8396],
							[55.5874, 26.8388],
							[55.5885, 26.8362],
							[55.5829, 26.8385],
							[55.584, 26.8421],
							[55.5854, 26.8415],
							[55.5846, 26.8396],
						],
					],
					[
						[
							[55.6532, 26.8743],
							[55.6587, 26.8679],
							[55.661, 26.859],
							[55.6699, 26.8535],
							[55.674, 26.8485],
							[55.6732, 26.8429],
							[55.6668, 26.8362],
							[55.6549, 26.8432],
							[55.6435, 26.846],
							[55.6437, 26.8532],
							[55.6404, 26.8579],
							[55.6418, 26.8604],
							[55.6368, 26.8613],
							[55.6376, 26.8676],
							[55.6515, 26.876],
							[55.6532, 26.8743],
						],
					],
					[
						[
							[56.0782, 27.0918],
							[56.079, 27.0929],
							[56.0801, 27.0932],
							[56.0788, 27.0915],
							[56.0765, 27.091],
							[56.0782, 27.0918],
						],
					],
					[
						[
							[56.054, 27.0946],
							[56.0554, 27.0943],
							[56.0579, 27.0954],
							[56.054, 27.0935],
							[56.0529, 27.096],
							[56.054, 27.0946],
						],
					],
					[
						[
							[56.0807, 27.0938],
							[56.0801, 27.0946],
							[56.081, 27.0965],
							[56.0812, 27.0949],
							[56.0807, 27.0938],
						],
					],
					[
						[
							[56.059, 27.0954],
							[56.0588, 27.0965],
							[56.0612, 27.0965],
							[56.0612, 27.0963],
							[56.059, 27.0954],
						],
					],
					[
						[
							[56.0526, 27.0968],
							[56.0518, 27.0971],
							[56.0515, 27.0985],
							[56.0526, 27.0982],
							[56.0526, 27.0968],
						],
					],
					[
						[
							[56.0807, 27.0968],
							[56.0801, 27.0979],
							[56.0768, 27.0968],
							[56.076, 27.0971],
							[56.0799, 27.0996],
							[56.0807, 27.0968],
						],
					],
					[
						[
							[56.1115, 27.1271],
							[56.1154, 27.1218],
							[56.1185, 27.121],
							[56.1113, 27.1204],
							[56.1149, 27.1215],
							[56.1104, 27.1282],
							[56.1115, 27.1271],
						],
					],
					[
						[
							[56.1093, 27.129],
							[56.1074, 27.1318],
							[56.1076, 27.1324],
							[56.1104, 27.1282],
							[56.1093, 27.129],
						],
					],
					[
						[
							[56.6735, 27.1479],
							[56.676, 27.1474],
							[56.6732, 27.1465],
							[56.671, 27.1488],
							[56.6735, 27.1479],
						],
					],
					[
						[
							[56.6688, 27.1546],
							[56.6671, 27.1532],
							[56.6657, 27.1557],
							[56.6676, 27.1562],
							[56.6688, 27.1546],
						],
					],
					[
						[
							[56.589, 27.1626],
							[56.591, 27.1632],
							[56.594, 27.161],
							[56.5968, 27.1576],
							[56.5965, 27.1557],
							[56.5904, 27.1582],
							[56.5876, 27.1643],
							[56.589, 27.1626],
						],
					],
					[
						[
							[56.2096, 27.1329],
							[56.209, 27.1304],
							[56.2079, 27.1301],
							[56.209, 27.1324],
							[56.2074, 27.1329],
							[56.2099, 27.1354],
							[56.2096, 27.1329],
						],
					],
					[
						[
							[56.5246, 27.1712],
							[56.5235, 27.1721],
							[56.5268, 27.1724],
							[56.5268, 27.1707],
							[56.5246, 27.1712],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Ilam", name: "ایلام" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[47.8477, 32.466],
							[47.8071, 32.4279],
							[47.798, 32.4089],
							[47.7475, 32.3529],
							[47.7267, 32.3346],
							[47.7286, 32.3157],
							[47.7173, 32.3161],
							[47.7182, 32.3066],
							[47.7314, 32.2873],
							[47.7456, 32.2586],
							[47.7465, 32.2491],
							[47.7598, 32.2299],
							[47.7616, 32.211],
							[47.7511, 32.202],
							[47.752, 32.1926],
							[47.731, 32.1747],
							[47.6657, 32.1492],
							[47.6344, 32.1314],
							[47.625, 32.1222],
							[47.6071, 32.1126],
							[47.6009, 32.1157],
							[47.596, 32.1162],
							[47.5716, 32.1139],
							[47.5634, 32.116],
							[47.5555, 32.1296],
							[47.5399, 32.1371],
							[47.5358, 32.1424],
							[47.5343, 32.1605],
							[47.5358, 32.1745],
							[47.5459, 32.1842],
							[47.5547, 32.1901],
							[47.5627, 32.1996],
							[47.5679, 32.2143],
							[47.5673, 32.2239],
							[47.557, 32.2319],
							[47.5382, 32.2384],
							[47.5284, 32.2482],
							[47.5218, 32.2575],
							[47.4998, 32.2727],
							[47.4961, 32.2822],
							[47.4985, 32.2917],
							[47.5027, 32.2979],
							[47.5022, 32.3008],
							[47.4889, 32.3128],
							[47.4747, 32.3192],
							[47.4641, 32.3208],
							[47.4591, 32.3189],
							[47.4514, 32.3201],
							[47.4453, 32.3235],
							[47.443, 32.3284],
							[47.4441, 32.3331],
							[47.4421, 32.3421],
							[47.438, 32.3461],
							[47.4291, 32.3505],
							[47.4262, 32.3528],
							[47.4258, 32.3556],
							[47.4443, 32.3578],
							[47.4494, 32.362],
							[47.4561, 32.3742],
							[47.4532, 32.3936],
							[47.4489, 32.4012],
							[47.4404, 32.4102],
							[47.4314, 32.4157],
							[47.4172, 32.4297],
							[47.4102, 32.4408],
							[47.3934, 32.4601],
							[47.3851, 32.4671],
							[47.3747, 32.4684],
							[47.3515, 32.4672],
							[47.3367, 32.4706],
							[47.3207, 32.4788],
							[47.3038, 32.4908],
							[47.2983, 32.4926],
							[47.2908, 32.4915],
							[47.2813, 32.4837],
							[47.2613, 32.4717],
							[47.2337, 32.4636],
							[47.2214, 32.458],
							[47.2142, 32.456],
							[47.2034, 32.4564],
							[47.1814, 32.4608],
							[47.1809, 32.4568],
							[47.1772, 32.4516],
							[47.1592, 32.4541],
							[47.104, 32.4788],
							[47.0679, 32.5104],
							[47.0558, 32.5231],
							[47.0439, 32.5305],
							[46.9954, 32.5718],
							[46.952, 32.597],
							[46.9405, 32.6099],
							[46.9203, 32.6251],
							[46.9081, 32.6295],
							[46.8907, 32.6401],
							[46.8767, 32.6572],
							[46.8322, 32.6929],
							[46.8236, 32.7023],
							[46.8109, 32.7083],
							[46.7979, 32.7113],
							[46.7875, 32.7157],
							[46.7774, 32.7274],
							[46.749, 32.7536],
							[46.7304, 32.7656],
							[46.7061, 32.7746],
							[46.6967, 32.784],
							[46.6857, 32.7919],
							[46.6652, 32.7999],
							[46.6595, 32.8042],
							[46.6467, 32.8207],
							[46.5756, 32.8534],
							[46.5629, 32.8621],
							[46.5453, 32.868],
							[46.5339, 32.8745],
							[46.5174, 32.8933],
							[46.5086, 32.899],
							[46.497, 32.9034],
							[46.4755, 32.9075],
							[46.4535, 32.9227],
							[46.4086, 32.9431],
							[46.3966, 32.9466],
							[46.3633, 32.9508],
							[46.3304, 32.9526],
							[46.2738, 32.952],
							[46.2565, 32.9503],
							[46.2506, 32.9484],
							[46.2378, 32.9483],
							[46.2142, 32.9506],
							[46.1896, 32.9551],
							[46.1752, 32.9549],
							[46.1614, 32.9527],
							[46.1528, 32.9554],
							[46.144, 32.9558],
							[46.102, 32.9696],
							[46.0954, 32.9791],
							[46.1, 33.0049],
							[46.1019, 33.0087],
							[46.1072, 33.0138],
							[46.1155, 33.0157],
							[46.1229, 33.0152],
							[46.1362, 33.0217],
							[46.1593, 33.0456],
							[46.1682, 33.0574],
							[46.1684, 33.0608],
							[46.1619, 33.0637],
							[46.1215, 33.07],
							[46.1108, 33.0735],
							[46.1037, 33.0806],
							[46.0986, 33.0824],
							[46.0821, 33.0837],
							[46.0674, 33.0869],
							[46.0491, 33.0936],
							[46.0469, 33.0963],
							[46.0466, 33.1004],
							[46.0497, 33.121],
							[46.0516, 33.1241],
							[46.0567, 33.1253],
							[46.0715, 33.1189],
							[46.0911, 33.1134],
							[46.1157, 33.1091],
							[46.1295, 33.1109],
							[46.1422, 33.119],
							[46.161, 33.1385],
							[46.1723, 33.1476],
							[46.1821, 33.1604],
							[46.1998, 33.1741],
							[46.2048, 33.1801],
							[46.2064, 33.1841],
							[46.2055, 33.1877],
							[46.2032, 33.1897],
							[46.1858, 33.1914],
							[46.1844, 33.1947],
							[46.1894, 33.2235],
							[46.1962, 33.2398],
							[46.1973, 33.2534],
							[46.187, 33.262],
							[46.1564, 33.2744],
							[46.1454, 33.2811],
							[46.1346, 33.2921],
							[46.1169, 33.3175],
							[46.0852, 33.3519],
							[46.0628, 33.3639],
							[46.0507, 33.3739],
							[46.0456, 33.3823],
							[46.0423, 33.392],
							[46.0391, 33.4162],
							[46.0365, 33.4241],
							[46.0389, 33.4474],
							[46.0355, 33.4647],
							[46.0333, 33.4677],
							[46.024, 33.4738],
							[46.0064, 33.4799],
							[45.9947, 33.5012],
							[45.9902, 33.5024],
							[45.9843, 33.5014],
							[45.9628, 33.4887],
							[45.9566, 33.4862],
							[45.9419, 33.4842],
							[45.9203, 33.4838],
							[45.8787, 33.4869],
							[45.874, 33.4879],
							[45.8713, 33.4909],
							[45.8724, 33.4948],
							[45.8788, 33.4996],
							[45.9379, 33.5318],
							[45.9502, 33.5467],
							[45.9516, 33.5523],
							[45.95, 33.5648],
							[45.9385, 33.5818],
							[45.9285, 33.5905],
							[45.9063, 33.601],
							[45.9102, 33.623],
							[45.907, 33.6261],
							[45.9008, 33.6273],
							[45.8794, 33.6242],
							[45.8629, 33.6238],
							[45.8434, 33.6256],
							[45.8169, 33.6171],
							[45.7822, 33.5965],
							[45.7659, 33.59],
							[45.7594, 33.591],
							[45.7552, 33.5935],
							[45.7543, 33.6022],
							[45.7748, 33.6137],
							[45.7772, 33.6162],
							[45.7772, 33.6229],
							[45.7668, 33.6355],
							[45.7473, 33.6489],
							[45.7416, 33.6922],
							[45.7496, 33.7127],
							[45.7471, 33.7325],
							[45.7573, 33.7434],
							[45.7562, 33.7533],
							[45.7655, 33.7744],
							[45.7725, 33.8262],
							[45.7824, 33.847],
							[45.8022, 33.8772],
							[45.8013, 33.8868],
							[45.8213, 33.916],
							[45.831, 33.9352],
							[45.8302, 33.9447],
							[45.818, 33.9541],
							[45.8171, 33.9636],
							[45.7805, 33.9918],
							[45.7796, 34.0013],
							[45.7682, 34.0012],
							[45.7893, 34.0204],
							[45.8007, 34.0205],
							[45.8357, 34.0114],
							[45.9593, 34.0149],
							[46.0022, 34.0357],
							[46.0125, 34.0456],
							[46.0238, 34.0461],
							[46.036, 34.0372],
							[46.0474, 34.0377],
							[46.0721, 34.0198],
							[46.1199, 34.0029],
							[46.1702, 33.9669],
							[46.205, 33.9684],
							[46.2505, 33.9799],
							[46.2972, 33.9818],
							[46.3099, 33.9727],
							[46.3217, 33.9732],
							[46.3345, 33.9641],
							[46.3591, 33.9555],
							[46.3719, 33.9465],
							[46.3966, 33.9378],
							[46.4611, 33.8924],
							[46.4751, 33.8738],
							[46.5, 33.8652],
							[46.526, 33.847],
							[46.6012, 33.8206],
							[46.6612, 33.8133],
							[46.7095, 33.7963],
							[46.7789, 33.7902],
							[46.8921, 33.7949],
							[46.9128, 33.8147],
							[46.9457, 33.8251],
							[46.957, 33.8254],
							[47.0011, 33.836],
							[47.0226, 33.8461],
							[47.0789, 33.8472],
							[47.0911, 33.8378],
							[47.138, 33.8195],
							[47.139, 33.8099],
							[47.1839, 33.8106],
							[47.2044, 33.8301],
							[47.2005, 33.8684],
							[47.2088, 33.8973],
							[47.2191, 33.907],
							[47.2153, 33.9453],
							[47.1917, 33.9544],
							[47.1785, 33.9733],
							[47.154, 33.992],
							[47.153, 34.0016],
							[47.1633, 34.0114],
							[47.1746, 34.0116],
							[47.1851, 34.0214],
							[47.2208, 34.0128],
							[47.2199, 34.0224],
							[47.2672, 34.0238],
							[47.3254, 34.0453],
							[47.3735, 34.0471],
							[47.3742, 34.0374],
							[47.3982, 34.0383],
							[47.3989, 34.0286],
							[47.4123, 34.0097],
							[47.4264, 33.9812],
							[47.4284, 33.9522],
							[47.4411, 33.9429],
							[47.4418, 33.9333],
							[47.4305, 33.9231],
							[47.4318, 33.9038],
							[47.4212, 33.884],
							[47.4099, 33.8738],
							[47.4106, 33.8641],
							[47.3987, 33.8637],
							[47.3994, 33.854],
							[47.3644, 33.8432],
							[47.3415, 33.8329],
							[47.3423, 33.8232],
							[47.3313, 33.8133],
							[47.3197, 33.813],
							[47.3097, 33.7934],
							[47.2992, 33.7836],
							[47.2776, 33.7736],
							[47.2673, 33.7639],
							[47.2224, 33.7632],
							[47.199, 33.7725],
							[47.1541, 33.7718],
							[47.1326, 33.7619],
							[47.1214, 33.7617],
							[47.1111, 33.752],
							[47.0876, 33.7612],
							[47.0774, 33.7514],
							[47.0661, 33.7512],
							[47.0445, 33.7412],
							[47.0342, 33.7314],
							[46.9779, 33.7302],
							[46.9367, 33.6907],
							[46.9376, 33.6812],
							[46.9038, 33.6801],
							[46.8935, 33.6702],
							[46.8944, 33.6606],
							[46.8861, 33.6314],
							[46.888, 33.6122],
							[46.8675, 33.5919],
							[46.8452, 33.5906],
							[46.8351, 33.5803],
							[46.8361, 33.5706],
							[46.8624, 33.533],
							[46.8866, 33.5148],
							[46.926, 33.4581],
							[46.9404, 33.4293],
							[46.9515, 33.4298],
							[46.9637, 33.4206],
							[46.9648, 33.4108],
							[46.977, 33.4015],
							[46.9904, 33.3824],
							[47.0026, 33.373],
							[47.0038, 33.3632],
							[47.0148, 33.3636],
							[47.0728, 33.3457],
							[47.1343, 33.2981],
							[47.1455, 33.2982],
							[47.169, 33.2888],
							[47.2618, 33.2606],
							[47.2842, 33.2607],
							[47.4073, 33.1644],
							[47.4789, 33.1259],
							[47.4801, 33.1161],
							[47.5539, 33.0581],
							[47.5774, 33.0485],
							[47.5897, 33.0388],
							[47.66, 33.0098],
							[47.6858, 32.9807],
							[47.687, 32.971],
							[47.6993, 32.9613],
							[47.7128, 32.9418],
							[47.7298, 32.8932],
							[47.731, 32.8835],
							[47.758, 32.8446],
							[47.7592, 32.8348],
							[47.7838, 32.8154],
							[47.8108, 32.7765],
							[47.8143, 32.7473],
							[47.8437, 32.689],
							[47.8595, 32.6501],
							[47.8742, 32.621],
							[47.8853, 32.621],
							[47.8988, 32.6015],
							[47.8888, 32.5918],
							[47.89, 32.5821],
							[47.8712, 32.553],
							[47.8623, 32.5336],
							[47.8657, 32.5046],
							[47.8567, 32.4853],
							[47.8466, 32.4757],
							[47.8477, 32.466],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Kerman", name: "کرمان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[58.838, 26.0207],
							[58.8302, 26.0209],
							[58.8306, 26.069],
							[58.8197, 26.0789],
							[58.8086, 26.0793],
							[58.7975, 26.07],
							[58.7974, 26.0604],
							[58.7754, 26.0611],
							[58.7316, 26.1009],
							[58.7206, 26.1012],
							[58.7095, 26.092],
							[58.7093, 26.0727],
							[58.6982, 26.0634],
							[58.6982, 26.0538],
							[58.687, 26.0445],
							[58.654, 26.0455],
							[58.643, 26.0555],
							[58.6039, 26.0567],
							[58.5989, 26.0568],
							[58.5878, 26.0475],
							[58.5437, 26.0489],
							[58.5438, 26.0585],
							[58.5328, 26.0685],
							[58.5331, 26.0973],
							[58.5332, 26.1166],
							[58.5443, 26.1259],
							[58.5333, 26.1358],
							[58.5113, 26.1365],
							[58.5115, 26.1653],
							[58.5337, 26.1839],
							[58.5337, 26.1935],
							[58.5227, 26.1939],
							[58.5227, 26.2035],
							[58.4343, 26.2061],
							[58.4233, 26.2161],
							[58.4122, 26.2164],
							[58.39, 26.2363],
							[58.3789, 26.2366],
							[58.3679, 26.2273],
							[58.3456, 26.2279],
							[58.3125, 26.2001],
							[58.3014, 26.2005],
							[58.2791, 26.2107],
							[58.2569, 26.2113],
							[58.2457, 26.2212],
							[58.2011, 26.232],
							[58.1344, 26.2339],
							[58.1234, 26.2246],
							[58.1013, 26.2156],
							[58.0901, 26.2159],
							[58.0791, 26.2067],
							[58.068, 26.207],
							[58.0679, 26.2166],
							[58.0568, 26.2169],
							[58.0567, 26.2361],
							[58.0677, 26.2453],
							[58.0788, 26.245],
							[58.0787, 26.2546],
							[58.0898, 26.2543],
							[58.1008, 26.2731],
							[58.1007, 26.2827],
							[58.0895, 26.2926],
							[58.0894, 26.3118],
							[58.1115, 26.3304],
							[58.1115, 26.34],
							[58.0892, 26.3598],
							[58.0891, 26.379],
							[58.1001, 26.3883],
							[58.0999, 26.4748],
							[58.111, 26.4937],
							[58.111, 26.5033],
							[58.1221, 26.5126],
							[58.1221, 26.5319],
							[58.1111, 26.5707],
							[58.1111, 26.5804],
							[58.1444, 26.6083],
							[58.1444, 26.618],
							[58.1555, 26.6273],
							[58.1557, 26.6756],
							[58.1447, 26.6856],
							[58.1337, 26.7149],
							[58.1227, 26.7249],
							[58.1228, 26.7442],
							[58.1339, 26.7439],
							[58.134, 26.7536],
							[58.1561, 26.7529],
							[58.1671, 26.7429],
							[58.1782, 26.7426],
							[58.1892, 26.7229],
							[58.2113, 26.703],
							[58.2224, 26.7026],
							[58.2335, 26.712],
							[58.2225, 26.7317],
							[58.2117, 26.7707],
							[58.2118, 26.7902],
							[58.1788, 26.8202],
							[58.1677, 26.8205],
							[58.1347, 26.8506],
							[58.1016, 26.871],
							[58.0577, 26.9112],
							[58.0356, 26.9215],
							[58.0137, 26.9417],
							[57.9915, 26.9423],
							[57.9805, 26.9523],
							[57.9695, 26.9527],
							[57.9583, 26.9432],
							[57.9361, 26.9439],
							[57.9141, 26.9543],
							[57.903, 26.9546],
							[57.8918, 26.9355],
							[57.8916, 26.916],
							[57.8804, 26.897],
							[57.8582, 26.8879],
							[57.8359, 26.8692],
							[57.8358, 26.8595],
							[57.8247, 26.8502],
							[57.8136, 26.8506],
							[57.8025, 26.8412],
							[57.7915, 26.8416],
							[57.7915, 26.8513],
							[57.7805, 26.8516],
							[57.6928, 26.9321],
							[57.6376, 26.9339],
							[57.6267, 26.944],
							[57.6047, 26.9545],
							[57.5937, 26.9645],
							[57.5718, 26.975],
							[57.5499, 26.9952],
							[57.4838, 26.9976],
							[57.4728, 27.0077],
							[57.4398, 27.0089],
							[57.407, 27.0393],
							[57.4071, 27.049],
							[57.3962, 27.0591],
							[57.3853, 27.0789],
							[57.3858, 27.1371],
							[57.3751, 27.1667],
							[57.3765, 27.3032],
							[57.3658, 27.3329],
							[57.3681, 27.4604],
							[57.3572, 27.4706],
							[57.3364, 27.5305],
							[57.3267, 27.5804],
							[57.327, 27.5903],
							[57.3158, 27.5906],
							[57.2831, 27.6213],
							[57.2494, 27.6221],
							[57.2276, 27.6426],
							[57.228, 27.6525],
							[57.2395, 27.6622],
							[57.2399, 27.6722],
							[57.2512, 27.6719],
							[57.2741, 27.6814],
							[57.2854, 27.6811],
							[57.297, 27.6908],
							[57.2978, 27.7108],
							[57.3095, 27.7205],
							[57.299, 27.7407],
							[57.2994, 27.7507],
							[57.289, 27.7708],
							[57.2672, 27.7912],
							[57.245, 27.8017],
							[57.2123, 27.8322],
							[57.2017, 27.8523],
							[57.1904, 27.8526],
							[57.1803, 27.8826],
							[57.1593, 27.9227],
							[57.1484, 27.9329],
							[57.1378, 27.953],
							[57.1265, 27.9532],
							[57.1043, 27.9637],
							[57.0477, 27.9651],
							[57.0368, 27.9753],
							[57.0255, 27.9755],
							[57.0259, 27.9854],
							[57.0149, 27.9956],
							[57.0153, 28.0055],
							[57.0059, 28.0552],
							[56.9946, 28.0554],
							[56.9965, 28.1048],
							[56.9753, 28.1449],
							[56.9776, 28.2042],
							[56.99, 28.2335],
							[56.9915, 28.2731],
							[56.9692, 28.2835],
							[56.9696, 28.2934],
							[56.9582, 28.2936],
							[56.9245, 28.3043],
							[56.9132, 28.3046],
							[56.9022, 28.3147],
							[56.8799, 28.3252],
							[56.8572, 28.3257],
							[56.8462, 28.3358],
							[56.8345, 28.3262],
							[56.8231, 28.3265],
							[56.7771, 28.3078],
							[56.7767, 28.2979],
							[56.765, 28.2883],
							[56.7527, 28.259],
							[56.741, 28.2494],
							[56.7296, 28.2497],
							[56.7176, 28.2302],
							[56.6823, 28.1916],
							[56.6593, 28.1823],
							[56.636, 28.1631],
							[56.5901, 28.1444],
							[56.5785, 28.1347],
							[56.5443, 28.1256],
							[56.4985, 28.1067],
							[56.4196, 28.1077],
							[56.386, 28.118],
							[56.3522, 28.1183],
							[56.3412, 28.1284],
							[56.3187, 28.1286],
							[56.2967, 28.1487],
							[56.2854, 28.1488],
							[56.2409, 28.1691],
							[56.2411, 28.179],
							[56.2078, 28.1992],
							[56.1527, 28.2494],
							[56.1194, 28.2696],
							[56.0973, 28.2897],
							[56.0534, 28.3397],
							[56.0313, 28.3598],
							[56.0316, 28.3697],
							[56.0208, 28.3897],
							[56.0218, 28.4293],
							[56.0574, 28.4883],
							[56.0704, 28.5474],
							[56.072, 28.6067],
							[56.0629, 28.6859],
							[56.0675, 28.854],
							[56.0566, 28.8739],
							[56.0461, 28.9037],
							[56.0466, 28.9235],
							[56.0355, 28.9335],
							[56.0242, 28.9336],
							[56.0131, 28.9436],
							[56.0017, 28.9438],
							[55.9788, 28.9341],
							[55.9672, 28.9243],
							[55.9558, 28.9244],
							[55.91, 28.9051],
							[55.8528, 28.8859],
							[55.8412, 28.8761],
							[55.7951, 28.8469],
							[55.7026, 28.7686],
							[55.6569, 28.7492],
							[55.6453, 28.7395],
							[55.6001, 28.7398],
							[55.5777, 28.7499],
							[55.5664, 28.75],
							[55.5666, 28.7599],
							[55.511, 28.7999],
							[55.5003, 28.8296],
							[55.4669, 28.8496],
							[55.4558, 28.8596],
							[55.4449, 28.8795],
							[55.3777, 28.9096],
							[55.3663, 28.9097],
							[55.3554, 28.9295],
							[55.3328, 28.9297],
							[55.3217, 28.9396],
							[55.2086, 28.9404],
							[55.1975, 28.9504],
							[55.1297, 28.9508],
							[55.1075, 28.9707],
							[55.1077, 28.9806],
							[55.0855, 29.0005],
							[55.0856, 29.0103],
							[55.0749, 29.04],
							[55.064, 29.0599],
							[55.0532, 29.0895],
							[55.0423, 29.1094],
							[55.0315, 29.139],
							[55.0209, 29.1786],
							[54.9992, 29.2281],
							[54.9671, 29.3368],
							[54.9452, 29.3764],
							[54.9453, 29.3862],
							[54.9342, 29.3962],
							[54.9228, 29.3962],
							[54.9117, 29.4062],
							[54.889, 29.4063],
							[54.8665, 29.4163],
							[54.6628, 29.4173],
							[54.629, 29.4274],
							[54.6178, 29.4373],
							[54.5953, 29.4472],
							[54.5843, 29.467],
							[54.573, 29.4671],
							[54.5618, 29.477],
							[54.5401, 29.5363],
							[54.5403, 29.556],
							[54.4959, 29.6154],
							[54.4848, 29.6352],
							[54.485, 29.645],
							[54.4738, 29.6549],
							[54.4739, 29.6648],
							[54.4516, 29.6945],
							[54.4518, 29.7044],
							[54.4407, 29.7242],
							[54.4295, 29.7341],
							[54.4302, 29.7933],
							[54.4416, 29.7932],
							[54.4758, 29.8128],
							[54.4872, 29.8128],
							[54.4873, 29.8226],
							[54.6007, 29.8221],
							[54.6237, 29.8418],
							[54.6238, 29.8516],
							[54.6127, 29.8714],
							[54.613, 29.8912],
							[54.6017, 29.9011],
							[54.5906, 29.9209],
							[54.5929, 30.1384],
							[54.6161, 30.1877],
							[54.6163, 30.2075],
							[54.628, 30.247],
							[54.6283, 30.2767],
							[54.617, 30.2867],
							[54.617, 30.2966],
							[54.5489, 30.3266],
							[54.5376, 30.3365],
							[54.5377, 30.3464],
							[54.5264, 30.3663],
							[54.5268, 30.4158],
							[54.5158, 30.4753],
							[54.5159, 30.4951],
							[54.5046, 30.5149],
							[54.5047, 30.5249],
							[54.4821, 30.5646],
							[54.4593, 30.5845],
							[54.4139, 30.6342],
							[54.4026, 30.6541],
							[54.3912, 30.6641],
							[54.3913, 30.7038],
							[54.4029, 30.7236],
							[54.403, 30.7633],
							[54.4145, 30.7732],
							[54.4146, 30.793],
							[54.4261, 30.8029],
							[54.4261, 30.8128],
							[54.4377, 30.8327],
							[54.4491, 30.8326],
							[54.4607, 30.8723],
							[54.5413, 30.9417],
							[54.5414, 30.9516],
							[54.576, 30.9813],
							[54.599, 30.9912],
							[54.645, 30.9911],
							[54.6794, 30.981],
							[54.7484, 30.9808],
							[54.783, 30.9907],
							[54.8175, 30.9906],
							[54.8866, 31.0003],
							[54.9097, 31.0102],
							[55.0021, 31.0298],
							[55.0368, 31.0396],
							[55.0483, 31.0396],
							[55.1061, 31.0493],
							[55.1869, 31.049],
							[55.233, 31.0389],
							[55.3137, 31.0286],
							[55.3483, 31.0185],
							[55.4524, 31.018],
							[55.5685, 31.0375],
							[55.6269, 31.0571],
							[55.6386, 31.057],
							[55.6504, 31.067],
							[55.6739, 31.0768],
							[55.6741, 31.0868],
							[55.6866, 31.1267],
							[55.6988, 31.1467],
							[55.7015, 31.2669],
							[55.7139, 31.2969],
							[55.7155, 31.3671],
							[55.718, 31.4877],
							[55.7069, 31.518],
							[55.7073, 31.5381],
							[55.7194, 31.5582],
							[55.7196, 31.5683],
							[55.7555, 31.5983],
							[55.8267, 31.6282],
							[55.874, 31.638],
							[55.9806, 31.6677],
							[56.0044, 31.6777],
							[56.0402, 31.6977],
							[56.0758, 31.7076],
							[56.1234, 31.7275],
							[56.1592, 31.7475],
							[56.2189, 31.7775],
							[56.2666, 31.7975],
							[56.3149, 31.8377],
							[56.3272, 31.8579],
							[56.3511, 31.8679],
							[56.4115, 31.9183],
							[56.4833, 31.9484],
							[56.4954, 31.9585],
							[56.5072, 31.9584],
							[56.5548, 31.9683],
							[56.5791, 31.9885],
							[56.5909, 31.9885],
							[56.6152, 32.0087],
							[56.6155, 32.0189],
							[56.6276, 32.029],
							[56.6163, 32.0493],
							[56.6859, 32.047],
							[56.7433, 32.0346],
							[56.7662, 32.0335],
							[56.7892, 32.0227],
							[56.8121, 32.0216],
							[56.8237, 32.0114],
							[56.8581, 32.0001],
							[56.8927, 31.9791],
							[56.9272, 31.9678],
							[56.9501, 31.9667],
							[57.0536, 31.9329],
							[57.1226, 31.9007],
							[57.1686, 31.8889],
							[57.2261, 31.8669],
							[57.272, 31.8552],
							[57.364, 31.8123],
							[57.3871, 31.792],
							[57.4215, 31.7904],
							[57.4675, 31.7691],
							[57.5134, 31.7574],
							[57.6052, 31.7147],
							[57.6397, 31.7036],
							[57.697, 31.6818],
							[57.7199, 31.6712],
							[57.7658, 31.6596],
							[57.7773, 31.6591],
							[57.8232, 31.6474],
							[57.8347, 31.6373],
							[57.8691, 31.6358],
							[57.9151, 31.6146],
							[57.9266, 31.6141],
							[57.9496, 31.6035],
							[57.984, 31.5924],
							[58.007, 31.5818],
							[58.053, 31.5511],
							[58.0645, 31.5506],
							[58.1795, 31.4977],
							[58.2486, 31.4757],
							[58.2716, 31.4651],
							[58.3177, 31.4537],
							[58.4791, 31.3802],
							[58.5599, 31.3482],
							[58.6292, 31.3263],
							[58.687, 31.3049],
							[58.7912, 31.272],
							[58.8028, 31.2619],
							[58.826, 31.2514],
							[58.8607, 31.2404],
							[58.9535, 31.1983],
							[58.9651, 31.1978],
							[59.0697, 31.1647],
							[59.1161, 31.1243],
							[59.1852, 31.0348],
							[59.2197, 30.9948],
							[59.2539, 30.9356],
							[59.2537, 30.926],
							[59.3113, 30.8754],
							[59.3111, 30.8658],
							[59.3339, 30.836],
							[59.3451, 30.8163],
							[59.3677, 30.7768],
							[59.3787, 30.7475],
							[59.3901, 30.7373],
							[59.4242, 30.6974],
							[59.47, 30.6568],
							[59.4925, 30.6269],
							[59.5154, 30.6067],
							[59.5034, 30.5976],
							[59.4774, 30.5122],
							[59.4768, 30.493],
							[59.4639, 30.4552],
							[59.4625, 30.4165],
							[59.4496, 30.3879],
							[59.4488, 30.3684],
							[59.4344, 30.3101],
							[59.4218, 30.2909],
							[59.4203, 30.2614],
							[59.4066, 30.2223],
							[59.3998, 30.0938],
							[59.3848, 30.0346],
							[59.3557, 29.9358],
							[59.3551, 29.9259],
							[59.3411, 29.8864],
							[59.3277, 29.8569],
							[59.3272, 29.847],
							[59.3144, 29.8274],
							[59.3138, 29.8175],
							[59.2866, 29.7485],
							[59.2849, 29.7188],
							[59.296, 29.7086],
							[59.2955, 29.6986],
							[59.3177, 29.6782],
							[59.3859, 29.6467],
							[59.4081, 29.6263],
							[59.4075, 29.6164],
							[59.4185, 29.6062],
							[59.4144, 29.5366],
							[59.4224, 29.4767],
							[59.4413, 29.4066],
							[59.4387, 29.3668],
							[59.4581, 29.3065],
							[59.4536, 29.2369],
							[59.4407, 29.2172],
							[59.44, 29.2073],
							[59.4271, 29.1876],
							[59.4019, 29.1583],
							[59.389, 29.1386],
							[59.3884, 29.1287],
							[59.3638, 29.1093],
							[59.2993, 29.0109],
							[59.2986, 29.001],
							[59.3192, 28.9606],
							[59.3288, 28.9305],
							[59.3281, 28.9205],
							[59.339, 28.9103],
							[59.3377, 28.8904],
							[59.3255, 28.8807],
							[59.3228, 28.8409],
							[59.3337, 28.8307],
							[59.333, 28.8207],
							[59.3548, 28.8003],
							[59.3535, 28.7804],
							[59.386, 28.7497],
							[59.3847, 28.7298],
							[59.3725, 28.7201],
							[59.3718, 28.7102],
							[59.2864, 28.6423],
							[59.2749, 28.6426],
							[59.2139, 28.5941],
							[59.1664, 28.5752],
							[59.1542, 28.5655],
							[59.1189, 28.5563],
							[59.1067, 28.5466],
							[59.0715, 28.5374],
							[59.0477, 28.5279],
							[59.0009, 28.519],
							[58.9765, 28.4995],
							[58.9419, 28.5003],
							[58.9182, 28.4908],
							[58.8951, 28.4913],
							[58.8829, 28.4816],
							[58.8823, 28.4716],
							[58.8701, 28.4619],
							[58.8682, 28.4321],
							[58.8791, 28.4219],
							[58.924, 28.401],
							[58.9355, 28.4008],
							[58.9695, 28.3901],
							[58.981, 28.3898],
							[59.0034, 28.3794],
							[59.0252, 28.359],
							[59.0456, 28.3187],
							[59.0449, 28.3088],
							[59.0558, 28.2986],
							[59.0417, 28.2591],
							[59.0403, 28.2392],
							[59.0269, 28.2096],
							[59.0025, 28.1903],
							[58.9897, 28.1706],
							[58.9539, 28.1515],
							[58.9417, 28.1418],
							[58.9059, 28.1227],
							[58.8937, 28.113],
							[58.8809, 28.0934],
							[58.8917, 28.0832],
							[58.8871, 28.0137],
							[58.8966, 27.9836],
							[58.8946, 27.9539],
							[58.9047, 27.9338],
							[58.9006, 27.8742],
							[58.9101, 27.8442],
							[58.8961, 27.636],
							[58.8827, 27.6065],
							[58.8815, 27.5867],
							[58.8688, 27.5672],
							[58.8676, 27.5474],
							[58.8423, 27.5083],
							[58.8285, 27.469],
							[58.8268, 27.4393],
							[58.8142, 27.4197],
							[58.8006, 27.3804],
							[58.7858, 27.3213],
							[58.7819, 27.2521],
							[58.7683, 27.2127],
							[58.7651, 27.1534],
							[58.7765, 27.1532],
							[58.7754, 27.1334],
							[58.7852, 27.1035],
							[58.7841, 27.0837],
							[58.7945, 27.0637],
							[58.8053, 27.0536],
							[58.7975, 26.8951],
							[58.8084, 26.885],
							[58.808, 26.875],
							[58.8302, 26.8647],
							[58.8411, 26.8546],
							[58.8743, 26.8342],
							[58.9188, 26.8136],
							[58.9293, 26.7936],
							[58.9403, 26.7835],
							[58.9398, 26.7736],
							[58.9508, 26.7634],
							[58.9467, 26.6353],
							[58.9125, 26.6068],
							[58.9013, 26.6071],
							[58.9012, 26.5973],
							[58.8899, 26.5879],
							[58.8898, 26.5782],
							[58.9343, 26.5382],
							[58.9566, 26.5279],
							[58.9678, 26.518],
							[58.9677, 26.4889],
							[58.9565, 26.4892],
							[58.923, 26.4708],
							[58.9007, 26.4617],
							[58.8895, 26.4524],
							[58.8784, 26.4335],
							[58.878, 26.3756],
							[58.889, 26.3656],
							[58.911, 26.3553],
							[58.9439, 26.3253],
							[58.9437, 26.3157],
							[58.9547, 26.3057],
							[58.9545, 26.2961],
							[58.9655, 26.2861],
							[58.9543, 26.2768],
							[58.9542, 26.2672],
							[58.9318, 26.2486],
							[58.9208, 26.2489],
							[58.9207, 26.2393],
							[58.9095, 26.23],
							[58.9094, 26.2204],
							[58.9202, 26.2008],
							[58.9091, 26.1916],
							[58.909, 26.1819],
							[58.8979, 26.1823],
							[58.8866, 26.1538],
							[58.8861, 26.1057],
							[58.897, 26.0958],
							[58.8858, 26.0769],
							[58.8855, 26.0481],
							[58.8633, 26.0295],
							[58.8631, 26.0103],
							[58.8521, 26.0106],
							[58.8412, 26.0206],
							[58.838, 26.0207],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Kermanshah", name: "کرمانشاه" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[45.8049, 33.973],
							[45.8171, 33.9636],
							[45.818, 33.9541],
							[45.8302, 33.9447],
							[45.831, 33.9352],
							[45.8213, 33.916],
							[45.8013, 33.8868],
							[45.8022, 33.8772],
							[45.7824, 33.847],
							[45.7725, 33.8262],
							[45.7655, 33.7744],
							[45.7562, 33.7533],
							[45.7573, 33.7434],
							[45.7471, 33.7325],
							[45.7496, 33.7127],
							[45.7416, 33.6922],
							[45.7473, 33.6489],
							[45.7101, 33.6744],
							[45.6831, 33.7005],
							[45.662, 33.7262],
							[45.659, 33.754],
							[45.6543, 33.7648],
							[45.6442, 33.7774],
							[45.6168, 33.7973],
							[45.6105, 33.8097],
							[45.5976, 33.8253],
							[45.5872, 33.8438],
							[45.5535, 33.8825],
							[45.5414, 33.9013],
							[45.5247, 33.9318],
							[45.5163, 33.9385],
							[45.5062, 33.9409],
							[45.4812, 33.9327],
							[45.4717, 33.9325],
							[45.4586, 33.9349],
							[45.4446, 33.9413],
							[45.4313, 33.9579],
							[45.4128, 33.9716],
							[45.418, 33.9748],
							[45.4406, 33.9819],
							[45.4469, 33.9855],
							[45.4549, 34.0016],
							[45.4788, 34.0138],
							[45.4774, 34.0311],
							[45.4796, 34.0347],
							[45.484, 34.0372],
							[45.4829, 34.0543],
							[45.4774, 34.0583],
							[45.479, 34.0651],
							[45.4841, 34.0722],
							[45.4991, 34.0814],
							[45.5026, 34.0934],
							[45.5097, 34.1027],
							[45.5188, 34.11],
							[45.538, 34.1144],
							[45.5438, 34.1212],
							[45.5501, 34.1247],
							[45.5586, 34.1255],
							[45.5724, 34.1344],
							[45.5804, 34.1433],
							[45.5812, 34.1478],
							[45.5778, 34.1575],
							[45.5657, 34.1797],
							[45.5716, 34.2008],
							[45.5794, 34.2151],
							[45.5911, 34.2482],
							[45.5934, 34.2596],
							[45.5944, 34.28],
							[45.5879, 34.3027],
							[45.5751, 34.3274],
							[45.5595, 34.3447],
							[45.5481, 34.3491],
							[45.5275, 34.3487],
							[45.5197, 34.3465],
							[45.5145, 34.3422],
							[45.5034, 34.3268],
							[45.493, 34.3396],
							[45.4916, 34.3478],
							[45.4774, 34.3736],
							[45.4714, 34.4001],
							[45.4635, 34.4242],
							[45.4544, 34.4366],
							[45.4532, 34.4576],
							[45.4657, 34.4651],
							[45.4702, 34.4695],
							[45.4767, 34.4691],
							[45.4867, 34.4629],
							[45.5001, 34.4625],
							[45.5117, 34.465],
							[45.517, 34.468],
							[45.5208, 34.4722],
							[45.5315, 34.4915],
							[45.5384, 34.5143],
							[45.5366, 34.5299],
							[45.5247, 34.5499],
							[45.5241, 34.5625],
							[45.5274, 34.5739],
							[45.536, 34.5915],
							[45.54, 34.5934],
							[45.552, 34.5926],
							[45.5799, 34.5655],
							[45.5959, 34.5574],
							[45.6168, 34.5521],
							[45.629, 34.5516],
							[45.644, 34.5584],
							[45.647, 34.5584],
							[45.6523, 34.5549],
							[45.66, 34.554],
							[45.6873, 34.5589],
							[45.706, 34.5562],
							[45.7219, 34.5447],
							[45.7355, 34.5397],
							[45.7483, 34.5416],
							[45.7501, 34.5466],
							[45.7451, 34.56],
							[45.749, 34.5779],
							[45.7432, 34.5879],
							[45.7347, 34.6122],
							[45.7368, 34.6195],
							[45.7367, 34.6284],
							[45.7275, 34.6561],
							[45.7212, 34.6667],
							[45.7136, 34.6685],
							[45.7104, 34.687],
							[45.7082, 34.6899],
							[45.6909, 34.7052],
							[45.6771, 34.713],
							[45.6743, 34.7169],
							[45.6757, 34.7241],
							[45.6862, 34.7384],
							[45.6863, 34.7504],
							[45.6881, 34.7536],
							[45.7087, 34.7697],
							[45.7106, 34.7744],
							[45.7003, 34.7943],
							[45.6988, 34.8084],
							[45.7011, 34.812],
							[45.7332, 34.8296],
							[45.7375, 34.8312],
							[45.7617, 34.8274],
							[45.7682, 34.8285],
							[45.7757, 34.8317],
							[45.7877, 34.8415],
							[45.7923, 34.8474],
							[45.7971, 34.8581],
							[45.7963, 34.8715],
							[45.7898, 34.8968],
							[45.7907, 34.903],
							[45.7986, 34.9099],
							[45.8091, 34.9091],
							[45.8486, 34.8941],
							[45.8654, 34.8958],
							[45.8701, 34.8976],
							[45.8741, 34.9015],
							[45.8815, 34.9248],
							[45.8975, 34.9448],
							[45.9026, 34.9546],
							[45.9025, 34.9592],
							[45.8928, 34.977],
							[45.8939, 34.986],
							[45.8884, 34.9963],
							[45.885, 35.0107],
							[45.883, 35.031],
							[45.8846, 35.0353],
							[45.8929, 35.0393],
							[45.907, 35.0424],
							[45.9278, 35.0433],
							[45.9293, 35.0447],
							[45.9255, 35.0688],
							[45.9299, 35.0773],
							[45.9344, 35.0807],
							[45.9398, 35.0819],
							[45.957, 35.0801],
							[45.9611, 35.0764],
							[45.9934, 35.0625],
							[46.0, 35.0632],
							[46.0118, 35.0686],
							[46.0168, 35.0617],
							[46.0223, 35.0597],
							[46.026, 35.0509],
							[46.029, 35.0486],
							[46.0331, 35.0469],
							[46.0396, 35.0491],
							[46.0456, 35.0474],
							[46.0517, 35.0484],
							[46.0521, 35.0431],
							[46.0553, 35.0408],
							[46.0565, 35.0358],
							[46.0641, 35.036],
							[46.0675, 35.0462],
							[46.07, 35.0489],
							[46.0747, 35.0496],
							[46.0769, 35.0541],
							[46.0688, 35.0546],
							[46.0665, 35.0584],
							[46.0724, 35.0794],
							[46.0851, 35.0851],
							[46.0942, 35.0858],
							[46.0992, 35.076],
							[46.1137, 35.0724],
							[46.1231, 35.074],
							[46.1303, 35.0787],
							[46.1434, 35.0826],
							[46.1603, 35.0838],
							[46.1734, 35.0964],
							[46.189, 35.1083],
							[46.1824, 35.1132],
							[46.1558, 35.1236],
							[46.1542, 35.129],
							[46.1591, 35.1406],
							[46.1566, 35.1593],
							[46.1597, 35.1656],
							[46.1641, 35.1693],
							[46.1868, 35.1787],
							[46.2029, 35.1911],
							[46.204, 35.1942],
							[46.2029, 35.1976],
							[46.1948, 35.204],
							[46.1959, 35.2096],
							[46.1887, 35.2158],
							[46.173, 35.2223],
							[46.1614, 35.22],
							[46.156, 35.2206],
							[46.1437, 35.227],
							[46.129, 35.2254],
							[46.1269, 35.2265],
							[46.1296, 35.2349],
							[46.1275, 35.2359],
							[46.1118, 35.2297],
							[46.1107, 35.2331],
							[46.1161, 35.2451],
							[46.1419, 35.2639],
							[46.1467, 35.2734],
							[46.153, 35.2774],
							[46.1759, 35.2656],
							[46.2253, 35.2281],
							[46.2995, 35.1916],
							[46.3123, 35.1821],
							[46.3389, 35.1532],
							[46.3895, 35.1159],
							[46.4156, 35.0873],
							[46.429, 35.0679],
							[46.4663, 35.0399],
							[46.5064, 34.9815],
							[46.5321, 34.9525],
							[46.5851, 34.8743],
							[46.586, 34.8642],
							[46.5974, 34.8648],
							[46.6096, 34.8552],
							[46.6201, 34.8658],
							[46.654, 34.8673],
							[46.655, 34.8572],
							[46.6672, 34.8476],
							[46.6568, 34.8371],
							[46.6577, 34.827],
							[46.6473, 34.8165],
							[46.6378, 34.7958],
							[46.6405, 34.7656],
							[46.6527, 34.756],
							[46.6536, 34.746],
							[46.665, 34.7465],
							[46.6772, 34.7369],
							[46.7111, 34.7383],
							[46.7216, 34.7488],
							[46.7329, 34.7492],
							[46.7433, 34.7597],
							[46.7424, 34.7698],
							[46.7537, 34.7702],
							[46.8025, 34.7316],
							[46.8253, 34.7325],
							[46.8363, 34.7431],
							[46.932, 34.7477],
							[46.9435, 34.7584],
							[46.9429, 34.7685],
							[46.9302, 34.7781],
							[46.9296, 34.7883],
							[46.9169, 34.7979],
							[46.9399, 34.8192],
							[47.0128, 34.8223],
							[47.036, 34.8435],
							[47.0232, 34.8531],
							[47.0227, 34.8632],
							[47.0099, 34.8729],
							[47.0087, 34.8931],
							[47.0203, 34.9037],
							[47.0446, 34.9046],
							[47.0574, 34.8949],
							[47.0817, 34.8958],
							[47.0933, 34.9063],
							[47.1049, 34.9169],
							[47.1042, 34.927],
							[47.0909, 34.9468],
							[47.0896, 34.9671],
							[47.1014, 34.9778],
							[47.1389, 34.9793],
							[47.1518, 34.9696],
							[47.1643, 34.9701],
							[47.1901, 34.9508],
							[47.2026, 34.9512],
							[47.2275, 34.9623],
							[47.2526, 34.9632],
							[47.2523, 34.9734],
							[47.2646, 34.984],
							[47.2644, 34.9941],
							[47.2512, 35.0139],
							[47.2504, 35.0443],
							[47.2629, 35.0448],
							[47.2627, 35.0549],
							[47.2749, 35.0654],
							[47.3376, 35.0676],
							[47.388, 35.0591],
							[47.438, 35.0608],
							[47.4502, 35.0713],
							[47.4498, 35.0814],
							[47.462, 35.0919],
							[47.4617, 35.102],
							[47.4489, 35.1117],
							[47.4485, 35.1218],
							[47.4354, 35.1417],
							[47.4226, 35.1514],
							[47.4215, 35.1817],
							[47.4589, 35.1829],
							[47.4717, 35.1731],
							[47.4849, 35.1533],
							[47.5104, 35.1339],
							[47.5357, 35.1245],
							[47.5484, 35.1147],
							[47.6105, 35.1166],
							[47.636, 35.097],
							[47.6364, 35.0869],
							[47.6622, 35.0573],
							[47.6625, 35.0472],
							[47.7135, 35.0081],
							[47.7138, 34.998],
							[47.7389, 34.9886],
							[47.7773, 34.9492],
							[47.7897, 34.9495],
							[47.8024, 34.9398],
							[47.8271, 34.9405],
							[47.8515, 34.9513],
							[47.8528, 34.9109],
							[47.8407, 34.9004],
							[47.8411, 34.8903],
							[47.829, 34.8799],
							[47.8167, 34.8795],
							[47.7925, 34.8586],
							[47.7802, 34.8582],
							[47.756, 34.8373],
							[47.7568, 34.807],
							[47.7698, 34.7871],
							[47.7706, 34.7568],
							[47.7585, 34.7463],
							[47.6092, 34.7418],
							[47.6095, 34.7316],
							[47.5848, 34.7207],
							[47.5725, 34.7102],
							[47.5732, 34.6798],
							[47.5859, 34.67],
							[47.5988, 34.6501],
							[47.6115, 34.6404],
							[47.624, 34.6408],
							[47.6367, 34.6311],
							[47.6741, 34.6322],
							[47.6869, 34.6124],
							[47.6996, 34.6026],
							[47.7369, 34.6038],
							[47.7496, 34.5941],
							[47.7744, 34.5949],
							[47.787, 34.5852],
							[47.7884, 34.5143],
							[47.801, 34.5046],
							[47.8134, 34.505],
							[47.826, 34.4953],
							[47.8383, 34.5058],
							[47.8381, 34.5159],
							[47.8501, 34.5365],
							[47.8619, 34.5673],
							[47.8617, 34.5774],
							[47.9113, 34.579],
							[47.9477, 34.6105],
							[47.9724, 34.6113],
							[47.985, 34.6016],
							[47.9973, 34.602],
							[47.999, 34.5211],
							[47.9871, 34.5005],
							[47.9877, 34.4702],
							[47.9883, 34.44],
							[48.0009, 34.4304],
							[48.0013, 34.4102],
							[47.9892, 34.3998],
							[47.9644, 34.399],
							[47.9393, 34.4183],
							[47.9269, 34.4179],
							[47.9143, 34.4275],
							[47.8647, 34.4259],
							[47.8025, 34.4339],
							[47.765, 34.4428],
							[47.7526, 34.4424],
							[47.7283, 34.4214],
							[47.7292, 34.3912],
							[47.7172, 34.3807],
							[47.7176, 34.3707],
							[47.694, 34.3498],
							[47.6819, 34.3493],
							[47.6571, 34.3585],
							[47.5842, 34.3562],
							[47.5847, 34.3462],
							[47.5731, 34.3359],
							[47.5768, 34.2665],
							[47.5901, 34.2473],
							[47.5932, 34.1989],
							[47.5696, 34.1886],
							[47.4243, 34.184],
							[47.3772, 34.1632],
							[47.3793, 34.1343],
							[47.3928, 34.1154],
							[47.4069, 34.0869],
							[47.419, 34.0873],
							[47.421, 34.0584],
							[47.3862, 34.0379],
							[47.3742, 34.0374],
							[47.3735, 34.0471],
							[47.3254, 34.0453],
							[47.2672, 34.0238],
							[47.2199, 34.0224],
							[47.2208, 34.0128],
							[47.1851, 34.0214],
							[47.1746, 34.0116],
							[47.1633, 34.0114],
							[47.153, 34.0016],
							[47.154, 33.992],
							[47.1785, 33.9733],
							[47.1917, 33.9544],
							[47.2153, 33.9453],
							[47.2191, 33.907],
							[47.2088, 33.8973],
							[47.2005, 33.8684],
							[47.2044, 33.8301],
							[47.1839, 33.8106],
							[47.139, 33.8099],
							[47.138, 33.8195],
							[47.0911, 33.8378],
							[47.0789, 33.8472],
							[47.0226, 33.8461],
							[47.0011, 33.836],
							[46.957, 33.8254],
							[46.9457, 33.8251],
							[46.9128, 33.8147],
							[46.8921, 33.7949],
							[46.7789, 33.7902],
							[46.7095, 33.7963],
							[46.6612, 33.8133],
							[46.6012, 33.8206],
							[46.526, 33.847],
							[46.5, 33.8652],
							[46.4751, 33.8738],
							[46.4611, 33.8924],
							[46.3966, 33.9378],
							[46.3719, 33.9465],
							[46.3591, 33.9555],
							[46.3345, 33.9641],
							[46.3217, 33.9732],
							[46.3099, 33.9727],
							[46.2972, 33.9818],
							[46.2505, 33.9799],
							[46.205, 33.9684],
							[46.1702, 33.9669],
							[46.1199, 34.0029],
							[46.0721, 34.0198],
							[46.0474, 34.0377],
							[46.036, 34.0372],
							[46.0238, 34.0461],
							[46.0125, 34.0456],
							[46.0022, 34.0357],
							[45.9593, 34.0149],
							[45.8357, 34.0114],
							[45.8007, 34.0205],
							[45.7893, 34.0204],
							[45.7682, 34.0012],
							[45.7796, 34.0013],
							[45.7805, 33.9918],
							[45.8049, 33.973],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Khuzestan", name: "خوزستان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[49.0867, 30.104],
							[49.0891, 30.0985],
							[49.0807, 30.1026],
							[49.0818, 30.1046],
							[49.0867, 30.104],
						],
					],
					[
						[
							[49.1204, 30.121],
							[49.119, 30.1157],
							[49.1199, 30.101],
							[49.1207, 30.0963],
							[49.124, 30.0938],
							[49.1204, 30.0927],
							[49.1188, 30.0943],
							[49.1112, 30.0887],
							[49.0963, 30.0902],
							[49.0951, 30.0918],
							[49.0982, 30.0926],
							[49.0935, 30.096],
							[49.0973, 30.1021],
							[49.1026, 30.1063],
							[49.1021, 30.1096],
							[49.1118, 30.1096],
							[49.1146, 30.1126],
							[49.1174, 30.1215],
							[49.1199, 30.1227],
							[49.1204, 30.121],
						],
					],
					[
						[
							[49.0076, 30.1357],
							[49.0143, 30.1329],
							[49.0154, 30.1299],
							[49.0165, 30.1327],
							[49.0196, 30.1343],
							[49.0196, 30.1306],
							[49.014, 30.1265],
							[49.0062, 30.1368],
							[49.0076, 30.1357],
						],
					],
					[
						[
							[49.1176, 30.1611],
							[49.1171, 30.1624],
							[49.1199, 30.1626],
							[49.1199, 30.161],
							[49.1176, 30.1611],
						],
					],
					[
						[
							[49.1295, 30.1507],
							[49.1299, 30.1485],
							[49.1274, 30.1521],
							[49.129, 30.1518],
							[49.1295, 30.1507],
						],
					],
					[
						[
							[49.1607, 30.1785],
							[49.1621, 30.174],
							[49.1673, 30.1749],
							[49.1874, 30.1705],
							[49.201, 30.1652],
							[49.2093, 30.1588],
							[49.2088, 30.156],
							[49.2051, 30.1532],
							[49.2082, 30.1551],
							[49.2135, 30.1549],
							[49.2163, 30.1482],
							[49.2085, 30.1468],
							[49.2062, 30.1437],
							[49.1996, 30.1446],
							[49.1811, 30.1421],
							[49.1771, 30.139],
							[49.1721, 30.1391],
							[49.164, 30.1363],
							[49.1613, 30.1374],
							[49.1599, 30.1365],
							[49.161, 30.1315],
							[49.1574, 30.1288],
							[49.1476, 30.1301],
							[49.1563, 30.1563],
							[49.1568, 30.166],
							[49.1535, 30.169],
							[49.1557, 30.1719],
							[49.1549, 30.1765],
							[49.1568, 30.1793],
							[49.1607, 30.1785],
						],
					],
					[
						[
							[49.2218, 30.2138],
							[49.236, 30.1982],
							[49.2357, 30.1865],
							[49.2335, 30.184],
							[49.2329, 30.1793],
							[49.2263, 30.1746],
							[49.2229, 30.1757],
							[49.2215, 30.1818],
							[49.2199, 30.1804],
							[49.2196, 30.1749],
							[49.2179, 30.1755],
							[49.2171, 30.1787],
							[49.2157, 30.1729],
							[49.214, 30.1771],
							[49.2118, 30.1751],
							[49.2112, 30.1782],
							[49.2074, 30.1807],
							[49.2081, 30.1757],
							[49.2065, 30.1726],
							[49.2082, 30.1696],
							[49.2115, 30.1696],
							[49.2141, 30.166],
							[49.2163, 30.1593],
							[49.2151, 30.1579],
							[49.2062, 30.1685],
							[49.2051, 30.1724],
							[49.2007, 30.1741],
							[49.1993, 30.1765],
							[49.199, 30.1796],
							[49.2035, 30.1871],
							[49.2029, 30.1974],
							[49.1999, 30.2035],
							[49.2121, 30.2051],
							[49.2143, 30.2099],
							[49.2182, 30.2118],
							[49.2199, 30.2146],
							[49.2218, 30.2138],
						],
					],
					[
						[
							[49.1624, 30.1896],
							[49.1646, 30.186],
							[49.1549, 30.1863],
							[49.1573, 30.1899],
							[49.1624, 30.1896],
						],
					],
					[
						[
							[49.0157, 30.1968],
							[49.0168, 30.2001],
							[49.0185, 30.2001],
							[49.0174, 30.1965],
							[49.0157, 30.1968],
						],
					],
					[
						[
							[49.0304, 30.2046],
							[49.0299, 30.2007],
							[49.0274, 30.1996],
							[49.0279, 30.2043],
							[49.0304, 30.2046],
						],
					],
					[
						[
							[49.0082, 30.201],
							[49.0051, 30.2021],
							[49.0074, 30.206],
							[49.0079, 30.206],
							[49.0082, 30.201],
						],
					],
					[
						[
							[49.0777, 30.2149],
							[49.0771, 30.2027],
							[49.0743, 30.2021],
							[49.0682, 30.2071],
							[49.0685, 30.2115],
							[49.0663, 30.2146],
							[49.0749, 30.2143],
							[49.0771, 30.2163],
							[49.0777, 30.2149],
						],
					],
					[
						[
							[48.9821, 30.2657],
							[48.984, 30.2638],
							[48.9837, 30.2596],
							[48.9868, 30.2576],
							[48.9865, 30.2526],
							[48.9837, 30.2563],
							[48.9821, 30.2535],
							[48.9807, 30.2538],
							[48.9804, 30.2568],
							[48.9827, 30.2596],
							[48.9799, 30.2651],
							[48.9815, 30.2679],
							[48.9821, 30.2657],
						],
					],
					[
						[
							[48.9783, 30.269],
							[48.9787, 30.2638],
							[48.9729, 30.2618],
							[48.9715, 30.2679],
							[48.974, 30.2685],
							[48.9732, 30.2657],
							[48.9743, 30.2654],
							[48.9771, 30.2693],
							[48.9783, 30.269],
						],
					],
					[
						[
							[48.9668, 30.2672],
							[48.9663, 30.2657],
							[48.9685, 30.2651],
							[48.9685, 30.2638],
							[48.9654, 30.2632],
							[48.9635, 30.2665],
							[48.9663, 30.2687],
							[48.9668, 30.2672],
						],
					],
					[
						[
							[48.9826, 30.271],
							[48.9804, 30.2699],
							[48.979, 30.2701],
							[48.9815, 30.2721],
							[48.9826, 30.271],
						],
					],
					[
						[
							[49.1485, 30.189],
							[49.1496, 30.1876],
							[49.1476, 30.1863],
							[49.146, 30.1899],
							[49.1476, 30.1904],
							[49.1485, 30.189],
						],
					],
					[
						[
							[49.2285, 30.2349],
							[49.2254, 30.2276],
							[49.2196, 30.2215],
							[49.219, 30.2143],
							[49.2137, 30.2104],
							[49.2126, 30.2065],
							[49.2032, 30.2057],
							[49.1993, 30.2038],
							[49.1965, 30.2074],
							[49.1974, 30.2162],
							[49.2029, 30.2202],
							[49.2132, 30.2229],
							[49.2179, 30.2329],
							[49.2237, 30.2354],
							[49.2285, 30.2349],
						],
					],
					[
						[
							[49.2169, 30.3496],
							[49.216, 30.3476],
							[49.2146, 30.3474],
							[49.2146, 30.3493],
							[49.2174, 30.3515],
							[49.2169, 30.3496],
						],
					],
					[
						[
							[49.1851, 30.3582],
							[49.1871, 30.3563],
							[49.1874, 30.3549],
							[49.1821, 30.359],
							[49.1851, 30.3582],
						],
					],
					[
						[
							[49.2324, 30.1635],
							[49.2319, 30.1571],
							[49.2287, 30.1551],
							[49.2276, 30.1515],
							[49.219, 30.1551],
							[49.2215, 30.1593],
							[49.2335, 30.1646],
							[49.2324, 30.1635],
						],
					],
					[
						[
							[49.2371, 30.164],
							[49.2371, 30.1618],
							[49.2326, 30.161],
							[49.234, 30.164],
							[49.2371, 30.164],
						],
					],
					[
						[
							[49.2343, 30.1751],
							[49.2363, 30.1749],
							[49.2346, 30.1704],
							[49.2285, 30.1668],
							[49.224, 30.1665],
							[49.226, 30.1699],
							[49.2265, 30.1685],
							[49.2279, 30.169],
							[49.2307, 30.1765],
							[49.2335, 30.1773],
							[49.2343, 30.1751],
						],
					],
					[
						[
							[49.2319, 30.4043],
							[49.2315, 30.4021],
							[49.2271, 30.4029],
							[49.2287, 30.4051],
							[49.2319, 30.4043],
						],
					],
					[
						[
							[49.2201, 30.3654],
							[49.221, 30.3621],
							[49.2185, 30.3535],
							[49.214, 30.3513],
							[49.2137, 30.3596],
							[49.2176, 30.3621],
							[49.2179, 30.366],
							[49.2201, 30.3654],
						],
					],
					[
						[
							[49.1954, 30.379],
							[49.1971, 30.3779],
							[49.1968, 30.376],
							[49.1932, 30.3768],
							[49.1926, 30.3799],
							[49.1954, 30.379],
						],
					],
					[
						[
							[49.1875, 30.384],
							[49.1882, 30.3821],
							[49.1838, 30.3832],
							[49.1851, 30.3849],
							[49.1875, 30.384],
						],
					],
					[
						[
							[49.1604, 30.4275],
							[49.1668, 30.4185],
							[49.1635, 30.4154],
							[49.1479, 30.4137],
							[49.1485, 30.4171],
							[49.1526, 30.4204],
							[49.1515, 30.4218],
							[49.1532, 30.4252],
							[49.1574, 30.4287],
							[49.1604, 30.4275],
						],
					],
					[
						[
							[49.2485, 30.411],
							[49.2468, 30.4099],
							[49.2454, 30.4115],
							[49.2465, 30.4118],
							[49.2485, 30.411],
						],
					],
					[
						[
							[49.251, 30.4165],
							[49.2482, 30.4137],
							[49.2463, 30.4143],
							[49.2479, 30.4168],
							[49.2385, 30.4137],
							[49.2357, 30.4137],
							[49.2329, 30.4163],
							[49.2487, 30.4185],
							[49.251, 30.4165],
						],
					],
					[
						[
							[49.2254, 30.4404],
							[49.2385, 30.4279],
							[49.246, 30.4268],
							[49.2501, 30.4226],
							[49.2501, 30.4207],
							[49.2329, 30.419],
							[49.2326, 30.4218],
							[49.2357, 30.4251],
							[49.2343, 30.426],
							[49.231, 30.4224],
							[49.231, 30.4176],
							[49.2249, 30.4149],
							[49.2193, 30.4151],
							[49.2096, 30.4118],
							[49.2004, 30.4137],
							[49.1999, 30.4157],
							[49.2035, 30.4146],
							[49.2093, 30.4204],
							[49.2065, 30.4229],
							[49.2212, 30.4301],
							[49.2218, 30.4332],
							[49.2235, 30.4341],
							[49.2212, 30.441],
							[49.2254, 30.4404],
						],
					],
					[
						[
							[50.0771, 30.2361],
							[50.0428, 30.204],
							[50.0407, 30.2065],
							[50.0351, 30.2057],
							[50.0196, 30.211],
							[49.9957, 30.2135],
							[49.9771, 30.2135],
							[49.9435, 30.2074],
							[49.9079, 30.1951],
							[49.8801, 30.1815],
							[49.8563, 30.1665],
							[49.809, 30.1332],
							[49.7743, 30.1143],
							[49.7718, 30.1126],
							[49.7713, 30.1101],
							[49.764, 30.1079],
							[49.7271, 30.0868],
							[49.7121, 30.076],
							[49.6721, 30.0521],
							[49.6374, 30.0326],
							[49.6051, 30.0171],
							[49.6029, 30.0146],
							[49.5937, 30.0113],
							[49.5876, 30.006],
							[49.5838, 30.0063],
							[49.5785, 30.0029],
							[49.5629, 29.9985],
							[49.5599, 30.0004],
							[49.5543, 30.0007],
							[49.5479, 30.0054],
							[49.526, 30.0254],
							[49.5074, 30.0451],
							[49.504, 30.059],
							[49.504, 30.0649],
							[49.5057, 30.0677],
							[49.5021, 30.1191],
							[49.4935, 30.1337],
							[49.4651, 30.1557],
							[49.4563, 30.1585],
							[49.4421, 30.1596],
							[49.3893, 30.151],
							[49.3863, 30.1499],
							[49.3779, 30.1421],
							[49.3826, 30.1421],
							[49.3949, 30.1474],
							[49.4115, 30.1482],
							[49.4135, 30.1446],
							[49.4121, 30.1435],
							[49.3982, 30.1443],
							[49.3926, 30.141],
							[49.3852, 30.1395],
							[49.3846, 30.1374],
							[49.3801, 30.1374],
							[49.3785, 30.139],
							[49.3746, 30.1363],
							[49.3729, 30.1368],
							[49.3718, 30.1405],
							[49.3688, 30.1404],
							[49.3682, 30.146],
							[49.3524, 30.1474],
							[49.3414, 30.1457],
							[49.3293, 30.1399],
							[49.3217, 30.1454],
							[49.3138, 30.1457],
							[49.2943, 30.1363],
							[49.2801, 30.1368],
							[49.2624, 30.1332],
							[49.2412, 30.1321],
							[49.2368, 30.1349],
							[49.2343, 30.1421],
							[49.2301, 30.1443],
							[49.2285, 30.1496],
							[49.231, 30.151],
							[49.2285, 30.151],
							[49.2287, 30.1526],
							[49.2335, 30.1513],
							[49.234, 30.1496],
							[49.2376, 30.1496],
							[49.2385, 30.1515],
							[49.2363, 30.1543],
							[49.2404, 30.1551],
							[49.241, 30.1576],
							[49.2432, 30.1574],
							[49.2438, 30.1549],
							[49.246, 30.1554],
							[49.2454, 30.1599],
							[49.249, 30.164],
							[49.2496, 30.174],
							[49.2523, 30.1791],
							[49.2507, 30.1838],
							[49.251, 30.1904],
							[49.2493, 30.191],
							[49.2474, 30.1963],
							[49.2274, 30.216],
							[49.2257, 30.2213],
							[49.2373, 30.2296],
							[49.2387, 30.2396],
							[49.2368, 30.2471],
							[49.2335, 30.2518],
							[49.2351, 30.2576],
							[49.2385, 30.2593],
							[49.2476, 30.2599],
							[49.2529, 30.2685],
							[49.2513, 30.2729],
							[49.246, 30.2763],
							[49.2454, 30.2865],
							[49.2465, 30.2874],
							[49.2374, 30.2921],
							[49.2355, 30.3027],
							[49.2265, 30.3149],
							[49.226, 30.321],
							[49.221, 30.3249],
							[49.2143, 30.3371],
							[49.2151, 30.3426],
							[49.2199, 30.3504],
							[49.224, 30.3407],
							[49.2338, 30.3385],
							[49.2396, 30.3335],
							[49.2321, 30.3413],
							[49.226, 30.3415],
							[49.2243, 30.3429],
							[49.2212, 30.3535],
							[49.2271, 30.3615],
							[49.2374, 30.3599],
							[49.2379, 30.3554],
							[49.2401, 30.3529],
							[49.2388, 30.3546],
							[49.2399, 30.3587],
							[49.2343, 30.3623],
							[49.2257, 30.3629],
							[49.2246, 30.3643],
							[49.2263, 30.3654],
							[49.2238, 30.3682],
							[49.2254, 30.3713],
							[49.2221, 30.3699],
							[49.2124, 30.3771],
							[49.2127, 30.3813],
							[49.2071, 30.3857],
							[49.2082, 30.3888],
							[49.2101, 30.3899],
							[49.216, 30.3888],
							[49.2176, 30.3835],
							[49.2196, 30.3841],
							[49.2157, 30.3903],
							[49.2113, 30.3924],
							[49.2071, 30.3907],
							[49.2071, 30.3946],
							[49.2051, 30.3968],
							[49.2071, 30.3971],
							[49.2113, 30.3929],
							[49.2279, 30.391],
							[49.2319, 30.3885],
							[49.2343, 30.3837],
							[49.2369, 30.3835],
							[49.2363, 30.3801],
							[49.239, 30.3782],
							[49.2376, 30.3818],
							[49.2429, 30.3829],
							[49.2429, 30.3843],
							[49.2376, 30.3843],
							[49.2356, 30.3893],
							[49.2293, 30.3931],
							[49.2171, 30.3951],
							[49.2168, 30.399],
							[49.221, 30.4024],
							[49.2304, 30.399],
							[49.2335, 30.3999],
							[49.236, 30.3973],
							[49.236, 30.4007],
							[49.239, 30.3999],
							[49.2401, 30.4004],
							[49.2357, 30.4043],
							[49.2449, 30.4029],
							[49.2446, 30.4043],
							[49.241, 30.4051],
							[49.2415, 30.4065],
							[49.2388, 30.4057],
							[49.2274, 30.4088],
							[49.2438, 30.409],
							[49.2532, 30.404],
							[49.2546, 30.4046],
							[49.2546, 30.4071],
							[49.2518, 30.4101],
							[49.2504, 30.409],
							[49.2513, 30.4079],
							[49.2499, 30.4082],
							[49.249, 30.4104],
							[49.2501, 30.4115],
							[49.2565, 30.4115],
							[49.2635, 30.4082],
							[49.2645, 30.4104],
							[49.2674, 30.4104],
							[49.2671, 30.4115],
							[49.2621, 30.4107],
							[49.2574, 30.4132],
							[49.2549, 30.4176],
							[49.261, 30.4212],
							[49.2632, 30.4199],
							[49.2646, 30.421],
							[49.2593, 30.4227],
							[49.2576, 30.4201],
							[49.2532, 30.4204],
							[49.2487, 30.4276],
							[49.2579, 30.4318],
							[49.2704, 30.4346],
							[49.2729, 30.4399],
							[49.2754, 30.4399],
							[49.2751, 30.4418],
							[49.2829, 30.4468],
							[49.289, 30.4476],
							[49.2915, 30.4441],
							[49.2946, 30.4432],
							[49.2904, 30.4485],
							[49.284, 30.4482],
							[49.2863, 30.4527],
							[49.2818, 30.4474],
							[49.2796, 30.4468],
							[49.2788, 30.4529],
							[49.2832, 30.4582],
							[49.2835, 30.461],
							[49.2776, 30.4526],
							[49.2779, 30.446],
							[49.271, 30.4413],
							[49.2687, 30.4374],
							[49.2674, 30.4371],
							[49.2671, 30.441],
							[49.271, 30.4468],
							[49.2715, 30.4515],
							[49.2699, 30.4499],
							[49.269, 30.4443],
							[49.2668, 30.4424],
							[49.2657, 30.4371],
							[49.2621, 30.4368],
							[49.2599, 30.4385],
							[49.2593, 30.4415],
							[49.261, 30.4438],
							[49.2596, 30.444],
							[49.2582, 30.4418],
							[49.2596, 30.4349],
							[49.2449, 30.4307],
							[49.2432, 30.4326],
							[49.2385, 30.4332],
							[49.2354, 30.4365],
							[49.2387, 30.4405],
							[49.2324, 30.4393],
							[49.2257, 30.4429],
							[49.226, 30.4451],
							[49.2193, 30.4452],
							[49.2201, 30.4479],
							[49.2326, 30.4457],
							[49.2318, 30.4474],
							[49.2335, 30.449],
							[49.2376, 30.4504],
							[49.2335, 30.4504],
							[49.2285, 30.4479],
							[49.2199, 30.4499],
							[49.2196, 30.4574],
							[49.2246, 30.4582],
							[49.2304, 30.454],
							[49.2268, 30.4579],
							[49.2354, 30.4618],
							[49.241, 30.4593],
							[49.2463, 30.4618],
							[49.246, 30.4629],
							[49.2407, 30.4613],
							[49.2374, 30.4638],
							[49.2349, 30.4635],
							[49.2276, 30.4593],
							[49.2251, 30.4596],
							[49.2251, 30.4615],
							[49.2238, 30.4613],
							[49.2212, 30.4649],
							[49.221, 30.4676],
							[49.2335, 30.4707],
							[49.2318, 30.4723],
							[49.2196, 30.4696],
							[49.2188, 30.4651],
							[49.2212, 30.4615],
							[49.2165, 30.4601],
							[49.2082, 30.4624],
							[49.2049, 30.466],
							[49.2007, 30.4676],
							[49.1968, 30.4674],
							[49.2021, 30.4724],
							[49.194, 30.4685],
							[49.1968, 30.4729],
							[49.2043, 30.4771],
							[49.2113, 30.4757],
							[49.2137, 30.4724],
							[49.216, 30.4732],
							[49.2146, 30.4776],
							[49.2154, 30.4799],
							[49.2093, 30.4782],
							[49.2027, 30.4793],
							[49.1982, 30.4771],
							[49.2038, 30.4868],
							[49.2068, 30.4846],
							[49.2129, 30.4857],
							[49.2038, 30.4871],
							[49.2043, 30.4893],
							[49.2118, 30.4957],
							[49.211, 30.4993],
							[49.2082, 30.5021],
							[49.209, 30.504],
							[49.2076, 30.5049],
							[49.2062, 30.5032],
							[49.2071, 30.5068],
							[49.206, 30.5071],
							[49.2046, 30.5035],
							[49.2085, 30.499],
							[49.2085, 30.494],
							[49.2049, 30.491],
							[49.2012, 30.4915],
							[49.199, 30.489],
							[49.1926, 30.4737],
							[49.184, 30.481],
							[49.1901, 30.4729],
							[49.1885, 30.4685],
							[49.1813, 30.4646],
							[49.1788, 30.4648],
							[49.1712, 30.4596],
							[49.1682, 30.4529],
							[49.1662, 30.4524],
							[49.166, 30.4546],
							[49.1637, 30.4532],
							[49.164, 30.4563],
							[49.1626, 30.4535],
							[49.1593, 30.4526],
							[49.1596, 30.4546],
							[49.1568, 30.4538],
							[49.1546, 30.4557],
							[49.1515, 30.4618],
							[49.1549, 30.4707],
							[49.1551, 30.4763],
							[49.1574, 30.481],
							[49.171, 30.4837],
							[49.1593, 30.4835],
							[49.1662, 30.4888],
							[49.1676, 30.491],
							[49.1671, 30.4915],
							[49.1582, 30.4835],
							[49.156, 30.4833],
							[49.156, 30.4868],
							[49.1604, 30.4924],
							[49.1635, 30.4929],
							[49.1637, 30.4949],
							[49.1624, 30.4985],
							[49.1604, 30.4974],
							[49.159, 30.4988],
							[49.1576, 30.5021],
							[49.159, 30.5037],
							[49.1585, 30.5046],
							[49.1563, 30.5032],
							[49.156, 30.501],
							[49.1601, 30.4954],
							[49.1549, 30.486],
							[49.1532, 30.4729],
							[49.1493, 30.4643],
							[49.1499, 30.4524],
							[49.146, 30.4518],
							[49.1418, 30.4549],
							[49.1385, 30.4621],
							[49.1432, 30.4913],
							[49.1332, 30.4935],
							[49.1299, 30.4993],
							[49.1288, 30.4976],
							[49.1318, 30.4935],
							[49.1268, 30.494],
							[49.1226, 30.5021],
							[49.1207, 30.501],
							[49.1162, 30.5043],
							[49.1165, 30.5099],
							[49.1085, 30.5135],
							[49.1054, 30.5121],
							[49.1096, 30.5126],
							[49.1151, 30.5093],
							[49.1149, 30.5021],
							[49.1199, 30.4999],
							[49.1204, 30.4982],
							[49.1157, 30.4974],
							[49.1146, 30.4935],
							[49.1115, 30.4926],
							[49.099, 30.4974],
							[49.0963, 30.5029],
							[49.0911, 30.5037],
							[49.0851, 30.5024],
							[49.0785, 30.5054],
							[49.0776, 30.504],
							[49.0807, 30.5007],
							[49.0793, 30.4996],
							[49.0804, 30.4962],
							[49.0782, 30.4918],
							[49.0774, 30.4824],
							[49.0796, 30.4793],
							[49.0885, 30.4765],
							[49.096, 30.4718],
							[49.1054, 30.4621],
							[49.1068, 30.4574],
							[49.1101, 30.4535],
							[49.1076, 30.4532],
							[49.1029, 30.456],
							[49.1004, 30.4554],
							[49.0992, 30.4587],
							[49.0968, 30.4601],
							[49.0887, 30.4613],
							[49.0843, 30.464],
							[49.081, 30.4632],
							[49.0804, 30.4574],
							[49.0962, 30.4535],
							[49.0937, 30.4513],
							[49.0943, 30.4499],
							[49.0967, 30.4513],
							[49.099, 30.4504],
							[49.1015, 30.4527],
							[49.1132, 30.4457],
							[49.1177, 30.4399],
							[49.1143, 30.4285],
							[49.1087, 30.4258],
							[49.1035, 30.4268],
							[49.094, 30.4235],
							[49.0887, 30.4246],
							[49.0885, 30.4224],
							[49.0843, 30.4243],
							[49.0832, 30.4218],
							[49.071, 30.421],
							[49.0701, 30.4235],
							[49.0626, 30.4246],
							[49.0584, 30.4324],
							[49.0526, 30.4379],
							[49.0401, 30.4429],
							[49.0318, 30.4549],
							[49.0321, 30.4568],
							[49.039, 30.4613],
							[49.0618, 30.4576],
							[49.0713, 30.4601],
							[49.0762, 30.4576],
							[49.079, 30.4582],
							[49.0771, 30.4632],
							[49.0713, 30.4654],
							[49.0665, 30.4804],
							[49.0671, 30.4996],
							[49.0588, 30.5135],
							[49.0576, 30.5129],
							[49.0599, 30.511],
							[49.0599, 30.5057],
							[49.0454, 30.5068],
							[49.0385, 30.5054],
							[49.0382, 30.504],
							[49.0541, 30.5027],
							[49.0596, 30.501],
							[49.0624, 30.4979],
							[49.061, 30.4846],
							[49.0638, 30.4651],
							[49.0593, 30.4632],
							[49.0401, 30.4654],
							[49.0332, 30.4623],
							[49.0299, 30.459],
							[49.0199, 30.4624],
							[49.0129, 30.4629],
							[49.0071, 30.4657],
							[49.0054, 30.4771],
							[49.0085, 30.4965],
							[49.0129, 30.5004],
							[49.0221, 30.5021],
							[49.0285, 30.5051],
							[49.0126, 30.5029],
							[49.0096, 30.5052],
							[48.9971, 30.506],
							[48.9896, 30.5049],
							[48.984, 30.501],
							[48.979, 30.5029],
							[48.9743, 30.5021],
							[48.9815, 30.5001],
							[48.9762, 30.496],
							[48.9932, 30.5018],
							[49.0062, 30.5018],
							[49.0093, 30.5004],
							[49.0062, 30.4957],
							[49.006, 30.4888],
							[49.0032, 30.4804],
							[49.004, 30.4665],
							[48.989, 30.4624],
							[48.9832, 30.4674],
							[48.9829, 30.4724],
							[48.981, 30.4746],
							[48.9821, 30.4679],
							[48.9854, 30.4629],
							[48.9749, 30.4626],
							[48.9613, 30.4638],
							[48.9546, 30.4707],
							[48.9524, 30.4824],
							[48.9545, 30.4855],
							[48.9635, 30.4907],
							[48.964, 30.4921],
							[48.9568, 30.489],
							[48.951, 30.4835],
							[48.9435, 30.4879],
							[48.9285, 30.4901],
							[48.9237, 30.4897],
							[48.9129, 30.4849],
							[48.9085, 30.4854],
							[48.9029, 30.4899],
							[48.8985, 30.5054],
							[48.8935, 30.5082],
							[48.8871, 30.5082],
							[48.8838, 30.5063],
							[48.8774, 30.4976],
							[48.8804, 30.499],
							[48.8863, 30.5071],
							[48.8921, 30.5076],
							[48.896, 30.5063],
							[48.8988, 30.5021],
							[48.8968, 30.4896],
							[48.9054, 30.4807],
							[48.9113, 30.4807],
							[48.9185, 30.4832],
							[48.9326, 30.4835],
							[48.946, 30.4804],
							[48.9499, 30.4776],
							[48.9507, 30.4665],
							[48.9543, 30.4624],
							[48.9588, 30.4601],
							[48.9874, 30.4588],
							[49.001, 30.4629],
							[49.0068, 30.463],
							[49.0276, 30.454],
							[49.0296, 30.4482],
							[49.0346, 30.4413],
							[49.0493, 30.4329],
							[49.0504, 30.431],
							[49.0524, 30.4315],
							[49.0529, 30.4293],
							[49.0557, 30.4276],
							[49.0529, 30.4226],
							[49.0415, 30.4176],
							[49.0346, 30.429],
							[49.0221, 30.4351],
							[49.0182, 30.441],
							[49.0135, 30.4413],
							[49.0088, 30.4385],
							[48.9949, 30.4393],
							[48.9918, 30.4338],
							[48.991, 30.4282],
							[48.9957, 30.4379],
							[49.0101, 30.4368],
							[49.0157, 30.4399],
							[49.0232, 30.4315],
							[49.0332, 30.4282],
							[49.0337, 30.4249],
							[49.0388, 30.4179],
							[49.036, 30.414],
							[49.0107, 30.3988],
							[49.0082, 30.399],
							[49.0007, 30.4046],
							[48.9701, 30.4118],
							[48.9404, 30.4126],
							[48.9254, 30.4096],
							[48.9113, 30.3977],
							[48.904, 30.3813],
							[48.901, 30.3779],
							[48.8904, 30.3751],
							[48.8835, 30.3688],
							[48.8796, 30.361],
							[48.881, 30.3576],
							[48.8804, 30.3535],
							[48.8771, 30.3529],
							[48.8746, 30.3476],
							[48.8718, 30.3468],
							[48.874, 30.344],
							[48.8715, 30.3401],
							[48.8751, 30.3379],
							[48.8729, 30.3371],
							[48.8726, 30.3315],
							[48.8743, 30.3282],
							[48.876, 30.3268],
							[48.8785, 30.3268],
							[48.879, 30.3285],
							[48.8824, 30.3274],
							[48.8793, 30.3196],
							[48.8751, 30.3176],
							[48.8674, 30.3185],
							[48.8501, 30.3282],
							[48.8471, 30.3246],
							[48.8615, 30.3104],
							[48.8668, 30.3024],
							[48.874, 30.2846],
							[48.8846, 30.2715],
							[48.8985, 30.2599],
							[48.9063, 30.2557],
							[48.911, 30.2565],
							[48.9118, 30.2618],
							[48.9132, 30.2613],
							[48.9335, 30.2313],
							[48.9304, 30.2243],
							[48.9318, 30.214],
							[48.9496, 30.1846],
							[48.9499, 30.1704],
							[48.9435, 30.1329],
							[48.9404, 30.1254],
							[48.9321, 30.1132],
							[48.9293, 30.1101],
							[48.9274, 30.1104],
							[48.9274, 30.1079],
							[48.934, 30.1018],
							[48.9365, 30.0951],
							[48.9388, 30.094],
							[48.941, 30.0765],
							[48.9396, 30.0738],
							[48.9404, 30.069],
							[48.9388, 30.0685],
							[48.9354, 30.0563],
							[48.924, 30.034],
							[48.9276, 30.0354],
							[48.9335, 30.0426],
							[48.9346, 30.0415],
							[48.9246, 30.0296],
							[48.9121, 30.0238],
							[48.896, 30.0207],
							[48.8901, 30.0276],
							[48.8876, 30.0243],
							[48.889, 30.0224],
							[48.8863, 30.011],
							[48.8885, 30.0088],
							[48.8929, 30.009],
							[48.8949, 30.011],
							[48.8949, 30.009],
							[48.8926, 30.0043],
							[48.8874, 30.0004],
							[48.8857, 30.001],
							[48.889, 30.0035],
							[48.8879, 30.0049],
							[48.8671, 30.0107],
							[48.8218, 30.0199],
							[48.7921, 30.0218],
							[48.7685, 30.0196],
							[48.7521, 30.0199],
							[48.7285, 30.0263],
							[48.7226, 30.0318],
							[48.7221, 30.0296],
							[48.7185, 30.0296],
							[48.7165, 30.0232],
							[48.7124, 30.0193],
							[48.7062, 30.0213],
							[48.704, 30.0193],
							[48.6965, 30.0176],
							[48.6932, 30.0185],
							[48.6901, 30.0149],
							[48.696, 30.0101],
							[48.6946, 30.0068],
							[48.6957, 30.0007],
							[48.6946, 29.9954],
							[48.6888, 29.9918],
							[48.6888, 29.9885],
							[48.7001, 29.9785],
							[48.6979, 29.9743],
							[48.6924, 29.9737],
							[48.6915, 29.976],
							[48.6951, 29.9751],
							[48.6963, 29.9782],
							[48.6915, 29.9774],
							[48.6918, 29.9793],
							[48.6874, 29.9801],
							[48.684, 29.9851],
							[48.6826, 29.9851],
							[48.6824, 29.9876],
							[48.681, 29.9857],
							[48.6757, 29.9871],
							[48.6779, 29.9799],
							[48.6774, 29.964],
							[48.6787, 29.9535],
							[48.6838, 29.9418],
							[48.6838, 29.9374],
							[48.6868, 29.9332],
							[48.6868, 29.9293],
							[48.6824, 29.9224],
							[48.6787, 29.9226],
							[48.6749, 29.9282],
							[48.6665, 29.9335],
							[48.6629, 29.9329],
							[48.661, 29.936],
							[48.6576, 29.9351],
							[48.6538, 29.9299],
							[48.6501, 29.9296],
							[48.6493, 29.9282],
							[48.641, 29.9287],
							[48.6407, 29.9257],
							[48.6374, 29.9279],
							[48.6288, 29.9293],
							[48.6237, 29.9326],
							[48.6224, 29.9357],
							[48.6221, 29.9313],
							[48.6115, 29.9274],
							[48.6118, 29.9238],
							[48.6087, 29.9212],
							[48.609, 29.9163],
							[48.6026, 29.9171],
							[48.6021, 29.9135],
							[48.5976, 29.9093],
							[48.5876, 29.9049],
							[48.579, 29.9024],
							[48.5686, 29.9018],
							[48.5566, 29.9191],
							[48.5048, 29.9693],
							[48.4846, 29.9799],
							[48.4576, 29.9972],
							[48.4479, 30.008],
							[48.4426, 30.0443],
							[48.4412, 30.0692],
							[48.4292, 30.0892],
							[48.4122, 30.1029],
							[48.3935, 30.1137],
							[48.383, 30.1273],
							[48.3864, 30.1358],
							[48.4013, 30.157],
							[48.4129, 30.169],
							[48.4138, 30.1754],
							[48.4115, 30.1882],
							[48.4075, 30.1982],
							[48.3987, 30.2104],
							[48.363, 30.242],
							[48.316, 30.2971],
							[48.2819, 30.3265],
							[48.2673, 30.3359],
							[48.2558, 30.3381],
							[48.2427, 30.3333],
							[48.231, 30.3234],
							[48.2236, 30.3192],
							[48.2138, 30.3185],
							[48.204, 30.3222],
							[48.1983, 30.3272],
							[48.1839, 30.3671],
							[48.1769, 30.4091],
							[48.1705, 30.4227],
							[48.1427, 30.4428],
							[48.1174, 30.4507],
							[48.0903, 30.4523],
							[48.0861, 30.4581],
							[48.0739, 30.4583],
							[48.0423, 30.4762],
							[48.0369, 30.4827],
							[48.0347, 30.5316],
							[48.0308, 30.9938],
							[47.7017, 30.9925],
							[47.6992, 31.4],
							[47.8649, 31.7792],
							[47.8643, 31.7929],
							[47.8185, 31.8264],
							[47.8048, 31.8412],
							[47.7995, 31.8524],
							[47.7991, 31.8637],
							[47.8036, 31.8801],
							[47.7756, 31.9027],
							[47.7556, 31.9123],
							[47.7455, 31.9197],
							[47.7375, 31.9296],
							[47.7353, 31.9547],
							[47.7314, 31.9639],
							[47.7104, 31.9888],
							[47.7089, 31.9989],
							[47.6813, 32.0317],
							[47.6598, 32.0431],
							[47.6537, 32.0506],
							[47.6524, 32.0749],
							[47.65, 32.0818],
							[47.6329, 32.0927],
							[47.6288, 32.0973],
							[47.6071, 32.1126],
							[47.625, 32.1222],
							[47.6344, 32.1314],
							[47.6657, 32.1492],
							[47.731, 32.1747],
							[47.752, 32.1926],
							[47.7511, 32.202],
							[47.7616, 32.211],
							[47.7598, 32.2299],
							[47.7465, 32.2491],
							[47.7456, 32.2586],
							[47.7314, 32.2873],
							[47.7182, 32.3066],
							[47.7173, 32.3161],
							[47.7286, 32.3157],
							[47.7267, 32.3346],
							[47.7475, 32.3529],
							[47.798, 32.4089],
							[47.8071, 32.4279],
							[47.8477, 32.466],
							[47.8466, 32.4757],
							[47.8567, 32.4853],
							[47.8657, 32.5046],
							[47.8623, 32.5336],
							[47.8712, 32.553],
							[47.89, 32.5821],
							[47.8888, 32.5918],
							[47.9088, 32.6113],
							[47.9076, 32.621],
							[47.9176, 32.6308],
							[47.9289, 32.6309],
							[47.96, 32.6607],
							[47.9716, 32.661],
							[47.9706, 32.6708],
							[47.9803, 32.6907],
							[47.9794, 32.7006],
							[47.9873, 32.7403],
							[48.0071, 32.7805],
							[48.0062, 32.7903],
							[48.0163, 32.8105],
							[48.0153, 32.8203],
							[48.0263, 32.8307],
							[48.0575, 32.8818],
							[48.0779, 32.9225],
							[48.0753, 32.9522],
							[48.0856, 32.9725],
							[48.0967, 32.9829],
							[48.0958, 32.9928],
							[48.1069, 33.0032],
							[48.1428, 33.0048],
							[48.2257, 33.0183],
							[48.2625, 33.0101],
							[48.4061, 33.017],
							[48.4764, 33.0402],
							[48.4875, 33.0507],
							[48.5235, 33.0525],
							[48.5721, 33.045],
							[48.6319, 33.048],
							[48.6447, 33.0388],
							[48.6566, 33.0393],
							[48.6821, 33.0209],
							[48.6829, 33.011],
							[48.6956, 33.0018],
							[48.7092, 32.9827],
							[48.7474, 32.955],
							[48.7594, 32.9556],
							[48.7945, 32.9671],
							[48.8184, 32.9682],
							[48.8678, 32.9507],
							[48.9992, 32.9566],
							[49.0128, 32.9375],
							[49.0375, 32.9287],
							[49.0884, 32.8915],
							[49.1258, 32.8735],
							[49.1776, 32.8265],
							[49.2157, 32.7986],
							[49.2522, 32.7903],
							[49.2768, 32.7816],
							[49.3361, 32.7841],
							[49.3591, 32.795],
							[49.4176, 32.8073],
							[49.4407, 32.8181],
							[49.4533, 32.8088],
							[49.454, 32.799],
							[49.4666, 32.7897],
							[49.4674, 32.7799],
							[49.4956, 32.7222],
							[49.4971, 32.7026],
							[49.5097, 32.6933],
							[49.5105, 32.6835],
							[49.523, 32.6742],
							[49.5466, 32.6752],
							[49.6047, 32.6875],
							[49.7106, 32.6918],
							[49.7231, 32.6825],
							[49.7348, 32.6829],
							[49.797, 32.6363],
							[49.7984, 32.6167],
							[49.7756, 32.606],
							[49.7551, 32.566],
							[49.7441, 32.5557],
							[49.7339, 32.5357],
							[49.7399, 32.4575],
							[49.7546, 32.4189],
							[49.7677, 32.3999],
							[49.77, 32.3706],
							[49.7831, 32.3515],
							[49.7955, 32.3422],
							[49.8334, 32.3046],
							[49.8588, 32.2762],
							[49.8711, 32.2669],
							[49.9233, 32.1906],
							[49.9248, 32.1711],
							[49.9378, 32.1521],
							[49.9515, 32.1233],
							[49.9631, 32.1237],
							[49.9754, 32.1144],
							[49.9999, 32.0957],
							[50.0006, 32.086],
							[50.0237, 32.0868],
							[50.0359, 32.0775],
							[50.059, 32.0783],
							[50.0713, 32.069],
							[50.0727, 32.0495],
							[50.0849, 32.0401],
							[50.087, 32.0109],
							[50.102, 31.9626],
							[50.1033, 31.9431],
							[50.129, 31.9049],
							[50.1412, 31.8956],
							[50.154, 31.8764],
							[50.1547, 31.8667],
							[50.1668, 31.8573],
							[50.1796, 31.8382],
							[50.1917, 31.8288],
							[50.2032, 31.8292],
							[50.2274, 31.8104],
							[50.228, 31.8006],
							[50.2413, 31.7717],
							[50.2534, 31.7623],
							[50.2649, 31.7626],
							[50.2769, 31.7532],
							[50.3004, 31.7441],
							[50.3131, 31.725],
							[50.3148, 31.6956],
							[50.3629, 31.6578],
							[50.3652, 31.6187],
							[50.3543, 31.6086],
							[50.3548, 31.5988],
							[50.3439, 31.5887],
							[50.3325, 31.5884],
							[50.2888, 31.5478],
							[50.2893, 31.538],
							[50.2784, 31.5279],
							[50.2789, 31.5181],
							[50.268, 31.5079],
							[50.2685, 31.4981],
							[50.2467, 31.4778],
							[50.2258, 31.4378],
							[50.1935, 31.3973],
							[50.1726, 31.3571],
							[50.1617, 31.3468],
							[50.1393, 31.3361],
							[50.1049, 31.3347],
							[50.0481, 31.3226],
							[50.0257, 31.3118],
							[50.0038, 31.2911],
							[49.9699, 31.2798],
							[49.9371, 31.2488],
							[49.9256, 31.2483],
							[49.9262, 31.2384],
							[49.9152, 31.2281],
							[49.9216, 31.1],
							[49.934, 31.0807],
							[49.9349, 31.061],
							[49.9587, 31.0423],
							[49.9596, 31.0226],
							[49.9723, 30.9934],
							[49.9732, 30.9737],
							[49.9622, 30.9633],
							[49.963, 30.9436],
							[49.9533, 30.9035],
							[49.9423, 30.8931],
							[49.9431, 30.8734],
							[49.9785, 30.8452],
							[50.0241, 30.8472],
							[50.0351, 30.8575],
							[50.0685, 30.8787],
							[50.1133, 30.9003],
							[50.1361, 30.9011],
							[50.1365, 30.8912],
							[50.1483, 30.8818],
							[50.1487, 30.8719],
							[50.1612, 30.8426],
							[50.162, 30.8229],
							[50.2207, 30.7754],
							[50.232, 30.7758],
							[50.2782, 30.7576],
							[50.29, 30.7481],
							[50.3475, 30.7301],
							[50.3592, 30.7205],
							[50.3705, 30.7209],
							[50.4056, 30.6922],
							[50.4059, 30.6823],
							[50.4176, 30.6728],
							[50.4185, 30.643],
							[50.4077, 30.6228],
							[50.4083, 30.603],
							[50.3975, 30.5828],
							[50.375, 30.5722],
							[50.3418, 30.5414],
							[50.3431, 30.4917],
							[50.3547, 30.4822],
							[50.355, 30.4722],
							[50.4011, 30.4438],
							[50.4014, 30.4339],
							[50.413, 30.4243],
							[50.418, 30.2379],
							[50.4299, 30.2189],
							[50.3058, 30.2162],
							[50.2829, 30.2252],
							[50.2603, 30.2247],
							[50.2487, 30.234],
							[50.2375, 30.2337],
							[50.2259, 30.2429],
							[50.1797, 30.2704],
							[50.1685, 30.2701],
							[50.1568, 30.2793],
							[50.1456, 30.279],
							[50.1347, 30.269],
							[50.1234, 30.2687],
							[50.1013, 30.2583],
							[50.0771, 30.2361],
						],
					],
					[
						[
							[48.894, 30.0168],
							[48.8946, 30.0151],
							[48.8921, 30.0154],
							[48.8924, 30.0176],
							[48.894, 30.0168],
						],
					],
					[
						[
							[48.901, 30.2668],
							[48.9093, 30.264],
							[48.9099, 30.2599],
							[48.9035, 30.261],
							[48.8979, 30.2674],
							[48.901, 30.2668],
						],
					],
					[
						[
							[48.8882, 30.2729],
							[48.8932, 30.2732],
							[48.8932, 30.2715],
							[48.886, 30.2726],
							[48.8865, 30.2738],
							[48.8882, 30.2729],
						],
					],
					[
						[
							[48.9679, 30.2818],
							[48.9651, 30.2813],
							[48.9671, 30.2787],
							[48.9618, 30.2813],
							[48.966, 30.2838],
							[48.9685, 30.2838],
							[48.9679, 30.2818],
						],
					],
					[
						[
							[48.9914, 30.3018],
							[48.9924, 30.2993],
							[48.9957, 30.2991],
							[48.9971, 30.2971],
							[48.9968, 30.2935],
							[48.9921, 30.2901],
							[48.9896, 30.291],
							[48.9865, 30.2974],
							[48.9888, 30.3021],
							[48.9914, 30.3018],
						],
					],
					[
						[
							[48.9463, 30.3215],
							[48.9635, 30.3113],
							[48.9721, 30.2971],
							[48.9827, 30.2846],
							[48.9849, 30.2782],
							[48.9829, 30.2771],
							[48.9829, 30.274],
							[48.9807, 30.2726],
							[48.9765, 30.2735],
							[48.9785, 30.2754],
							[48.974, 30.2779],
							[48.9746, 30.2743],
							[48.9693, 30.2743],
							[48.9657, 30.2713],
							[48.9604, 30.2718],
							[48.9588, 30.2768],
							[48.9685, 30.2774],
							[48.9693, 30.2799],
							[48.9718, 30.2804],
							[48.9746, 30.2796],
							[48.9754, 30.2832],
							[48.9718, 30.2868],
							[48.9682, 30.2865],
							[48.9671, 30.2893],
							[48.9632, 30.2904],
							[48.9615, 30.2938],
							[48.9582, 30.2929],
							[48.9571, 30.2968],
							[48.9599, 30.2988],
							[48.9571, 30.299],
							[48.9554, 30.3035],
							[48.9588, 30.3046],
							[48.954, 30.306],
							[48.9532, 30.3079],
							[48.9512, 30.3068],
							[48.9521, 30.3115],
							[48.9557, 30.311],
							[48.9518, 30.3137],
							[48.9521, 30.3171],
							[48.9496, 30.3174],
							[48.9488, 30.314],
							[48.9479, 30.3188],
							[48.9463, 30.3188],
							[48.9463, 30.3132],
							[48.9432, 30.3124],
							[48.9404, 30.3043],
							[48.9415, 30.3024],
							[48.9451, 30.3037],
							[48.9499, 30.3032],
							[48.9549, 30.2968],
							[48.9521, 30.2965],
							[48.9546, 30.2954],
							[48.9546, 30.294],
							[48.9518, 30.2946],
							[48.9526, 30.2929],
							[48.9554, 30.2935],
							[48.9562, 30.2957],
							[48.9574, 30.2926],
							[48.9604, 30.2924],
							[48.9646, 30.2885],
							[48.9646, 30.2865],
							[48.9604, 30.2874],
							[48.9596, 30.2893],
							[48.9571, 30.2896],
							[48.9554, 30.2868],
							[48.9515, 30.2882],
							[48.9484, 30.294],
							[48.9446, 30.2962],
							[48.9451, 30.2993],
							[48.9432, 30.2996],
							[48.9407, 30.2971],
							[48.939, 30.2982],
							[48.9382, 30.3021],
							[48.9421, 30.3201],
							[48.9432, 30.3221],
							[48.9463, 30.3215],
						],
					],
					[
						[
							[48.9785, 30.3118],
							[48.979, 30.3077],
							[48.9762, 30.3082],
							[48.9754, 30.3099],
							[48.9774, 30.3105],
							[48.9774, 30.3124],
							[48.9785, 30.3118],
						],
					],
					[
						[
							[48.9849, 30.3438],
							[48.9868, 30.3443],
							[48.9893, 30.3415],
							[48.9915, 30.3418],
							[48.9991, 30.3329],
							[49.0063, 30.3288],
							[49.0054, 30.3129],
							[49.0013, 30.3088],
							[48.9985, 30.3104],
							[48.9979, 30.3129],
							[49.0004, 30.3149],
							[49.001, 30.3175],
							[49.0004, 30.329],
							[48.9965, 30.3282],
							[48.9968, 30.326],
							[48.9938, 30.326],
							[48.9921, 30.3232],
							[48.9893, 30.3232],
							[48.9915, 30.3265],
							[48.989, 30.3285],
							[48.9888, 30.3365],
							[48.9818, 30.3429],
							[48.9826, 30.3446],
							[48.9849, 30.3438],
						],
					],
					[
						[
							[48.9593, 30.3351],
							[48.9604, 30.3326],
							[48.9582, 30.3307],
							[48.959, 30.3274],
							[48.9615, 30.326],
							[48.9618, 30.3301],
							[48.9629, 30.3301],
							[48.9654, 30.3232],
							[48.9646, 30.3193],
							[48.9565, 30.3274],
							[48.9565, 30.3351],
							[48.9593, 30.3351],
						],
					],
					[
						[
							[48.9476, 30.3346],
							[48.9507, 30.3315],
							[48.9493, 30.3257],
							[48.9463, 30.3257],
							[48.9415, 30.3299],
							[48.9426, 30.3326],
							[48.9468, 30.3354],
							[48.9476, 30.3346],
						],
					],
					[
						[
							[48.966, 30.3515],
							[48.9715, 30.3457],
							[48.9707, 30.3376],
							[48.9663, 30.3385],
							[48.9671, 30.3412],
							[48.9651, 30.3426],
							[48.9657, 30.3407],
							[48.9635, 30.3401],
							[48.9599, 30.3465],
							[48.9599, 30.3507],
							[48.9621, 30.3526],
							[48.966, 30.3515],
						],
					],
					[
						[
							[48.9579, 30.3529],
							[48.9588, 30.351],
							[48.9571, 30.3507],
							[48.9588, 30.3471],
							[48.956, 30.3485],
							[48.9546, 30.3518],
							[48.9565, 30.3543],
							[48.9579, 30.3529],
						],
					],
					[
						[
							[48.948, 30.3993],
							[48.9454, 30.3971],
							[48.929, 30.3982],
							[48.9377, 30.3999],
							[48.948, 30.3993],
						],
					],
					[
						[
							[48.9974, 30.404],
							[48.9996, 30.4029],
							[48.9996, 30.401],
							[48.9932, 30.4046],
							[48.9974, 30.404],
						],
					],
					[
						[
							[49.0029, 30.2607],
							[49.0101, 30.2582],
							[49.0235, 30.2588],
							[49.041, 30.2532],
							[49.0743, 30.2279],
							[49.0765, 30.2171],
							[49.0651, 30.2179],
							[49.061, 30.2138],
							[49.0483, 30.2082],
							[49.046, 30.2049],
							[49.0426, 30.2057],
							[49.0399, 30.2096],
							[49.0354, 30.2101],
							[49.0318, 30.2151],
							[49.0309, 30.2213],
							[49.0271, 30.2249],
							[49.0193, 30.2226],
							[49.0187, 30.2296],
							[49.0174, 30.2296],
							[49.0171, 30.2274],
							[49.0085, 30.2268],
							[49.0071, 30.2318],
							[49.0057, 30.2321],
							[49.0043, 30.229],
							[49.0024, 30.2285],
							[49.0021, 30.2385],
							[48.999, 30.239],
							[48.9976, 30.2443],
							[48.9996, 30.2463],
							[48.9996, 30.2615],
							[49.0029, 30.2607],
						],
					],
					[
						[
							[48.9613, 30.3988],
							[48.9704, 30.3963],
							[48.9971, 30.3815],
							[49.0065, 30.3788],
							[49.0235, 30.3824],
							[49.0268, 30.3843],
							[49.0296, 30.3885],
							[49.0371, 30.3915],
							[49.0371, 30.3927],
							[49.041, 30.3932],
							[49.0471, 30.3865],
							[49.0493, 30.3793],
							[49.0554, 30.3743],
							[49.0607, 30.3726],
							[49.061, 30.3699],
							[49.0568, 30.3629],
							[49.0621, 30.3701],
							[49.0643, 30.369],
							[49.0657, 30.3657],
							[49.0654, 30.3563],
							[49.0693, 30.3496],
							[49.0663, 30.344],
							[49.0707, 30.3487],
							[49.074, 30.3429],
							[49.0726, 30.349],
							[49.0665, 30.3579],
							[49.0679, 30.3624],
							[49.074, 30.3549],
							[49.0749, 30.3549],
							[49.0674, 30.3638],
							[49.0685, 30.3657],
							[49.074, 30.3638],
							[49.0776, 30.359],
							[49.0826, 30.3585],
							[49.0844, 30.3568],
							[49.0843, 30.3485],
							[49.0901, 30.3446],
							[49.0946, 30.3354],
							[49.0974, 30.3371],
							[49.0932, 30.3399],
							[49.0923, 30.3449],
							[49.0985, 30.3465],
							[49.1093, 30.3535],
							[49.1209, 30.3543],
							[49.1332, 30.3526],
							[49.1368, 30.3543],
							[49.1393, 30.3504],
							[49.1379, 30.3557],
							[49.1446, 30.3599],
							[49.1424, 30.3688],
							[49.1479, 30.3729],
							[49.1399, 30.3693],
							[49.1271, 30.3701],
							[49.126, 30.3774],
							[49.1304, 30.3863],
							[49.134, 30.3854],
							[49.1351, 30.3829],
							[49.1399, 30.3832],
							[49.149, 30.379],
							[49.1507, 30.381],
							[49.1468, 30.3888],
							[49.1482, 30.389],
							[49.151, 30.3832],
							[49.1526, 30.3826],
							[49.1532, 30.386],
							[49.1496, 30.391],
							[49.154, 30.389],
							[49.1557, 30.3832],
							[49.1627, 30.3804],
							[49.1587, 30.374],
							[49.1599, 30.3707],
							[49.1615, 30.3699],
							[49.161, 30.3735],
							[49.1635, 30.3779],
							[49.1654, 30.3782],
							[49.1696, 30.3738],
							[49.1723, 30.3663],
							[49.1724, 30.3648],
							[49.1676, 30.3618],
							[49.1618, 30.3513],
							[49.1676, 30.3574],
							[49.1693, 30.3618],
							[49.1735, 30.3646],
							[49.1763, 30.3643],
							[49.1788, 30.3621],
							[49.1801, 30.3565],
							[49.1815, 30.3571],
							[49.1882, 30.3526],
							[49.1888, 30.3487],
							[49.1874, 30.3463],
							[49.1904, 30.3504],
							[49.1882, 30.354],
							[49.1926, 30.351],
							[49.1918, 30.3468],
							[49.1926, 30.3404],
							[49.1924, 30.3457],
							[49.1943, 30.3513],
							[49.1881, 30.3576],
							[49.1824, 30.3604],
							[49.1821, 30.3621],
							[49.1932, 30.3604],
							[49.1982, 30.3579],
							[49.1993, 30.3596],
							[49.1924, 30.3632],
							[49.1855, 30.3627],
							[49.1849, 30.3649],
							[49.1824, 30.3643],
							[49.1743, 30.3688],
							[49.1724, 30.376],
							[49.1746, 30.3765],
							[49.1746, 30.3799],
							[49.1982, 30.3743],
							[49.2032, 30.3649],
							[49.2029, 30.371],
							[49.2085, 30.3682],
							[49.2088, 30.3624],
							[49.2068, 30.361],
							[49.2082, 30.3593],
							[49.2076, 30.3568],
							[49.2093, 30.3613],
							[49.2115, 30.3618],
							[49.2113, 30.366],
							[49.2151, 30.3651],
							[49.2146, 30.3629],
							[49.211, 30.3615],
							[49.2129, 30.3499],
							[49.2088, 30.3463],
							[49.2132, 30.3485],
							[49.2135, 30.3432],
							[49.2124, 30.3382],
							[49.2088, 30.3357],
							[49.2126, 30.3371],
							[49.2185, 30.3254],
							[49.2171, 30.3213],
							[49.2201, 30.3238],
							[49.2241, 30.3193],
							[49.2293, 30.3079],
							[49.229, 30.3049],
							[49.2335, 30.3029],
							[49.236, 30.2904],
							[49.243, 30.2871],
							[49.2435, 30.2771],
							[49.2463, 30.2729],
							[49.2504, 30.2704],
							[49.2509, 30.2676],
							[49.2468, 30.2604],
							[49.2385, 30.2601],
							[49.2343, 30.2576],
							[49.2307, 30.2496],
							[49.2296, 30.2391],
							[49.2154, 30.2343],
							[49.2129, 30.2313],
							[49.2115, 30.2237],
							[49.1999, 30.2201],
							[49.1946, 30.2151],
							[49.1946, 30.2062],
							[49.1999, 30.1935],
							[49.1971, 30.1843],
							[49.1888, 30.1921],
							[49.1779, 30.1949],
							[49.1876, 30.1913],
							[49.1932, 30.1863],
							[49.1949, 30.1824],
							[49.1949, 30.1749],
							[49.1765, 30.1799],
							[49.1713, 30.1829],
							[49.1693, 30.1818],
							[49.1662, 30.1843],
							[49.1668, 30.1915],
							[49.1527, 30.1935],
							[49.1515, 30.2021],
							[49.1496, 30.2043],
							[49.141, 30.206],
							[49.1415, 30.2154],
							[49.1396, 30.2157],
							[49.139, 30.219],
							[49.1265, 30.2237],
							[49.1257, 30.2265],
							[49.1271, 30.2274],
							[49.1329, 30.2265],
							[49.1327, 30.2285],
							[49.1276, 30.2315],
							[49.1129, 30.2276],
							[49.1115, 30.2285],
							[49.1115, 30.2315],
							[49.109, 30.2318],
							[49.1096, 30.236],
							[49.1043, 30.236],
							[49.1029, 30.241],
							[49.0999, 30.2438],
							[49.0907, 30.2426],
							[49.0866, 30.2493],
							[49.0843, 30.2496],
							[49.084, 30.2443],
							[49.0779, 30.2407],
							[49.066, 30.2485],
							[49.0649, 30.2518],
							[49.0546, 30.2568],
							[49.0501, 30.2568],
							[49.0468, 30.2601],
							[49.0193, 30.2735],
							[49.0088, 30.2765],
							[49.0077, 30.2804],
							[49.0051, 30.2776],
							[48.999, 30.2782],
							[48.9996, 30.2838],
							[49.0013, 30.2843],
							[49.0026, 30.289],
							[49.0068, 30.2935],
							[49.0057, 30.3043],
							[49.0107, 30.3229],
							[49.0093, 30.3312],
							[48.994, 30.3446],
							[48.9843, 30.3499],
							[48.9699, 30.3549],
							[48.9449, 30.3607],
							[48.9437, 30.3624],
							[48.946, 30.3635],
							[48.9457, 30.3654],
							[48.9429, 30.3682],
							[48.9393, 30.3679],
							[48.9362, 30.3701],
							[48.9282, 30.379],
							[48.9238, 30.3882],
							[48.9232, 30.394],
							[48.9265, 30.3979],
							[48.9454, 30.3963],
							[48.949, 30.3979],
							[48.9562, 30.3979],
							[48.9588, 30.3996],
							[48.9613, 30.3988],
						],
					],
					[
						[
							[49.0721, 30.3793],
							[49.0785, 30.3721],
							[49.0843, 30.3685],
							[49.0837, 30.3602],
							[49.0782, 30.3599],
							[49.0763, 30.3638],
							[49.0671, 30.3682],
							[49.0629, 30.3732],
							[49.0679, 30.3793],
							[49.0721, 30.3793],
						],
					],
					[
						[
							[49.061, 30.406],
							[49.0893, 30.3932],
							[49.0988, 30.3918],
							[49.1235, 30.3927],
							[49.1279, 30.3885],
							[49.1268, 30.3865],
							[49.1276, 30.3832],
							[49.1249, 30.3793],
							[49.1249, 30.3707],
							[49.1274, 30.3679],
							[49.1399, 30.3685],
							[49.1418, 30.3657],
							[49.1415, 30.3601],
							[49.1315, 30.354],
							[49.1279, 30.3568],
							[49.1276, 30.3551],
							[49.1251, 30.3546],
							[49.1213, 30.3557],
							[49.1101, 30.3549],
							[49.0976, 30.3479],
							[49.0921, 30.3465],
							[49.0887, 30.3474],
							[49.0854, 30.3579],
							[49.086, 30.3629],
							[49.089, 30.3629],
							[49.0876, 30.3676],
							[49.0788, 30.3754],
							[49.0868, 30.3768],
							[49.0935, 30.3721],
							[49.1053, 30.3693],
							[49.1085, 30.3704],
							[49.1126, 30.376],
							[49.1071, 30.3707],
							[49.0929, 30.3735],
							[49.0873, 30.3776],
							[49.0768, 30.3771],
							[49.0704, 30.3815],
							[49.0671, 30.381],
							[49.0626, 30.3771],
							[49.0576, 30.3771],
							[49.054, 30.3813],
							[49.0508, 30.3907],
							[49.0432, 30.3976],
							[49.0518, 30.4057],
							[49.061, 30.406],
						],
					],
					[
						[
							[49.0954, 30.4157],
							[49.0982, 30.4115],
							[49.1124, 30.4051],
							[49.1126, 30.4032],
							[49.106, 30.4013],
							[49.0935, 30.4029],
							[49.084, 30.4085],
							[49.0815, 30.4118],
							[49.0821, 30.4132],
							[49.0902, 30.4159],
							[49.0954, 30.4157],
						],
					],
					[
						[
							[49.1003, 30.4154],
							[49.1013, 30.4132],
							[49.0985, 30.4135],
							[49.0982, 30.4163],
							[49.1003, 30.4154],
						],
					],
					[
						[
							[49.0951, 30.5007],
							[49.0971, 30.4968],
							[49.1093, 30.4932],
							[49.111, 30.4901],
							[49.1087, 30.4865],
							[49.1043, 30.4868],
							[49.1018, 30.4851],
							[49.1057, 30.4779],
							[49.1037, 30.4668],
							[49.0968, 30.4746],
							[49.0801, 30.4829],
							[49.081, 30.4929],
							[49.0832, 30.4946],
							[49.0846, 30.4918],
							[49.0871, 30.494],
							[49.0868, 30.4954],
							[49.0838, 30.4954],
							[49.0835, 30.4974],
							[49.0915, 30.5018],
							[49.0951, 30.5007],
						],
					],
					[
						[
							[49.1921, 30.4631],
							[49.1993, 30.4599],
							[49.216, 30.456],
							[49.2151, 30.4471],
							[49.2187, 30.4401],
							[49.2201, 30.4401],
							[49.2215, 30.4354],
							[49.2196, 30.4296],
							[49.2154, 30.4296],
							[49.211, 30.4263],
							[49.2074, 30.426],
							[49.2065, 30.4276],
							[49.2032, 30.4235],
							[49.1985, 30.4226],
							[49.1988, 30.4257],
							[49.2035, 30.4279],
							[49.2038, 30.4299],
							[49.1985, 30.4263],
							[49.1915, 30.4265],
							[49.1932, 30.424],
							[49.1874, 30.4246],
							[49.1849, 30.4285],
							[49.186, 30.431],
							[49.1882, 30.4313],
							[49.191, 30.4301],
							[49.1929, 30.4304],
							[49.1829, 30.4335],
							[49.1807, 30.4374],
							[49.1768, 30.4357],
							[49.1768, 30.4376],
							[49.1801, 30.4396],
							[49.1774, 30.4396],
							[49.1776, 30.441],
							[49.1757, 30.4391],
							[49.1738, 30.4396],
							[49.1776, 30.4426],
							[49.176, 30.4463],
							[49.1746, 30.4463],
							[49.1804, 30.451],
							[49.1754, 30.4488],
							[49.1738, 30.451],
							[49.1738, 30.4549],
							[49.1855, 30.4629],
							[49.1921, 30.4631],
						],
					],
					[
						[
							[49.1213, 30.4957],
							[49.1251, 30.4921],
							[49.1367, 30.4899],
							[49.1393, 30.4879],
							[49.1365, 30.476],
							[49.1368, 30.4707],
							[49.1349, 30.4693],
							[49.1349, 30.4624],
							[49.141, 30.4485],
							[49.1365, 30.4482],
							[49.1352, 30.4499],
							[49.1296, 30.451],
							[49.1251, 30.4571],
							[49.1229, 30.4532],
							[49.1179, 30.4515],
							[49.1118, 30.456],
							[49.1065, 30.4626],
							[49.1063, 30.4751],
							[49.1101, 30.479],
							[49.1068, 30.4779],
							[49.1035, 30.4849],
							[49.1099, 30.4863],
							[49.1149, 30.491],
							[49.1174, 30.4965],
							[49.1213, 30.4957],
						],
					],
					[
						[
							[49.1626, 30.4946],
							[49.1604, 30.494],
							[49.1613, 30.4965],
							[49.1624, 30.496],
							[49.1626, 30.4946],
						],
					],
					[
						[
							[48.876, 30.2949],
							[48.8771, 30.2935],
							[48.8751, 30.2926],
							[48.8735, 30.2954],
							[48.876, 30.2949],
						],
					],
					[
						[
							[48.936, 30.3568],
							[48.9371, 30.3547],
							[48.936, 30.3505],
							[48.9313, 30.3401],
							[48.9318, 30.3338],
							[48.9351, 30.3254],
							[48.9351, 30.3176],
							[48.9321, 30.3107],
							[48.9263, 30.304],
							[48.9229, 30.3043],
							[48.9199, 30.3102],
							[48.9175, 30.3107],
							[48.9149, 30.3085],
							[48.9143, 30.3026],
							[48.909, 30.3035],
							[48.9038, 30.3074],
							[48.904, 30.3104],
							[48.9024, 30.311],
							[48.9012, 30.3146],
							[48.9041, 30.3149],
							[48.9035, 30.3124],
							[48.9054, 30.3115],
							[48.9087, 30.3191],
							[48.9087, 30.3221],
							[48.9059, 30.3254],
							[48.9126, 30.3301],
							[48.9129, 30.339],
							[48.911, 30.3385],
							[48.9107, 30.3338],
							[48.9082, 30.3354],
							[48.9063, 30.3335],
							[48.9007, 30.3343],
							[48.8988, 30.3371],
							[48.9007, 30.3404],
							[48.8999, 30.3449],
							[48.8963, 30.3443],
							[48.8957, 30.3415],
							[48.8943, 30.349],
							[48.9057, 30.3518],
							[48.9126, 30.3499],
							[48.9132, 30.354],
							[48.9157, 30.354],
							[48.9188, 30.3574],
							[48.936, 30.3568],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Kohgiluyehand Buyer Ahmad", name: "کهگیلویه و بویر احمد" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[50.5899, 30.1543],
							[50.5781, 30.1734],
							[50.5442, 30.1728],
							[50.5326, 30.1822],
							[50.5208, 30.2013],
							[50.4756, 30.2005],
							[50.464, 30.2099],
							[50.4299, 30.2189],
							[50.418, 30.2379],
							[50.413, 30.4243],
							[50.4014, 30.4339],
							[50.4011, 30.4438],
							[50.355, 30.4722],
							[50.3547, 30.4822],
							[50.3431, 30.4917],
							[50.3418, 30.5414],
							[50.375, 30.5722],
							[50.3975, 30.5828],
							[50.4083, 30.603],
							[50.4077, 30.6228],
							[50.4185, 30.643],
							[50.4176, 30.6728],
							[50.4059, 30.6823],
							[50.4056, 30.6922],
							[50.3705, 30.7209],
							[50.3592, 30.7205],
							[50.3475, 30.7301],
							[50.29, 30.7481],
							[50.2782, 30.7576],
							[50.232, 30.7758],
							[50.2207, 30.7754],
							[50.162, 30.8229],
							[50.1612, 30.8426],
							[50.1487, 30.8719],
							[50.1483, 30.8818],
							[50.1365, 30.8912],
							[50.1361, 30.9011],
							[50.1133, 30.9003],
							[50.0685, 30.8787],
							[50.0351, 30.8575],
							[50.0241, 30.8472],
							[49.9785, 30.8452],
							[49.9431, 30.8734],
							[49.9423, 30.8931],
							[49.9533, 30.9035],
							[49.963, 30.9436],
							[49.9622, 30.9633],
							[49.9732, 30.9737],
							[49.9723, 30.9934],
							[49.9596, 31.0226],
							[49.9587, 31.0423],
							[49.9349, 31.061],
							[49.934, 31.0807],
							[49.9216, 31.1],
							[49.9152, 31.2281],
							[49.9262, 31.2384],
							[49.9256, 31.2483],
							[49.9371, 31.2488],
							[49.9699, 31.2798],
							[50.0038, 31.2911],
							[50.0257, 31.3118],
							[50.0481, 31.3226],
							[50.1049, 31.3347],
							[50.1393, 31.3361],
							[50.1617, 31.3468],
							[50.1726, 31.3571],
							[50.1935, 31.3973],
							[50.2258, 31.4378],
							[50.2467, 31.4778],
							[50.2576, 31.4879],
							[50.3045, 31.4698],
							[50.3523, 31.4319],
							[50.3757, 31.4227],
							[50.4105, 31.4139],
							[50.4458, 31.3952],
							[50.4572, 31.3955],
							[50.492, 31.3866],
							[50.5605, 31.3883],
							[50.5714, 31.3984],
							[50.5833, 31.3889],
							[50.6066, 31.3796],
							[50.618, 31.3798],
							[50.6418, 31.3607],
							[50.6423, 31.3509],
							[50.6898, 31.3126],
							[50.6902, 31.3027],
							[50.7258, 31.274],
							[50.7491, 31.2646],
							[50.7727, 31.2453],
							[50.8086, 31.2066],
							[50.8205, 31.197],
							[50.8327, 31.1775],
							[50.8563, 31.1583],
							[50.8677, 31.1584],
							[50.9031, 31.1295],
							[50.938, 31.1103],
							[50.9607, 31.1106],
							[50.9839, 31.1011],
							[50.9952, 31.1013],
							[51.0062, 31.1113],
							[51.0516, 31.1119],
							[51.0751, 31.0925],
							[51.1433, 31.0933],
							[51.1542, 31.1033],
							[51.1648, 31.1231],
							[51.1758, 31.1331],
							[51.1863, 31.1529],
							[51.1977, 31.153],
							[51.2086, 31.163],
							[51.2314, 31.1632],
							[51.2537, 31.1733],
							[51.2877, 31.1737],
							[51.3108, 31.1641],
							[51.2998, 31.1541],
							[51.3032, 31.0655],
							[51.3394, 31.0067],
							[51.351, 30.9969],
							[51.3751, 30.9577],
							[51.5147, 30.8405],
							[51.549, 30.8309],
							[51.5603, 30.8309],
							[51.5835, 30.8114],
							[51.6177, 30.8017],
							[51.618, 30.7918],
							[51.6412, 30.7723],
							[51.6533, 30.7427],
							[51.6657, 30.7033],
							[51.6662, 30.6835],
							[51.6788, 30.6342],
							[51.6796, 30.6046],
							[51.6683, 30.6046],
							[51.6688, 30.5848],
							[51.6575, 30.5847],
							[51.6577, 30.5749],
							[51.6354, 30.5648],
							[51.6243, 30.5549],
							[51.602, 30.5449],
							[51.5684, 30.5347],
							[51.5458, 30.5346],
							[51.5234, 30.5245],
							[51.5008, 30.5243],
							[51.4777, 30.5439],
							[51.4549, 30.5536],
							[51.4436, 30.5535],
							[51.432, 30.5633],
							[51.4096, 30.5532],
							[51.3983, 30.5531],
							[51.3868, 30.5629],
							[51.386, 30.5926],
							[51.3747, 30.5925],
							[51.3631, 30.6023],
							[51.3178, 30.6018],
							[51.3176, 30.6117],
							[51.2831, 30.6311],
							[51.2602, 30.6407],
							[51.2486, 30.6505],
							[51.2027, 30.6698],
							[51.1232, 30.6687],
							[51.1121, 30.6587],
							[51.1123, 30.6488],
							[51.1237, 30.6489],
							[51.1466, 30.6393],
							[51.1471, 30.6195],
							[51.1363, 30.5996],
							[51.137, 30.5698],
							[51.1717, 30.5406],
							[51.1726, 30.5009],
							[51.1958, 30.4813],
							[51.1962, 30.4615],
							[51.1848, 30.4613],
							[51.1737, 30.4512],
							[51.1625, 30.4411],
							[51.1627, 30.4311],
							[51.1743, 30.4213],
							[51.2087, 30.402],
							[51.2102, 30.3223],
							[51.1988, 30.3221],
							[51.199, 30.3121],
							[51.1878, 30.302],
							[51.1428, 30.2813],
							[51.0979, 30.2506],
							[51.064, 30.2401],
							[51.0529, 30.2299],
							[51.0188, 30.2293],
							[51.0073, 30.2391],
							[51.0075, 30.2291],
							[50.9739, 30.1987],
							[50.9516, 30.1786],
							[50.9291, 30.1684],
							[50.9178, 30.1683],
							[50.884, 30.158],
							[50.8613, 30.1578],
							[50.8384, 30.1673],
							[50.8271, 30.1671],
							[50.8273, 30.1574],
							[50.7934, 30.157],
							[50.7592, 30.1663],
							[50.7594, 30.1566],
							[50.7481, 30.1564],
							[50.7365, 30.166],
							[50.7023, 30.1752],
							[50.691, 30.1751],
							[50.68, 30.1652],
							[50.6576, 30.1553],
							[50.6012, 30.1545],
							[50.6015, 30.1449],
							[50.5899, 30.1543],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Kordestan", name: "کردستان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[47.1772, 34.9604],
							[47.1643, 34.9701],
							[47.1518, 34.9696],
							[47.1389, 34.9793],
							[47.1014, 34.9778],
							[47.0896, 34.9671],
							[47.0909, 34.9468],
							[47.1042, 34.927],
							[47.1049, 34.9169],
							[47.0933, 34.9063],
							[47.0817, 34.8958],
							[47.0574, 34.8949],
							[47.0446, 34.9046],
							[47.0203, 34.9037],
							[47.0087, 34.8931],
							[47.0099, 34.8729],
							[47.0227, 34.8632],
							[47.0232, 34.8531],
							[47.036, 34.8435],
							[47.0128, 34.8223],
							[46.9399, 34.8192],
							[46.9169, 34.7979],
							[46.9296, 34.7883],
							[46.9302, 34.7781],
							[46.9429, 34.7685],
							[46.9435, 34.7584],
							[46.932, 34.7477],
							[46.8363, 34.7431],
							[46.8253, 34.7325],
							[46.8025, 34.7316],
							[46.7537, 34.7702],
							[46.7424, 34.7698],
							[46.7433, 34.7597],
							[46.7329, 34.7492],
							[46.7216, 34.7488],
							[46.7111, 34.7383],
							[46.6772, 34.7369],
							[46.665, 34.7465],
							[46.6536, 34.746],
							[46.6527, 34.756],
							[46.6405, 34.7656],
							[46.6378, 34.7958],
							[46.6473, 34.8165],
							[46.6577, 34.827],
							[46.6568, 34.8371],
							[46.6672, 34.8476],
							[46.655, 34.8572],
							[46.654, 34.8673],
							[46.6201, 34.8658],
							[46.6096, 34.8552],
							[46.5974, 34.8648],
							[46.586, 34.8642],
							[46.5851, 34.8743],
							[46.5321, 34.9525],
							[46.5064, 34.9815],
							[46.4663, 35.0399],
							[46.429, 35.0679],
							[46.4156, 35.0873],
							[46.3895, 35.1159],
							[46.3389, 35.1532],
							[46.3123, 35.1821],
							[46.2995, 35.1916],
							[46.2253, 35.2281],
							[46.1759, 35.2656],
							[46.153, 35.2774],
							[46.156, 35.2796],
							[46.1556, 35.2867],
							[46.1435, 35.3106],
							[46.1304, 35.3225],
							[46.1294, 35.3266],
							[46.1312, 35.3322],
							[46.1269, 35.3395],
							[46.1142, 35.3439],
							[46.0995, 35.3446],
							[46.0883, 35.3575],
							[46.0737, 35.3636],
							[46.0704, 35.3663],
							[46.0611, 35.3794],
							[46.0509, 35.3834],
							[46.029, 35.4246],
							[46.0099, 35.4464],
							[46.0, 35.4804],
							[45.9893, 35.4996],
							[45.9924, 35.5086],
							[45.9978, 35.514],
							[46.0112, 35.5173],
							[46.0127, 35.5222],
							[46.0, 35.5472],
							[45.9832, 35.5661],
							[45.978, 35.5746],
							[45.9798, 35.5767],
							[45.987, 35.5771],
							[46.0, 35.5741],
							[46.0055, 35.5694],
							[46.0133, 35.5698],
							[46.0181, 35.5733],
							[46.0166, 35.5772],
							[46.0077, 35.5854],
							[46.0122, 35.5983],
							[46.0135, 35.6115],
							[46.012, 35.6254],
							[46.0, 35.6346],
							[46.0, 35.6378],
							[46.0038, 35.6419],
							[46.009, 35.6535],
							[46.0081, 35.6667],
							[46.0151, 35.6798],
							[46.0472, 35.6962],
							[46.0549, 35.698],
							[46.0886, 35.6841],
							[46.1037, 35.685],
							[46.1342, 35.6939],
							[46.1551, 35.6873],
							[46.1684, 35.6926],
							[46.1726, 35.6968],
							[46.1799, 35.7101],
							[46.1886, 35.7106],
							[46.2016, 35.7194],
							[46.2064, 35.7201],
							[46.2322, 35.7117],
							[46.2392, 35.7123],
							[46.2534, 35.721],
							[46.2795, 35.7296],
							[46.2827, 35.735],
							[46.2822, 35.7433],
							[46.3002, 35.747],
							[46.3037, 35.7562],
							[46.3058, 35.7575],
							[46.3218, 35.7588],
							[46.3306, 35.7646],
							[46.3342, 35.7719],
							[46.3484, 35.7884],
							[46.3489, 35.8091],
							[46.3451, 35.8162],
							[46.3397, 35.821],
							[46.3122, 35.8252],
							[46.2945, 35.8313],
							[46.2908, 35.8308],
							[46.273, 35.8149],
							[46.2679, 35.8121],
							[46.2522, 35.81],
							[46.2459, 35.8115],
							[46.2301, 35.8109],
							[46.2277, 35.81],
							[46.2157, 35.7976],
							[46.2108, 35.7957],
							[46.2032, 35.7997],
							[46.1748, 35.798],
							[46.1605, 35.8004],
							[46.1564, 35.8018],
							[46.145, 35.8131],
							[46.1354, 35.8282],
							[46.1352, 35.8314],
							[46.1436, 35.8417],
							[46.1422, 35.8442],
							[46.1167, 35.842],
							[46.1017, 35.8559],
							[46.0933, 35.8607],
							[46.0869, 35.8601],
							[46.0638, 35.8523],
							[46.0496, 35.8503],
							[46.0328, 35.8362],
							[46.0254, 35.8332],
							[46.0148, 35.8322],
							[46.0037, 35.8331],
							[46.0, 35.8375],
							[45.9754, 35.8426],
							[45.9641, 35.8433],
							[45.9568, 35.8397],
							[45.9518, 35.833],
							[45.9407, 35.8245],
							[45.908, 35.8377],
							[45.8968, 35.8394],
							[45.8864, 35.8373],
							[45.8803, 35.8321],
							[45.8706, 35.8143],
							[45.8493, 35.8166],
							[45.825, 35.8117],
							[45.815, 35.8142],
							[45.7989, 35.8226],
							[45.7808, 35.8117],
							[45.7732, 35.803],
							[45.7628, 35.8009],
							[45.7574, 35.804],
							[45.7284, 35.8446],
							[45.7141, 35.8732],
							[45.7027, 35.884],
							[45.6968, 35.8936],
							[45.6729, 35.9053],
							[45.6693, 35.9147],
							[45.6611, 35.9245],
							[45.6463, 35.9355],
							[45.6425, 35.9441],
							[45.6446, 35.9492],
							[45.6312, 35.9591],
							[45.6052, 35.9624],
							[45.6016, 35.9668],
							[45.6012, 35.9792],
							[45.5975, 35.9817],
							[45.5781, 35.9828],
							[45.5737, 35.9844],
							[45.5616, 35.9987],
							[45.5568, 36.0013],
							[45.5483, 36.002],
							[45.5296, 35.997],
							[45.5187, 35.999],
							[45.5019, 36.006],
							[45.542, 36.0205],
							[45.5517, 36.0309],
							[45.5627, 36.0314],
							[45.5819, 36.0525],
							[45.5751, 36.1023],
							[45.6308, 36.1064],
							[45.6296, 36.1164],
							[45.6498, 36.1383],
							[45.6486, 36.1483],
							[45.6599, 36.1492],
							[45.6574, 36.1692],
							[45.6449, 36.1783],
							[45.6424, 36.1983],
							[45.6877, 36.2021],
							[45.6979, 36.2131],
							[45.6954, 36.2332],
							[45.6828, 36.2423],
							[45.6816, 36.2523],
							[45.7156, 36.2547],
							[45.748, 36.2662],
							[45.8148, 36.2682],
							[45.8825, 36.2598],
							[45.9045, 36.26],
							[45.9139, 36.2693],
							[45.9252, 36.2695],
							[45.947, 36.2895],
							[45.9443, 36.3183],
							[45.9294, 36.3463],
							[45.9256, 36.3845],
							[45.9481, 36.4051],
							[45.9471, 36.4147],
							[45.9583, 36.425],
							[46.0051, 36.4476],
							[46.0406, 36.4599],
							[46.1293, 36.4661],
							[46.143, 36.4574],
							[46.1695, 36.4495],
							[46.2241, 36.4144],
							[46.2369, 36.4153],
							[46.2506, 36.4065],
							[46.2634, 36.4073],
							[46.3259, 36.4307],
							[46.3515, 36.4323],
							[46.378, 36.4243],
							[46.4546, 36.4291],
							[46.5192, 36.4233],
							[46.6336, 36.4302],
							[46.6707, 36.4421],
							[46.696, 36.4436],
							[46.7204, 36.4548],
							[46.7583, 36.457],
							[46.7826, 36.4682],
							[46.7952, 36.469],
							[46.8087, 36.4599],
							[46.8213, 36.4606],
							[46.8222, 36.4509],
							[46.8356, 36.4418],
							[46.8373, 36.4222],
							[46.8499, 36.4229],
							[46.8508, 36.4131],
							[46.8768, 36.4046],
							[46.8902, 36.3955],
							[46.891, 36.3856],
							[46.9177, 36.3673],
							[46.9302, 36.3679],
							[46.9436, 36.3587],
							[46.9804, 36.3705],
							[46.9937, 36.3613],
							[47.0187, 36.3625],
							[47.0445, 36.3539],
							[47.0703, 36.3453],
							[47.0836, 36.336],
							[47.146, 36.339],
							[47.1951, 36.3514],
							[47.2574, 36.3544],
							[47.2527, 36.414],
							[47.2643, 36.4246],
							[47.2768, 36.4252],
							[47.2884, 36.4358],
							[47.2876, 36.4457],
							[47.2611, 36.4643],
							[47.2727, 36.4748],
							[47.2718, 36.4848],
							[47.2834, 36.4953],
							[47.3075, 36.5065],
							[47.3324, 36.5078],
							[47.3465, 36.4885],
							[47.3598, 36.4792],
							[47.3607, 36.4693],
							[47.3739, 36.4599],
							[47.4112, 36.4617],
							[47.4725, 36.4747],
							[47.5098, 36.4765],
							[47.5355, 36.4676],
							[47.575, 36.4393],
							[47.5874, 36.4399],
							[47.6378, 36.4321],
							[47.675, 36.4337],
							[47.7632, 36.4173],
							[47.8506, 36.4108],
							[47.8637, 36.4012],
							[47.8761, 36.4017],
							[47.8892, 36.3921],
							[47.8906, 36.3718],
							[47.9029, 36.3723],
							[47.9043, 36.3521],
							[47.9167, 36.3526],
							[47.9187, 36.3222],
							[47.9324, 36.3024],
							[47.9201, 36.3019],
							[47.9207, 36.2918],
							[47.9344, 36.272],
							[47.9364, 36.2416],
							[47.9494, 36.2319],
							[47.963, 36.212],
							[47.9649, 36.1816],
							[47.9778, 36.1718],
							[47.9809, 36.1211],
							[47.9692, 36.1105],
							[47.9698, 36.1003],
							[47.9587, 36.0796],
							[47.9634, 35.9984],
							[47.9282, 35.9668],
							[47.9041, 35.9559],
							[47.9047, 35.9458],
							[47.8806, 35.9348],
							[47.8823, 35.9044],
							[47.8952, 35.8946],
							[47.8958, 35.8845],
							[47.9081, 35.8849],
							[47.9333, 35.8755],
							[48.0093, 35.837],
							[48.0344, 35.8276],
							[48.1103, 35.7889],
							[48.1732, 35.7601],
							[48.186, 35.7502],
							[48.1865, 35.7401],
							[48.1987, 35.7404],
							[48.2115, 35.7306],
							[48.2125, 35.7102],
							[48.2257, 35.6903],
							[48.2135, 35.6899],
							[48.2007, 35.6998],
							[48.1502, 35.7289],
							[48.075, 35.7574],
							[48.0125, 35.7761],
							[47.9879, 35.7754],
							[47.9889, 35.7551],
							[48.0018, 35.7453],
							[48.0397, 35.726],
							[48.0647, 35.7165],
							[48.0903, 35.6968],
							[48.0908, 35.6866],
							[48.0785, 35.6863],
							[48.0913, 35.6765],
							[48.1051, 35.6464],
							[48.1179, 35.6365],
							[48.1183, 35.6264],
							[48.1066, 35.6159],
							[48.0943, 35.6156],
							[48.057, 35.6248],
							[48.0447, 35.6244],
							[47.9976, 35.5826],
							[47.9853, 35.5822],
							[47.9858, 35.5721],
							[47.9489, 35.5711],
							[47.9234, 35.5907],
							[47.9229, 35.6009],
							[47.9101, 35.6107],
							[47.908, 35.6512],
							[47.8824, 35.6708],
							[47.8701, 35.6705],
							[47.8583, 35.66],
							[47.8588, 35.6498],
							[47.8352, 35.6289],
							[47.8229, 35.6285],
							[47.8111, 35.618],
							[47.7988, 35.6177],
							[47.8008, 35.5772],
							[47.8393, 35.5479],
							[47.8526, 35.528],
							[47.8786, 35.4983],
							[47.8924, 35.4682],
							[47.9056, 35.4483],
							[47.9475, 35.3381],
							[47.9612, 35.3081],
							[47.9616, 35.298],
							[47.987, 35.2785],
							[48.012, 35.269],
							[48.0251, 35.2492],
							[48.0624, 35.2401],
							[48.0751, 35.2304],
							[48.0873, 35.2307],
							[48.1127, 35.2112],
							[48.1258, 35.1914],
							[48.1384, 35.1816],
							[48.1646, 35.142],
							[48.1662, 35.1017],
							[48.144, 35.0405],
							[48.1209, 34.9995],
							[48.0974, 34.9685],
							[48.0978, 34.9584],
							[48.0855, 34.9581],
							[48.0735, 34.9476],
							[48.0613, 34.9473],
							[48.0107, 34.9862],
							[48.0226, 34.9966],
							[48.0215, 35.0269],
							[48.0089, 35.0366],
							[47.9966, 35.0363],
							[47.9846, 35.0258],
							[47.9857, 34.9955],
							[47.9737, 34.9851],
							[47.9741, 34.975],
							[47.9621, 34.9646],
							[47.9375, 34.9639],
							[47.9248, 34.9736],
							[47.8998, 34.983],
							[47.8751, 34.9823],
							[47.8511, 34.9614],
							[47.8515, 34.9513],
							[47.8271, 34.9405],
							[47.8024, 34.9398],
							[47.7897, 34.9495],
							[47.7773, 34.9492],
							[47.7389, 34.9886],
							[47.7138, 34.998],
							[47.7135, 35.0081],
							[47.6625, 35.0472],
							[47.6622, 35.0573],
							[47.6364, 35.0869],
							[47.636, 35.097],
							[47.6105, 35.1166],
							[47.5484, 35.1147],
							[47.5357, 35.1245],
							[47.5104, 35.1339],
							[47.4849, 35.1533],
							[47.4717, 35.1731],
							[47.4589, 35.1829],
							[47.4215, 35.1817],
							[47.4226, 35.1514],
							[47.4354, 35.1417],
							[47.4485, 35.1218],
							[47.4489, 35.1117],
							[47.4617, 35.102],
							[47.462, 35.0919],
							[47.4498, 35.0814],
							[47.4502, 35.0713],
							[47.438, 35.0608],
							[47.388, 35.0591],
							[47.3376, 35.0676],
							[47.2749, 35.0654],
							[47.2627, 35.0549],
							[47.2629, 35.0448],
							[47.2504, 35.0443],
							[47.2512, 35.0139],
							[47.2644, 34.9941],
							[47.2646, 34.984],
							[47.2523, 34.9734],
							[47.2526, 34.9632],
							[47.2275, 34.9623],
							[47.2026, 34.9512],
							[47.1901, 34.9508],
							[47.1772, 34.9604],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Lorestan", name: "لرستان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[49.9479, 33.3204],
							[49.9367, 33.3003],
							[49.9251, 33.29],
							[49.9257, 33.2704],
							[49.9035, 33.2303],
							[49.881, 33.2],
							[49.8815, 33.1901],
							[49.8701, 33.1799],
							[49.8716, 33.1504],
							[49.8602, 33.1402],
							[49.8607, 33.1304],
							[49.7897, 33.1276],
							[49.7665, 33.1169],
							[49.7552, 33.1066],
							[49.731, 33.1155],
							[49.6954, 33.1141],
							[49.6841, 33.1038],
							[49.6852, 33.0842],
							[49.6739, 33.0739],
							[49.662, 33.0734],
							[49.627, 33.0622],
							[49.6157, 33.0519],
							[49.6181, 33.0126],
							[49.6443, 32.9743],
							[49.6468, 32.9351],
							[49.6356, 32.9248],
							[49.6237, 32.9243],
							[49.6013, 32.9036],
							[49.5895, 32.9032],
							[49.5664, 32.8923],
							[49.5308, 32.8909],
							[49.5077, 32.88],
							[49.4958, 32.8795],
							[49.4846, 32.8692],
							[49.4727, 32.8687],
							[49.4622, 32.8486],
							[49.4503, 32.8481],
							[49.4533, 32.8088],
							[49.4407, 32.8181],
							[49.4176, 32.8073],
							[49.3591, 32.795],
							[49.3361, 32.7841],
							[49.2768, 32.7816],
							[49.2522, 32.7903],
							[49.2157, 32.7986],
							[49.1776, 32.8265],
							[49.1258, 32.8735],
							[49.0884, 32.8915],
							[49.0375, 32.9287],
							[49.0128, 32.9375],
							[48.9992, 32.9566],
							[48.8678, 32.9507],
							[48.8184, 32.9682],
							[48.7945, 32.9671],
							[48.7594, 32.9556],
							[48.7474, 32.955],
							[48.7092, 32.9827],
							[48.6956, 33.0018],
							[48.6829, 33.011],
							[48.6821, 33.0209],
							[48.6566, 33.0393],
							[48.6447, 33.0388],
							[48.6319, 33.048],
							[48.5721, 33.045],
							[48.5235, 33.0525],
							[48.4875, 33.0507],
							[48.4764, 33.0402],
							[48.4061, 33.017],
							[48.2625, 33.0101],
							[48.2257, 33.0183],
							[48.1428, 33.0048],
							[48.1069, 33.0032],
							[48.0958, 32.9928],
							[48.0967, 32.9829],
							[48.0856, 32.9725],
							[48.0753, 32.9522],
							[48.0779, 32.9225],
							[48.0575, 32.8818],
							[48.0263, 32.8307],
							[48.0153, 32.8203],
							[48.0163, 32.8105],
							[48.0062, 32.7903],
							[48.0071, 32.7805],
							[47.9873, 32.7403],
							[47.9794, 32.7006],
							[47.9803, 32.6907],
							[47.9706, 32.6708],
							[47.9716, 32.661],
							[47.96, 32.6607],
							[47.9289, 32.6309],
							[47.9176, 32.6308],
							[47.9076, 32.621],
							[47.9088, 32.6113],
							[47.8988, 32.6015],
							[47.8853, 32.621],
							[47.8742, 32.621],
							[47.8595, 32.6501],
							[47.8437, 32.689],
							[47.8143, 32.7473],
							[47.8108, 32.7765],
							[47.7838, 32.8154],
							[47.7592, 32.8348],
							[47.758, 32.8446],
							[47.731, 32.8835],
							[47.7298, 32.8932],
							[47.7128, 32.9418],
							[47.6993, 32.9613],
							[47.687, 32.971],
							[47.6858, 32.9807],
							[47.66, 33.0098],
							[47.5897, 33.0388],
							[47.5774, 33.0485],
							[47.5539, 33.0581],
							[47.4801, 33.1161],
							[47.4789, 33.1259],
							[47.4073, 33.1644],
							[47.2842, 33.2607],
							[47.2618, 33.2606],
							[47.169, 33.2888],
							[47.1455, 33.2982],
							[47.1343, 33.2981],
							[47.0728, 33.3457],
							[47.0148, 33.3636],
							[47.0038, 33.3632],
							[47.0026, 33.373],
							[46.9904, 33.3824],
							[46.977, 33.4015],
							[46.9648, 33.4108],
							[46.9637, 33.4206],
							[46.9515, 33.4298],
							[46.9404, 33.4293],
							[46.926, 33.4581],
							[46.8866, 33.5148],
							[46.8624, 33.533],
							[46.8361, 33.5706],
							[46.8351, 33.5803],
							[46.8452, 33.5906],
							[46.8675, 33.5919],
							[46.888, 33.6122],
							[46.8861, 33.6314],
							[46.8944, 33.6606],
							[46.8935, 33.6702],
							[46.9038, 33.6801],
							[46.9376, 33.6812],
							[46.9367, 33.6907],
							[46.9779, 33.7302],
							[47.0342, 33.7314],
							[47.0445, 33.7412],
							[47.0661, 33.7512],
							[47.0774, 33.7514],
							[47.0876, 33.7612],
							[47.1111, 33.752],
							[47.1214, 33.7617],
							[47.1326, 33.7619],
							[47.1541, 33.7718],
							[47.199, 33.7725],
							[47.2224, 33.7632],
							[47.2673, 33.7639],
							[47.2776, 33.7736],
							[47.2992, 33.7836],
							[47.3097, 33.7934],
							[47.3197, 33.813],
							[47.3313, 33.8133],
							[47.3423, 33.8232],
							[47.3415, 33.8329],
							[47.3644, 33.8432],
							[47.3994, 33.854],
							[47.3987, 33.8637],
							[47.4106, 33.8641],
							[47.4099, 33.8738],
							[47.4212, 33.884],
							[47.4318, 33.9038],
							[47.4305, 33.9231],
							[47.4418, 33.9333],
							[47.4411, 33.9429],
							[47.4284, 33.9522],
							[47.4264, 33.9812],
							[47.4123, 34.0097],
							[47.3989, 34.0286],
							[47.3982, 34.0383],
							[47.3862, 34.0379],
							[47.421, 34.0584],
							[47.419, 34.0873],
							[47.4069, 34.0869],
							[47.3928, 34.1154],
							[47.3793, 34.1343],
							[47.3772, 34.1632],
							[47.4243, 34.184],
							[47.5696, 34.1886],
							[47.5932, 34.1989],
							[47.5901, 34.2473],
							[47.5768, 34.2665],
							[47.5731, 34.3359],
							[47.5847, 34.3462],
							[47.5842, 34.3562],
							[47.6571, 34.3585],
							[47.6819, 34.3493],
							[47.7184, 34.3506],
							[47.7816, 34.3027],
							[47.7825, 34.2829],
							[47.8201, 34.2643],
							[47.8455, 34.2454],
							[47.8704, 34.2364],
							[47.9214, 34.1992],
							[47.9464, 34.1903],
							[47.9592, 34.1811],
							[47.9715, 34.1815],
							[47.9842, 34.1722],
							[48.0338, 34.1641],
							[48.0588, 34.1553],
							[48.0711, 34.1557],
							[48.1093, 34.1279],
							[48.1224, 34.109],
							[48.1986, 34.0535],
							[48.2844, 34.0565],
							[48.2971, 34.0473],
							[48.3584, 34.0496],
							[48.371, 34.0404],
							[48.4322, 34.0428],
							[48.4448, 34.0336],
							[48.4571, 34.0341],
							[48.4823, 34.0158],
							[48.5068, 34.0168],
							[48.5064, 34.0264],
							[48.5182, 34.0365],
							[48.5178, 34.0462],
							[48.565, 34.0866],
							[48.5772, 34.0871],
							[48.5898, 34.078],
							[48.6751, 34.0816],
							[48.6987, 34.1019],
							[48.7108, 34.1024],
							[48.7234, 34.0933],
							[48.7368, 34.0651],
							[48.7745, 34.0379],
							[48.7866, 34.0384],
							[48.7992, 34.0294],
							[48.8482, 34.022],
							[48.8729, 34.0135],
							[48.8971, 34.0146],
							[48.9097, 34.0055],
							[48.9339, 34.0066],
							[48.9589, 33.9886],
							[48.9711, 33.9891],
							[48.9593, 33.979],
							[48.9472, 33.9784],
							[48.9359, 33.9587],
							[48.9363, 33.9491],
							[48.9488, 33.94],
							[48.9492, 33.9304],
							[48.9618, 33.9214],
							[48.9622, 33.9118],
							[48.9747, 33.9027],
							[48.963, 33.8926],
							[48.9638, 33.8733],
							[48.952, 33.8631],
							[48.9294, 33.8235],
							[48.9298, 33.8139],
							[48.9181, 33.8037],
							[48.9189, 33.7845],
							[48.9076, 33.7646],
							[48.9092, 33.7261],
							[48.9213, 33.7266],
							[48.9452, 33.7374],
							[48.9694, 33.7385],
							[48.9706, 33.7095],
							[48.9831, 33.7004],
							[49.0558, 33.7036],
							[49.0808, 33.6854],
							[49.105, 33.6864],
							[49.1288, 33.6972],
							[49.1408, 33.6977],
							[49.1658, 33.6794],
							[49.2021, 33.6809],
							[49.2017, 33.6906],
							[49.2254, 33.7013],
							[49.2488, 33.7217],
							[49.2729, 33.7227],
							[49.2846, 33.7329],
							[49.2833, 33.7619],
							[49.295, 33.7721],
							[49.2946, 33.7817],
							[49.3063, 33.7919],
							[49.3043, 33.8402],
							[49.3159, 33.8503],
							[49.3641, 33.8524],
							[49.3887, 33.8437],
							[49.4128, 33.8448],
							[49.4124, 33.8544],
							[49.4236, 33.8742],
							[49.4352, 33.8844],
							[49.4473, 33.8849],
							[49.4589, 33.895],
							[49.483, 33.896],
							[49.5075, 33.8874],
							[49.5199, 33.8782],
							[49.5327, 33.8594],
							[49.5451, 33.8503],
							[49.5707, 33.8126],
							[49.5831, 33.8034],
							[49.5835, 33.7937],
							[49.5963, 33.7749],
							[49.5847, 33.7647],
							[49.5855, 33.7453],
							[49.5627, 33.7153],
							[49.5639, 33.6862],
							[49.601, 33.6586],
							[49.6014, 33.6489],
							[49.6134, 33.6493],
							[49.6258, 33.6401],
							[49.6377, 33.6406],
							[49.6741, 33.6323],
							[49.6864, 33.6231],
							[49.7107, 33.6143],
							[49.7118, 33.5851],
							[49.7365, 33.5666],
							[49.7604, 33.5676],
							[49.772, 33.5778],
							[49.7709, 33.607],
							[49.782, 33.6269],
							[49.7928, 33.6565],
							[49.8044, 33.6667],
							[49.8163, 33.6672],
							[49.8279, 33.6773],
							[49.8398, 33.6778],
							[49.8521, 33.6686],
							[49.854, 33.62],
							[49.9136, 33.6223],
							[49.914, 33.6125],
							[49.9267, 33.5935],
							[49.9277, 33.5643],
							[49.9165, 33.5443],
							[49.9169, 33.5346],
							[49.9292, 33.5253],
							[49.9411, 33.5257],
							[49.953, 33.5262],
							[49.9645, 33.5364],
							[49.9764, 33.5368],
							[49.9767, 33.5271],
							[49.9893, 33.508],
							[50.0015, 33.4987],
							[49.9897, 33.4983],
							[49.9669, 33.4681],
							[49.9438, 33.4476],
							[49.9444, 33.4281],
							[49.9566, 33.4187],
							[49.9804, 33.4196],
							[49.9926, 33.4103],
							[49.9939, 33.3711],
							[49.9826, 33.3511],
							[49.9479, 33.3204],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Markazi", name: "مرکزی" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[50.3985, 33.6685],
							[50.3752, 33.658],
							[50.2926, 33.6553],
							[50.2338, 33.6436],
							[50.1172, 33.5908],
							[50.1057, 33.5807],
							[50.0589, 33.5594],
							[50.0127, 33.5187],
							[50.0015, 33.4987],
							[49.9893, 33.508],
							[49.9767, 33.5271],
							[49.9764, 33.5368],
							[49.9645, 33.5364],
							[49.953, 33.5262],
							[49.9411, 33.5257],
							[49.9292, 33.5253],
							[49.9169, 33.5346],
							[49.9165, 33.5443],
							[49.9277, 33.5643],
							[49.9267, 33.5935],
							[49.914, 33.6125],
							[49.9136, 33.6223],
							[49.854, 33.62],
							[49.8521, 33.6686],
							[49.8398, 33.6778],
							[49.8279, 33.6773],
							[49.8163, 33.6672],
							[49.8044, 33.6667],
							[49.7928, 33.6565],
							[49.782, 33.6269],
							[49.7709, 33.607],
							[49.772, 33.5778],
							[49.7604, 33.5676],
							[49.7365, 33.5666],
							[49.7118, 33.5851],
							[49.7107, 33.6143],
							[49.6864, 33.6231],
							[49.6741, 33.6323],
							[49.6377, 33.6406],
							[49.6258, 33.6401],
							[49.6134, 33.6493],
							[49.6014, 33.6489],
							[49.601, 33.6586],
							[49.5639, 33.6862],
							[49.5627, 33.7153],
							[49.5855, 33.7453],
							[49.5847, 33.7647],
							[49.5963, 33.7749],
							[49.5835, 33.7937],
							[49.5831, 33.8034],
							[49.5707, 33.8126],
							[49.5451, 33.8503],
							[49.5327, 33.8594],
							[49.5199, 33.8782],
							[49.5075, 33.8874],
							[49.483, 33.896],
							[49.4589, 33.895],
							[49.4473, 33.8849],
							[49.4352, 33.8844],
							[49.4236, 33.8742],
							[49.4124, 33.8544],
							[49.4128, 33.8448],
							[49.3887, 33.8437],
							[49.3641, 33.8524],
							[49.3159, 33.8503],
							[49.3043, 33.8402],
							[49.3063, 33.7919],
							[49.2946, 33.7817],
							[49.295, 33.7721],
							[49.2833, 33.7619],
							[49.2846, 33.7329],
							[49.2729, 33.7227],
							[49.2488, 33.7217],
							[49.2254, 33.7013],
							[49.2017, 33.6906],
							[49.2021, 33.6809],
							[49.1658, 33.6794],
							[49.1408, 33.6977],
							[49.1288, 33.6972],
							[49.105, 33.6864],
							[49.0808, 33.6854],
							[49.0558, 33.7036],
							[48.9831, 33.7004],
							[48.9706, 33.7095],
							[48.9694, 33.7385],
							[48.9452, 33.7374],
							[48.9213, 33.7266],
							[48.9092, 33.7261],
							[48.9076, 33.7646],
							[48.9189, 33.7845],
							[48.9181, 33.8037],
							[48.9298, 33.8139],
							[48.9294, 33.8235],
							[48.952, 33.8631],
							[48.9638, 33.8733],
							[48.963, 33.8926],
							[48.9747, 33.9027],
							[48.9622, 33.9118],
							[48.9618, 33.9214],
							[48.9492, 33.9304],
							[48.9488, 33.94],
							[48.9363, 33.9491],
							[48.9359, 33.9587],
							[48.9472, 33.9784],
							[48.9593, 33.979],
							[48.9711, 33.9891],
							[48.9824, 34.0089],
							[49.0058, 34.0292],
							[49.0296, 34.0399],
							[49.0413, 34.05],
							[49.0534, 34.0506],
							[49.0768, 34.0709],
							[49.0889, 34.0714],
							[49.0881, 34.0906],
							[49.0998, 34.1007],
							[49.0994, 34.1103],
							[49.0861, 34.1385],
							[49.0857, 34.148],
							[49.0736, 34.1475],
							[49.0698, 34.2539],
							[49.0568, 34.2828],
							[49.0565, 34.2927],
							[49.0438, 34.3118],
							[49.0067, 34.3398],
							[48.9938, 34.369],
							[48.9812, 34.3883],
							[48.9688, 34.3977],
							[48.9676, 34.4474],
							[48.9786, 34.4979],
							[48.99, 34.5284],
							[48.9891, 34.5583],
							[48.9643, 34.5772],
							[48.928, 34.5756],
							[48.9156, 34.585],
							[48.891, 34.594],
							[48.8789, 34.5935],
							[48.8665, 34.6029],
							[48.8655, 34.6329],
							[48.8773, 34.6434],
							[48.8898, 34.6339],
							[48.9019, 34.6344],
							[48.9268, 34.6155],
							[48.9752, 34.6176],
							[48.9991, 34.6286],
							[49.0112, 34.6291],
							[49.0732, 34.582],
							[49.0977, 34.5731],
							[49.0974, 34.583],
							[49.1088, 34.6035],
							[49.0964, 34.6129],
							[49.0958, 34.6328],
							[49.0831, 34.6522],
							[49.081, 34.712],
							[49.0679, 34.7414],
							[49.0672, 34.7614],
							[49.0907, 34.7824],
							[49.139, 34.7845],
							[49.1386, 34.7944],
							[49.1503, 34.8049],
							[49.15, 34.8149],
							[49.1379, 34.8144],
							[49.1244, 34.8538],
							[49.1115, 34.8732],
							[49.1097, 34.9231],
							[49.1214, 34.9336],
							[49.1335, 34.9341],
							[49.1584, 34.9152],
							[49.3272, 34.9226],
							[49.3517, 34.9137],
							[49.4359, 34.9175],
							[49.4483, 34.9081],
							[49.4487, 34.8982],
							[49.437, 34.8877],
							[49.4374, 34.8777],
							[49.4257, 34.8672],
							[49.4144, 34.8467],
							[49.4148, 34.8368],
							[49.4031, 34.8263],
							[49.4046, 34.7865],
							[49.417, 34.7771],
							[49.4173, 34.7672],
							[49.4297, 34.7578],
							[49.4417, 34.7584],
							[49.4534, 34.7689],
							[49.4782, 34.7502],
							[49.4909, 34.7309],
							[49.5033, 34.7215],
							[49.5153, 34.7221],
							[49.5157, 34.7122],
							[49.5517, 34.7139],
							[49.575, 34.735],
							[49.574, 34.7648],
							[49.5602, 34.8138],
							[49.5598, 34.8238],
							[49.5467, 34.853],
							[49.5453, 34.8927],
							[49.5325, 34.912],
							[49.5205, 34.9115],
							[49.5198, 34.9314],
							[49.5074, 34.9408],
							[49.507, 34.9507],
							[49.4822, 34.9695],
							[49.4702, 34.969],
							[49.4454, 34.9878],
							[49.4213, 34.9867],
							[49.3969, 34.9956],
							[49.3844, 35.005],
							[49.3603, 35.0039],
							[49.3359, 35.0128],
							[49.3234, 35.0223],
							[49.2873, 35.0207],
							[49.2628, 35.0297],
							[49.2504, 35.0391],
							[49.25, 35.0491],
							[49.2375, 35.0586],
							[49.2372, 35.0686],
							[49.2488, 35.0791],
							[49.2485, 35.0891],
							[49.2846, 35.0906],
							[49.3091, 35.0816],
							[49.3694, 35.0843],
							[49.3927, 35.1053],
							[49.392, 35.1252],
							[49.4032, 35.1457],
							[49.4021, 35.1757],
							[49.3897, 35.1851],
							[49.3893, 35.1951],
							[49.3396, 35.233],
							[49.3392, 35.243],
							[49.3264, 35.2625],
							[49.3132, 35.2921],
							[49.3128, 35.3021],
							[49.3241, 35.3226],
							[49.3233, 35.3426],
							[49.3584, 35.3741],
							[49.3704, 35.3746],
							[49.3708, 35.3646],
							[49.3832, 35.3551],
							[49.384, 35.3351],
							[49.3731, 35.3046],
							[49.3855, 35.2951],
							[49.3863, 35.2751],
							[49.4345, 35.2771],
							[49.4461, 35.2876],
							[49.4699, 35.2986],
							[49.4815, 35.3091],
							[49.4928, 35.3297],
							[49.48, 35.3491],
							[49.4428, 35.3776],
							[49.4424, 35.3876],
							[49.43, 35.3971],
							[49.4292, 35.4172],
							[49.4409, 35.4277],
							[49.4405, 35.4377],
							[49.4522, 35.4482],
							[49.5125, 35.4507],
							[49.5369, 35.4417],
							[49.5975, 35.4343],
							[49.6215, 35.4353],
							[49.6212, 35.4453],
							[49.6431, 35.5063],
							[49.6427, 35.5163],
							[49.6788, 35.5179],
							[49.6905, 35.5284],
							[49.7506, 35.531],
							[49.7743, 35.5421],
							[49.7863, 35.5426],
							[49.834, 35.5547],
							[49.8456, 35.5653],
							[49.8576, 35.5658],
							[49.929, 35.5891],
							[49.9406, 35.5996],
							[50.0365, 35.6041],
							[50.0482, 35.6147],
							[50.0722, 35.6158],
							[50.1074, 35.6375],
							[50.143, 35.6492],
							[50.1901, 35.6715],
							[50.2377, 35.6737],
							[50.2618, 35.6649],
							[50.2856, 35.6661],
							[50.3099, 35.6473],
							[50.3691, 35.6501],
							[50.405, 35.6419],
							[50.4873, 35.6557],
							[50.5112, 35.6469],
							[50.5354, 35.6281],
							[50.5472, 35.6286],
							[50.5596, 35.6092],
							[50.5839, 35.5904],
							[50.5842, 35.5804],
							[50.5963, 35.571],
							[50.6198, 35.572],
							[50.6195, 35.582],
							[50.6309, 35.5925],
							[50.6189, 35.6019],
							[50.6185, 35.6119],
							[50.5943, 35.6308],
							[50.5819, 35.6501],
							[50.5698, 35.6596],
							[50.5695, 35.6695],
							[50.581, 35.68],
							[50.675, 35.6842],
							[50.6754, 35.6743],
							[50.6874, 35.6648],
							[50.6992, 35.6653],
							[50.7008, 35.6155],
							[50.6901, 35.585],
							[50.6904, 35.575],
							[50.6793, 35.5546],
							[50.7035, 35.5356],
							[50.7149, 35.5461],
							[50.7143, 35.5661],
							[50.7495, 35.5675],
							[50.7609, 35.578],
							[50.7727, 35.5785],
							[50.7723, 35.5885],
							[50.7596, 35.618],
							[50.7592, 35.6279],
							[50.7706, 35.6384],
							[50.7941, 35.6394],
							[50.8062, 35.6299],
							[50.8193, 35.5904],
							[50.8317, 35.5709],
							[50.8559, 35.5518],
							[50.8686, 35.5223],
							[50.8807, 35.5127],
							[50.8828, 35.4527],
							[50.8714, 35.4422],
							[50.8717, 35.4322],
							[50.861, 35.4017],
							[50.8614, 35.3917],
							[50.8393, 35.3508],
							[50.8396, 35.3408],
							[50.8282, 35.3303],
							[50.8175, 35.2999],
							[50.807, 35.2594],
							[50.7956, 35.249],
							[50.7848, 35.2185],
							[50.7505, 35.1871],
							[50.7279, 35.1562],
							[50.7293, 35.1162],
							[50.7192, 35.0658],
							[50.7085, 35.0353],
							[50.7103, 34.9853],
							[50.7003, 34.9348],
							[50.7021, 34.8848],
							[50.6656, 34.9135],
							[50.6077, 34.8813],
							[50.5608, 34.8695],
							[50.5135, 34.8677],
							[50.4658, 34.8758],
							[50.4196, 34.8439],
							[50.3496, 34.8111],
							[50.2547, 34.807],
							[50.2065, 34.8249],
							[50.1705, 34.8333],
							[50.1485, 34.7824],
							[50.1383, 34.732],
							[50.1509, 34.7126],
							[50.1754, 34.6937],
							[50.1884, 34.6643],
							[50.1527, 34.6627],
							[50.1054, 34.6506],
							[50.0345, 34.6274],
							[50.0358, 34.5876],
							[50.0848, 34.5498],
							[50.0858, 34.5199],
							[50.1099, 34.511],
							[50.1459, 34.5026],
							[50.1825, 34.4741],
							[50.2182, 34.4757],
							[50.2536, 34.4871],
							[50.2787, 34.4381],
							[50.3146, 34.4296],
							[50.3039, 34.379],
							[50.3054, 34.3091],
							[50.3173, 34.3096],
							[50.4746, 34.1963],
							[50.4865, 34.1967],
							[50.5214, 34.2177],
							[50.5333, 34.2181],
							[50.5564, 34.2387],
							[50.6269, 34.261],
							[50.6509, 34.2518],
							[50.6635, 34.2224],
							[50.6998, 34.194],
							[50.7358, 34.1754],
							[50.7591, 34.186],
							[50.771, 34.1863],
							[50.7833, 34.1671],
							[50.8072, 34.158],
							[50.8314, 34.1392],
							[50.8675, 34.1208],
							[50.9149, 34.1124],
							[51.033, 34.1059],
							[51.0451, 34.0964],
							[51.0465, 34.0477],
							[51.035, 34.0377],
							[51.0241, 34.0081],
							[51.0129, 33.9883],
							[50.9896, 33.978],
							[50.9667, 33.9579],
							[50.9434, 33.9475],
							[50.932, 33.9374],
							[50.9322, 33.9277],
							[50.9211, 33.9079],
							[50.934, 33.8692],
							[50.9345, 33.8497],
							[50.9233, 33.8299],
							[50.9236, 33.8201],
							[50.9007, 33.8],
							[50.8889, 33.7997],
							[50.8886, 33.8094],
							[50.8769, 33.8091],
							[50.8413, 33.8179],
							[50.8293, 33.8274],
							[50.8175, 33.827],
							[50.7831, 33.7968],
							[50.7484, 33.7764],
							[50.7141, 33.7461],
							[50.6791, 33.7354],
							[50.6326, 33.7146],
							[50.5977, 33.7038],
							[50.5741, 33.7031],
							[50.5509, 33.6927],
							[50.4567, 33.6898],
							[50.4335, 33.6793],
							[50.4217, 33.679],
							[50.3985, 33.6685],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Mazandaran", name: "مازندران" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[52.2421, 35.8603],
							[52.2313, 35.8399],
							[52.1865, 35.7988],
							[52.1758, 35.7784],
							[52.1641, 35.7782],
							[52.153, 35.768],
							[52.0248, 35.7659],
							[52.001, 35.7755],
							[51.9893, 35.7753],
							[51.965, 35.7951],
							[51.9418, 35.7947],
							[51.9291, 35.8146],
							[51.917, 35.8245],
							[51.9165, 35.8345],
							[51.8918, 35.8643],
							[51.8797, 35.8742],
							[51.8656, 35.9243],
							[51.8405, 35.964],
							[51.7921, 36.0032],
							[51.7684, 36.0127],
							[51.7563, 36.0225],
							[51.7089, 36.0415],
							[51.6857, 36.0409],
							[51.6388, 36.0497],
							[51.6155, 36.0491],
							[51.6035, 36.0588],
							[51.5798, 36.0682],
							[51.371, 36.0619],
							[51.3706, 36.0719],
							[51.359, 36.0715],
							[51.3553, 36.1513],
							[51.3312, 36.1704],
							[51.3196, 36.17],
							[51.2843, 36.1787],
							[51.2728, 36.1783],
							[51.2255, 36.1964],
							[51.2134, 36.2059],
							[51.1438, 36.2032],
							[51.1317, 36.2126],
							[51.0969, 36.2112],
							[51.0728, 36.2302],
							[51.0612, 36.2297],
							[51.0482, 36.2591],
							[51.0241, 36.2781],
							[51.0124, 36.2776],
							[51.012, 36.2876],
							[50.9771, 36.2862],
							[50.9535, 36.2952],
							[50.907, 36.2932],
							[50.8833, 36.3022],
							[50.8717, 36.3017],
							[50.8596, 36.3112],
							[50.8242, 36.3197],
							[50.801, 36.3187],
							[50.7986, 36.3687],
							[50.787, 36.3682],
							[50.7511, 36.3867],
							[50.7269, 36.4057],
							[50.6794, 36.4238],
							[50.6673, 36.4333],
							[50.6547, 36.4528],
							[50.6425, 36.4624],
							[50.6187, 36.4714],
							[50.6071, 36.471],
							[50.5711, 36.4896],
							[50.5473, 36.4987],
							[50.487, 36.5365],
							[50.4623, 36.5657],
							[50.4506, 36.5652],
							[50.414, 36.594],
							[50.3657, 36.6223],
							[50.3045, 36.6703],
							[50.3281, 36.6712],
							[50.3744, 36.6931],
							[50.4084, 36.7245],
							[50.408, 36.7346],
							[50.4193, 36.745],
							[50.4279, 36.826],
							[50.4268, 36.8564],
							[50.4379, 36.8771],
							[50.4721, 36.9088],
							[50.5073, 36.9101],
							[50.5187, 36.9207],
							[50.5419, 36.9318],
							[50.5536, 36.9323],
							[50.5765, 36.9535],
							[50.5757, 36.9859],
							[50.5954, 36.9699],
							[50.6503, 36.9498],
							[50.6599, 36.944],
							[50.6636, 36.9413],
							[50.6763, 36.9236],
							[50.6942, 36.9121],
							[50.7314, 36.8941],
							[50.7636, 36.8843],
							[50.7759, 36.8788],
							[50.7892, 36.8691],
							[50.8401, 36.8441],
							[50.8579, 36.8395],
							[50.8708, 36.8337],
							[50.9319, 36.7972],
							[50.9607, 36.7824],
							[50.9867, 36.7722],
							[50.994, 36.771],
							[51.0289, 36.7557],
							[51.0575, 36.7468],
							[51.0758, 36.7431],
							[51.1015, 36.7349],
							[51.1632, 36.7208],
							[51.1789, 36.72],
							[51.1892, 36.7167],
							[51.2074, 36.7134],
							[51.2208, 36.7131],
							[51.2532, 36.7059],
							[51.2716, 36.7053],
							[51.2881, 36.7004],
							[51.3491, 36.6936],
							[51.3525, 36.6947],
							[51.371, 36.6928],
							[51.3779, 36.6899],
							[51.3793, 36.692],
							[51.4063, 36.6913],
							[51.4228, 36.6891],
							[51.4348, 36.6857],
							[51.4734, 36.6651],
							[51.5085, 36.6587],
							[51.5211, 36.6517],
							[51.5493, 36.6418],
							[51.5738, 36.6358],
							[51.585, 36.6303],
							[51.5964, 36.621],
							[51.6033, 36.6178],
							[51.6434, 36.6057],
							[51.6733, 36.5992],
							[51.6986, 36.597],
							[51.7342, 36.591],
							[51.8146, 36.5868],
							[51.8357, 36.5791],
							[51.8488, 36.5769],
							[51.9118, 36.5723],
							[51.9772, 36.5757],
							[52.0402, 36.586],
							[52.0618, 36.5881],
							[52.1478, 36.6071],
							[52.1648, 36.613],
							[52.1998, 36.6211],
							[52.2168, 36.6273],
							[52.3237, 36.6533],
							[52.4201, 36.6744],
							[52.442, 36.6808],
							[52.4482, 36.6801],
							[52.4497, 36.683],
							[52.4758, 36.6851],
							[52.5108, 36.6915],
							[52.5732, 36.6998],
							[52.6446, 36.7139],
							[52.6526, 36.7139],
							[52.654, 36.7159],
							[52.6996, 36.7208],
							[52.7389, 36.7294],
							[52.7451, 36.7278],
							[52.7474, 36.7299],
							[52.7981, 36.7375],
							[52.8945, 36.7568],
							[52.9615, 36.7743],
							[52.9883, 36.7829],
							[53.0075, 36.7865],
							[53.0589, 36.802],
							[53.0683, 36.8033],
							[53.1049, 36.8164],
							[53.1111, 36.8166],
							[53.128, 36.8215],
							[53.1943, 36.8346],
							[53.2157, 36.8372],
							[53.2345, 36.8414],
							[53.2568, 36.8435],
							[53.2842, 36.849],
							[53.4844, 36.8737],
							[53.5126, 36.875],
							[53.5706, 36.8827],
							[53.6492, 36.8887],
							[53.6849, 36.8934],
							[53.7488, 36.897],
							[53.8534, 36.9074],
							[53.8954, 36.9083],
							[53.9163, 36.9103],
							[53.9254, 36.9116],
							[53.939, 36.919],
							[53.9524, 36.919],
							[53.9757, 36.9276],
							[53.9879, 36.9291],
							[54.0061, 36.9378],
							[54.008, 36.9437],
							[54.0161, 36.9475],
							[54.0258, 36.9474],
							[54.027, 36.9442],
							[54.0236, 36.9384],
							[54.0143, 36.9328],
							[54.0221, 36.9098],
							[54.0197, 36.9059],
							[54.0082, 36.9031],
							[53.9977, 36.9051],
							[53.9706, 36.9036],
							[53.9649, 36.9044],
							[53.9604, 36.9081],
							[53.9569, 36.9015],
							[53.9508, 36.8988],
							[53.932, 36.9],
							[53.9274, 36.8973],
							[53.927, 36.8923],
							[53.9244, 36.8907],
							[53.8854, 36.8917],
							[53.8567, 36.8856],
							[53.847, 36.8867],
							[53.8375, 36.8806],
							[53.8292, 36.8798],
							[53.7976, 36.8804],
							[53.7882, 36.8826],
							[53.7811, 36.8817],
							[53.7681, 36.8755],
							[53.753, 36.874],
							[53.7464, 36.8707],
							[53.7446, 36.8682],
							[53.7046, 36.8634],
							[53.6781, 36.8632],
							[53.6848, 36.8546],
							[53.6813, 36.8515],
							[53.6731, 36.852],
							[53.6634, 36.856],
							[53.6301, 36.863],
							[53.6177, 36.8639],
							[53.5974, 36.8616],
							[53.5968, 36.8592],
							[53.6003, 36.854],
							[53.6091, 36.8496],
							[53.6221, 36.839],
							[53.634, 36.8336],
							[53.6469, 36.8234],
							[53.6631, 36.8191],
							[53.667, 36.8156],
							[53.6613, 36.8103],
							[53.6336, 36.8089],
							[53.5935, 36.8155],
							[53.5811, 36.8155],
							[53.5767, 36.812],
							[53.5791, 36.8068],
							[53.6033, 36.8055],
							[53.6172, 36.803],
							[53.634, 36.8033],
							[53.6717, 36.808],
							[53.6908, 36.8069],
							[53.7068, 36.8032],
							[53.7053, 36.7857],
							[53.7281, 36.7567],
							[53.7381, 36.7275],
							[53.7742, 36.7083],
							[53.7977, 36.689],
							[53.859, 36.6701],
							[53.9086, 36.6608],
							[53.9574, 36.6416],
							[54.0053, 36.6125],
							[54.0641, 36.5636],
							[54.0858, 36.5242],
							[54.1084, 36.4947],
							[54.1202, 36.4848],
							[54.0948, 36.4847],
							[54.042, 36.4644],
							[53.9901, 36.454],
							[53.9498, 36.4337],
							[53.9088, 36.4034],
							[53.8827, 36.3932],
							[53.8818, 36.3831],
							[53.8684, 36.373],
							[53.8676, 36.363],
							[53.8408, 36.3426],
							[53.8393, 36.3225],
							[53.8261, 36.3124],
							[53.8254, 36.3023],
							[53.8117, 36.2821],
							[53.8095, 36.2419],
							[53.7961, 36.2217],
							[53.7944, 36.1815],
							[53.7811, 36.1512],
							[53.7808, 36.1411],
							[53.7311, 36.1206],
							[53.6567, 36.0596],
							[53.6446, 36.0595],
							[53.6323, 36.0494],
							[53.6322, 36.0393],
							[53.6078, 35.9989],
							[53.6078, 35.9889],
							[53.5957, 35.9887],
							[53.5717, 35.9483],
							[53.5476, 35.938],
							[53.5355, 35.9379],
							[53.5115, 35.9276],
							[53.4757, 35.9071],
							[53.4636, 35.907],
							[53.4397, 35.8967],
							[53.4157, 35.8964],
							[53.3799, 35.886],
							[53.3319, 35.8854],
							[53.3317, 35.8955],
							[53.3197, 35.8953],
							[53.2955, 35.9051],
							[53.2835, 35.905],
							[53.2718, 35.8948],
							[53.2364, 35.8742],
							[53.2247, 35.864],
							[53.2128, 35.8638],
							[53.2011, 35.8536],
							[53.1772, 35.8533],
							[53.1656, 35.8431],
							[53.1414, 35.8528],
							[53.1298, 35.8426],
							[53.0582, 35.8416],
							[53.0466, 35.8313],
							[53.0347, 35.8312],
							[53.0105, 35.8409],
							[52.9986, 35.8407],
							[52.9494, 35.8804],
							[52.9491, 35.8905],
							[52.9368, 35.9004],
							[52.913, 35.9],
							[52.8899, 35.8795],
							[52.878, 35.8793],
							[52.8664, 35.869],
							[52.7596, 35.8675],
							[52.7233, 35.8872],
							[52.7114, 35.887],
							[52.687, 35.9069],
							[52.663, 35.9167],
							[52.6508, 35.9267],
							[52.639, 35.9265],
							[52.6268, 35.9365],
							[52.5442, 35.9353],
							[52.5321, 35.9453],
							[52.4964, 35.9549],
							[52.4724, 35.9647],
							[52.4481, 35.9846],
							[52.4128, 35.9841],
							[52.4015, 35.9738],
							[52.378, 35.9734],
							[52.3549, 35.963],
							[52.3323, 35.9425],
							[52.3205, 35.9423],
							[52.2754, 35.9012],
							[52.2421, 35.8603],
						],
					],
					[
						[
							[53.6453, 36.8476],
							[53.6344, 36.8491],
							[53.6241, 36.8539],
							[53.6196, 36.854],
							[53.6171, 36.8512],
							[53.6117, 36.8536],
							[53.6072, 36.8532],
							[53.6048, 36.858],
							[53.6082, 36.8604],
							[53.6157, 36.8603],
							[53.632, 36.8571],
							[53.6469, 36.8566],
							[53.6498, 36.849],
							[53.6453, 36.8476],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "North Khorasan", name: "خراسان شمالی" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[58.0019, 37.068],
							[57.9892, 37.0583],
							[57.9888, 37.0484],
							[58.0002, 37.0283],
							[58.0238, 37.008],
							[58.086, 37.0065],
							[58.0935, 36.9569],
							[58.0887, 36.9272],
							[58.1139, 36.9266],
							[58.1282, 36.9362],
							[58.1448, 36.9655],
							[58.1475, 36.9951],
							[58.16, 36.9948],
							[58.1708, 36.9748],
							[58.1673, 36.9452],
							[58.2143, 36.9242],
							[58.2393, 36.9236],
							[58.2607, 36.9032],
							[58.2157, 36.8742],
							[58.1627, 36.8654],
							[58.1657, 36.8253],
							[58.1465, 36.7957],
							[58.2222, 36.7939],
							[58.2307, 36.7738],
							[58.2124, 36.7442],
							[58.1787, 36.6954],
							[58.2165, 36.6945],
							[58.1592, 36.6464],
							[58.12, 36.6375],
							[58.0934, 36.6282],
							[58.0177, 36.6301],
							[57.7817, 36.6657],
							[57.7717, 36.6858],
							[57.5314, 36.7735],
							[57.4737, 36.8055],
							[57.4291, 36.8371],
							[57.3677, 36.8494],
							[57.2749, 36.8928],
							[57.0342, 37.012],
							[56.6458, 36.7528],
							[56.6296, 36.7234],
							[56.6784, 36.7022],
							[56.6716, 36.6426],
							[56.5919, 36.615],
							[56.5283, 36.6167],
							[56.5166, 36.6269],
							[56.5039, 36.6272],
							[56.4296, 36.649],
							[56.3276, 36.6516],
							[56.2457, 36.7233],
							[56.2084, 36.7342],
							[56.1977, 36.7544],
							[56.1625, 36.7851],
							[56.1381, 36.7956],
							[56.0695, 36.857],
							[56.0728, 36.877],
							[56.0617, 36.8872],
							[56.0653, 36.9072],
							[56.0782, 36.9069],
							[56.0821, 36.9269],
							[56.1011, 36.9566],
							[56.1032, 36.9666],
							[56.1205, 36.9864],
							[56.1098, 36.9967],
							[56.1121, 37.0067],
							[56.0886, 37.0173],
							[56.091, 37.0274],
							[56.0804, 37.0377],
							[56.0828, 37.0478],
							[56.0958, 37.0475],
							[56.0982, 37.0575],
							[56.0878, 37.0679],
							[56.0619, 37.0684],
							[56.0774, 37.0782],
							[56.0799, 37.0883],
							[56.0979, 37.1082],
							[56.1109, 37.1079],
							[56.139, 37.1174],
							[56.1648, 37.1169],
							[56.1927, 37.1264],
							[56.2056, 37.1261],
							[56.2075, 37.1361],
							[56.1979, 37.1565],
							[56.1737, 37.1671],
							[56.1609, 37.1674],
							[56.1465, 37.1576],
							[56.1079, 37.1585],
							[56.0934, 37.1487],
							[56.0547, 37.1495],
							[56.0066, 37.1706],
							[55.9953, 37.1809],
							[55.9968, 37.1909],
							[55.9852, 37.2012],
							[55.9865, 37.2112],
							[55.9748, 37.2215],
							[55.9639, 37.2419],
							[55.9535, 37.2722],
							[55.9413, 37.2825],
							[55.9296, 37.3029],
							[55.9169, 37.3031],
							[55.8672, 37.3443],
							[55.8675, 37.3544],
							[55.9438, 37.4135],
							[55.9426, 37.4538],
							[55.9545, 37.4738],
							[55.9672, 37.4735],
							[55.9916, 37.4932],
							[56.0298, 37.4924],
							[56.0541, 37.5121],
							[56.0534, 37.5222],
							[56.0655, 37.5321],
							[56.0909, 37.5315],
							[56.0902, 37.5416],
							[56.1411, 37.5406],
							[56.1531, 37.5504],
							[56.1658, 37.5501],
							[56.1905, 37.5597],
							[56.2287, 37.5588],
							[56.2907, 37.5776],
							[56.3026, 37.5874],
							[56.3128, 37.6175],
							[56.3237, 37.6374],
							[56.3228, 37.6476],
							[56.3081, 37.6681],
							[56.307, 37.6783],
							[56.2932, 37.6887],
							[56.2921, 37.6988],
							[56.2654, 37.7094],
							[56.2514, 37.7198],
							[56.2386, 37.7201],
							[56.2245, 37.7304],
							[56.1861, 37.7312],
							[56.172, 37.7415],
							[56.1707, 37.7515],
							[56.1551, 37.7716],
							[56.1522, 37.7913],
							[56.162, 37.8108],
							[56.1585, 37.8303],
							[56.1697, 37.8398],
							[56.1678, 37.8495],
							[56.1789, 37.8589],
							[56.1747, 37.8781],
							[56.1856, 37.8875],
							[56.1762, 37.9255],
							[56.1618, 37.9718],
							[56.1718, 37.9808],
							[56.1724, 38.0156],
							[56.1773, 38.0413],
							[56.1665, 38.0831],
							[56.1732, 38.0825],
							[56.2115, 38.0614],
							[56.2184, 38.0603],
							[56.2469, 38.0621],
							[56.2876, 38.0706],
							[56.3392, 38.0706],
							[56.3411, 38.0891],
							[56.3479, 38.0979],
							[56.3563, 38.1038],
							[56.3587, 38.112],
							[56.3571, 38.1171],
							[56.3519, 38.1228],
							[56.3467, 38.1321],
							[56.3345, 38.1383],
							[56.3343, 38.1475],
							[56.3291, 38.1552],
							[56.3272, 38.1649],
							[56.3314, 38.1737],
							[56.3402, 38.1838],
							[56.3541, 38.1949],
							[56.4245, 38.245],
							[56.4633, 38.254],
							[56.5121, 38.2526],
							[56.5364, 38.2563],
							[56.5752, 38.2524],
							[56.5865, 38.2452],
							[56.594, 38.2372],
							[56.599, 38.2279],
							[56.6041, 38.2234],
							[56.6213, 38.2305],
							[56.643, 38.2358],
							[56.6454, 38.2378],
							[56.6481, 38.2444],
							[56.6597, 38.2516],
							[56.6733, 38.2546],
							[56.6969, 38.2571],
							[56.7168, 38.2533],
							[56.7276, 38.2544],
							[56.7368, 38.2575],
							[56.7505, 38.2695],
							[56.7567, 38.2725],
							[56.7632, 38.2625],
							[56.7672, 38.261],
							[56.7979, 38.2346],
							[56.8263, 38.2264],
							[56.8407, 38.218],
							[56.8561, 38.2115],
							[56.8888, 38.2039],
							[56.9119, 38.2004],
							[56.9559, 38.1967],
							[56.9808, 38.1994],
							[56.9862, 38.1978],
							[56.9906, 38.1938],
							[56.9974, 38.1835],
							[56.9978, 38.1803],
							[57.0017, 38.1774],
							[57.0224, 38.1737],
							[57.052, 38.1719],
							[57.0619, 38.1722],
							[57.0804, 38.1767],
							[57.0945, 38.1847],
							[57.1339, 38.2214],
							[57.145, 38.2337],
							[57.1605, 38.2565],
							[57.165, 38.2596],
							[57.1788, 38.2616],
							[57.2028, 38.2698],
							[57.2205, 38.2699],
							[57.2329, 38.2671],
							[57.2451, 38.2617],
							[57.249, 38.2574],
							[57.251, 38.2521],
							[57.2525, 38.2468],
							[57.251, 38.2413],
							[57.2537, 38.2371],
							[57.2561, 38.2228],
							[57.2582, 38.2195],
							[57.2852, 38.2083],
							[57.2955, 38.1988],
							[57.2965, 38.185],
							[57.2929, 38.1736],
							[57.2924, 38.1629],
							[57.2937, 38.1502],
							[57.334, 38.1284],
							[57.342, 38.1201],
							[57.3476, 38.1069],
							[57.3555, 38.0967],
							[57.3753, 38.083],
							[57.3794, 38.0778],
							[57.3806, 38.0729],
							[57.3771, 38.0579],
							[57.3673, 38.0383],
							[57.3662, 38.0331],
							[57.3685, 38.022],
							[57.3678, 38.0097],
							[57.3628, 37.9984],
							[57.3531, 37.9866],
							[57.3523, 37.982],
							[57.3563, 37.9761],
							[57.3776, 37.9683],
							[57.3899, 37.9656],
							[57.4074, 37.9567],
							[57.4294, 37.941],
							[57.4946, 37.9254],
							[57.512, 37.9158],
							[57.518, 37.9144],
							[57.5637, 37.9235],
							[57.5892, 37.9246],
							[57.6361, 37.9142],
							[57.6552, 37.9132],
							[57.6868, 37.9088],
							[57.6958, 37.909],
							[57.7058, 37.9118],
							[57.7255, 37.9115],
							[57.7326, 37.9096],
							[57.7422, 37.9019],
							[57.7282, 37.8852],
							[57.7052, 37.8639],
							[57.6096, 37.8423],
							[57.5979, 37.8322],
							[57.5618, 37.8225],
							[57.5632, 37.7826],
							[57.5759, 37.7625],
							[57.5884, 37.7524],
							[57.5887, 37.7425],
							[57.5648, 37.723],
							[57.5406, 37.7136],
							[57.5409, 37.684],
							[57.5532, 37.6739],
							[57.5902, 37.6634],
							[57.6262, 37.6728],
							[57.7108, 37.6716],
							[57.7716, 37.6608],
							[57.8195, 37.6604],
							[57.9405, 37.6394],
							[57.9858, 37.6594],
							[58.0446, 37.659],
							[58.0457, 37.6489],
							[58.0361, 37.6286],
							[58.0479, 37.6285],
							[58.0856, 37.6077],
							[58.0558, 37.5572],
							[58.1555, 37.5155],
							[58.1816, 37.495],
							[58.209, 37.4645],
							[58.2135, 37.4241],
							[58.2276, 37.4038],
							[58.1945, 37.3743],
							[58.1603, 37.3549],
							[58.162, 37.335],
							[58.1508, 37.3252],
							[58.1653, 37.295],
							[58.1668, 37.2751],
							[58.1547, 37.2753],
							[58.0959, 37.2464],
							[58.097, 37.2264],
							[58.0741, 37.1969],
							[58.0762, 37.1267],
							[58.0517, 37.087],
							[58.0269, 37.0775],
							[58.0145, 37.0778],
							[58.0019, 37.068],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Qazvin", name: "قزوین" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[50.3501, 35.8788],
							[50.3034, 35.8566],
							[50.2915, 35.8561],
							[50.28, 35.8456],
							[50.2565, 35.8345],
							[50.2334, 35.8134],
							[50.234, 35.7934],
							[50.2224, 35.7829],
							[50.2227, 35.7729],
							[50.235, 35.7635],
							[50.2359, 35.7336],
							[50.2484, 35.7142],
							[50.2853, 35.676],
							[50.2856, 35.6661],
							[50.2618, 35.6649],
							[50.2377, 35.6737],
							[50.1901, 35.6715],
							[50.143, 35.6492],
							[50.1074, 35.6375],
							[50.0722, 35.6158],
							[50.0482, 35.6147],
							[50.0365, 35.6041],
							[49.9406, 35.5996],
							[49.929, 35.5891],
							[49.8576, 35.5658],
							[49.8456, 35.5653],
							[49.834, 35.5547],
							[49.7863, 35.5426],
							[49.7743, 35.5421],
							[49.7506, 35.531],
							[49.6905, 35.5284],
							[49.6788, 35.5179],
							[49.6427, 35.5163],
							[49.6431, 35.5063],
							[49.6212, 35.4453],
							[49.6215, 35.4353],
							[49.5975, 35.4343],
							[49.5369, 35.4417],
							[49.5125, 35.4507],
							[49.4522, 35.4482],
							[49.4405, 35.4377],
							[49.4401, 35.4477],
							[49.4277, 35.4572],
							[49.4032, 35.4663],
							[49.3791, 35.4653],
							[49.3674, 35.4548],
							[49.3553, 35.4543],
							[49.3304, 35.4734],
							[49.3183, 35.4729],
							[49.3059, 35.4824],
							[49.2455, 35.4801],
							[49.2338, 35.4695],
							[49.1371, 35.4658],
							[49.052, 35.4726],
							[49.0274, 35.4817],
							[49.0023, 35.5009],
							[49.0015, 35.5211],
							[49.0132, 35.5316],
							[49.0859, 35.5343],
							[49.0976, 35.5448],
							[49.0968, 35.5649],
							[49.0343, 35.613],
							[49.0213, 35.6328],
							[48.9967, 35.642],
							[48.9716, 35.6614],
							[48.9348, 35.6703],
							[48.8377, 35.6673],
							[48.826, 35.6568],
							[48.8139, 35.6564],
							[48.8021, 35.646],
							[48.8025, 35.6358],
							[48.7644, 35.6753],
							[48.7388, 35.705],
							[48.7249, 35.7451],
							[48.6125, 35.8028],
							[48.5866, 35.8326],
							[48.5847, 35.8732],
							[48.6346, 35.8543],
							[48.6463, 35.8648],
							[48.6703, 35.8757],
							[48.72, 35.8568],
							[48.744, 35.8676],
							[48.7431, 35.8879],
							[48.7544, 35.9086],
							[48.7905, 35.9198],
							[48.9245, 35.9236],
							[48.924, 35.9338],
							[48.9614, 35.9145],
							[48.9982, 35.9054],
							[49.0359, 35.8761],
							[49.0602, 35.8769],
							[49.0225, 35.9062],
							[49.033, 35.9471],
							[49.0561, 35.9783],
							[49.0914, 36.0099],
							[49.1274, 36.0211],
							[49.1639, 36.0223],
							[49.2595, 36.066],
							[49.3085, 36.0576],
							[49.3564, 36.0796],
							[49.4049, 36.0813],
							[49.416, 36.1121],
							[49.4031, 36.1319],
							[49.3891, 36.1822],
							[49.3992, 36.2333],
							[49.3494, 36.2622],
							[49.3226, 36.3225],
							[49.333, 36.3636],
							[49.2835, 36.3827],
							[49.2597, 36.3718],
							[49.2578, 36.4127],
							[49.2091, 36.4114],
							[49.1974, 36.4009],
							[49.1843, 36.421],
							[49.1589, 36.4408],
							[49.1345, 36.4401],
							[49.1217, 36.4501],
							[49.1207, 36.4705],
							[49.1068, 36.5009],
							[49.1168, 36.5423],
							[49.0911, 36.5621],
							[49.0659, 36.5718],
							[49.0278, 36.5914],
							[49.0019, 36.6113],
							[48.9985, 36.6628],
							[49.044, 36.7155],
							[49.0662, 36.7471],
							[49.0999, 36.7893],
							[49.1606, 36.8009],
							[49.1975, 36.8017],
							[49.2458, 36.8131],
							[49.2826, 36.8139],
							[49.2956, 36.8039],
							[49.3202, 36.8045],
							[49.3209, 36.7942],
							[49.3467, 36.7742],
							[49.3474, 36.764],
							[49.3359, 36.7534],
							[49.3501, 36.7229],
							[49.4014, 36.6831],
							[49.4148, 36.6629],
							[49.4657, 36.6231],
							[49.4904, 36.6135],
							[49.5146, 36.6142],
							[49.5273, 36.6043],
							[49.5393, 36.6046],
							[49.5508, 36.6152],
							[49.5761, 36.5955],
							[49.6123, 36.5966],
							[49.637, 36.5872],
							[49.6485, 36.5977],
							[49.6846, 36.5988],
							[49.6978, 36.5789],
							[49.7099, 36.5793],
							[49.7465, 36.5703],
							[49.7591, 36.5605],
							[49.8552, 36.5636],
							[49.8666, 36.5742],
							[49.8901, 36.5851],
							[49.9021, 36.5855],
							[49.9135, 36.596],
							[49.913, 36.6061],
							[49.925, 36.6065],
							[49.9954, 36.6393],
							[50.0194, 36.6401],
							[50.0308, 36.6506],
							[50.0548, 36.6514],
							[50.0663, 36.6619],
							[50.0903, 36.6627],
							[50.1018, 36.6733],
							[50.1377, 36.6745],
							[50.1731, 36.6859],
							[50.2326, 36.6879],
							[50.2809, 36.6695],
							[50.3045, 36.6703],
							[50.3657, 36.6223],
							[50.414, 36.594],
							[50.4506, 36.5652],
							[50.4623, 36.5657],
							[50.487, 36.5365],
							[50.5473, 36.4987],
							[50.5711, 36.4896],
							[50.6071, 36.471],
							[50.6187, 36.4714],
							[50.6425, 36.4624],
							[50.6547, 36.4528],
							[50.6673, 36.4333],
							[50.6794, 36.4238],
							[50.7269, 36.4057],
							[50.7511, 36.3867],
							[50.787, 36.3682],
							[50.7986, 36.3687],
							[50.801, 36.3187],
							[50.7661, 36.3172],
							[50.7191, 36.3252],
							[50.6842, 36.3237],
							[50.6604, 36.3327],
							[50.59, 36.3398],
							[50.5783, 36.3394],
							[50.5545, 36.3484],
							[50.4962, 36.346],
							[50.4499, 36.3341],
							[50.4265, 36.3331],
							[50.404, 36.3121],
							[50.4054, 36.282],
							[50.4176, 36.2725],
							[50.4293, 36.273],
							[50.4532, 36.264],
							[50.4654, 36.2545],
							[50.4659, 36.2445],
							[50.4784, 36.2249],
							[50.5023, 36.2159],
							[50.5027, 36.2059],
							[50.5144, 36.2064],
							[50.5733, 36.1989],
							[50.5967, 36.1999],
							[50.6205, 36.1909],
							[50.6209, 36.1809],
							[50.633, 36.1714],
							[50.6343, 36.1415],
							[50.6005, 36.11],
							[50.5788, 36.0691],
							[50.5675, 36.0586],
							[50.5569, 36.0281],
							[50.5573, 36.0181],
							[50.5354, 35.9772],
							[50.5366, 35.9472],
							[50.5252, 35.9367],
							[50.502, 35.9257],
							[50.4667, 35.9241],
							[50.4553, 35.9136],
							[50.4203, 35.902],
							[50.4088, 35.8915],
							[50.3855, 35.8804],
							[50.3501, 35.8788],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Qom", name: "قم" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[51.7889, 34.542],
							[51.7545, 34.5112],
							[51.708, 34.4902],
							[51.6962, 34.49],
							[51.6729, 34.4795],
							[51.6611, 34.4793],
							[51.6143, 34.4683],
							[51.4964, 34.4658],
							[51.4372, 34.4745],
							[51.225, 34.4694],
							[51.1781, 34.4582],
							[51.143, 34.4472],
							[51.1312, 34.4469],
							[51.0961, 34.4359],
							[51.0843, 34.4355],
							[51.0609, 34.4248],
							[51.0379, 34.4041],
							[51.0265, 34.3836],
							[51.015, 34.3733],
							[51.0154, 34.3532],
							[51.0043, 34.3127],
							[51.0056, 34.2527],
							[50.9945, 34.2226],
							[50.995, 34.2028],
							[50.9839, 34.1729],
							[50.9842, 34.1631],
							[50.973, 34.1432],
							[50.9733, 34.1335],
							[50.9974, 34.1147],
							[51.033, 34.1059],
							[50.9149, 34.1124],
							[50.8675, 34.1208],
							[50.8314, 34.1392],
							[50.8072, 34.158],
							[50.7833, 34.1671],
							[50.771, 34.1863],
							[50.7591, 34.186],
							[50.7358, 34.1754],
							[50.6998, 34.194],
							[50.6635, 34.2224],
							[50.6509, 34.2518],
							[50.6269, 34.261],
							[50.5564, 34.2387],
							[50.5333, 34.2181],
							[50.5214, 34.2177],
							[50.4865, 34.1967],
							[50.4746, 34.1963],
							[50.3173, 34.3096],
							[50.3054, 34.3091],
							[50.3039, 34.379],
							[50.3146, 34.4296],
							[50.2787, 34.4381],
							[50.2536, 34.4871],
							[50.2182, 34.4757],
							[50.1825, 34.4741],
							[50.1459, 34.5026],
							[50.1099, 34.511],
							[50.0858, 34.5199],
							[50.0848, 34.5498],
							[50.0358, 34.5876],
							[50.0345, 34.6274],
							[50.1054, 34.6506],
							[50.1527, 34.6627],
							[50.1884, 34.6643],
							[50.1754, 34.6937],
							[50.1509, 34.7126],
							[50.1383, 34.732],
							[50.1485, 34.7824],
							[50.1705, 34.8333],
							[50.2065, 34.8249],
							[50.2547, 34.807],
							[50.3496, 34.8111],
							[50.4196, 34.8439],
							[50.4658, 34.8758],
							[50.5135, 34.8677],
							[50.5608, 34.8695],
							[50.6077, 34.8813],
							[50.6656, 34.9135],
							[50.7021, 34.8848],
							[50.7003, 34.9348],
							[50.7103, 34.9853],
							[50.7085, 35.0353],
							[50.7192, 35.0658],
							[50.7293, 35.1162],
							[50.7279, 35.1562],
							[50.7505, 35.1871],
							[50.7848, 35.2185],
							[50.7851, 35.2085],
							[50.7969, 35.2089],
							[50.7966, 35.2189],
							[50.9262, 35.2237],
							[50.9853, 35.2157],
							[51.0677, 35.2185],
							[51.0798, 35.2088],
							[51.1272, 35.2003],
							[51.2224, 35.173],
							[51.2463, 35.1636],
							[51.305, 35.1652],
							[51.3289, 35.1558],
							[51.414, 35.0875],
							[51.4745, 35.0486],
							[51.4862, 35.0489],
							[51.5463, 35.02],
							[51.6064, 34.9911],
							[51.6547, 34.9619],
							[51.7384, 34.9333],
							[51.7624, 34.9237],
							[51.8099, 34.9145],
							[51.8584, 34.8852],
							[51.8701, 34.8854],
							[51.8824, 34.8756],
							[51.8828, 34.8655],
							[51.8955, 34.8456],
							[51.8963, 34.8255],
							[51.9085, 34.8157],
							[51.9094, 34.7956],
							[51.8984, 34.7753],
							[51.8769, 34.7246],
							[51.8773, 34.7146],
							[51.8677, 34.6541],
							[51.8681, 34.644],
							[51.8459, 34.6034],
							[51.8233, 34.5728],
							[51.7889, 34.542],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Razavi Khorasan", name: "خراسان رضوی" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[58.638, 34.0787],
							[58.6722, 34.0469],
							[58.6825, 34.0261],
							[58.6665, 33.9957],
							[58.6631, 33.965],
							[58.6471, 33.9346],
							[58.6188, 33.9046],
							[58.6625, 33.8418],
							[58.6471, 33.8115],
							[58.6337, 33.8017],
							[58.6195, 33.7816],
							[58.593, 33.7619],
							[58.6045, 33.7513],
							[58.6037, 33.7411],
							[58.6143, 33.7203],
							[58.6258, 33.7097],
							[58.6127, 33.6999],
							[58.5988, 33.6799],
							[58.598, 33.6697],
							[58.6211, 33.6485],
							[58.6334, 33.6481],
							[58.6572, 33.6372],
							[58.668, 33.6164],
							[58.6803, 33.616],
							[58.6773, 33.5753],
							[58.6881, 33.5545],
							[58.7112, 33.5334],
							[58.8202, 33.5096],
							[58.8317, 33.499],
							[58.8423, 33.4784],
							[58.8283, 33.4585],
							[58.8038, 33.4592],
							[58.8005, 33.4188],
							[58.7868, 33.399],
							[58.7853, 33.3788],
							[58.7731, 33.3792],
							[58.7468, 33.3496],
							[58.7334, 33.3298],
							[58.6701, 33.2813],
							[58.6329, 33.2622],
							[58.5964, 33.2533],
							[58.5594, 33.2342],
							[58.511, 33.2256],
							[58.4747, 33.2166],
							[58.4134, 33.1881],
							[58.3764, 33.1689],
							[58.3019, 33.1104],
							[58.2771, 33.0809],
							[58.2645, 33.0511],
							[58.264, 33.031],
							[58.2509, 32.9412],
							[58.2507, 32.9013],
							[58.2158, 32.8433],
							[58.2047, 32.8047],
							[58.1475, 32.7584],
							[58.1477, 32.7486],
							[58.1011, 32.7702],
							[58.0892, 32.7902],
							[58.0887, 32.8195],
							[58.0764, 32.8688],
							[58.0297, 32.9001],
							[57.9137, 32.9247],
							[57.8671, 32.956],
							[57.8089, 32.9879],
							[57.7626, 32.9997],
							[57.7276, 33.0305],
							[57.6918, 33.1598],
							[57.6804, 33.22],
							[57.6694, 33.3209],
							[57.6717, 33.4522],
							[57.6619, 33.5236],
							[57.6388, 33.5445],
							[57.6165, 33.5858],
							[57.5935, 33.6068],
							[57.5726, 33.6787],
							[57.5365, 33.6695],
							[57.463, 33.6105],
							[57.4032, 33.5818],
							[57.3207, 33.564],
							[57.2505, 33.5662],
							[57.1574, 33.5893],
							[57.0995, 33.6214],
							[57.0648, 33.6528],
							[57.0538, 33.7037],
							[57.0432, 33.7649],
							[57.022, 33.8468],
							[57.0113, 33.8776],
							[56.9924, 34.0003],
							[56.9823, 34.0414],
							[56.984, 34.0822],
							[57.0105, 34.1427],
							[57.0255, 34.2036],
							[57.0271, 34.2343],
							[57.0674, 34.315],
							[57.0955, 34.3858],
							[57.1113, 34.4467],
							[57.1646, 34.5268],
							[57.2029, 34.5563],
							[57.2291, 34.5861],
							[57.305, 34.6246],
							[57.4067, 34.6625],
							[57.5394, 34.7399],
							[57.5952, 34.7889],
							[57.6499, 34.8278],
							[57.6784, 34.8572],
							[57.7193, 34.8864],
							[57.7205, 34.8964],
							[57.7103, 34.9166],
							[57.6819, 34.9974],
							[57.5535, 35.0804],
							[57.5057, 35.1015],
							[57.3998, 35.164],
							[57.3531, 35.1951],
							[57.279, 35.2068],
							[57.1912, 35.2087],
							[57.1135, 35.1903],
							[57.0083, 35.1524],
							[56.942, 35.1236],
							[56.8108, 35.0759],
							[56.7192, 35.0275],
							[56.5923, 34.9797],
							[56.5175, 34.961],
							[56.4187, 34.9427],
							[56.3448, 34.924],
							[56.2958, 34.9148],
							[56.2105, 34.9061],
							[56.2229, 34.9159],
							[56.2484, 34.9555],
							[56.2739, 34.9851],
							[56.3246, 35.0243],
							[56.3378, 35.0441],
							[56.3506, 35.0539],
							[56.4028, 35.1031],
							[56.4682, 35.1521],
							[56.5205, 35.1813],
							[56.5474, 35.2009],
							[56.5995, 35.2201],
							[56.6121, 35.2199],
							[56.6773, 35.2388],
							[56.7301, 35.258],
							[56.7553, 35.2576],
							[56.7817, 35.2671],
							[56.7955, 35.2769],
							[56.8344, 35.2862],
							[56.8619, 35.3058],
							[56.863, 35.3158],
							[56.8768, 35.3256],
							[56.8939, 35.3654],
							[56.895, 35.3754],
							[56.9133, 35.4252],
							[56.9144, 35.4352],
							[56.9293, 35.4549],
							[56.9304, 35.4649],
							[56.943, 35.4646],
							[56.9728, 35.5039],
							[57.0014, 35.5333],
							[57.0438, 35.5722],
							[57.0575, 35.5818],
							[57.0725, 35.6014],
							[57.0862, 35.611],
							[57.1011, 35.6305],
							[57.1069, 35.6801],
							[57.0966, 35.7002],
							[57.0726, 35.7107],
							[57.0624, 35.7308],
							[57.0281, 35.7614],
							[56.9927, 35.7821],
							[56.9812, 35.7923],
							[56.9686, 35.7926],
							[56.9343, 35.8232],
							[56.9217, 35.8235],
							[56.9102, 35.8337],
							[56.8862, 35.8442],
							[56.8518, 35.8748],
							[56.8392, 35.8751],
							[56.8277, 35.8853],
							[56.8036, 35.8958],
							[56.7691, 35.9264],
							[56.7703, 35.9363],
							[56.7588, 35.9465],
							[56.761, 35.9663],
							[56.7506, 35.9863],
							[56.7528, 36.0061],
							[56.7435, 36.0361],
							[56.732, 36.0463],
							[56.7353, 36.0759],
							[56.7238, 36.0861],
							[56.7271, 36.1157],
							[56.7156, 36.1259],
							[56.7189, 36.1556],
							[56.7096, 36.1855],
							[56.7118, 36.2053],
							[56.7002, 36.2155],
							[56.692, 36.2554],
							[56.704, 36.3641],
							[56.736, 36.4227],
							[56.7658, 36.4616],
							[56.7796, 36.4711],
							[56.7807, 36.481],
							[56.8084, 36.5001],
							[56.8175, 36.579],
							[56.8059, 36.5892],
							[56.807, 36.5991],
							[56.7723, 36.6299],
							[56.7353, 36.6409],
							[56.7237, 36.6511],
							[56.711, 36.6515],
							[56.6844, 36.6423],
							[56.6716, 36.6426],
							[56.6784, 36.7022],
							[56.6296, 36.7234],
							[56.6458, 36.7528],
							[57.0342, 37.012],
							[57.2749, 36.8928],
							[57.3677, 36.8494],
							[57.4291, 36.8371],
							[57.4737, 36.8055],
							[57.5314, 36.7735],
							[57.7717, 36.6858],
							[57.7817, 36.6657],
							[58.0177, 36.6301],
							[58.0934, 36.6282],
							[58.12, 36.6375],
							[58.1592, 36.6464],
							[58.2165, 36.6945],
							[58.1787, 36.6954],
							[58.2124, 36.7442],
							[58.2307, 36.7738],
							[58.2222, 36.7939],
							[58.1465, 36.7957],
							[58.1657, 36.8253],
							[58.1627, 36.8654],
							[58.2157, 36.8742],
							[58.2607, 36.9032],
							[58.2393, 36.9236],
							[58.2143, 36.9242],
							[58.1673, 36.9452],
							[58.1708, 36.9748],
							[58.16, 36.9948],
							[58.1475, 36.9951],
							[58.1448, 36.9655],
							[58.1282, 36.9362],
							[58.1139, 36.9266],
							[58.0887, 36.9272],
							[58.0935, 36.9569],
							[58.086, 37.0065],
							[58.0238, 37.008],
							[58.0002, 37.0283],
							[57.9888, 37.0484],
							[57.9892, 37.0583],
							[58.0145, 37.0778],
							[58.0269, 37.0775],
							[58.0517, 37.087],
							[58.0762, 37.1267],
							[58.0741, 37.1969],
							[58.097, 37.2264],
							[58.0959, 37.2464],
							[58.1547, 37.2753],
							[58.1668, 37.2751],
							[58.1653, 37.295],
							[58.1508, 37.3252],
							[58.162, 37.335],
							[58.1603, 37.3549],
							[58.1945, 37.3743],
							[58.2276, 37.4038],
							[58.2135, 37.4241],
							[58.209, 37.4645],
							[58.1816, 37.495],
							[58.1555, 37.5155],
							[58.0558, 37.5572],
							[58.0856, 37.6077],
							[58.0479, 37.6285],
							[58.0361, 37.6286],
							[58.0457, 37.6489],
							[58.0446, 37.659],
							[57.9858, 37.6594],
							[57.9405, 37.6394],
							[57.8195, 37.6604],
							[57.7716, 37.6608],
							[57.7108, 37.6716],
							[57.6262, 37.6728],
							[57.5902, 37.6634],
							[57.5532, 37.6739],
							[57.5409, 37.684],
							[57.5406, 37.7136],
							[57.5648, 37.723],
							[57.5887, 37.7425],
							[57.5884, 37.7524],
							[57.5759, 37.7625],
							[57.5632, 37.7826],
							[57.5618, 37.8225],
							[57.5979, 37.8322],
							[57.6096, 37.8423],
							[57.7052, 37.8639],
							[57.7282, 37.8852],
							[57.7422, 37.9019],
							[57.7572, 37.8952],
							[57.7872, 37.8931],
							[57.794, 37.8901],
							[57.8171, 37.8665],
							[57.8278, 37.8615],
							[57.8344, 37.8605],
							[57.8622, 37.8649],
							[57.8807, 37.864],
							[57.9114, 37.8563],
							[57.919, 37.8526],
							[57.9325, 37.85],
							[57.956, 37.8424],
							[57.9633, 37.838],
							[57.9791, 37.8321],
							[58.0019, 37.8176],
							[58.0154, 37.8114],
							[58.0223, 37.8069],
							[58.0294, 37.7993],
							[58.0337, 37.7996],
							[58.0422, 37.796],
							[58.0494, 37.7953],
							[58.0753, 37.7988],
							[58.0889, 37.7937],
							[58.0938, 37.7935],
							[58.0974, 37.7876],
							[58.103, 37.7841],
							[58.107, 37.7837],
							[58.119, 37.7906],
							[58.1347, 37.7778],
							[58.1414, 37.7791],
							[58.1537, 37.7859],
							[58.1598, 37.7857],
							[58.167, 37.7806],
							[58.1807, 37.7743],
							[58.1992, 37.7694],
							[58.2119, 37.7601],
							[58.214, 37.7391],
							[58.2161, 37.7358],
							[58.2257, 37.7286],
							[58.2272, 37.7246],
							[58.2321, 37.7212],
							[58.2332, 37.7062],
							[58.2261, 37.6875],
							[58.2264, 37.6829],
							[58.2301, 37.6779],
							[58.2373, 37.6735],
							[58.2914, 37.6565],
							[58.2999, 37.655],
							[58.3376, 37.6572],
							[58.3655, 37.6466],
							[58.3688, 37.644],
							[58.3715, 37.6292],
							[58.3871, 37.617],
							[58.3928, 37.6154],
							[58.4016, 37.6175],
							[58.413, 37.6241],
							[58.4218, 37.626],
							[58.4598, 37.625],
							[58.4733, 37.6279],
							[58.4923, 37.6353],
							[58.5126, 37.6484],
							[58.5219, 37.6707],
							[58.5375, 37.6865],
							[58.5533, 37.6931],
							[58.5772, 37.6928],
							[58.5903, 37.6862],
							[58.6056, 37.6733],
							[58.6205, 37.6555],
							[58.6508, 37.6431],
							[58.6718, 37.6377],
							[58.7382, 37.6294],
							[58.7422, 37.6299],
							[58.7483, 37.645],
							[58.7536, 37.6505],
							[58.7623, 37.6517],
							[58.7931, 37.6415],
							[58.8042, 37.6435],
							[58.8095, 37.649],
							[58.8152, 37.6632],
							[58.8233, 37.6717],
							[58.8239, 37.6756],
							[58.8161, 37.6868],
							[58.8178, 37.6911],
							[58.8219, 37.692],
							[58.8575, 37.6768],
							[58.8869, 37.6534],
							[58.8934, 37.6503],
							[58.9324, 37.6475],
							[58.951, 37.6443],
							[58.9958, 37.6258],
							[59.0476, 37.6128],
							[59.0695, 37.6009],
							[59.106, 37.5761],
							[59.1393, 37.5563],
							[59.1548, 37.5507],
							[59.1684, 37.5402],
							[59.1864, 37.5315],
							[59.1889, 37.5261],
							[59.2002, 37.5191],
							[59.2056, 37.5115],
							[59.2126, 37.5056],
							[59.2318, 37.5035],
							[59.2467, 37.4981],
							[59.2763, 37.4973],
							[59.2905, 37.5026],
							[59.3099, 37.5135],
							[59.3199, 37.5167],
							[59.3356, 37.5179],
							[59.3478, 37.5154],
							[59.3553, 37.5077],
							[59.3573, 37.5012],
							[59.3781, 37.4829],
							[59.384, 37.475],
							[59.3872, 37.468],
							[59.385, 37.4614],
							[59.3843, 37.4431],
							[59.4031, 37.426],
							[59.4026, 37.4231],
							[59.3869, 37.4177],
							[59.377, 37.4097],
							[59.3735, 37.3984],
							[59.3864, 37.3548],
							[59.3924, 37.3267],
							[59.3984, 37.3115],
							[59.4066, 37.3026],
							[59.4219, 37.296],
							[59.4267, 37.2921],
							[59.4338, 37.2795],
							[59.4483, 37.268],
							[59.4907, 37.2235],
							[59.4901, 37.2203],
							[59.48, 37.2124],
							[59.475, 37.2043],
							[59.4977, 37.1898],
							[59.5204, 37.1801],
							[59.5312, 37.172],
							[59.552, 37.1663],
							[59.5543, 37.1595],
							[59.5486, 37.1393],
							[59.5508, 37.1271],
							[59.5538, 37.1225],
							[59.5992, 37.1192],
							[59.6059, 37.1177],
							[59.6247, 37.1081],
							[59.6305, 37.1094],
							[59.6424, 37.1074],
							[59.6512, 37.1092],
							[59.6578, 37.1236],
							[59.6601, 37.1264],
							[59.6654, 37.1287],
							[59.6819, 37.1298],
							[59.6995, 37.1282],
							[59.7113, 37.1248],
							[59.7223, 37.1184],
							[59.7383, 37.1042],
							[59.7436, 37.1023],
							[59.7585, 37.1027],
							[59.7603, 37.1099],
							[59.7625, 37.1123],
							[59.77, 37.1133],
							[59.778, 37.1119],
							[59.7983, 37.1018],
							[59.8331, 37.0921],
							[59.8451, 37.085],
							[59.8761, 37.0561],
							[59.8896, 37.0526],
							[59.8991, 37.0436],
							[59.9275, 37.0298],
							[59.9442, 37.0281],
							[59.9789, 37.0342],
							[60.0, 37.0314],
							[60.0, 37.0395],
							[60.0044, 37.0397],
							[60.021, 37.0333],
							[60.0507, 37.0122],
							[60.0843, 36.9785],
							[60.0889, 36.9707],
							[60.1026, 36.936],
							[60.1768, 36.8424],
							[60.2142, 36.8105],
							[60.2487, 36.7768],
							[60.2705, 36.7513],
							[60.3314, 36.6562],
							[60.34, 36.6477],
							[60.3524, 36.6419],
							[60.3707, 36.6242],
							[60.4832, 36.6271],
							[60.6348, 36.6276],
							[60.721, 36.6354],
							[61.1402, 36.6621],
							[61.1476, 36.6559],
							[61.1499, 36.6504],
							[61.1481, 36.6487],
							[61.191, 36.5677],
							[61.1932, 36.5485],
							[61.1893, 36.5299],
							[61.1794, 36.5142],
							[61.1724, 36.4987],
							[61.1782, 36.4684],
							[61.1755, 36.4482],
							[61.164, 36.3931],
							[61.1637, 36.381],
							[61.1702, 36.3403],
							[61.1995, 36.2684],
							[61.206, 36.2598],
							[61.2091, 36.2423],
							[61.2292, 36.1724],
							[61.2328, 36.146],
							[61.2265, 36.1201],
							[61.1733, 36.0191],
							[61.1683, 36.0028],
							[61.1593, 35.9896],
							[61.123, 35.968],
							[61.1243, 35.9633],
							[61.1286, 35.9586],
							[61.1795, 35.9503],
							[61.211, 35.9363],
							[61.2269, 35.9184],
							[61.234, 35.9155],
							[61.232, 35.9002],
							[61.2293, 35.8952],
							[61.2314, 35.8929],
							[61.2421, 35.8957],
							[61.2482, 35.8928],
							[61.238, 35.8827],
							[61.2435, 35.8733],
							[61.2419, 35.8668],
							[61.2444, 35.8581],
							[61.2535, 35.8544],
							[61.2548, 35.8489],
							[61.2522, 35.8431],
							[61.2596, 35.8354],
							[61.257, 35.8297],
							[61.26, 35.8129],
							[61.2653, 35.8059],
							[61.2568, 35.7999],
							[61.2468, 35.7698],
							[61.2549, 35.7661],
							[61.2552, 35.7621],
							[61.2525, 35.758],
							[61.25, 35.749],
							[61.2503, 35.7282],
							[61.2418, 35.7061],
							[61.2338, 35.7074],
							[61.2293, 35.7016],
							[61.2198, 35.6948],
							[61.219, 35.6915],
							[61.2227, 35.6804],
							[61.2229, 35.6604],
							[61.2272, 35.6541],
							[61.2319, 35.6583],
							[61.2422, 35.6507],
							[61.2413, 35.6499],
							[61.2522, 35.6326],
							[61.2827, 35.6086],
							[61.2753, 35.6035],
							[61.2786, 35.5974],
							[61.2707, 35.5893],
							[61.2768, 35.5856],
							[61.2774, 35.5747],
							[61.2691, 35.5664],
							[61.2732, 35.5596],
							[61.2731, 35.553],
							[61.2806, 35.5514],
							[61.2815, 35.549],
							[61.2833, 35.5405],
							[61.2813, 35.5337],
							[61.2754, 35.5303],
							[61.2803, 35.5252],
							[61.279, 35.5213],
							[61.2732, 35.5149],
							[61.2668, 35.5132],
							[61.2626, 35.5071],
							[61.2641, 35.5043],
							[61.2606, 35.5005],
							[61.2583, 35.4905],
							[61.2525, 35.4883],
							[61.2518, 35.4852],
							[61.2486, 35.4829],
							[61.2485, 35.4772],
							[61.2415, 35.4752],
							[61.2397, 35.4689],
							[61.2355, 35.4638],
							[61.2371, 35.4569],
							[61.236, 35.4502],
							[61.2256, 35.4463],
							[61.2225, 35.4415],
							[61.233, 35.4333],
							[61.2268, 35.4217],
							[61.2256, 35.4201],
							[61.2154, 35.4198],
							[61.2115, 35.4131],
							[61.1993, 35.4107],
							[61.1977, 35.4068],
							[61.1963, 35.407],
							[61.1881, 35.3996],
							[61.1947, 35.3889],
							[61.1964, 35.3882],
							[61.1982, 35.3897],
							[61.2005, 35.3871],
							[61.1992, 35.3847],
							[61.1941, 35.3823],
							[61.1949, 35.3794],
							[61.1994, 35.378],
							[61.2001, 35.3727],
							[61.1944, 35.3762],
							[61.1849, 35.3707],
							[61.1855, 35.3582],
							[61.1781, 35.357],
							[61.1764, 35.3409],
							[61.1825, 35.3346],
							[61.183, 35.3308],
							[61.1866, 35.3283],
							[61.1858, 35.3235],
							[61.1918, 35.3187],
							[61.1907, 35.3165],
							[61.1871, 35.3163],
							[61.1901, 35.3085],
							[61.1898, 35.3012],
							[61.1919, 35.3013],
							[61.192, 35.2999],
							[61.19, 35.2993],
							[61.1909, 35.2916],
							[61.1825, 35.2905],
							[61.1786, 35.2925],
							[61.1749, 35.2896],
							[61.1684, 35.2893],
							[61.1654, 35.2852],
							[61.1674, 35.2852],
							[61.1608, 35.2789],
							[61.1589, 35.2749],
							[61.1559, 35.2766],
							[61.1566, 35.2824],
							[61.1328, 35.2782],
							[61.127, 35.28],
							[61.1229, 35.2774],
							[61.1155, 35.2819],
							[61.1089, 35.2819],
							[61.103, 35.2791],
							[61.0999, 35.2757],
							[61.0927, 35.2616],
							[61.0971, 35.2579],
							[61.0995, 35.2419],
							[61.0978, 35.2416],
							[61.0978, 35.2367],
							[61.1031, 35.2333],
							[61.1, 35.229],
							[61.0996, 35.2214],
							[61.102, 35.2162],
							[61.1174, 35.2114],
							[61.1171, 35.2073],
							[61.1149, 35.2053],
							[61.1147, 35.1994],
							[61.1052, 35.199],
							[61.1024, 35.1862],
							[61.097, 35.1843],
							[61.0951, 35.1772],
							[61.0876, 35.1713],
							[61.0934, 35.1653],
							[61.0943, 35.1598],
							[61.1097, 35.1614],
							[61.1135, 35.1604],
							[61.1149, 35.1581],
							[61.1091, 35.1546],
							[61.0864, 35.1526],
							[61.0907, 35.1442],
							[61.0959, 35.1432],
							[61.103, 35.129],
							[61.1129, 35.1153],
							[61.1207, 35.0874],
							[61.1215, 35.0779],
							[61.1029, 35.0586],
							[61.0992, 35.0317],
							[61.0888, 34.9977],
							[61.0903, 34.9563],
							[61.0849, 34.936],
							[61.0566, 34.9136],
							[61.0541, 34.9099],
							[61.0566, 34.9016],
							[61.0575, 34.8829],
							[61.0628, 34.8571],
							[61.0559, 34.8121],
							[61.0568, 34.794],
							[61.0125, 34.7315],
							[61.0055, 34.7268],
							[60.9993, 34.7179],
							[60.9887, 34.7089],
							[60.9822, 34.6981],
							[60.9809, 34.6929],
							[60.9838, 34.6747],
							[60.986, 34.6701],
							[60.9837, 34.6621],
							[60.984, 34.6565],
							[60.9893, 34.6494],
							[60.9914, 34.6416],
							[60.9887, 34.6353],
							[60.9847, 34.6312],
							[60.9678, 34.6211],
							[60.9547, 34.6169],
							[60.9477, 34.6173],
							[60.9324, 34.6279],
							[60.9212, 34.6275],
							[60.9123, 34.6228],
							[60.9001, 34.5903],
							[60.8971, 34.5725],
							[60.8948, 34.5683],
							[60.8779, 34.561],
							[60.847, 34.5436],
							[60.8247, 34.5445],
							[60.8176, 34.5419],
							[60.8065, 34.5423],
							[60.7853, 34.5381],
							[60.7669, 34.5306],
							[60.7318, 34.5229],
							[60.7254, 34.5146],
							[60.7277, 34.5104],
							[60.746, 34.4962],
							[60.8051, 34.4588],
							[60.8461, 34.4263],
							[60.8532, 34.4055],
							[60.8691, 34.3741],
							[60.8971, 34.3511],
							[60.9109, 34.3324],
							[60.9347, 34.3059],
							[60.9035, 34.3047],
							[60.8452, 34.3125],
							[60.7921, 34.3111],
							[60.7408, 34.312],
							[60.7039, 34.3155],
							[60.6882, 34.3124],
							[60.6764, 34.3042],
							[60.6744, 34.2989],
							[60.6742, 34.2873],
							[60.6718, 34.2835],
							[60.6366, 34.2582],
							[60.5938, 34.2203],
							[60.5298, 34.1444],
							[60.5187, 34.1294],
							[60.5051, 34.0833],
							[60.479, 34.0768],
							[60.3952, 34.0629],
							[60.3162, 34.0568],
							[60.1611, 34.0546],
							[60.11, 34.0571],
							[60.0595, 34.0698],
							[59.9957, 34.0623],
							[59.8939, 34.0563],
							[59.7301, 34.0629],
							[59.6686, 34.0858],
							[59.6456, 34.1176],
							[59.5991, 34.1708],
							[59.577, 34.2128],
							[59.5425, 34.2552],
							[59.5323, 34.2864],
							[59.5083, 34.2976],
							[59.392, 34.2399],
							[59.3663, 34.2305],
							[59.3274, 34.2113],
							[59.3142, 34.2015],
							[59.2884, 34.1921],
							[59.252, 34.2036],
							[59.2395, 34.204],
							[59.2004, 34.1848],
							[59.1621, 34.1758],
							[59.1132, 34.1877],
							[59.0883, 34.1885],
							[59.0634, 34.1893],
							[59.0375, 34.1798],
							[59.025, 34.1802],
							[59.0115, 34.1703],
							[58.997, 34.1502],
							[58.97, 34.1305],
							[58.9555, 34.1104],
							[58.8961, 34.1431],
							[58.7971, 34.1563],
							[58.7357, 34.1683],
							[58.6732, 34.1701],
							[58.6471, 34.1606],
							[58.6323, 34.1404],
							[58.6152, 34.0998],
							[58.6289, 34.1097],
							[58.638, 34.0787],
						],
						[
							[61.1947, 35.3851],
							[61.1938, 35.3848],
							[61.1945, 35.3847],
							[61.1947, 35.3851],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Semnan", name: "سمنان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[52.7536, 34.3579],
							[52.7059, 34.3671],
							[52.6702, 34.3766],
							[52.6581, 34.3864],
							[52.6342, 34.3961],
							[52.5984, 34.4055],
							[52.5744, 34.4152],
							[52.479, 34.4336],
							[52.455, 34.4433],
							[52.4192, 34.4527],
							[52.3717, 34.4519],
							[52.3477, 34.4615],
							[52.1817, 34.4586],
							[52.1583, 34.4482],
							[52.111, 34.4473],
							[52.0876, 34.4369],
							[52.0521, 34.4362],
							[52.0163, 34.4457],
							[52.0045, 34.4455],
							[51.9331, 34.4643],
							[51.8971, 34.4837],
							[51.8849, 34.4936],
							[51.8253, 34.5126],
							[51.7889, 34.542],
							[51.8233, 34.5728],
							[51.8459, 34.6034],
							[51.8681, 34.644],
							[51.8677, 34.6541],
							[51.8773, 34.7146],
							[51.8769, 34.7246],
							[51.8984, 34.7753],
							[51.9094, 34.7956],
							[51.9085, 34.8157],
							[51.8963, 34.8255],
							[51.8955, 34.8456],
							[51.8828, 34.8655],
							[51.8824, 34.8756],
							[51.8932, 34.8959],
							[51.8895, 34.9764],
							[51.9003, 34.9967],
							[51.9791, 35.0685],
							[51.9786, 35.0786],
							[52.001, 35.0991],
							[52.0, 35.1193],
							[51.9878, 35.1291],
							[51.9873, 35.1392],
							[51.9745, 35.1592],
							[51.9628, 35.159],
							[51.9262, 35.1886],
							[51.9007, 35.2285],
							[51.8885, 35.2384],
							[51.8514, 35.2781],
							[51.8271, 35.2978],
							[51.8261, 35.318],
							[51.8134, 35.3379],
							[51.8114, 35.3783],
							[51.8226, 35.3886],
							[51.8221, 35.3987],
							[51.8317, 35.4392],
							[51.8424, 35.4596],
							[51.854, 35.4598],
							[51.8488, 35.5607],
							[51.8711, 35.5813],
							[51.8833, 35.5714],
							[51.8959, 35.5515],
							[51.9076, 35.5517],
							[51.9198, 35.5418],
							[51.9431, 35.5422],
							[51.9542, 35.5525],
							[51.9775, 35.5529],
							[52.014, 35.5232],
							[52.0146, 35.5131],
							[52.0384, 35.5034],
							[52.1095, 35.4843],
							[52.1461, 35.4546],
							[52.1578, 35.4548],
							[52.219, 35.4052],
							[52.2546, 35.3956],
							[52.3019, 35.3862],
							[52.3136, 35.3864],
							[52.3481, 35.397],
							[52.395, 35.3977],
							[52.443, 35.3782],
							[52.5145, 35.3591],
							[52.5386, 35.3493],
							[52.5979, 35.3401],
							[52.622, 35.3304],
							[52.6572, 35.3309],
							[52.6919, 35.3415],
							[52.7154, 35.3419],
							[52.8313, 35.3739],
							[52.8542, 35.3843],
							[52.8766, 35.4048],
							[52.8884, 35.405],
							[52.9333, 35.446],
							[52.9451, 35.4462],
							[52.9558, 35.4665],
							[53.0019, 35.4874],
							[53.0014, 35.4975],
							[53.0127, 35.5077],
							[53.0359, 35.5182],
							[53.0586, 35.5387],
							[53.0818, 35.5491],
							[53.0932, 35.5594],
							[53.0927, 35.5695],
							[53.1037, 35.5898],
							[53.1015, 35.6403],
							[53.0888, 35.6603],
							[53.0626, 35.7205],
							[53.0619, 35.7407],
							[53.0492, 35.7607],
							[53.0362, 35.7908],
							[53.0347, 35.8312],
							[53.0466, 35.8313],
							[53.0582, 35.8416],
							[53.1298, 35.8426],
							[53.1414, 35.8528],
							[53.1656, 35.8431],
							[53.1772, 35.8533],
							[53.2011, 35.8536],
							[53.2128, 35.8638],
							[53.2247, 35.864],
							[53.2364, 35.8742],
							[53.2718, 35.8948],
							[53.2835, 35.905],
							[53.2955, 35.9051],
							[53.3197, 35.8953],
							[53.3317, 35.8955],
							[53.3319, 35.8854],
							[53.3799, 35.886],
							[53.4157, 35.8964],
							[53.4397, 35.8967],
							[53.4636, 35.907],
							[53.4757, 35.9071],
							[53.5115, 35.9276],
							[53.5355, 35.9379],
							[53.5476, 35.938],
							[53.5717, 35.9483],
							[53.5957, 35.9887],
							[53.6078, 35.9889],
							[53.6078, 35.9989],
							[53.6322, 36.0393],
							[53.6323, 36.0494],
							[53.6446, 36.0595],
							[53.6567, 36.0596],
							[53.7311, 36.1206],
							[53.7808, 36.1411],
							[53.7811, 36.1512],
							[53.7944, 36.1815],
							[53.7961, 36.2217],
							[53.8095, 36.2419],
							[53.8117, 36.2821],
							[53.8254, 36.3023],
							[53.8261, 36.3124],
							[53.8393, 36.3225],
							[53.8408, 36.3426],
							[53.8676, 36.363],
							[53.8684, 36.373],
							[53.8818, 36.3831],
							[53.8827, 36.3932],
							[53.9088, 36.4034],
							[53.9498, 36.4337],
							[53.9901, 36.454],
							[54.042, 36.4644],
							[54.0948, 36.4847],
							[54.1329, 36.4849],
							[54.1719, 36.4951],
							[54.2736, 36.4956],
							[54.2981, 36.4858],
							[54.3362, 36.486],
							[54.4106, 36.4664],
							[54.4233, 36.4665],
							[54.4504, 36.4865],
							[54.4767, 36.4965],
							[54.4903, 36.5065],
							[54.5156, 36.5065],
							[54.5419, 36.5165],
							[54.5672, 36.5166],
							[54.5943, 36.5365],
							[54.607, 36.5365],
							[54.6206, 36.5465],
							[54.635, 36.5664],
							[54.7045, 36.636],
							[54.7452, 36.6658],
							[54.7867, 36.7056],
							[54.8265, 36.7254],
							[54.8927, 36.755],
							[54.9453, 36.7747],
							[54.958, 36.7746],
							[55.0225, 36.784],
							[55.0488, 36.7937],
							[55.1637, 36.7925],
							[55.2267, 36.7818],
							[55.2522, 36.7815],
							[55.4285, 36.7492],
							[55.4413, 36.749],
							[55.4678, 36.7586],
							[55.4934, 36.7582],
							[55.5208, 36.7777],
							[55.5354, 36.7973],
							[55.5404, 36.847],
							[55.5487, 36.9066],
							[55.5716, 37.0264],
							[55.5889, 37.0462],
							[55.5911, 37.0563],
							[55.6523, 37.0957],
							[55.6547, 37.1058],
							[55.6701, 37.1157],
							[55.6881, 37.1356],
							[55.712, 37.1858],
							[55.7137, 37.1958],
							[55.7472, 37.2557],
							[55.816, 37.3351],
							[55.8292, 37.3551],
							[55.842, 37.3549],
							[55.8549, 37.3647],
							[55.8675, 37.3544],
							[55.8672, 37.3443],
							[55.9169, 37.3031],
							[55.9296, 37.3029],
							[55.9413, 37.2825],
							[55.9535, 37.2722],
							[55.9639, 37.2419],
							[55.9748, 37.2215],
							[55.9865, 37.2112],
							[55.9852, 37.2012],
							[55.9968, 37.1909],
							[55.9953, 37.1809],
							[56.0066, 37.1706],
							[56.0547, 37.1495],
							[56.0934, 37.1487],
							[56.1079, 37.1585],
							[56.1465, 37.1576],
							[56.1609, 37.1674],
							[56.1737, 37.1671],
							[56.1979, 37.1565],
							[56.2075, 37.1361],
							[56.2056, 37.1261],
							[56.1927, 37.1264],
							[56.1648, 37.1169],
							[56.139, 37.1174],
							[56.1109, 37.1079],
							[56.0979, 37.1082],
							[56.0799, 37.0883],
							[56.0774, 37.0782],
							[56.0619, 37.0684],
							[56.0878, 37.0679],
							[56.0982, 37.0575],
							[56.0958, 37.0475],
							[56.0828, 37.0478],
							[56.0804, 37.0377],
							[56.091, 37.0274],
							[56.0886, 37.0173],
							[56.1121, 37.0067],
							[56.1098, 36.9967],
							[56.1205, 36.9864],
							[56.1032, 36.9666],
							[56.1011, 36.9566],
							[56.0821, 36.9269],
							[56.0782, 36.9069],
							[56.0653, 36.9072],
							[56.0617, 36.8872],
							[56.0728, 36.877],
							[56.0695, 36.857],
							[56.1381, 36.7956],
							[56.1625, 36.7851],
							[56.1977, 36.7544],
							[56.2084, 36.7342],
							[56.2457, 36.7233],
							[56.3276, 36.6516],
							[56.4296, 36.649],
							[56.5039, 36.6272],
							[56.5166, 36.6269],
							[56.5283, 36.6167],
							[56.5919, 36.615],
							[56.6716, 36.6426],
							[56.6844, 36.6423],
							[56.711, 36.6515],
							[56.7237, 36.6511],
							[56.7353, 36.6409],
							[56.7723, 36.6299],
							[56.807, 36.5991],
							[56.8059, 36.5892],
							[56.8175, 36.579],
							[56.8084, 36.5001],
							[56.7807, 36.481],
							[56.7796, 36.4711],
							[56.7658, 36.4616],
							[56.736, 36.4227],
							[56.704, 36.3641],
							[56.692, 36.2554],
							[56.7002, 36.2155],
							[56.7118, 36.2053],
							[56.7096, 36.1855],
							[56.7189, 36.1556],
							[56.7156, 36.1259],
							[56.7271, 36.1157],
							[56.7238, 36.0861],
							[56.7353, 36.0759],
							[56.732, 36.0463],
							[56.7435, 36.0361],
							[56.7528, 36.0061],
							[56.7506, 35.9863],
							[56.761, 35.9663],
							[56.7588, 35.9465],
							[56.7703, 35.9363],
							[56.7691, 35.9264],
							[56.8036, 35.8958],
							[56.8277, 35.8853],
							[56.8392, 35.8751],
							[56.8518, 35.8748],
							[56.8862, 35.8442],
							[56.9102, 35.8337],
							[56.9217, 35.8235],
							[56.9343, 35.8232],
							[56.9686, 35.7926],
							[56.9812, 35.7923],
							[56.9927, 35.7821],
							[57.0281, 35.7614],
							[57.0624, 35.7308],
							[57.0726, 35.7107],
							[57.0966, 35.7002],
							[57.1069, 35.6801],
							[57.1011, 35.6305],
							[57.0862, 35.611],
							[57.0725, 35.6014],
							[57.0575, 35.5818],
							[57.0438, 35.5722],
							[57.0014, 35.5333],
							[56.9728, 35.5039],
							[56.943, 35.4646],
							[56.9304, 35.4649],
							[56.9293, 35.4549],
							[56.9144, 35.4352],
							[56.9133, 35.4252],
							[56.895, 35.3754],
							[56.8939, 35.3654],
							[56.8768, 35.3256],
							[56.863, 35.3158],
							[56.8619, 35.3058],
							[56.8344, 35.2862],
							[56.7955, 35.2769],
							[56.7817, 35.2671],
							[56.7553, 35.2576],
							[56.7301, 35.258],
							[56.6773, 35.2388],
							[56.6121, 35.2199],
							[56.5995, 35.2201],
							[56.5474, 35.2009],
							[56.5205, 35.1813],
							[56.4682, 35.1521],
							[56.4028, 35.1031],
							[56.3506, 35.0539],
							[56.3378, 35.0441],
							[56.3246, 35.0243],
							[56.2739, 34.9851],
							[56.2484, 34.9555],
							[56.2229, 34.9159],
							[56.2105, 34.9061],
							[56.1244, 34.7677],
							[56.0769, 34.6986],
							[56.0416, 34.6593],
							[56.0302, 34.6297],
							[56.007, 34.6002],
							[55.9954, 34.5904],
							[55.984, 34.5707],
							[55.9724, 34.561],
							[55.9267, 34.5021],
							[55.9154, 34.4825],
							[55.904, 34.4727],
							[55.8927, 34.453],
							[55.8813, 34.4433],
							[55.87, 34.4236],
							[55.8586, 34.4138],
							[55.8587, 34.4037],
							[55.8354, 34.3738],
							[55.8238, 34.374],
							[55.8121, 34.3641],
							[55.7774, 34.3546],
							[55.5587, 34.3588],
							[55.5241, 34.3695],
							[55.4439, 34.3608],
							[55.4093, 34.3613],
							[55.3868, 34.3517],
							[55.3753, 34.3518],
							[55.3752, 34.342],
							[55.3633, 34.3518],
							[55.3628, 34.3618],
							[55.3509, 34.3618],
							[55.3384, 34.3717],
							[55.3141, 34.3817],
							[55.3021, 34.3817],
							[55.2658, 34.3916],
							[55.1586, 34.3914],
							[55.1233, 34.3813],
							[55.0285, 34.371],
							[54.4583, 34.3678],
							[54.4231, 34.3575],
							[54.0915, 34.3548],
							[54.0089, 34.344],
							[53.9616, 34.3435],
							[53.9382, 34.3332],
							[53.6069, 34.3297],
							[53.5361, 34.3188],
							[53.2401, 34.315],
							[53.1689, 34.324],
							[52.9082, 34.3201],
							[52.8844, 34.3298],
							[52.8409, 34.3291],
							[52.8369, 34.3391],
							[52.825, 34.3389],
							[52.7536, 34.3579],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Sistan and Baluchestan", name: "سیستان و بلوچستان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[59.8932, 25.3454],
							[59.8971, 25.3449],
							[59.9004, 25.3424],
							[59.9029, 25.3429],
							[59.9029, 25.3418],
							[59.8865, 25.3415],
							[59.8915, 25.3457],
							[59.8932, 25.3454],
						],
					],
					[
						[
							[59.2632, 25.4621],
							[59.2839, 25.4632],
							[59.2879, 25.4658],
							[59.2842, 25.4694],
							[59.2693, 25.4714],
							[59.2787, 25.4794],
							[59.2677, 25.4893],
							[59.2787, 25.489],
							[59.2787, 25.5081],
							[59.2677, 25.5084],
							[59.2677, 25.4989],
							[59.257, 25.4992],
							[59.2595, 25.5019],
							[59.2567, 25.5046],
							[59.2567, 25.5088],
							[59.2457, 25.5091],
							[59.2457, 25.5187],
							[59.2347, 25.5286],
							[59.2347, 25.5478],
							[59.2127, 25.5676],
							[59.2127, 25.5772],
							[59.2017, 25.5775],
							[59.1798, 25.5973],
							[59.1798, 25.6165],
							[59.1689, 25.6264],
							[59.1799, 25.6357],
							[59.169, 25.6456],
							[59.114, 25.6472],
							[59.114, 25.6568],
							[59.1031, 25.6668],
							[59.1034, 25.7244],
							[59.0924, 25.7343],
							[59.0706, 25.7638],
							[59.0157, 25.7942],
							[58.9938, 25.8141],
							[58.939, 25.8542],
							[58.8843, 25.9039],
							[58.8844, 25.9135],
							[58.8735, 25.9234],
							[58.8294, 25.9248],
							[58.8295, 25.9344],
							[58.8185, 25.9443],
							[58.8186, 25.954],
							[58.8297, 25.9632],
							[58.8298, 25.9825],
							[58.841, 26.0014],
							[58.852, 26.001],
							[58.8631, 26.0103],
							[58.8633, 26.0295],
							[58.8855, 26.0481],
							[58.8858, 26.0769],
							[58.897, 26.0958],
							[58.8861, 26.1057],
							[58.8866, 26.1538],
							[58.8979, 26.1823],
							[58.909, 26.1819],
							[58.9091, 26.1916],
							[58.9202, 26.2008],
							[58.9094, 26.2204],
							[58.9095, 26.23],
							[58.9207, 26.2393],
							[58.9208, 26.2489],
							[58.9318, 26.2486],
							[58.9542, 26.2672],
							[58.9543, 26.2768],
							[58.9655, 26.2861],
							[58.9545, 26.2961],
							[58.9547, 26.3057],
							[58.9437, 26.3157],
							[58.9439, 26.3253],
							[58.911, 26.3553],
							[58.889, 26.3656],
							[58.878, 26.3756],
							[58.8784, 26.4335],
							[58.8895, 26.4524],
							[58.9007, 26.4617],
							[58.923, 26.4708],
							[58.9565, 26.4892],
							[58.9677, 26.4889],
							[58.9678, 26.518],
							[58.9566, 26.5279],
							[58.9343, 26.5382],
							[58.8898, 26.5782],
							[58.8899, 26.5879],
							[58.9012, 26.5973],
							[58.9013, 26.6071],
							[58.9125, 26.6068],
							[58.9467, 26.6353],
							[58.9508, 26.7634],
							[58.9398, 26.7736],
							[58.9403, 26.7835],
							[58.9293, 26.7936],
							[58.9188, 26.8136],
							[58.8743, 26.8342],
							[58.8411, 26.8546],
							[58.8302, 26.8647],
							[58.808, 26.875],
							[58.8084, 26.885],
							[58.7975, 26.8951],
							[58.8053, 27.0536],
							[58.7945, 27.0637],
							[58.7841, 27.0837],
							[58.7852, 27.1035],
							[58.7754, 27.1334],
							[58.7765, 27.1532],
							[58.7651, 27.1534],
							[58.7683, 27.2127],
							[58.7819, 27.2521],
							[58.7858, 27.3213],
							[58.8006, 27.3804],
							[58.8142, 27.4197],
							[58.8268, 27.4393],
							[58.8285, 27.469],
							[58.8423, 27.5083],
							[58.8676, 27.5474],
							[58.8688, 27.5672],
							[58.8815, 27.5867],
							[58.8827, 27.6065],
							[58.8961, 27.636],
							[58.9101, 27.8442],
							[58.9006, 27.8742],
							[58.9047, 27.9338],
							[58.8946, 27.9539],
							[58.8966, 27.9836],
							[58.8871, 28.0137],
							[58.8917, 28.0832],
							[58.8809, 28.0934],
							[58.8937, 28.113],
							[58.9059, 28.1227],
							[58.9417, 28.1418],
							[58.9539, 28.1515],
							[58.9897, 28.1706],
							[59.0025, 28.1903],
							[59.0269, 28.2096],
							[59.0403, 28.2392],
							[59.0417, 28.2591],
							[59.0558, 28.2986],
							[59.0449, 28.3088],
							[59.0456, 28.3187],
							[59.0252, 28.359],
							[59.0034, 28.3794],
							[58.981, 28.3898],
							[58.9695, 28.3901],
							[58.9355, 28.4008],
							[58.924, 28.401],
							[58.8791, 28.4219],
							[58.8682, 28.4321],
							[58.8701, 28.4619],
							[58.8823, 28.4716],
							[58.8829, 28.4816],
							[58.8951, 28.4913],
							[58.9182, 28.4908],
							[58.9419, 28.5003],
							[58.9765, 28.4995],
							[59.0009, 28.519],
							[59.0477, 28.5279],
							[59.0715, 28.5374],
							[59.1067, 28.5466],
							[59.1189, 28.5563],
							[59.1542, 28.5655],
							[59.1664, 28.5752],
							[59.2139, 28.5941],
							[59.2749, 28.6426],
							[59.2864, 28.6423],
							[59.3718, 28.7102],
							[59.3725, 28.7201],
							[59.3847, 28.7298],
							[59.386, 28.7497],
							[59.3535, 28.7804],
							[59.3548, 28.8003],
							[59.333, 28.8207],
							[59.3337, 28.8307],
							[59.3228, 28.8409],
							[59.3255, 28.8807],
							[59.3377, 28.8904],
							[59.339, 28.9103],
							[59.3281, 28.9205],
							[59.3288, 28.9305],
							[59.3192, 28.9606],
							[59.2986, 29.001],
							[59.2993, 29.0109],
							[59.3638, 29.1093],
							[59.3884, 29.1287],
							[59.389, 29.1386],
							[59.4019, 29.1583],
							[59.4271, 29.1876],
							[59.44, 29.2073],
							[59.4407, 29.2172],
							[59.4536, 29.2369],
							[59.4581, 29.3065],
							[59.4387, 29.3668],
							[59.4413, 29.4066],
							[59.4224, 29.4767],
							[59.4144, 29.5366],
							[59.4185, 29.6062],
							[59.4075, 29.6164],
							[59.4081, 29.6263],
							[59.3859, 29.6467],
							[59.3177, 29.6782],
							[59.2955, 29.6986],
							[59.296, 29.7086],
							[59.2849, 29.7188],
							[59.2866, 29.7485],
							[59.3138, 29.8175],
							[59.3144, 29.8274],
							[59.3272, 29.847],
							[59.3277, 29.8569],
							[59.3411, 29.8864],
							[59.3551, 29.9259],
							[59.3557, 29.9358],
							[59.3848, 30.0346],
							[59.3998, 30.0938],
							[59.4066, 30.2223],
							[59.4203, 30.2614],
							[59.4218, 30.2909],
							[59.4344, 30.3101],
							[59.4488, 30.3684],
							[59.4496, 30.3879],
							[59.4625, 30.4165],
							[59.4639, 30.4552],
							[59.4768, 30.493],
							[59.4774, 30.5122],
							[59.5034, 30.5976],
							[59.5154, 30.6067],
							[59.5627, 30.6238],
							[59.5747, 30.6328],
							[59.5981, 30.6318],
							[59.6572, 30.6483],
							[59.6806, 30.6473],
							[59.716, 30.6553],
							[59.7518, 30.673],
							[59.8112, 30.6803],
							[59.847, 30.6886],
							[59.8711, 30.6974],
							[59.9188, 30.7053],
							[59.9307, 30.7048],
							[59.943, 30.7141],
							[59.9548, 30.7136],
							[60.0026, 30.7216],
							[60.0868, 30.7476],
							[60.1105, 30.7467],
							[60.1228, 30.7559],
							[60.183, 30.7731],
							[60.219, 30.7815],
							[60.2668, 30.7894],
							[60.3033, 30.8075],
							[60.3157, 30.8168],
							[60.3275, 30.8163],
							[60.4066, 30.7445],
							[60.4184, 30.744],
							[60.4296, 30.7337],
							[60.4883, 30.7312],
							[60.5111, 30.7204],
							[60.5228, 30.7198],
							[60.5338, 30.7095],
							[60.5455, 30.709],
							[60.5449, 30.6992],
							[60.5559, 30.6888],
							[60.5675, 30.6883],
							[60.5784, 30.678],
							[60.634, 30.6457],
							[60.6447, 30.6353],
							[60.6668, 30.6243],
							[60.6879, 30.6035],
							[60.6976, 30.5832],
							[60.7176, 30.5525],
							[60.7367, 30.5118],
							[60.776, 30.45],
							[60.8368, 30.3866],
							[60.8714, 30.3852],
							[60.9193, 30.3938],
							[60.9525, 30.3921],
							[61.0199, 30.3988],
							[61.0421, 30.3978],
							[61.0441, 30.4177],
							[61.0562, 30.4271],
							[61.0572, 30.4371],
							[61.0502, 30.4774],
							[61.0401, 30.4878],
							[61.0421, 30.5077],
							[61.0239, 30.5485],
							[61.0249, 30.5584],
							[61.0148, 30.5689],
							[61.0167, 30.5887],
							[61.0075, 30.6091],
							[61.0103, 30.6389],
							[60.9938, 30.6994],
							[61.0077, 30.8579],
							[60.9992, 30.8881],
							[61.0062, 30.9771],
							[61.0187, 30.9964],
							[61.0201, 31.0162],
							[61.0332, 31.0454],
							[61.0345, 31.0652],
							[61.0473, 31.0944],
							[61.061, 31.1435],
							[61.0723, 31.153],
							[61.0962, 31.2117],
							[61.1071, 31.2213],
							[61.1184, 31.2409],
							[61.1188, 31.2508],
							[61.141, 31.2899],
							[61.1623, 31.3092],
							[61.1632, 31.3684],
							[61.1532, 31.3787],
							[61.1534, 31.3885],
							[61.1434, 31.3988],
							[61.1239, 31.4391],
							[61.1239, 31.4527],
							[61.7065, 31.3772],
							[61.7776, 31.3036],
							[61.7729, 31.2521],
							[61.7778, 31.2181],
							[61.7744, 31.2094],
							[61.8012, 31.1735],
							[61.8079, 31.1577],
							[61.8072, 31.1469],
							[61.8104, 31.1403],
							[61.8094, 31.1343],
							[61.8157, 31.1215],
							[61.8154, 31.1136],
							[61.8181, 31.106],
							[61.8343, 31.0879],
							[61.8295, 31.0674],
							[61.8333, 31.0624],
							[61.8332, 31.0539],
							[61.8392, 31.0359],
							[61.8339, 31.0302],
							[61.8349, 31.0206],
							[61.8254, 31.0037],
							[61.8254, 30.9977],
							[61.8324, 30.972],
							[61.816, 30.9598],
							[61.7977, 30.9413],
							[61.7957, 30.9405],
							[61.7878, 30.9435],
							[61.7852, 30.9366],
							[61.7849, 30.9112],
							[61.7862, 30.904],
							[61.79, 30.893],
							[61.7976, 30.8853],
							[61.7982, 30.8801],
							[61.7958, 30.8668],
							[61.7991, 30.8545],
							[61.8076, 30.8376],
							[61.8069, 30.8353],
							[61.803, 30.8294],
							[61.794, 30.8262],
							[61.7767, 30.8109],
							[61.7592, 30.79],
							[61.2094, 30.2176],
							[60.8658, 29.8532],
							[60.8866, 29.8424],
							[60.8994, 29.8375],
							[61.1259, 29.5729],
							[61.1926, 29.5025],
							[61.3111, 29.3965],
							[61.3726, 29.3487],
							[61.3775, 29.3353],
							[61.3734, 29.3165],
							[61.3577, 29.2828],
							[61.3835, 29.2215],
							[61.391, 29.2136],
							[61.3991, 29.2085],
							[61.4224, 29.2005],
							[61.4287, 29.1938],
							[61.4148, 29.1562],
							[61.4134, 29.1412],
							[61.4158, 29.1356],
							[61.4435, 29.1281],
							[61.4586, 29.1224],
							[61.4705, 29.114],
							[61.4779, 29.1028],
							[61.4789, 29.0806],
							[61.5032, 29.0564],
							[61.5056, 29.0481],
							[61.5032, 29.0192],
							[61.5043, 29.0075],
							[61.5472, 28.9626],
							[61.5633, 28.9425],
							[61.5736, 28.914],
							[61.62, 28.8557],
							[61.6403, 28.8216],
							[61.6657, 28.7952],
							[61.681, 28.7706],
							[61.7211, 28.7382],
							[61.7775, 28.6799],
							[61.7956, 28.6558],
							[61.8042, 28.6402],
							[61.8229, 28.6327],
							[61.8701, 28.5971],
							[61.9086, 28.5738],
							[61.9576, 28.5487],
							[61.9932, 28.5408],
							[62.0158, 28.5286],
							[62.0433, 28.5079],
							[62.1158, 28.4851],
							[62.1524, 28.4895],
							[62.1705, 28.4875],
							[62.2024, 28.4787],
							[62.2399, 28.4659],
							[62.2694, 28.4665],
							[62.2963, 28.4635],
							[62.35, 28.4412],
							[62.3725, 28.438],
							[62.4397, 28.4071],
							[62.4723, 28.3761],
							[62.5029, 28.3313],
							[62.516, 28.3223],
							[62.5416, 28.31],
							[62.5731, 28.2785],
							[62.5923, 28.2551],
							[62.7869, 28.2812],
							[62.7919, 28.2771],
							[62.7966, 28.2089],
							[62.7808, 28.1029],
							[62.7682, 28.0324],
							[62.7718, 28.0151],
							[62.7901, 27.9781],
							[62.7975, 27.9145],
							[62.8069, 27.8597],
							[62.8198, 27.805],
							[62.83, 27.7353],
							[62.8386, 27.6474],
							[62.852, 27.5427],
							[62.8554, 27.4651],
							[62.8074, 27.3401],
							[62.8281, 27.3193],
							[62.8325, 27.3049],
							[62.8232, 27.2862],
							[62.7815, 27.2514],
							[62.783, 27.2343],
							[62.7979, 27.2215],
							[62.8214, 27.2123],
							[62.8437, 27.2131],
							[62.8783, 27.2171],
							[62.8923, 27.2108],
							[62.9087, 27.1992],
							[62.9517, 27.1921],
							[62.9772, 27.1951],
							[63.0586, 27.2332],
							[63.1158, 27.2344],
							[63.1842, 27.2414],
							[63.2795, 27.1922],
							[63.2992, 27.1789],
							[63.3175, 27.1387],
							[63.3131, 27.1293],
							[63.3035, 27.1224],
							[63.2903, 27.1157],
							[63.2732, 27.1024],
							[63.2559, 27.083],
							[63.2567, 26.9287],
							[63.2764, 26.8893],
							[63.2814, 26.8746],
							[63.2755, 26.8648],
							[63.2048, 26.8495],
							[63.1921, 26.8435],
							[63.2032, 26.7683],
							[63.1997, 26.738],
							[63.1731, 26.6701],
							[63.1692, 26.6473],
							[63.162, 26.6436],
							[63.1565, 26.645],
							[63.09, 26.6441],
							[63.0514, 26.6376],
							[63.0019, 26.6519],
							[62.9859, 26.6533],
							[62.9465, 26.6529],
							[62.9195, 26.6461],
							[62.8401, 26.6475],
							[62.8216, 26.6522],
							[62.7787, 26.6523],
							[62.7607, 26.6484],
							[62.7368, 26.6385],
							[62.7316, 26.6343],
							[62.7264, 26.6241],
							[62.7214, 26.6199],
							[62.6972, 26.6134],
							[62.6852, 26.6126],
							[62.6609, 26.6165],
							[62.6483, 26.6091],
							[62.6378, 26.5994],
							[62.6081, 26.585],
							[62.5299, 26.5785],
							[62.512, 26.5813],
							[62.4784, 26.5696],
							[62.4634, 26.5676],
							[62.4412, 26.57],
							[62.4345, 26.5681],
							[62.4107, 26.5444],
							[62.4035, 26.5393],
							[62.3971, 26.5383],
							[62.3613, 26.5395],
							[62.3433, 26.5312],
							[62.3336, 26.5285],
							[62.3151, 26.5289],
							[62.306, 26.5244],
							[62.2994, 26.5136],
							[62.2959, 26.4987],
							[62.297, 26.4941],
							[62.3088, 26.4767],
							[62.3022, 26.4676],
							[62.2826, 26.4473],
							[62.2698, 26.4457],
							[62.2577, 26.4488],
							[62.2564, 26.446],
							[62.2646, 26.4321],
							[62.2647, 26.4127],
							[62.2913, 26.3868],
							[62.2915, 26.3711],
							[62.2825, 26.3584],
							[62.2635, 26.3582],
							[62.2329, 26.3661],
							[62.1997, 26.3695],
							[62.1447, 26.3893],
							[62.1324, 26.39],
							[62.1281, 26.3804],
							[62.1281, 26.3296],
							[62.1211, 26.3168],
							[62.1067, 26.3142],
							[62.0611, 26.3154],
							[61.9867, 26.2797],
							[61.9582, 26.2686],
							[61.9074, 26.2601],
							[61.8708, 26.2467],
							[61.8479, 26.2281],
							[61.833, 26.1711],
							[61.8018, 25.9943],
							[61.7914, 25.9261],
							[61.7825, 25.8856],
							[61.7802, 25.8709],
							[61.7819, 25.8515],
							[61.7804, 25.8182],
							[61.7688, 25.8018],
							[61.7486, 25.7984],
							[61.7177, 25.796],
							[61.6892, 25.7992],
							[61.6906, 25.7585],
							[61.6899, 25.7179],
							[61.705, 25.7039],
							[61.7067, 25.6863],
							[61.7045, 25.6725],
							[61.689, 25.6626],
							[61.6807, 25.6544],
							[61.6672, 25.5124],
							[61.6698, 25.4219],
							[61.6643, 25.3334],
							[61.6556, 25.2975],
							[61.6451, 25.2736],
							[61.6294, 25.2452],
							[61.6105, 25.195],
							[61.5987, 25.1868],
							[61.5899, 25.1837],
							[61.5768, 25.1843],
							[61.5771, 25.1865],
							[61.5751, 25.1876],
							[61.5746, 25.1932],
							[61.5726, 25.1957],
							[61.5632, 25.1982],
							[61.5626, 25.2032],
							[61.5649, 25.2143],
							[61.5638, 25.219],
							[61.5646, 25.2207],
							[61.5693, 25.2185],
							[61.5735, 25.219],
							[61.5754, 25.2207],
							[61.5751, 25.2246],
							[61.5735, 25.2282],
							[61.571, 25.2296],
							[61.5601, 25.2282],
							[61.549, 25.2296],
							[61.5507, 25.2357],
							[61.5485, 25.2387],
							[61.5493, 25.2346],
							[61.5468, 25.2326],
							[61.5468, 25.2307],
							[61.549, 25.2285],
							[61.5601, 25.2274],
							[61.5685, 25.2288],
							[61.5729, 25.2254],
							[61.5735, 25.2221],
							[61.5721, 25.2201],
							[61.5665, 25.2229],
							[61.5618, 25.2204],
							[61.5618, 25.2065],
							[61.5599, 25.1993],
							[61.5607, 25.1924],
							[61.5665, 25.1868],
							[61.5732, 25.1843],
							[61.5738, 25.1799],
							[61.5621, 25.1824],
							[61.5393, 25.1815],
							[61.536, 25.1863],
							[61.5329, 25.1874],
							[61.521, 25.1854],
							[61.5038, 25.1907],
							[61.5018, 25.1957],
							[61.499, 25.1982],
							[61.5038, 25.1899],
							[61.5215, 25.1843],
							[61.5207, 25.1821],
							[61.5057, 25.1782],
							[61.5024, 25.1751],
							[61.499, 25.1746],
							[61.4971, 25.1701],
							[61.4951, 25.1699],
							[61.4979, 25.1751],
							[61.4976, 25.1787],
							[61.4957, 25.1782],
							[61.4949, 25.1746],
							[61.4926, 25.1729],
							[61.4907, 25.1829],
							[61.4793, 25.184],
							[61.481, 25.1824],
							[61.4896, 25.181],
							[61.4912, 25.1696],
							[61.4926, 25.1682],
							[61.4954, 25.169],
							[61.4987, 25.166],
							[61.5029, 25.1665],
							[61.5038, 25.164],
							[61.5015, 25.1621],
							[61.501, 25.159],
							[61.5024, 25.1565],
							[61.4996, 25.1549],
							[61.4982, 25.1468],
							[61.4943, 25.146],
							[61.4896, 25.1413],
							[61.4854, 25.1401],
							[61.4863, 25.1351],
							[61.4821, 25.1329],
							[61.4818, 25.1299],
							[61.4793, 25.131],
							[61.4779, 25.1271],
							[61.4704, 25.1257],
							[61.4682, 25.1238],
							[61.4679, 25.121],
							[61.4604, 25.1193],
							[61.4704, 25.1185],
							[61.4724, 25.1226],
							[61.4768, 25.1251],
							[61.4793, 25.1249],
							[61.4801, 25.1204],
							[61.4829, 25.1199],
							[61.4865, 25.1118],
							[61.4793, 25.1063],
							[61.4815, 25.1024],
							[61.4707, 25.1024],
							[61.4676, 25.1007],
							[61.4501, 25.0982],
							[61.4504, 25.0946],
							[61.4263, 25.0907],
							[61.4157, 25.0854],
							[61.4107, 25.0785],
							[61.4096, 25.0718],
							[61.411, 25.0701],
							[61.4188, 25.0682],
							[61.4212, 25.0726],
							[61.4199, 25.0674],
							[61.4254, 25.0637],
							[61.4249, 25.0604],
							[61.4229, 25.059],
							[61.4196, 25.059],
							[61.3985, 25.0671],
							[61.329, 25.0829],
							[61.2762, 25.1001],
							[61.2468, 25.1076],
							[61.2346, 25.1126],
							[61.1838, 25.1224],
							[61.1779, 25.126],
							[61.1801, 25.1293],
							[61.1785, 25.1332],
							[61.1818, 25.1346],
							[61.1821, 25.1365],
							[61.1796, 25.1404],
							[61.1729, 25.1446],
							[61.1726, 25.1507],
							[61.1754, 25.1457],
							[61.1801, 25.1454],
							[61.1815, 25.1482],
							[61.1779, 25.1488],
							[61.1776, 25.1571],
							[61.1724, 25.1635],
							[61.146, 25.1738],
							[61.0782, 25.1951],
							[60.9785, 25.2185],
							[60.9335, 25.2263],
							[60.9165, 25.2307],
							[60.906, 25.2315],
							[60.8971, 25.2343],
							[60.8513, 25.2401],
							[60.8482, 25.2465],
							[60.8421, 25.2488],
							[60.8393, 25.2482],
							[60.8332, 25.2524],
							[60.811, 25.2521],
							[60.8076, 25.2543],
							[60.7988, 25.2549],
							[60.796, 25.2588],
							[60.7901, 25.2604],
							[60.7776, 25.2615],
							[60.774, 25.2632],
							[60.7696, 25.2618],
							[60.7546, 25.2638],
							[60.7515, 25.2657],
							[60.751, 25.2693],
							[60.7471, 25.2679],
							[60.7468, 25.2699],
							[60.744, 25.2696],
							[60.744, 25.2671],
							[60.7479, 25.266],
							[60.744, 25.2663],
							[60.7435, 25.2699],
							[60.7393, 25.2718],
							[60.7243, 25.2721],
							[60.7204, 25.2746],
							[60.711, 25.2757],
							[60.6763, 25.2746],
							[60.656, 25.2787],
							[60.634, 25.2799],
							[60.609, 25.2846],
							[60.6076, 25.2885],
							[60.6021, 25.2921],
							[60.6051, 25.291],
							[60.6029, 25.2938],
							[60.6037, 25.2979],
							[60.6049, 25.2982],
							[60.6046, 25.2935],
							[60.6087, 25.2896],
							[60.6126, 25.2924],
							[60.6165, 25.2915],
							[60.6196, 25.2929],
							[60.6213, 25.2968],
							[60.6224, 25.2951],
							[60.626, 25.296],
							[60.6271, 25.3032],
							[60.6237, 25.3113],
							[60.6193, 25.311],
							[60.614, 25.3143],
							[60.6207, 25.3143],
							[60.6218, 25.3179],
							[60.6146, 25.326],
							[60.6132, 25.3346],
							[60.6054, 25.3396],
							[60.604, 25.3385],
							[60.6046, 25.3363],
							[60.6018, 25.3368],
							[60.604, 25.3415],
							[60.5949, 25.3474],
							[60.6007, 25.3518],
							[60.5996, 25.3551],
							[60.6026, 25.3549],
							[60.6076, 25.3638],
							[60.609, 25.3707],
							[60.6085, 25.3829],
							[60.6099, 25.3865],
							[60.6057, 25.3996],
							[60.5699, 25.4326],
							[60.5554, 25.4385],
							[60.5379, 25.4426],
							[60.4996, 25.4415],
							[60.4874, 25.4385],
							[60.4885, 25.4335],
							[60.4871, 25.4337],
							[60.4857, 25.4382],
							[60.4815, 25.4382],
							[60.4657, 25.4326],
							[60.4468, 25.4204],
							[60.4357, 25.4101],
							[60.4318, 25.4085],
							[60.4193, 25.3951],
							[60.4074, 25.3788],
							[60.4032, 25.3662],
							[60.4296, 25.3576],
							[60.434, 25.3579],
							[60.4357, 25.3557],
							[60.4335, 25.3524],
							[60.4335, 25.3574],
							[60.4293, 25.3568],
							[60.4032, 25.3657],
							[60.4029, 25.3518],
							[60.4079, 25.3388],
							[60.4415, 25.3374],
							[60.4418, 25.3354],
							[60.4476, 25.3324],
							[60.454, 25.3312],
							[60.4543, 25.3282],
							[60.4485, 25.3207],
							[60.4479, 25.3171],
							[60.4515, 25.3121],
							[60.4604, 25.3074],
							[60.4621, 25.3024],
							[60.4721, 25.2985],
							[60.4732, 25.2962],
							[60.4724, 25.2915],
							[60.469, 25.2874],
							[60.4671, 25.2871],
							[60.4599, 25.2893],
							[60.4562, 25.2926],
							[60.4504, 25.2926],
							[60.4238, 25.2985],
							[60.4229, 25.301],
							[60.4276, 25.2985],
							[60.4324, 25.301],
							[60.4324, 25.3026],
							[60.4304, 25.3015],
							[60.4271, 25.3054],
							[60.4271, 25.3113],
							[60.4185, 25.3146],
							[60.4026, 25.3174],
							[60.3924, 25.3171],
							[60.3901, 25.319],
							[60.3829, 25.3193],
							[60.3796, 25.3218],
							[60.3765, 25.3218],
							[60.3746, 25.3246],
							[60.3685, 25.324],
							[60.3674, 25.326],
							[60.3549, 25.3262],
							[60.3499, 25.3293],
							[60.3379, 25.3307],
							[60.3213, 25.3296],
							[60.3129, 25.3324],
							[60.3082, 25.3357],
							[60.3076, 25.339],
							[60.2974, 25.349],
							[60.2954, 25.3535],
							[60.2957, 25.3563],
							[60.2974, 25.3526],
							[60.3118, 25.3557],
							[60.3138, 25.359],
							[60.3138, 25.3629],
							[60.3107, 25.3696],
							[60.3035, 25.3776],
							[60.2935, 25.3849],
							[60.2762, 25.3888],
							[60.2599, 25.3882],
							[60.2421, 25.3832],
							[60.221, 25.3796],
							[60.1957, 25.3685],
							[60.191, 25.3621],
							[60.1896, 25.3457],
							[60.2007, 25.3424],
							[60.2057, 25.3343],
							[60.2096, 25.3321],
							[60.2054, 25.3262],
							[60.1971, 25.3243],
							[60.1929, 25.3282],
							[60.189, 25.3285],
							[60.1827, 25.3341],
							[60.1771, 25.3363],
							[60.149, 25.3379],
							[60.1185, 25.3446],
							[60.1071, 25.3524],
							[60.1071, 25.3571],
							[60.1157, 25.3585],
							[60.1135, 25.3662],
							[60.1087, 25.3718],
							[60.0976, 25.3768],
							[60.0812, 25.3799],
							[60.0435, 25.3815],
							[59.9846, 25.3757],
							[59.9124, 25.3604],
							[59.904, 25.3568],
							[59.8968, 25.3504],
							[59.8957, 25.3554],
							[59.8996, 25.359],
							[59.8963, 25.359],
							[59.8926, 25.3554],
							[59.8921, 25.3526],
							[59.8901, 25.3521],
							[59.8857, 25.3549],
							[59.871, 25.3576],
							[59.8688, 25.364],
							[59.8596, 25.3646],
							[59.8593, 25.3662],
							[59.8557, 25.3654],
							[59.8551, 25.369],
							[59.8476, 25.3726],
							[59.8451, 25.3776],
							[59.8482, 25.3813],
							[59.8537, 25.3824],
							[59.8529, 25.3874],
							[59.8496, 25.3921],
							[59.8407, 25.3976],
							[59.8182, 25.4029],
							[59.7837, 25.4046],
							[59.7315, 25.4021],
							[59.6915, 25.3985],
							[59.6515, 25.3913],
							[59.619, 25.3893],
							[59.606, 25.3896],
							[59.5913, 25.3932],
							[59.5901, 25.3949],
							[59.5793, 25.3965],
							[59.5563, 25.406],
							[59.5493, 25.411],
							[59.5429, 25.4124],
							[59.5265, 25.4262],
							[59.5265, 25.4321],
							[59.5199, 25.4415],
							[59.5151, 25.4432],
							[59.509, 25.4493],
							[59.5038, 25.451],
							[59.4985, 25.4585],
							[59.4932, 25.4624],
							[59.4876, 25.4657],
							[59.4765, 25.4685],
							[59.451, 25.4718],
							[59.4362, 25.4721],
							[59.421, 25.4693],
							[59.4093, 25.4643],
							[59.3913, 25.4676],
							[59.3899, 25.4654],
							[59.3763, 25.4618],
							[59.346, 25.459],
							[59.3087, 25.4526],
							[59.2851, 25.4449],
							[59.269, 25.4368],
							[59.261, 25.4376],
							[59.2579, 25.4454],
							[59.2468, 25.4546],
							[59.2571, 25.458],
							[59.2632, 25.4621],
						],
					],
					[
						[
							[61.5285, 25.1821],
							[61.5321, 25.186],
							[61.534, 25.186],
							[61.5335, 25.1821],
							[61.5285, 25.1821],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "South Khorasan", name: "خراسان جنوبی" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[60.8224, 32.0266],
							[60.8017, 31.958],
							[60.7897, 31.8951],
							[60.7812, 31.8428],
							[60.7772, 31.8051],
							[60.775, 31.7425],
							[60.7826, 31.681],
							[60.7926, 31.6217],
							[60.8306, 31.5065],
							[60.8457, 31.4869],
							[61.1239, 31.4527],
							[61.1239, 31.4391],
							[61.1434, 31.3988],
							[61.1534, 31.3885],
							[61.1532, 31.3787],
							[61.1632, 31.3684],
							[61.1623, 31.3092],
							[61.141, 31.2899],
							[61.1188, 31.2508],
							[61.1184, 31.2409],
							[61.1071, 31.2213],
							[61.0962, 31.2117],
							[61.0723, 31.153],
							[61.061, 31.1435],
							[61.0473, 31.0944],
							[61.0345, 31.0652],
							[61.0332, 31.0454],
							[61.0201, 31.0162],
							[61.0187, 30.9964],
							[61.0062, 30.9771],
							[60.9992, 30.8881],
							[61.0077, 30.8579],
							[60.9938, 30.6994],
							[61.0103, 30.6389],
							[61.0075, 30.6091],
							[61.0167, 30.5887],
							[61.0148, 30.5689],
							[61.0249, 30.5584],
							[61.0239, 30.5485],
							[61.0421, 30.5077],
							[61.0401, 30.4878],
							[61.0502, 30.4774],
							[61.0572, 30.4371],
							[61.0562, 30.4271],
							[61.0441, 30.4177],
							[61.0421, 30.3978],
							[61.0199, 30.3988],
							[60.9525, 30.3921],
							[60.9193, 30.3938],
							[60.8714, 30.3852],
							[60.8368, 30.3866],
							[60.776, 30.45],
							[60.7367, 30.5118],
							[60.7176, 30.5525],
							[60.6976, 30.5832],
							[60.6879, 30.6035],
							[60.6668, 30.6243],
							[60.6447, 30.6353],
							[60.634, 30.6457],
							[60.5784, 30.678],
							[60.5675, 30.6883],
							[60.5559, 30.6888],
							[60.5449, 30.6992],
							[60.5455, 30.709],
							[60.5338, 30.7095],
							[60.5228, 30.7198],
							[60.5111, 30.7204],
							[60.4883, 30.7312],
							[60.4296, 30.7337],
							[60.4184, 30.744],
							[60.4066, 30.7445],
							[60.3275, 30.8163],
							[60.3157, 30.8168],
							[60.3033, 30.8075],
							[60.2668, 30.7894],
							[60.219, 30.7815],
							[60.183, 30.7731],
							[60.1228, 30.7559],
							[60.1105, 30.7467],
							[60.0868, 30.7476],
							[60.0026, 30.7216],
							[59.9548, 30.7136],
							[59.943, 30.7141],
							[59.9307, 30.7048],
							[59.9188, 30.7053],
							[59.8711, 30.6974],
							[59.847, 30.6886],
							[59.8112, 30.6803],
							[59.7518, 30.673],
							[59.716, 30.6553],
							[59.6806, 30.6473],
							[59.6572, 30.6483],
							[59.5981, 30.6318],
							[59.5747, 30.6328],
							[59.5627, 30.6238],
							[59.5154, 30.6067],
							[59.4925, 30.6269],
							[59.47, 30.6568],
							[59.4242, 30.6974],
							[59.3901, 30.7373],
							[59.3787, 30.7475],
							[59.3677, 30.7768],
							[59.3451, 30.8163],
							[59.3339, 30.836],
							[59.3111, 30.8658],
							[59.3113, 30.8754],
							[59.2537, 30.926],
							[59.2539, 30.9356],
							[59.2197, 30.9948],
							[59.1852, 31.0348],
							[59.1161, 31.1243],
							[59.0697, 31.1647],
							[58.9651, 31.1978],
							[58.9535, 31.1983],
							[58.8607, 31.2404],
							[58.826, 31.2514],
							[58.8028, 31.2619],
							[58.7912, 31.272],
							[58.687, 31.3049],
							[58.6292, 31.3263],
							[58.5599, 31.3482],
							[58.4791, 31.3802],
							[58.3177, 31.4537],
							[58.2716, 31.4651],
							[58.2486, 31.4757],
							[58.1795, 31.4977],
							[58.0645, 31.5506],
							[58.053, 31.5511],
							[58.007, 31.5818],
							[57.984, 31.5924],
							[57.9496, 31.6035],
							[57.9266, 31.6141],
							[57.9151, 31.6146],
							[57.8691, 31.6358],
							[57.8347, 31.6373],
							[57.8462, 31.656],
							[57.869, 31.6838],
							[57.8803, 31.7506],
							[57.9261, 31.8161],
							[57.9604, 31.8532],
							[57.9833, 31.8811],
							[58.0178, 31.9086],
							[58.0177, 31.9472],
							[58.0292, 31.9757],
							[58.0292, 31.9951],
							[58.0522, 32.0134],
							[58.1905, 32.1625],
							[58.1903, 32.2305],
							[58.2131, 32.2685],
							[58.2361, 32.287],
							[58.2476, 32.2865],
							[58.2474, 32.3059],
							[58.2356, 32.3259],
							[58.2119, 32.3755],
							[58.1765, 32.4257],
							[58.1531, 32.4462],
							[58.1521, 32.5047],
							[58.129, 32.5057],
							[58.1284, 32.535],
							[58.1052, 32.5457],
							[58.0817, 32.5662],
							[58.1158, 32.594],
							[58.1274, 32.5935],
							[58.1268, 32.6228],
							[58.1498, 32.6315],
							[58.1613, 32.631],
							[58.161, 32.6505],
							[58.1489, 32.6803],
							[58.1475, 32.7584],
							[58.2047, 32.8047],
							[58.2158, 32.8433],
							[58.2507, 32.9013],
							[58.2509, 32.9412],
							[58.264, 33.031],
							[58.2645, 33.0511],
							[58.2771, 33.0809],
							[58.3019, 33.1104],
							[58.3265, 33.1299],
							[58.3764, 33.1689],
							[58.4134, 33.1881],
							[58.4747, 33.2166],
							[58.511, 33.2256],
							[58.5594, 33.2342],
							[58.5964, 33.2533],
							[58.6329, 33.2622],
							[58.6701, 33.2813],
							[58.7334, 33.3298],
							[58.7468, 33.3496],
							[58.7731, 33.3792],
							[58.7853, 33.3788],
							[58.7868, 33.399],
							[58.8005, 33.4188],
							[58.8038, 33.4592],
							[58.8283, 33.4585],
							[58.8423, 33.4784],
							[58.8317, 33.499],
							[58.8202, 33.5096],
							[58.7112, 33.5334],
							[58.6881, 33.5545],
							[58.6773, 33.5753],
							[58.6803, 33.616],
							[58.668, 33.6164],
							[58.6572, 33.6372],
							[58.6334, 33.6481],
							[58.6211, 33.6485],
							[58.598, 33.6697],
							[58.5988, 33.6799],
							[58.6127, 33.6999],
							[58.6258, 33.7097],
							[58.6143, 33.7203],
							[58.6037, 33.7411],
							[58.6045, 33.7513],
							[58.593, 33.7619],
							[58.6195, 33.7816],
							[58.6337, 33.8017],
							[58.6471, 33.8115],
							[58.6625, 33.8418],
							[58.6188, 33.9046],
							[58.6471, 33.9346],
							[58.6631, 33.965],
							[58.6665, 33.9957],
							[58.6825, 34.0261],
							[58.6722, 34.0469],
							[58.638, 34.0787],
							[58.6289, 34.1097],
							[58.6152, 34.0998],
							[58.6323, 34.1404],
							[58.6471, 34.1606],
							[58.6732, 34.1701],
							[58.7357, 34.1683],
							[58.7971, 34.1563],
							[58.8961, 34.1431],
							[58.9555, 34.1104],
							[58.97, 34.1305],
							[58.997, 34.1502],
							[59.0115, 34.1703],
							[59.025, 34.1802],
							[59.0375, 34.1798],
							[59.0634, 34.1893],
							[59.0883, 34.1885],
							[59.1132, 34.1877],
							[59.1621, 34.1758],
							[59.2004, 34.1848],
							[59.2395, 34.204],
							[59.252, 34.2036],
							[59.2884, 34.1921],
							[59.3142, 34.2015],
							[59.3274, 34.2113],
							[59.3663, 34.2305],
							[59.392, 34.2399],
							[59.5083, 34.2976],
							[59.5323, 34.2864],
							[59.5425, 34.2552],
							[59.577, 34.2128],
							[59.5991, 34.1708],
							[59.6456, 34.1176],
							[59.6686, 34.0858],
							[59.7301, 34.0629],
							[59.8939, 34.0563],
							[59.9957, 34.0623],
							[60.0595, 34.0698],
							[60.11, 34.0571],
							[60.1611, 34.0546],
							[60.3162, 34.0568],
							[60.3952, 34.0629],
							[60.479, 34.0768],
							[60.5051, 34.0833],
							[60.5049, 34.0768],
							[60.5106, 34.0454],
							[60.5261, 34.0019],
							[60.5319, 33.9776],
							[60.5627, 33.794],
							[60.5622, 33.7911],
							[60.5264, 33.7439],
							[60.5318, 33.6502],
							[60.6728, 33.5318],
							[60.7566, 33.5301],
							[60.9, 33.5389],
							[60.9488, 33.5125],
							[60.9301, 33.4967],
							[60.9056, 33.4988],
							[60.8844, 33.5033],
							[60.8536, 33.4843],
							[60.8658, 33.4595],
							[60.8683, 33.4189],
							[60.8273, 33.398],
							[60.6368, 33.2265],
							[60.5841, 33.1275],
							[60.5949, 33.0333],
							[60.608, 33.0154],
							[60.6193, 33.0043],
							[60.6245, 32.9895],
							[60.7088, 32.7068],
							[60.8159, 32.4724],
							[60.8577, 32.2669],
							[60.8802, 32.2004],
							[60.8443, 32.0674],
							[60.8224, 32.0266],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Tehran", name: "تهران" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[51.8099, 34.9145],
							[51.7624, 34.9237],
							[51.7384, 34.9333],
							[51.6547, 34.9619],
							[51.6064, 34.9911],
							[51.5463, 35.02],
							[51.4862, 35.0489],
							[51.4745, 35.0486],
							[51.414, 35.0875],
							[51.3289, 35.1558],
							[51.305, 35.1652],
							[51.2463, 35.1636],
							[51.2224, 35.173],
							[51.1272, 35.2003],
							[51.0798, 35.2088],
							[51.0677, 35.2185],
							[50.9853, 35.2157],
							[50.9262, 35.2237],
							[50.7966, 35.2189],
							[50.7969, 35.2089],
							[50.7851, 35.2085],
							[50.7848, 35.2185],
							[50.7956, 35.249],
							[50.807, 35.2594],
							[50.8175, 35.2999],
							[50.8282, 35.3303],
							[50.8396, 35.3408],
							[50.8393, 35.3508],
							[50.8614, 35.3917],
							[50.861, 35.4017],
							[50.8717, 35.4322],
							[50.8714, 35.4422],
							[50.8828, 35.4527],
							[50.8807, 35.5127],
							[50.8686, 35.5223],
							[50.8559, 35.5518],
							[50.8539, 35.5534],
							[50.8611, 35.5769],
							[50.8958, 35.5994],
							[50.9374, 35.6098],
							[50.9617, 35.6098],
							[50.9895, 35.6011],
							[51.0224, 35.5855],
							[51.0467, 35.5786],
							[51.0762, 35.5786],
							[51.0849, 35.5838],
							[51.1057, 35.615],
							[51.1161, 35.6126],
							[51.1161, 35.6462],
							[51.1265, 35.6619],
							[51.1525, 35.6861],
							[51.1904, 35.7057],
							[51.1161, 35.733],
							[51.0987, 35.7521],
							[51.0866, 35.7746],
							[51.0745, 35.7885],
							[51.0623, 35.811],
							[51.0606, 35.8371],
							[51.0693, 35.8475],
							[51.0883, 35.8648],
							[51.1126, 35.8787],
							[51.149, 35.9064],
							[51.1785, 35.9394],
							[51.2046, 35.9515],
							[51.2497, 35.9515],
							[51.2705, 35.9446],
							[51.3086, 35.9151],
							[51.3416, 35.9134],
							[51.3881, 35.9134],
							[51.3832, 35.9325],
							[51.3676, 35.9481],
							[51.3589, 35.9602],
							[51.3589, 35.9845],
							[51.3641, 36.0018],
							[51.3798, 36.0036],
							[51.411, 36.0175],
							[51.4318, 36.04],
							[51.4318, 36.0638],
							[51.5798, 36.0682],
							[51.6035, 36.0588],
							[51.6155, 36.0491],
							[51.6388, 36.0497],
							[51.6857, 36.0409],
							[51.7089, 36.0415],
							[51.7563, 36.0225],
							[51.7684, 36.0127],
							[51.7921, 36.0032],
							[51.8405, 35.964],
							[51.8656, 35.9243],
							[51.8797, 35.8742],
							[51.8918, 35.8643],
							[51.9165, 35.8345],
							[51.917, 35.8245],
							[51.9291, 35.8146],
							[51.9418, 35.7947],
							[51.965, 35.7951],
							[51.9893, 35.7753],
							[52.001, 35.7755],
							[52.0248, 35.7659],
							[52.153, 35.768],
							[52.1641, 35.7782],
							[52.1758, 35.7784],
							[52.1865, 35.7988],
							[52.2313, 35.8399],
							[52.2421, 35.8603],
							[52.2754, 35.9012],
							[52.3205, 35.9423],
							[52.3323, 35.9425],
							[52.3549, 35.963],
							[52.378, 35.9734],
							[52.4015, 35.9738],
							[52.4128, 35.9841],
							[52.4481, 35.9846],
							[52.4724, 35.9647],
							[52.4964, 35.9549],
							[52.5321, 35.9453],
							[52.5442, 35.9353],
							[52.6268, 35.9365],
							[52.639, 35.9265],
							[52.6508, 35.9267],
							[52.663, 35.9167],
							[52.687, 35.9069],
							[52.7114, 35.887],
							[52.7233, 35.8872],
							[52.7596, 35.8675],
							[52.8664, 35.869],
							[52.878, 35.8793],
							[52.8899, 35.8795],
							[52.913, 35.9],
							[52.9368, 35.9004],
							[52.9491, 35.8905],
							[52.9494, 35.8804],
							[52.9986, 35.8407],
							[53.0105, 35.8409],
							[53.0347, 35.8312],
							[53.0362, 35.7908],
							[53.0492, 35.7607],
							[53.0619, 35.7407],
							[53.0626, 35.7205],
							[53.0888, 35.6603],
							[53.1015, 35.6403],
							[53.1037, 35.5898],
							[53.0927, 35.5695],
							[53.0932, 35.5594],
							[53.0818, 35.5491],
							[53.0586, 35.5387],
							[53.0359, 35.5182],
							[53.0127, 35.5077],
							[53.0014, 35.4975],
							[53.0019, 35.4874],
							[52.9558, 35.4665],
							[52.9451, 35.4462],
							[52.9333, 35.446],
							[52.8884, 35.405],
							[52.8766, 35.4048],
							[52.8542, 35.3843],
							[52.8313, 35.3739],
							[52.7154, 35.3419],
							[52.6919, 35.3415],
							[52.6572, 35.3309],
							[52.622, 35.3304],
							[52.5979, 35.3401],
							[52.5386, 35.3493],
							[52.5145, 35.3591],
							[52.443, 35.3782],
							[52.395, 35.3977],
							[52.3481, 35.397],
							[52.3136, 35.3864],
							[52.3019, 35.3862],
							[52.2546, 35.3956],
							[52.219, 35.4052],
							[52.1578, 35.4548],
							[52.1461, 35.4546],
							[52.1095, 35.4843],
							[52.0384, 35.5034],
							[52.0146, 35.5131],
							[52.014, 35.5232],
							[51.9775, 35.5529],
							[51.9542, 35.5525],
							[51.9431, 35.5422],
							[51.9198, 35.5418],
							[51.9076, 35.5517],
							[51.8959, 35.5515],
							[51.8833, 35.5714],
							[51.8711, 35.5813],
							[51.8488, 35.5607],
							[51.854, 35.4598],
							[51.8424, 35.4596],
							[51.8317, 35.4392],
							[51.8221, 35.3987],
							[51.8226, 35.3886],
							[51.8114, 35.3783],
							[51.8134, 35.3379],
							[51.8261, 35.318],
							[51.8271, 35.2978],
							[51.8514, 35.2781],
							[51.8885, 35.2384],
							[51.9007, 35.2285],
							[51.9262, 35.1886],
							[51.9628, 35.159],
							[51.9745, 35.1592],
							[51.9873, 35.1392],
							[51.9878, 35.1291],
							[52.0, 35.1193],
							[52.001, 35.0991],
							[51.9786, 35.0786],
							[51.9791, 35.0685],
							[51.9003, 34.9967],
							[51.8895, 34.9764],
							[51.8932, 34.8959],
							[51.8824, 34.8756],
							[51.8701, 34.8854],
							[51.8584, 34.8852],
							[51.8099, 34.9145],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "West Azarbaijan", name: "آذربایجان غربی" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[45.6979, 36.2131],
							[45.6877, 36.2021],
							[45.6424, 36.1983],
							[45.6449, 36.1783],
							[45.6574, 36.1692],
							[45.6599, 36.1492],
							[45.6486, 36.1483],
							[45.6498, 36.1383],
							[45.6296, 36.1164],
							[45.6308, 36.1064],
							[45.5751, 36.1023],
							[45.5819, 36.0525],
							[45.5627, 36.0314],
							[45.5517, 36.0309],
							[45.542, 36.0205],
							[45.4978, 36.0045],
							[45.4826, 36.0031],
							[45.4741, 35.9957],
							[45.4668, 35.9934],
							[45.4425, 35.9968],
							[45.4334, 35.9855],
							[45.4279, 35.9835],
							[45.4171, 35.9837],
							[45.4145, 35.9758],
							[45.41, 35.9728],
							[45.4024, 35.9732],
							[45.3892, 35.9771],
							[45.3803, 35.9758],
							[45.3711, 35.9807],
							[45.3611, 35.9784],
							[45.3557, 35.9822],
							[45.3493, 35.997],
							[45.3419, 36.0068],
							[45.3594, 36.032],
							[45.3638, 36.0559],
							[45.3702, 36.0646],
							[45.3871, 36.0784],
							[45.3868, 36.0853],
							[45.3739, 36.1087],
							[45.3595, 36.1198],
							[45.3547, 36.1343],
							[45.3416, 36.1448],
							[45.3407, 36.1626],
							[45.3358, 36.1754],
							[45.344, 36.1973],
							[45.3376, 36.2135],
							[45.3332, 36.2339],
							[45.3237, 36.2424],
							[45.2931, 36.2474],
							[45.2791, 36.2534],
							[45.2785, 36.2562],
							[45.2861, 36.2629],
							[45.2965, 36.2691],
							[45.3146, 36.276],
							[45.3113, 36.2807],
							[45.2982, 36.2906],
							[45.2889, 36.3025],
							[45.2834, 36.3055],
							[45.2815, 36.31],
							[45.2825, 36.3265],
							[45.2885, 36.3416],
							[45.2849, 36.3576],
							[45.2872, 36.3692],
							[45.2851, 36.3793],
							[45.2794, 36.3923],
							[45.2702, 36.4025],
							[45.2527, 36.427],
							[45.2457, 36.4317],
							[45.2381, 36.433],
							[45.2191, 36.4254],
							[45.2183, 36.4295],
							[45.2087, 36.4233],
							[45.1994, 36.4214],
							[45.189, 36.4152],
							[45.1775, 36.4137],
							[45.1621, 36.4053],
							[45.1442, 36.405],
							[45.1296, 36.408],
							[45.108, 36.4192],
							[45.0994, 36.426],
							[45.0927, 36.4348],
							[45.0918, 36.4496],
							[45.0727, 36.4684],
							[45.0697, 36.4888],
							[45.0591, 36.5032],
							[45.0369, 36.5145],
							[45.0225, 36.5239],
							[45.0191, 36.5275],
							[45.0149, 36.5364],
							[45.016, 36.5432],
							[45.0194, 36.547],
							[45.0369, 36.556],
							[45.0518, 36.5678],
							[45.0544, 36.5748],
							[45.0424, 36.5886],
							[45.0411, 36.5942],
							[45.0483, 36.6062],
							[45.0553, 36.6125],
							[45.0617, 36.6138],
							[45.0626, 36.6186],
							[45.0736, 36.6252],
							[45.0765, 36.6336],
							[45.0778, 36.6422],
							[45.0766, 36.6607],
							[45.0791, 36.6718],
							[45.0723, 36.6907],
							[45.0475, 36.7293],
							[45.0323, 36.7402],
							[45.0262, 36.7425],
							[44.9963, 36.7467],
							[44.9846, 36.7546],
							[44.9739, 36.7652],
							[44.9564, 36.7748],
							[44.939, 36.776],
							[44.9114, 36.7732],
							[44.8852, 36.7737],
							[44.8662, 36.7794],
							[44.8608, 36.7841],
							[44.8557, 36.793],
							[44.8517, 36.8049],
							[44.8522, 36.8152],
							[44.8599, 36.8344],
							[44.878, 36.8556],
							[44.8995, 36.8686],
							[44.9075, 36.8717],
							[44.9241, 36.9074],
							[44.9247, 36.9163],
							[44.9157, 36.9342],
							[44.8932, 36.9582],
							[44.9068, 36.9959],
							[44.9169, 37.0048],
							[44.9219, 37.0147],
							[44.9206, 37.0205],
							[44.9146, 37.0286],
							[44.8867, 37.0352],
							[44.8779, 37.0434],
							[44.8691, 37.0488],
							[44.8546, 37.0467],
							[44.8366, 37.0393],
							[44.8218, 37.0476],
							[44.8082, 37.0661],
							[44.7992, 37.0729],
							[44.7964, 37.0779],
							[44.7944, 37.0904],
							[44.7773, 37.1109],
							[44.7756, 37.1158],
							[44.7772, 37.1215],
							[44.7868, 37.135],
							[44.805, 37.1481],
							[44.8071, 37.1545],
							[44.805, 37.1656],
							[44.7899, 37.1792],
							[44.7877, 37.1836],
							[44.7782, 37.224],
							[44.7791, 37.2281],
							[44.7855, 37.2372],
							[44.7974, 37.2468],
							[44.8171, 37.258],
							[44.8338, 37.2745],
							[44.835, 37.2864],
							[44.8233, 37.3076],
							[44.8026, 37.3237],
							[44.7566, 37.3482],
							[44.7403, 37.3733],
							[44.7334, 37.3777],
							[44.7188, 37.383],
							[44.6958, 37.3833],
							[44.6893, 37.385],
							[44.683, 37.4018],
							[44.6745, 37.4111],
							[44.6686, 37.4215],
							[44.6624, 37.4275],
							[44.654, 37.4304],
							[44.6229, 37.435],
							[44.6075, 37.4407],
							[44.6026, 37.4443],
							[44.6103, 37.4732],
							[44.6216, 37.4779],
							[44.6245, 37.4817],
							[44.6228, 37.4912],
							[44.6116, 37.5117],
							[44.612, 37.5215],
							[44.6216, 37.5436],
							[44.6167, 37.5525],
							[44.6178, 37.5559],
							[44.6334, 37.569],
							[44.6388, 37.5756],
							[44.6385, 37.6036],
							[44.6353, 37.6084],
							[44.6298, 37.612],
							[44.6132, 37.6174],
							[44.5965, 37.6353],
							[44.587, 37.6389],
							[44.5809, 37.6465],
							[44.5812, 37.6518],
							[44.5827, 37.6563],
							[44.5912, 37.6672],
							[44.6045, 37.6747],
							[44.6122, 37.6812],
							[44.6327, 37.7028],
							[44.6383, 37.7163],
							[44.6347, 37.7263],
							[44.6301, 37.7318],
							[44.6143, 37.7412],
							[44.6106, 37.7542],
							[44.6011, 37.7627],
							[44.5971, 37.7638],
							[44.5836, 37.7611],
							[44.5637, 37.7744],
							[44.5519, 37.774],
							[44.5413, 37.7767],
							[44.5327, 37.7766],
							[44.5193, 37.771],
							[44.4985, 37.7671],
							[44.4767, 37.7655],
							[44.463, 37.7684],
							[44.4597, 37.7706],
							[44.4593, 37.774],
							[44.4698, 37.792],
							[44.4702, 37.7979],
							[44.4681, 37.8003],
							[44.4638, 37.8012],
							[44.4517, 37.7985],
							[44.4431, 37.7984],
							[44.4386, 37.8004],
							[44.4342, 37.8093],
							[44.4301, 37.8129],
							[44.4137, 37.8216],
							[44.4121, 37.8244],
							[44.4133, 37.8285],
							[44.4219, 37.8403],
							[44.422, 37.8463],
							[44.4164, 37.8541],
							[44.3771, 37.8626],
							[44.339, 37.8741],
							[44.3341, 37.8731],
							[44.3143, 37.8636],
							[44.2975, 37.868],
							[44.2763, 37.8663],
							[44.2596, 37.8805],
							[44.2389, 37.8871],
							[44.2405, 37.9036],
							[44.2455, 37.913],
							[44.266, 37.9385],
							[44.2654, 37.9427],
							[44.2564, 37.9554],
							[44.2569, 37.9595],
							[44.2681, 37.9751],
							[44.2796, 37.9871],
							[44.2799, 38.0001],
							[44.2815, 38.0033],
							[44.3135, 38.0299],
							[44.3168, 38.036],
							[44.3167, 38.0475],
							[44.3256, 38.0549],
							[44.3211, 38.0661],
							[44.3225, 38.0697],
							[44.3311, 38.0765],
							[44.3383, 38.0766],
							[44.3431, 38.0867],
							[44.3526, 38.0992],
							[44.3583, 38.1008],
							[44.3792, 38.0976],
							[44.3774, 38.1053],
							[44.3641, 38.1271],
							[44.3663, 38.1303],
							[44.3778, 38.1319],
							[44.3932, 38.1367],
							[44.4013, 38.141],
							[44.4048, 38.1449],
							[44.4068, 38.1494],
							[44.406, 38.1548],
							[44.4171, 38.2103],
							[44.4156, 38.2222],
							[44.4077, 38.2391],
							[44.4082, 38.2512],
							[44.4161, 38.261],
							[44.4222, 38.2736],
							[44.4269, 38.2782],
							[44.4616, 38.2877],
							[44.5054, 38.3184],
							[44.5082, 38.3218],
							[44.5095, 38.3279],
							[44.5054, 38.3487],
							[44.5011, 38.3537],
							[44.4799, 38.358],
							[44.4769, 38.3655],
							[44.4667, 38.379],
							[44.457, 38.3851],
							[44.4512, 38.3865],
							[44.4468, 38.3856],
							[44.4385, 38.38],
							[44.4186, 38.3739],
							[44.3993, 38.3649],
							[44.3867, 38.3635],
							[44.3828, 38.3653],
							[44.3795, 38.3737],
							[44.3765, 38.3768],
							[44.3705, 38.3775],
							[44.3548, 38.3738],
							[44.3457, 38.3774],
							[44.323, 38.3825],
							[44.3177, 38.3854],
							[44.3167, 38.3921],
							[44.3217, 38.4073],
							[44.322, 38.4153],
							[44.3167, 38.4359],
							[44.3173, 38.4416],
							[44.3263, 38.4582],
							[44.3395, 38.5028],
							[44.3308, 38.5247],
							[44.3269, 38.5501],
							[44.3275, 38.5586],
							[44.3347, 38.5835],
							[44.3368, 38.6228],
							[44.3325, 38.629],
							[44.316, 38.6361],
							[44.2882, 38.6443],
							[44.2855, 38.6474],
							[44.2851, 38.6508],
							[44.2997, 38.6674],
							[44.3012, 38.6717],
							[44.2984, 38.679],
							[44.2866, 38.6943],
							[44.2837, 38.7041],
							[44.2772, 38.716],
							[44.2955, 38.7444],
							[44.298, 38.7519],
							[44.2972, 38.7698],
							[44.3, 38.7813],
							[44.3134, 38.7929],
							[44.3186, 38.8003],
							[44.3161, 38.8339],
							[44.313, 38.8397],
							[44.3025, 38.8493],
							[44.2903, 38.8553],
							[44.262, 38.8528],
							[44.256, 38.8551],
							[44.2457, 38.8768],
							[44.2296, 38.8857],
							[44.2241, 38.8919],
							[44.222, 38.8959],
							[44.223, 38.9025],
							[44.2189, 38.9155],
							[44.2069, 38.9242],
							[44.2026, 38.931],
							[44.2034, 38.9383],
							[44.2091, 38.9498],
							[44.2085, 38.9539],
							[44.2057, 38.9561],
							[44.1935, 38.9579],
							[44.1913, 38.9599],
							[44.1903, 38.967],
							[44.1978, 38.9782],
							[44.1971, 38.9805],
							[44.1846, 38.9881],
							[44.1815, 38.9942],
							[44.1816, 39.0045],
							[44.1874, 39.0125],
							[44.211, 39.0141],
							[44.2191, 39.0213],
							[44.2188, 39.0288],
							[44.2005, 39.069],
							[44.1947, 39.0738],
							[44.1836, 39.0793],
							[44.1545, 39.0882],
							[44.1521, 39.092],
							[44.1542, 39.0981],
							[44.1724, 39.1111],
							[44.1869, 39.1163],
							[44.196, 39.1168],
							[44.2082, 39.1136],
							[44.2275, 39.0993],
							[44.2323, 39.1003],
							[44.2323, 39.1094],
							[44.2184, 39.1386],
							[44.2015, 39.1505],
							[44.1927, 39.1548],
							[44.1764, 39.1724],
							[44.1266, 39.1894],
							[44.1133, 39.2021],
							[44.1073, 39.2145],
							[44.1089, 39.2247],
							[44.1149, 39.2355],
							[44.1151, 39.2387],
							[44.1013, 39.2486],
							[44.0981, 39.2542],
							[44.0981, 39.2583],
							[44.1023, 39.2694],
							[44.1089, 39.2751],
							[44.1117, 39.2864],
							[44.109, 39.2898],
							[44.0962, 39.2923],
							[44.0879, 39.2974],
							[44.087, 39.3011],
							[44.091, 39.3216],
							[44.0885, 39.3295],
							[44.0796, 39.3374],
							[44.0614, 39.3475],
							[44.0514, 39.3567],
							[44.0473, 39.3668],
							[44.0473, 39.3764],
							[44.0493, 39.3795],
							[44.0677, 39.3868],
							[44.07, 39.3904],
							[44.0683, 39.4008],
							[44.0698, 39.4053],
							[44.0906, 39.4066],
							[44.109, 39.4045],
							[44.1326, 39.3992],
							[44.1436, 39.4002],
							[44.1459, 39.3946],
							[44.1381, 39.3798],
							[44.1421, 39.3778],
							[44.1518, 39.3772],
							[44.1546, 39.3791],
							[44.1579, 39.393],
							[44.1748, 39.4024],
							[44.1928, 39.4067],
							[44.2243, 39.4103],
							[44.2345, 39.4081],
							[44.2607, 39.394],
							[44.2734, 39.3898],
							[44.2836, 39.3834],
							[44.2934, 39.3746],
							[44.3017, 39.3715],
							[44.3137, 39.374],
							[44.3299, 39.3818],
							[44.353, 39.3875],
							[44.3726, 39.3977],
							[44.3963, 39.4066],
							[44.4237, 39.4098],
							[44.4319, 39.4179],
							[44.4411, 39.4359],
							[44.4396, 39.4485],
							[44.4327, 39.4674],
							[44.4334, 39.4731],
							[44.453, 39.4968],
							[44.4523, 39.5073],
							[44.4431, 39.5237],
							[44.4354, 39.5454],
							[44.4362, 39.5607],
							[44.4755, 39.6062],
							[44.4896, 39.6301],
							[44.4916, 39.6463],
							[44.4849, 39.6777],
							[44.487, 39.6866],
							[44.4951, 39.6949],
							[44.5132, 39.7084],
							[44.5406, 39.7223],
							[44.5876, 39.7579],
							[44.5939, 39.7647],
							[44.6066, 39.7734],
							[44.6148, 39.7762],
							[44.6235, 39.7772],
							[44.6314, 39.776],
							[44.6349, 39.7719],
							[44.6386, 39.7605],
							[44.6518, 39.738],
							[44.6564, 39.7328],
							[44.6691, 39.7235],
							[44.7219, 39.7073],
							[44.7778, 39.6609],
							[44.8138, 39.6391],
							[44.8202, 39.6252],
							[44.8556, 39.6033],
							[44.8778, 39.6107],
							[44.8819, 39.6094],
							[44.8838, 39.604],
							[44.8825, 39.5983],
							[44.8854, 39.5947],
							[44.8905, 39.595],
							[44.8961, 39.5981],
							[44.903, 39.5996],
							[44.9044, 39.5947],
							[44.8989, 39.5847],
							[44.8991, 39.5803],
							[44.9058, 39.5754],
							[44.9182, 39.5709],
							[44.9201, 39.5688],
							[44.9201, 39.5543],
							[44.9148, 39.5438],
							[44.9167, 39.541],
							[44.9258, 39.5359],
							[44.9204, 39.5275],
							[44.9226, 39.5226],
							[44.9307, 39.5158],
							[44.9454, 39.5072],
							[44.9505, 39.5013],
							[44.9535, 39.4942],
							[44.948, 39.4885],
							[44.9532, 39.4832],
							[44.9533, 39.4809],
							[44.9483, 39.4738],
							[44.9527, 39.4658],
							[44.9464, 39.4606],
							[44.951, 39.4551],
							[44.9589, 39.4504],
							[44.9785, 39.4485],
							[44.9812, 39.445],
							[44.972, 39.4411],
							[44.9688, 39.435],
							[44.9712, 39.4276],
							[44.9773, 39.4222],
							[45.0225, 39.4014],
							[45.0375, 39.3927],
							[45.0485, 39.3825],
							[45.0605, 39.378],
							[45.0615, 39.369],
							[45.0832, 39.3553],
							[45.088, 39.3494],
							[45.0882, 39.3443],
							[45.085, 39.3378],
							[45.0864, 39.3336],
							[45.0892, 39.3312],
							[45.1025, 39.3287],
							[45.1613, 39.2369],
							[45.1651, 39.2074],
							[45.1816, 39.209],
							[45.1831, 39.2187],
							[45.19, 39.2212],
							[45.2005, 39.2125],
							[45.2028, 39.2065],
							[45.2088, 39.2012],
							[45.2284, 39.2013],
							[45.2954, 39.1833],
							[45.3104, 39.1926],
							[45.3228, 39.1968],
							[45.3315, 39.1955],
							[45.3351, 39.1892],
							[45.3261, 39.1763],
							[45.3182, 39.1707],
							[45.3277, 39.16],
							[45.3483, 39.1628],
							[45.3588, 39.1578],
							[45.3633, 39.1449],
							[45.3644, 39.1325],
							[45.3956, 39.1001],
							[45.4017, 39.0884],
							[45.3991, 39.0779],
							[45.4017, 39.0672],
							[45.4077, 39.0619],
							[45.4306, 39.0508],
							[45.4467, 39.0504],
							[45.45, 39.0477],
							[45.4479, 39.0407],
							[45.4479, 39.0297],
							[45.4336, 39.0068],
							[45.4364, 38.9984],
							[45.4533, 38.9865],
							[45.466, 38.9814],
							[45.4883, 38.977],
							[45.4543, 38.9541],
							[45.4526, 38.9449],
							[45.3956, 38.9016],
							[45.3713, 38.8896],
							[45.3718, 38.8798],
							[45.3596, 38.8787],
							[45.3602, 38.8689],
							[45.3486, 38.858],
							[45.3523, 38.808],
							[45.3409, 38.797],
							[45.3314, 38.7654],
							[45.3202, 38.7542],
							[45.3267, 38.7015],
							[45.3624, 38.6364],
							[45.3629, 38.6263],
							[45.3756, 38.6169],
							[45.3762, 38.6067],
							[45.3652, 38.5855],
							[45.3663, 38.5651],
							[45.3548, 38.5541],
							[45.3554, 38.5439],
							[45.2858, 38.488],
							[45.2157, 38.4428],
							[45.1121, 38.3442],
							[45.101, 38.3122],
							[45.0892, 38.3012],
							[45.09, 38.2276],
							[45.1021, 38.2281],
							[45.1143, 38.218],
							[45.1142, 38.2073],
							[45.1628, 38.1668],
							[45.1628, 38.156],
							[45.175, 38.1459],
							[45.1872, 38.1249],
							[45.1873, 38.1139],
							[45.1996, 38.0928],
							[45.1998, 38.0708],
							[45.2124, 38.0386],
							[45.2168, 37.885],
							[45.2091, 37.8198],
							[45.2286, 37.676],
							[45.245, 37.6483],
							[45.2598, 37.63],
							[45.2611, 37.6206],
							[45.2746, 37.6118],
							[45.2772, 37.593],
							[45.2894, 37.5936],
							[45.2907, 37.5842],
							[45.3189, 37.5573],
							[45.3323, 37.5486],
							[45.3445, 37.5492],
							[45.3605, 37.5218],
							[45.3631, 37.5031],
							[45.3521, 37.4931],
							[45.3547, 37.4744],
							[45.3475, 37.4364],
							[45.339, 37.4077],
							[45.3514, 37.3143],
							[45.3649, 37.3056],
							[45.3797, 37.2877],
							[45.3933, 37.2791],
							[45.3945, 37.2697],
							[45.4204, 37.2619],
							[45.4734, 37.2369],
							[45.4857, 37.2377],
							[45.5128, 37.2206],
							[45.5658, 37.1959],
							[45.5929, 37.1789],
							[45.6187, 37.1713],
							[45.62, 37.162],
							[45.6323, 37.1628],
							[45.673, 37.1376],
							[45.6743, 37.1283],
							[45.7015, 37.1115],
							[45.7029, 37.1022],
							[45.7151, 37.1031],
							[45.7286, 37.0947],
							[45.7896, 37.0993],
							[45.8251, 37.1114],
							[45.85, 37.1133],
							[45.8736, 37.1244],
							[45.9113, 37.1271],
							[45.9392, 37.1104],
							[45.9546, 37.0928],
							[45.9559, 37.0835],
							[45.9699, 37.0752],
							[45.9713, 37.0659],
							[45.9853, 37.0576],
							[45.9867, 37.0483],
							[45.9993, 37.0492],
							[46.0134, 37.0409],
							[46.026, 37.0418],
							[46.0542, 37.0251],
							[46.057, 37.0066],
							[46.0725, 36.9891],
							[46.0739, 36.9798],
							[46.1021, 36.9632],
							[46.1148, 36.9642],
							[46.1416, 36.9568],
							[46.1558, 36.9485],
							[46.1826, 36.9412],
							[46.1982, 36.9237],
							[46.2109, 36.9246],
							[46.2123, 36.9154],
							[46.2265, 36.9071],
							[46.2422, 36.8896],
							[46.2705, 36.8731],
							[46.2832, 36.8741],
							[46.307, 36.8851],
							[46.3197, 36.8861],
							[46.3308, 36.8962],
							[46.3293, 36.9054],
							[46.342, 36.9063],
							[46.3389, 36.9247],
							[46.35, 36.9349],
							[46.3469, 36.9533],
							[46.358, 36.9634],
							[46.3706, 36.9644],
							[46.4147, 37.005],
							[46.4651, 37.0087],
							[46.487, 37.0291],
							[46.4996, 37.03],
							[46.5137, 37.0217],
							[46.5514, 37.0245],
							[46.5465, 37.0522],
							[46.5574, 37.0623],
							[46.6326, 37.0679],
							[46.661, 37.0513],
							[46.6643, 37.0328],
							[46.6786, 37.0245],
							[46.6803, 37.0153],
							[46.7179, 37.018],
							[46.7447, 37.0107],
							[46.7573, 37.0116],
							[46.7858, 36.995],
							[46.7876, 36.9858],
							[46.8019, 36.9775],
							[46.8036, 36.9683],
							[46.7819, 36.948],
							[46.7837, 36.9388],
							[46.7711, 36.9379],
							[46.7728, 36.9287],
							[46.8049, 36.8937],
							[46.8335, 36.877],
							[46.8397, 36.8396],
							[46.83, 36.82],
							[46.8091, 36.7899],
							[46.8144, 36.752],
							[46.8283, 36.7433],
							[46.9052, 36.7388],
							[46.9555, 36.742],
							[46.9568, 36.7324],
							[46.9693, 36.7332],
							[46.9705, 36.7236],
							[47.0082, 36.726],
							[47.0435, 36.7476],
							[47.1438, 36.7538],
							[47.1551, 36.7643],
							[47.1801, 36.7658],
							[47.2212, 36.739],
							[47.236, 36.7202],
							[47.2518, 36.6917],
							[47.2529, 36.6819],
							[47.2715, 36.6237],
							[47.3012, 36.5758],
							[47.3324, 36.5078],
							[47.3075, 36.5065],
							[47.2834, 36.4953],
							[47.2718, 36.4848],
							[47.2727, 36.4748],
							[47.2611, 36.4643],
							[47.2876, 36.4457],
							[47.2884, 36.4358],
							[47.2768, 36.4252],
							[47.2643, 36.4246],
							[47.2527, 36.414],
							[47.2574, 36.3544],
							[47.1951, 36.3514],
							[47.146, 36.339],
							[47.0836, 36.336],
							[47.0703, 36.3453],
							[47.0445, 36.3539],
							[47.0187, 36.3625],
							[46.9937, 36.3613],
							[46.9804, 36.3705],
							[46.9436, 36.3587],
							[46.9302, 36.3679],
							[46.9177, 36.3673],
							[46.891, 36.3856],
							[46.8902, 36.3955],
							[46.8768, 36.4046],
							[46.8508, 36.4131],
							[46.8499, 36.4229],
							[46.8373, 36.4222],
							[46.8356, 36.4418],
							[46.8222, 36.4509],
							[46.8213, 36.4606],
							[46.8087, 36.4599],
							[46.7952, 36.469],
							[46.7826, 36.4682],
							[46.7583, 36.457],
							[46.7204, 36.4548],
							[46.696, 36.4436],
							[46.6707, 36.4421],
							[46.6336, 36.4302],
							[46.5192, 36.4233],
							[46.4546, 36.4291],
							[46.378, 36.4243],
							[46.3515, 36.4323],
							[46.3259, 36.4307],
							[46.2634, 36.4073],
							[46.2506, 36.4065],
							[46.2369, 36.4153],
							[46.2241, 36.4144],
							[46.1695, 36.4495],
							[46.143, 36.4574],
							[46.1293, 36.4661],
							[46.0406, 36.4599],
							[46.0051, 36.4476],
							[45.9583, 36.425],
							[45.9471, 36.4147],
							[45.9481, 36.4051],
							[45.9256, 36.3845],
							[45.9294, 36.3463],
							[45.9443, 36.3183],
							[45.947, 36.2895],
							[45.9252, 36.2695],
							[45.9139, 36.2693],
							[45.9045, 36.26],
							[45.8825, 36.2598],
							[45.8148, 36.2682],
							[45.748, 36.2662],
							[45.7156, 36.2547],
							[45.6816, 36.2523],
							[45.6828, 36.2423],
							[45.6954, 36.2332],
							[45.6979, 36.2131],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Yazd", name: "یزد" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[53.6381, 30.7651],
							[53.6266, 30.785],
							[53.6035, 30.8346],
							[53.6035, 30.8445],
							[53.5804, 30.8842],
							[53.5574, 30.9139],
							[53.5342, 30.9635],
							[53.5226, 30.9834],
							[53.4525, 31.1322],
							[53.4403, 31.1918],
							[53.417, 31.2216],
							[53.4166, 31.2514],
							[53.4048, 31.2712],
							[53.3932, 31.2811],
							[53.3931, 31.2911],
							[53.3695, 31.3308],
							[53.3463, 31.3506],
							[53.3345, 31.3705],
							[53.2295, 31.4597],
							[53.1599, 31.4992],
							[53.1252, 31.509],
							[53.1018, 31.5288],
							[53.0904, 31.5288],
							[53.0211, 31.5484],
							[52.9747, 31.5681],
							[52.9401, 31.5779],
							[52.9286, 31.5779],
							[52.894, 31.5877],
							[52.8708, 31.5975],
							[52.8697, 31.6372],
							[52.858, 31.647],
							[52.846, 31.6668],
							[52.8336, 31.6965],
							[52.8216, 31.7163],
							[52.8193, 31.7955],
							[52.8297, 31.8352],
							[52.8409, 31.8452],
							[52.8382, 31.9343],
							[52.8487, 31.9641],
							[52.8596, 31.984],
							[52.8911, 32.0732],
							[52.8903, 32.0929],
							[52.9015, 32.1029],
							[52.9, 32.1423],
							[52.9108, 32.1621],
							[52.9085, 32.2213],
							[52.8954, 32.2607],
							[52.8924, 32.3397],
							[52.8791, 32.389],
							[52.8753, 32.4978],
							[52.8866, 32.5077],
							[52.8975, 32.5276],
							[52.9091, 32.5277],
							[52.932, 32.5378],
							[52.9784, 32.5382],
							[53.0245, 32.5484],
							[53.0474, 32.5585],
							[53.0587, 32.5685],
							[53.0812, 32.5984],
							[53.1042, 32.6086],
							[53.1158, 32.6086],
							[53.15, 32.6387],
							[53.1733, 32.6389],
							[53.231, 32.6692],
							[53.2776, 32.6695],
							[53.3008, 32.6797],
							[53.3476, 32.6701],
							[53.3709, 32.6703],
							[53.4412, 32.6509],
							[53.4997, 32.6414],
							[53.5348, 32.6416],
							[53.5817, 32.622],
							[53.6169, 32.6123],
							[53.7105, 32.613],
							[53.7924, 32.6335],
							[53.8629, 32.664],
							[53.8748, 32.6741],
							[53.9103, 32.6844],
							[53.922, 32.6845],
							[53.9932, 32.7151],
							[54.0881, 32.7459],
							[54.1597, 32.7865],
							[54.2667, 32.8174],
							[54.2904, 32.8176],
							[54.338, 32.8279],
							[54.3498, 32.828],
							[54.3974, 32.8384],
							[54.4569, 32.8488],
							[54.5044, 32.8591],
							[54.6472, 32.9],
							[54.6591, 32.9001],
							[54.7067, 32.9105],
							[54.7783, 32.941],
							[54.8024, 32.9612],
							[54.8383, 32.9815],
							[54.8505, 33.0016],
							[54.8745, 33.0218],
							[54.899, 33.0721],
							[54.9113, 33.1023],
							[54.9233, 33.1124],
							[54.9359, 33.1626],
							[54.9603, 33.213],
							[54.9604, 33.223],
							[54.9728, 33.2632],
							[54.9733, 33.3033],
							[54.9852, 33.3134],
							[54.9855, 33.3435],
							[54.9975, 33.3636],
							[54.9976, 33.3736],
							[55.0572, 33.4244],
							[55.1046, 33.425],
							[55.1876, 33.4361],
							[55.235, 33.4268],
							[55.2469, 33.437],
							[55.2826, 33.4757],
							[55.2946, 33.4952],
							[55.3183, 33.5242],
							[55.3302, 33.5338],
							[55.3421, 33.5532],
							[55.3421, 33.5631],
							[55.3539, 33.5825],
							[55.3776, 33.6312],
							[55.3894, 33.6507],
							[55.3893, 33.6901],
							[55.4011, 33.7095],
							[55.3996, 33.9757],
							[55.3874, 34.0352],
							[55.387, 34.1834],
							[55.375, 34.2133],
							[55.3753, 34.3518],
							[55.3868, 34.3517],
							[55.4093, 34.3613],
							[55.4439, 34.3608],
							[55.5241, 34.3695],
							[55.5587, 34.3588],
							[55.7774, 34.3546],
							[55.8121, 34.3641],
							[55.8238, 34.374],
							[55.8354, 34.3738],
							[55.8587, 34.4037],
							[55.8586, 34.4138],
							[55.87, 34.4236],
							[55.8813, 34.4433],
							[55.8927, 34.453],
							[55.904, 34.4727],
							[55.9154, 34.4825],
							[55.9267, 34.5021],
							[55.9724, 34.561],
							[55.984, 34.5707],
							[55.9954, 34.5904],
							[56.007, 34.6002],
							[56.0302, 34.6297],
							[56.0416, 34.6593],
							[56.0769, 34.6986],
							[56.1244, 34.7677],
							[56.2105, 34.9061],
							[56.2958, 34.9148],
							[56.3448, 34.924],
							[56.4187, 34.9427],
							[56.5175, 34.961],
							[56.5923, 34.9797],
							[56.7192, 35.0275],
							[56.8108, 35.0759],
							[56.942, 35.1236],
							[57.0083, 35.1524],
							[57.1135, 35.1903],
							[57.1912, 35.2087],
							[57.279, 35.2068],
							[57.3531, 35.1951],
							[57.3998, 35.164],
							[57.5057, 35.1015],
							[57.5535, 35.0804],
							[57.6819, 34.9974],
							[57.7103, 34.9166],
							[57.7205, 34.8964],
							[57.7193, 34.8864],
							[57.6784, 34.8572],
							[57.6499, 34.8278],
							[57.5952, 34.7889],
							[57.5394, 34.7399],
							[57.4067, 34.6625],
							[57.305, 34.6246],
							[57.2291, 34.5861],
							[57.2029, 34.5563],
							[57.1646, 34.5268],
							[57.1113, 34.4467],
							[57.0955, 34.3858],
							[57.0674, 34.315],
							[57.0271, 34.2343],
							[57.0255, 34.2036],
							[57.0105, 34.1427],
							[56.984, 34.0822],
							[56.9823, 34.0414],
							[56.9924, 34.0003],
							[57.0113, 33.8776],
							[57.022, 33.8468],
							[57.0432, 33.7649],
							[57.0538, 33.7037],
							[57.0648, 33.6528],
							[57.0995, 33.6214],
							[57.1574, 33.5893],
							[57.2505, 33.5662],
							[57.3207, 33.564],
							[57.4032, 33.5818],
							[57.463, 33.6105],
							[57.5365, 33.6695],
							[57.5726, 33.6787],
							[57.5935, 33.6068],
							[57.6165, 33.5858],
							[57.6388, 33.5445],
							[57.6619, 33.5236],
							[57.6717, 33.4522],
							[57.6694, 33.3209],
							[57.6804, 33.22],
							[57.6918, 33.1598],
							[57.7276, 33.0305],
							[57.7626, 32.9997],
							[57.8089, 32.9879],
							[57.8671, 32.956],
							[57.9137, 32.9247],
							[58.0297, 32.9001],
							[58.0764, 32.8688],
							[58.0887, 32.8195],
							[58.0892, 32.7902],
							[58.1011, 32.7702],
							[58.1477, 32.7486],
							[58.1489, 32.6803],
							[58.161, 32.6505],
							[58.1613, 32.631],
							[58.1498, 32.6315],
							[58.1268, 32.6228],
							[58.1274, 32.5935],
							[58.1158, 32.594],
							[58.0817, 32.5662],
							[58.1052, 32.5457],
							[58.1284, 32.535],
							[58.129, 32.5057],
							[58.1521, 32.5047],
							[58.1531, 32.4462],
							[58.1765, 32.4257],
							[58.2119, 32.3755],
							[58.2356, 32.3259],
							[58.2474, 32.3059],
							[58.2476, 32.2865],
							[58.2361, 32.287],
							[58.2131, 32.2685],
							[58.1903, 32.2305],
							[58.1905, 32.1625],
							[58.0522, 32.0134],
							[58.0292, 31.9951],
							[58.0292, 31.9757],
							[58.0177, 31.9472],
							[58.0178, 31.9086],
							[57.9833, 31.8811],
							[57.9604, 31.8532],
							[57.9261, 31.8161],
							[57.8803, 31.7506],
							[57.869, 31.6838],
							[57.8462, 31.656],
							[57.8347, 31.6373],
							[57.8232, 31.6474],
							[57.7773, 31.6591],
							[57.7658, 31.6596],
							[57.7199, 31.6712],
							[57.697, 31.6818],
							[57.6397, 31.7036],
							[57.6052, 31.7147],
							[57.5134, 31.7574],
							[57.4675, 31.7691],
							[57.4215, 31.7904],
							[57.3871, 31.792],
							[57.364, 31.8123],
							[57.272, 31.8552],
							[57.2261, 31.8669],
							[57.1686, 31.8889],
							[57.1226, 31.9007],
							[57.0536, 31.9329],
							[56.9501, 31.9667],
							[56.9272, 31.9678],
							[56.8927, 31.9791],
							[56.8581, 32.0001],
							[56.8237, 32.0114],
							[56.8121, 32.0216],
							[56.7892, 32.0227],
							[56.7662, 32.0335],
							[56.7433, 32.0346],
							[56.6859, 32.047],
							[56.6163, 32.0493],
							[56.6276, 32.029],
							[56.6155, 32.0189],
							[56.6152, 32.0087],
							[56.5909, 31.9885],
							[56.5791, 31.9885],
							[56.5548, 31.9683],
							[56.5072, 31.9584],
							[56.4954, 31.9585],
							[56.4833, 31.9484],
							[56.4115, 31.9183],
							[56.3511, 31.8679],
							[56.3272, 31.8579],
							[56.3149, 31.8377],
							[56.2666, 31.7975],
							[56.2189, 31.7775],
							[56.1592, 31.7475],
							[56.1234, 31.7275],
							[56.0758, 31.7076],
							[56.0402, 31.6977],
							[56.0044, 31.6777],
							[55.9806, 31.6677],
							[55.874, 31.638],
							[55.8267, 31.6282],
							[55.7555, 31.5983],
							[55.7196, 31.5683],
							[55.7194, 31.5582],
							[55.7073, 31.5381],
							[55.7069, 31.518],
							[55.718, 31.4877],
							[55.7155, 31.3671],
							[55.7139, 31.2969],
							[55.7015, 31.2669],
							[55.6988, 31.1467],
							[55.6866, 31.1267],
							[55.6741, 31.0868],
							[55.6739, 31.0768],
							[55.6504, 31.067],
							[55.6386, 31.057],
							[55.6269, 31.0571],
							[55.5685, 31.0375],
							[55.4524, 31.018],
							[55.3483, 31.0185],
							[55.3137, 31.0286],
							[55.233, 31.0389],
							[55.1869, 31.049],
							[55.1061, 31.0493],
							[55.0483, 31.0396],
							[55.0368, 31.0396],
							[55.0021, 31.0298],
							[54.9097, 31.0102],
							[54.8866, 31.0003],
							[54.8175, 30.9906],
							[54.783, 30.9907],
							[54.7484, 30.9808],
							[54.6794, 30.981],
							[54.645, 30.9911],
							[54.599, 30.9912],
							[54.576, 30.9813],
							[54.5414, 30.9516],
							[54.5413, 30.9417],
							[54.4607, 30.8723],
							[54.4491, 30.8326],
							[54.4377, 30.8327],
							[54.4261, 30.8128],
							[54.4261, 30.8029],
							[54.4146, 30.793],
							[54.4145, 30.7732],
							[54.403, 30.7633],
							[54.4029, 30.7236],
							[54.3913, 30.7038],
							[54.3912, 30.6641],
							[54.4026, 30.6541],
							[54.4139, 30.6342],
							[54.4593, 30.5845],
							[54.4821, 30.5646],
							[54.5047, 30.5249],
							[54.5046, 30.5149],
							[54.5159, 30.4951],
							[54.5158, 30.4753],
							[54.5268, 30.4158],
							[54.5264, 30.3663],
							[54.5377, 30.3464],
							[54.5376, 30.3365],
							[54.5489, 30.3266],
							[54.617, 30.2966],
							[54.617, 30.2867],
							[54.6283, 30.2767],
							[54.628, 30.247],
							[54.6163, 30.2075],
							[54.6161, 30.1877],
							[54.5929, 30.1384],
							[54.5906, 29.9209],
							[54.6017, 29.9011],
							[54.613, 29.8912],
							[54.6127, 29.8714],
							[54.6238, 29.8516],
							[54.6237, 29.8418],
							[54.6007, 29.8221],
							[54.4873, 29.8226],
							[54.4872, 29.8128],
							[54.4758, 29.8128],
							[54.4416, 29.7932],
							[54.4189, 29.7933],
							[54.3738, 29.8133],
							[54.3514, 29.8331],
							[54.3062, 29.853],
							[54.2724, 29.863],
							[54.2611, 29.8729],
							[54.2272, 29.8829],
							[54.182, 29.893],
							[54.1708, 29.9029],
							[54.1257, 29.9327],
							[54.1031, 29.9426],
							[54.092, 29.9624],
							[54.0694, 29.9822],
							[54.0582, 30.002],
							[54.047, 30.0119],
							[54.0471, 30.0317],
							[54.036, 30.0811],
							[54.0363, 30.1701],
							[54.0251, 30.2196],
							[54.0139, 30.2493],
							[54.0026, 30.2889],
							[53.98, 30.3187],
							[53.9346, 30.3584],
							[53.9119, 30.3881],
							[53.821, 30.4676],
							[53.8096, 30.4874],
							[53.7982, 30.5172],
							[53.7754, 30.5469],
							[53.764, 30.5568],
							[53.7411, 30.5965],
							[53.7069, 30.6461],
							[53.6954, 30.6759],
							[53.6725, 30.6957],
							[53.6496, 30.7255],
							[53.6496, 30.7354],
							[53.6381, 30.7651],
						],
					],
				],
			},
		},
		{
			properties: { "name:en": "Zanjan", name: "زنجان" },
			geometry: {
				type: "MultiPolygon",
				coordinates: [
					[
						[
							[48.4733, 35.6364],
							[48.4245, 35.635],
							[48.401, 35.614],
							[48.3887, 35.6136],
							[48.377, 35.6032],
							[48.3525, 35.6025],
							[48.3276, 35.6119],
							[48.3031, 35.6112],
							[48.2904, 35.621],
							[48.2781, 35.6206],
							[48.2654, 35.6304],
							[48.2649, 35.6406],
							[48.2522, 35.6504],
							[48.2262, 35.6801],
							[48.2257, 35.6903],
							[48.2125, 35.7102],
							[48.2115, 35.7306],
							[48.1987, 35.7404],
							[48.1865, 35.7401],
							[48.186, 35.7502],
							[48.1732, 35.7601],
							[48.1103, 35.7889],
							[48.0344, 35.8276],
							[48.0093, 35.837],
							[47.9333, 35.8755],
							[47.9081, 35.8849],
							[47.8958, 35.8845],
							[47.8952, 35.8946],
							[47.8823, 35.9044],
							[47.8806, 35.9348],
							[47.9047, 35.9458],
							[47.9041, 35.9559],
							[47.9282, 35.9668],
							[47.9634, 35.9984],
							[47.9587, 36.0796],
							[47.9698, 36.1003],
							[47.9692, 36.1105],
							[47.9809, 36.1211],
							[47.9778, 36.1718],
							[47.9649, 36.1816],
							[47.963, 36.212],
							[47.9494, 36.2319],
							[47.9364, 36.2416],
							[47.9344, 36.272],
							[47.9207, 36.2918],
							[47.9201, 36.3019],
							[47.9324, 36.3024],
							[47.9187, 36.3222],
							[47.9167, 36.3526],
							[47.9043, 36.3521],
							[47.9029, 36.3723],
							[47.8906, 36.3718],
							[47.8892, 36.3921],
							[47.8761, 36.4017],
							[47.8637, 36.4012],
							[47.8506, 36.4108],
							[47.7632, 36.4173],
							[47.675, 36.4337],
							[47.6378, 36.4321],
							[47.5874, 36.4399],
							[47.575, 36.4393],
							[47.5355, 36.4676],
							[47.5098, 36.4765],
							[47.4725, 36.4747],
							[47.4112, 36.4617],
							[47.3739, 36.4599],
							[47.3607, 36.4693],
							[47.3598, 36.4792],
							[47.3465, 36.4885],
							[47.3324, 36.5078],
							[47.3012, 36.5758],
							[47.2715, 36.6237],
							[47.2529, 36.6819],
							[47.2518, 36.6917],
							[47.236, 36.7202],
							[47.2212, 36.739],
							[47.1938, 36.7569],
							[47.1776, 36.7851],
							[47.1737, 36.814],
							[47.1849, 36.8243],
							[47.1933, 36.8538],
							[47.2155, 36.8745],
							[47.225, 36.8943],
							[47.2688, 36.9354],
							[47.3156, 36.9576],
							[47.3282, 36.9584],
							[47.3498, 36.9788],
							[47.348, 36.9881],
							[47.3799, 37.0185],
							[47.3779, 37.0277],
							[47.4096, 37.0581],
							[47.4452, 37.0699],
							[47.4557, 37.08],
							[47.4808, 37.0817],
							[47.5038, 37.0927],
							[47.5248, 37.1129],
							[47.5332, 37.1323],
							[47.5457, 37.1332],
							[47.5686, 37.1442],
							[47.602, 37.1653],
							[47.6645, 37.1696],
							[47.675, 37.1798],
							[47.725, 37.1831],
							[47.7396, 37.1747],
							[47.8148, 37.1797],
							[47.8377, 37.1906],
							[47.8503, 37.1915],
							[47.8858, 37.2033],
							[47.9401, 37.1878],
							[47.9777, 37.1903],
							[47.9924, 37.1817],
							[48.0803, 37.1873],
							[48.1411, 37.2005],
							[48.1662, 37.2021],
							[48.1935, 37.1943],
							[48.2082, 37.1857],
							[48.2334, 37.1872],
							[48.2482, 37.1786],
							[48.2755, 37.1707],
							[48.3007, 37.1722],
							[48.3281, 37.1643],
							[48.366, 37.1665],
							[48.387, 37.1868],
							[48.4206, 37.2077],
							[48.4311, 37.2178],
							[48.4563, 37.2192],
							[48.4668, 37.2293],
							[48.5174, 37.2322],
							[48.5322, 37.2235],
							[48.5493, 37.2054],
							[48.57, 37.1681],
							[48.5988, 37.15],
							[48.6492, 37.1525],
							[48.642, 37.1912],
							[48.6506, 37.2109],
							[48.6485, 37.2203],
							[48.6738, 37.2216],
							[48.718, 37.195],
							[48.7433, 37.1963],
							[48.7753, 37.2269],
							[48.7732, 37.2363],
							[48.8113, 37.2383],
							[48.8261, 37.2294],
							[48.8281, 37.2198],
							[48.8572, 37.2017],
							[48.8825, 37.2028],
							[48.9095, 37.1942],
							[48.9238, 37.1848],
							[48.9364, 37.1854],
							[48.9505, 37.176],
							[48.952, 37.166],
							[48.9646, 37.1665],
							[48.9785, 37.157],
							[49.005, 37.1478],
							[49.0338, 37.1183],
							[49.0736, 37.0991],
							[49.0871, 37.0893],
							[49.115, 37.0592],
							[49.1284, 37.0492],
							[49.1427, 37.0289],
							[49.1826, 36.9988],
							[49.1842, 36.978],
							[49.1982, 36.9575],
							[49.2275, 36.8957],
							[49.2328, 36.8232],
							[49.2458, 36.8131],
							[49.1975, 36.8017],
							[49.1606, 36.8009],
							[49.0999, 36.7893],
							[49.0662, 36.7471],
							[49.044, 36.7155],
							[48.9985, 36.6628],
							[49.0019, 36.6113],
							[49.0278, 36.5914],
							[49.0659, 36.5718],
							[49.0911, 36.5621],
							[49.1168, 36.5423],
							[49.1068, 36.5009],
							[49.1207, 36.4705],
							[49.1217, 36.4501],
							[49.1345, 36.4401],
							[49.1589, 36.4408],
							[49.1843, 36.421],
							[49.1974, 36.4009],
							[49.2091, 36.4114],
							[49.2578, 36.4127],
							[49.2597, 36.3718],
							[49.2835, 36.3827],
							[49.333, 36.3636],
							[49.3226, 36.3225],
							[49.3494, 36.2622],
							[49.3992, 36.2333],
							[49.3891, 36.1822],
							[49.4031, 36.1319],
							[49.416, 36.1121],
							[49.4049, 36.0813],
							[49.3564, 36.0796],
							[49.3085, 36.0576],
							[49.2595, 36.066],
							[49.1639, 36.0223],
							[49.1274, 36.0211],
							[49.0914, 36.0099],
							[49.0561, 35.9783],
							[49.033, 35.9471],
							[49.0225, 35.9062],
							[49.0602, 35.8769],
							[49.0359, 35.8761],
							[48.9982, 35.9054],
							[48.9614, 35.9145],
							[48.924, 35.9338],
							[48.9245, 35.9236],
							[48.7905, 35.9198],
							[48.7544, 35.9086],
							[48.7431, 35.8879],
							[48.744, 35.8676],
							[48.72, 35.8568],
							[48.6703, 35.8757],
							[48.6463, 35.8648],
							[48.6346, 35.8543],
							[48.5847, 35.8732],
							[48.5866, 35.8326],
							[48.6125, 35.8028],
							[48.7249, 35.7451],
							[48.7388, 35.705],
							[48.7644, 35.6753],
							[48.8025, 35.6358],
							[48.8055, 35.565],
							[48.7691, 35.5639],
							[48.7209, 35.5524],
							[48.6723, 35.551],
							[48.5858, 35.5788],
							[48.561, 35.5882],
							[48.5231, 35.6175],
							[48.4733, 35.6364],
						],
					],
				],
			},
		},
	],
};
